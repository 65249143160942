import React, { Fragment, useState } from 'react';
import { UserDetailsProps } from './interface';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { functions } from './helpers';
import { AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai';
import './styles.scss';

const UserDetails: React.FC<UserDetailsProps> = ({ config, item, details }) => {
  const [tokenIsVisible, setTokenIsVisible] = useState<boolean>(false);

  const toggleShowToken = () => {
    setTokenIsVisible(!tokenIsVisible);
  };

  return (
    <div className="jumbotron" id="dev-user-accordion-details--1">
      <div id="top--section--2">
        <h6 className="display-5">{config?.strings?.main}</h6>
        <p className="lead smlead">
          {config?.strings?.lead}
          <span className="stronger-ft">{item?.client_user}</span>.
        </p>
      </div>

      <div className="card" id="spec-margin-card--2">
        <div className="card-body">
          <h5 className="card-title ct-csubt">{config?.strings?.cardTitle}</h5>
          <h6 className="card-subtitle text-muted ct-csubt">
            <IoInformationCircleOutline className="the-icon" />{' '}
            {config?.strings?.cardSubtitle}
          </h6>

          <div
            className="card-text"
            style={{ position: 'relative', zIndex: 2 }}
          >
            <div className="container pdg">
              <div className="row mt-15p">
                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 information-spec-cols">
                  {Object.entries<any>(details).map(([key, value], idx) => {
                    return (
                      <div
                        className="col-xs-12 col-sm-12 information-spec-cols"
                        key={idx}
                      >
                        <Fragment key={key}>
                          <span className="lead smaller-lead-ft--1">
                            {functions.returnLabel(key)}
                          </span>

                          {functions.keyIsDate(key) ? (
                            <span className="lead smaller-lead-ft--1">
                              {functions.formatDate(value, key)}
                            </span>
                          ) : (
                            <span className={`lead smaller-lead-ft--1`}>
                              {value}
                            </span>
                          )}
                        </Fragment>
                      </div>
                    );
                  })}
                </div>

                <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 information-spec-cols">
                  {Object.entries<any>(item)
                    .slice(
                      0,
                      Math.ceil(Object.entries<any>(item).length / 2) + 2,
                    )
                    .map(([key, value], idx) => {
                      return (
                        <div
                          className="col-xs-12 col-sm-12 information-spec-cols"
                          key={idx}
                        >
                          <Fragment key={key}>
                            <span className="lead smaller-lead-ft--1">
                              {key === 'client_secret' ? (
                                <span
                                  className="badge badge-info spec-info-bdg with-pointer"
                                  onClick={toggleShowToken}
                                >
                                  {tokenIsVisible ? (
                                    <>
                                      <AiOutlineEyeInvisible
                                        style={{
                                          marginBottom: '0.006rem',
                                          marginRight: '4px',
                                        }}
                                      />
                                      Hide{' '}
                                    </>
                                  ) : (
                                    <>
                                      <AiOutlineEye
                                        style={{
                                          marginBottom: '0.002rem',
                                          marginRight: '4px',
                                        }}
                                      />
                                      Show{' '}
                                    </>
                                  )}
                                  Client Secret
                                </span>
                              ) : (
                                functions.returnLabel(key)
                              )}
                            </span>

                            {functions.keyIsDate(key) ? (
                              <span className="lead smaller-lead-ft--1">
                                {functions.formatDate(value, key)}
                              </span>
                            ) : key === 'client_secret' ? (
                              tokenIsVisible ? (
                                <span className={`lead smaller-lead-ft--1`}>
                                  {item?.client_secret}
                                </span>
                              ) : (
                                ''
                              )
                            ) : (
                              <span className={`lead smaller-lead-ft--1`}>
                                {value}
                              </span>
                            )}
                          </Fragment>
                        </div>
                      );
                    })}
                </div>
                <div className="col-xs-12 col-sm-12 col-md-4 information-spec-cols">
                  {Object.entries<any>(item)
                    .slice(Math.ceil(Object.entries<any>(item).length / 2) + 2)
                    .map(([key, value], idx) => {
                      return (
                        <div
                          className="col-xs-12 col-sm-12 information-spec-cols"
                          key={idx}
                        >
                          <Fragment key={key}>
                            {key === 'client_secret' ? (
                              <span
                                className="badge badge-info spec-info-bdg with-pointer"
                                onClick={toggleShowToken}
                              >
                                {tokenIsVisible ? (
                                  <>
                                    <AiOutlineEyeInvisible
                                      style={{
                                        marginBottom: '0.006rem',
                                        marginRight: '4px',
                                      }}
                                    />
                                    Hide{' '}
                                  </>
                                ) : (
                                  <>
                                    <AiOutlineEye
                                      style={{
                                        marginBottom: '0.002rem',
                                        marginRight: '4px',
                                      }}
                                    />
                                    Show{' '}
                                  </>
                                )}
                                Client Secret
                              </span>
                            ) : (
                              <span className="lead smaller-lead-ft--1">
                                {functions.returnLabel(key)}
                              </span>
                            )}

                            <span className="lead smaller-lead-ft--1">
                              {functions.keyIsDate(key)
                                ? functions.formatDate(value, key)
                                : key === 'client_secret' && !tokenIsVisible
                                ? ''
                                : value}
                            </span>
                          </Fragment>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default UserDetails;
