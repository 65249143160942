import React, { useState, useEffect, useRef } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { SystemDetailsProps, Cdvs, DeviceData } from './interface';
import { functions } from './helpers';
import { formatInTimeZone } from 'date-fns-tz';
import { HoursOfOperation, VentilationSetup, SystemId } from './components';
import {
  BackSection,
  Button,
  Modal,
  Spinner,
} from 'business/modules/admin/common';
import { HiOutlineXCircle } from 'react-icons/hi';
import { CiWarning } from 'react-icons/ci';
import { RiEdit2Line, RiSave3Fill } from 'react-icons/ri';
import './styles.scss';

const SystemDetails: React.FC<SystemDetailsProps> = ({
  selectVentSetup,
  currSystemDetails,
  loadedSysDetails,
  saveSystemDetails,
  connectedDevsDesignations,
  designationsAreLoaded,
  sensorsList,
  sensorsAreLoaded,
  showSensorsListPerAccount,
  saveList,
  showSystemsListPerAccount,
  getDeviceSettings,
  inputValues,
  setInputValues,
  devStatus,
  setDevStatus,
  ventSetupValues,
  setVentSetupValues,
  hoursOfOp,
  setHoursOfOp,
  storeSystemSelectData,
  setSystemHours,
  timeToStartStop,
  setErrors,
  errors,
  saveOriginalVSet,
  devDetailsAreLoaded,
  setDevicesDetails,
  markPrimaryCtrAsVC,
  getAdminSystemSettingsList,
  getNoMergedAdminSystemSettings,
  systemsList,
  mergedSystems,
  mergedSystemsAreLoaded,
  setUpdateQuitting,
  systemKey,
  selectTimezone,
  timeinHoursAndMins,
  setTimeInHoursAndMins,
  loading,
  changeTab,
  defaultIcon,
  setIconToShow,
  time,
  setTimezoneStr,
  defCurrentSelectBox,
  setDropdownsSelections,
  signalUpdate,
  setSystemKey,
  userQuitSystemUpdate,
}) => {
  const systemSetupRef = useRef<any>(null);
  const [settingsMatch, setSettingsMatch]: any = useState<boolean>(true);
  const [matchWasAssessed, setMatchWasAssessed] = useState<boolean>(false);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [devName, setDevName] = useState<string>('');
  const [systemDetails, setSystemDetails] = useState<any>({});
  const [primaryControl, setPrimaryControl] = useState<any>([]);
  const [sensorsLoaded, setSensorsLoaded] = useState<boolean>(false);
  const [addSensorLabels, setAddSensorsLabels] = useState<any>([]);
  const [loadedList, setLoadedList] = useState<boolean>(false);
  const [currentVentSetup, setCurrentVentSetup] = useState<string>(
    'Calculated Flow',
  );
  const [originalVSet, setOriginalVentSet] = useState<string>(
    'Calculated Flow',
  );
  const [sensorId, setSensorId] = useState<string>('');
  const [sensorNm, setSensorNm] = useState<string>('');
  const [primaryDefault, setPrimaryDefault] = useState<any>({});
  const [cdvs, setCdvs]: any = useState<Cdvs[]>([]);
  const [modeAfterComparison, setModeAfterComparison] = useState<string>('');
  const [settingsObj, setSettingsObject]: any = useState<any>();
  const [sensorDevices, setSensorDevices]: any = useState<DeviceData[]>();
  const [emptyFields, setEmptyFields] = useState<any>([]);
  const [errorsList, setErrorsList] = useState<any>([]);

  const fanConnect: Cdvs = {
    cail: 'FanConnect',
    cdid: 'fanConnect',
    dmac: '',
    vent: 'FanConnect',
  };

  const primaryCtrlIsFC: any =
    primaryControl &&
    primaryControl.length > 0 &&
    primaryControl[0] === 'FanConnect';

  const toggleEdit = () => {
    setDisabled(!disabled);
    setUpdateQuitting(false);
  };

  const loadDefaultValues = () => {
    if (loadedSysDetails) {
      let timeHourMins: any = functions.handleTz(currSystemDetails);
      setTimeInHoursAndMins(timeHourMins);

      let inputs: any = functions.handleInputs(inputValues, currSystemDetails);
      setInputValues(inputs);

      let vSetup: any = functions.setVSetup(ventSetupValues, currSystemDetails);

      setVentSetupValues(vSetup);

      setDevStatus(currSystemDetails.status === 'active' ? true : false);

      setHoursOfOp(
        currSystemDetails?.details?.settings?.conf?.hroa === 1 ? true : false,
      );

      let sysTime: any = functions.handleTime(
        timeToStartStop,
        currSystemDetails,
      );

      setSystemHours(sysTime);

      let sysData: any = functions.setSysDetailsState(
        sensorNm,
        sensorId,
        currSystemDetails,
        config.ventOptions,
        primaryControl,
        addSensorLabels,
        devStatus,
        hoursOfOp,
        config.tz,
      );

      setSystemDetails(sysData);
    }
  };

  const compareSystemSettings = async (
    deviceId: string,
    currSettings: any,
    allSensors: any,
    ventMode?: any,
  ) => {
    let hasDiffs: boolean = false;

    let result: any = await getNoMergedAdminSystemSettings({
      id: localStorage.getItem('currentAccount'),
    });

    if (result && result.length > 0) {
      const devSettings: any = functions.getDevSets(result, deviceId);

      let currDevSettings: any = functions.getCurrSets(devSettings);

      if (currSettings && currDevSettings) {
        const currentMode: string = functions.determineCurrMode(
          ventMode,
          currDevSettings,
        );

        setModeAfterComparison(currentMode);

        const currentModeTags: any = functions.mapVentSetupMode(currentMode);

        Object.keys(currDevSettings).forEach((key: string) => {
          if (currDevSettings.hasOwnProperty(key)) {
            if (!key.includes('_')) {
              const thereAreDiffs = functions.validateDiffs(
                config._config,
                currSettings,
                currDevSettings,
                key,
                currentModeTags,
              );

              if (thereAreDiffs) {
                hasDiffs = thereAreDiffs;
              }
            } else {
              functions.handleSensors(key, currDevSettings, allSensors);
            }
          }
        });

        setSettingsMatch(!hasDiffs);
        setMatchWasAssessed(true);
      }
    }
  };

  const onChangeValues = (event: any, id?: string) => {
    const name = event.target.name;
    const value = event.target.value;

    if (name === 'hourStop') {
      setSystemHours({
        ...timeToStartStop,
        [name]: value,
      });

      let timeErr: any = functions.handleTimeError(value, name);

      setErrors(timeErr);
    }

    if (name === 'hourStart') {
      setSystemHours({
        ...timeToStartStop,
        [name]: value,
      });

      let timeErr: any = functions.handleTimeError(value, name);

      setErrors(timeErr);
    }

    if (functions.validateTypeOfState(name) === 'isSystemIdData') {
      setInputValues(functions.selectTypeOfState(name, value, inputValues));
    } else if (functions.validateTypeOfState(name) === 'isVentData') {
      setVentSetupValues(
        functions.selectTypeOfState(name, value, ventSetupValues),
      );

      let errs = functions.handleErrs(value, name);
      setErrors(errs);
    }

    if (name === 'minutesPerHourExhaustRun') {
      if (!primaryControl) {
      } else {
        setVentSetupValues(
          functions.setVS(ventSetupValues, primaryCtrlIsFC, value),
        );

        let errs = functions.handleErrs(value, name);
        setErrors(errs);
      }
    }

    if (event.target.id === `deviceStatus`) {
      setDevStatus(!devStatus);
    }
    if (event.target.id === `hoursOfOperation`) {
      if (disabled) return;
      setHoursOfOp(functions.setHours(id));
    }
  };

  const confirmUpdate = () => {
    //TODO: Update system
  };

  const cancelUpdate = () => {
    defCurrentSelectBox('');
    storeSystemSelectData({ name: originalVSet });
    setErrors({ hasError: false, resetAll: true });
    setInputValues({
      ...inputValues,
      systemName: currSystemDetails.device_name,
      systemLocationState: currSystemDetails?.device_state?.name
        ? currSystemDetails.device_state.name
        : 'No state data.',
      systemLocationCity: currSystemDetails?.device_city?.name
        ? currSystemDetails.device_city.name
        : 'No city data.',
      systemLocationZipCode: currSystemDetails?.device_zipcode?.zip
        ? currSystemDetails.device_zipcode.zip
        : 'No zip code data.',
    });
    setDevStatus(currSystemDetails.status === 'active' ? true : false);
    setIconToShow(defaultIcon);

    setTimezoneStr(time);
    setHoursOfOp(
      currSystemDetails?.details?.settings?.conf?.hroa === 1 ? true : false,
    );
    setVentSetupValues({
      ...ventSetupValues,
      exhaustFanDelayTime: currSystemDetails.details.settings.conf.efdt,
      minutesPerHourSupplyFlow: currSystemDetails.details.settings.conf?.mnsf,

      minutesPerHourExhaustRunFC: currSystemDetails.details.settings.conf?.mner,
      minutesPerHourExhaustRunVC:
        currSystemDetails.details.settings.conf?.cdvs.length > 0
          ? currSystemDetails.details.settings.conf?.cdvs[0].mvcr
          : 0,
      additionalMixTime: currSystemDetails.details.settings.conf.mixt,
      flowMeasuredHeatCall: currSystemDetails.details.settings.conf.fhfr,
      flowMeasuredCoolingCall: currSystemDetails.details.settings.conf.fcfr,
      flowMeasuredFanOnlyCall: currSystemDetails.details.settings.conf.fffr,
      requiredContinuousAirFlow: currSystemDetails.details.settings.conf.carq,
      exhaustFanFlowRate: currSystemDetails.details.settings.conf.effr,
    });

    setSystemHours({
      hourStart: currSystemDetails.details.settings.conf.hrsv,
      hourStop: currSystemDetails.details.settings.conf.hstv,
    });

    setUpdateQuitting(true);
    cancel();
    toggleEdit();
  };

  useEffect(() => {
    if (matchWasAssessed) {
      setOriginalVentSet(modeAfterComparison);
    }
  }, [settingsMatch, matchWasAssessed, modeAfterComparison]);

  useEffect(() => {
    if (sensorsLoaded && loadedSysDetails) {
      const allDevicesTemp: any = [];
      let allSensorTemp: any = [];
      let sensors = sensorsList;
      let systems = systemsList;
      let _devicesData: any = [];

      //gathers all the devices (systems and sensors)
      _devicesData = sensors.concat(systems);

      if (_devicesData.length === 0) return;

      _devicesData.length > 0 &&
        _devicesData.forEach((device: DeviceData) => {
          if (device.device_type === 'aircycler') {
            if (device.device_id === currSystemDetails?.device_id) {
              let res: any = functions.filterCdvs(device?.details, fanConnect);

              setPrimaryDefault(res?.primaryDefault);
              setCdvs(res?.cdvs);
            } else {
              allDevicesTemp.push(device);
            }
          } else {
            if (sensorsLoaded && loadedSysDetails && mergedSystemsAreLoaded) {
              allSensorTemp.push(functions.validateSensor(device));
            }
          }
        });

      setSensorDevices(allSensorTemp);
    }
  }, [sensorsLoaded, loadedSysDetails, mergedSystems, mergedSystemsAreLoaded]);

  useEffect(() => {
    if (mergedSystemsAreLoaded && loadedSysDetails) {
      const { fcsp } = currSystemDetails?.details?.settings?.conf || {};

      if (mergedSystems.length > 0) {
        mergedSystems.forEach((elem: any) => {
          if (elem.system_id === currSystemDetails?.device_id) {
            setSettingsObject(elem?.settings);

            let handleISResult;
            if (currSystemDetails && currSystemDetails?.details) {
              let res: any = functions.filterCdvs(
                currSystemDetails?.details,
                fanConnect,
              );

              setPrimaryDefault(res?.primaryDefault);
              setCdvs(res?.cdvs);

              let filteredDevicesTmp: any =
                currSystemDetails?.details?.settings?.conf?.cdvs || [];

              if (filteredDevicesTmp.length > 0) {
                filteredDevicesTmp.forEach((device: any) => {
                  const { cdid } = device;
                  device.vent = functions.mapConnectedLabel(cdid);
                });
              }

              if (fcsp) filteredDevicesTmp.push(fanConnect);

              handleISResult = functions.handleInitialSettings(
                currSystemDetails,
                filteredDevicesTmp,
                elem,
                cdvs,
              );

              compareSystemSettings(
                currSystemDetails?.device_id,
                handleISResult,
                sensorDevices,
                handleISResult?.ventilation_setup,
              );

              setCurrentVentSetup(handleISResult?.ventilation_setup);
              setModeAfterComparison(handleISResult?.ventilation_setup);
              setOriginalVentSet(handleISResult?.ventilation_setup);
              storeSystemSelectData({
                name: handleISResult?.ventilation_setup,
              });
            }
          }
        });
      }
    }
  }, [
    mergedSystemsAreLoaded,
    mergedSystems,
    loadedSysDetails,
    currSystemDetails,
    settingsObj,
    devDetailsAreLoaded,
  ]);

  useEffect(() => {
    const currentAcc: any = localStorage.getItem('currentAccount');

    async function loadSystemsInfo() {
      let response: any = await showSystemsListPerAccount(currentAcc);

      if (response && response?.statusCode === 200 && loadedSysDetails) {
        let current = functions.setCurrent(response, currSystemDetails);

        if (current && current.length > 0) {
          let devSett: any = await getDeviceSettings({
            device_id: current[0].device_id,
            id: currentAcc,
          });

          if (devSett && devSett?.statusCode === 200) {
            setDevicesDetails(devSett?.body?.data);

            let sensorsInformation = functions.handleSettings(
              devSett,
              sensorsList,
              sensorsAreLoaded,
            );

            let inf: any = functions.setSensInf(sensorsInformation);

            setSensorNm(inf?.name);
            setSensorId(inf?.id);
          }
        }
      }

      return () => {};
    }
    loadSystemsInfo();
  }, [
    loadedSysDetails,
    sensorsAreLoaded,
    devDetailsAreLoaded,
    mergedSystems,
    mergedSystemsAreLoaded,
  ]);

  useEffect(() => {
    const name: any = localStorage.getItem('currentSystemNameSysPage');
    if (name) setDevName(name);
    return () => {};
  }, []);

  useEffect(() => {
    const currSysDetails: any = localStorage.getItem('currSystemDetailsData');
    if (currSysDetails) saveSystemDetails(JSON.parse(currSysDetails));
    return () => {};
  }, []);

  useEffect(() => {
    loadDefaultValues();

    return () => {};
  }, [loadedSysDetails, sensorsLoaded, sensorNm, sensorId]);

  useEffect(() => {
    if (designationsAreLoaded) {
      let filteredResult: any = functions.getFiltered(
        connectedDevsDesignations,
        currSystemDetails,
      );

      if (filteredResult && filteredResult.length > 0) {
        let primCtrl: any = functions.setPrimCtrlCList(filteredResult);

        setPrimaryControl(primCtrl);
      }
      return () => {};
    }
  }, [designationsAreLoaded, connectedDevsDesignations, currSystemDetails]);

  useEffect(() => {
    let savedDevDes: any = localStorage.getItem('connectedDevsDesignations');

    if (savedDevDes) {
      let pCtrl: any = functions.setConnDevs(savedDevDes, currSystemDetails);

      setPrimaryControl(pCtrl);
    }

    return () => {};
  }, [loadedSysDetails]);

  useEffect(() => {
    const fetchSensors = async () => {
      let currentUser: any = localStorage.getItem('currentAccount');
      if (currentUser) {
        const sensorsData: any = await showSensorsListPerAccount(currentUser);

        if (sensorsData && sensorsData?.statusCode === 200) {
          let _sensors = functions.storeSens(sensorsData);

          saveList(_sensors, 'sensors');

          setSensorsLoaded(true);
        }
      }
    };
    fetchSensors();
    return () => {};
  }, []);

  useEffect(() => {
    if (sensorsAreLoaded && sensorsLoaded) {
      let theData: any = functions.handleSensList(sensorsList);
      setAddSensorsLabels(theData);
      setLoadedList(true);

      return () => {};
    }
  }, [sensorsAreLoaded, sensorsList, sensorsLoaded]);

  useEffect(() => {
    setCurrentVentSetup(selectVentSetup);
    return () => {};
  }, [selectVentSetup]);

  useEffect(() => {
    if (originalVSet && originalVSet.length > 0) {
      saveOriginalVSet(originalVSet);
    }
    return () => {};
  }, [originalVSet]);

  useEffect(() => {
    if (primaryControl && primaryControl.length > 0) {
      if (functions.isAC(primaryControl)) {
        markPrimaryCtrAsVC(true);
      }
    }
  }, [primaryControl]);

  useEffect(() => {
    const _id: any = localStorage.getItem('currentAccount');

    async function fetchSystemsInfo() {
      await getAdminSystemSettingsList({
        id: _id,
      });
    }
    fetchSystemsInfo();
  }, []);

  useEffect(() => {
    if (selectTimezone && selectTimezone.length > 0) {
      setTimeInHoursAndMins(
        `${formatInTimeZone(new Date(), `${selectTimezone}`, 'HH:mm')}`,
      );
    }
  }, [selectTimezone]);

  useEffect(() => {
    if (!currentVentSetup || modeAfterComparison.length === 0) {
      loading.start('Loading System Details...');
    } else {
      loading.stop();
    }
  }, [currentVentSetup, modeAfterComparison]);

  const cancel = () => {
    const newerRef = Date.now();
    newerRef.toString();
    //when canceling, put time to default

    setTimeInHoursAndMins(
      `${formatInTimeZone(
        new Date(),
        `${
          config?.timezone3[currSystemDetails.details.settings.conf.tmzn]
            .timezone
        }`,
        config?.strings?.hmFormat,
      )}`,
    );

    setSystemKey(newerRef);

    if (!disabled) {
      /* replace default values since the user no longer wants data update*/

      setTimezoneStr(time);
      defCurrentSelectBox('');
      storeSystemSelectData({ name: originalVSet });

      setErrors({ hasError: false, resetAll: true });

      setInputValues({
        ...inputValues,
        systemName: currSystemDetails.device_name,
        systemLocationState: currSystemDetails?.device_state?.name
          ? currSystemDetails.device_state.name
          : config?.strings?.noState,
        systemLocationCity: currSystemDetails?.device_city?.name
          ? currSystemDetails.device_city.name
          : config?.strings?.noCity,
        systemLocationZipCode: currSystemDetails?.device_zipcode?.zip
          ? currSystemDetails.device_zipcode.zip
          : config?.strings?.noZip,
      });

      setDevStatus(currSystemDetails.status === 'active' ? true : false);

      setIconToShow(defaultIcon);

      setHoursOfOp(
        currSystemDetails?.details?.settings?.conf?.hroa === 1 ? true : false,
      );

      setVentSetupValues({
        ...ventSetupValues,
        exhaustFanDelayTime: currSystemDetails.details.settings.conf.efdt,
        minutesPerHourSupplyFlow: currSystemDetails.details.settings.conf?.mnsf,

        minutesPerHourExhaustRunFC:
          currSystemDetails.details.settings.conf?.mner,
        minutesPerHourExhaustRunVC:
          currSystemDetails.details.settings.conf?.cdvs.length > 0
            ? currSystemDetails.details.settings.conf?.cdvs[0].mvcr
            : 0,
        additionalMixTime: currSystemDetails.details.settings.conf.mixt,
        flowMeasuredHeatCall: currSystemDetails.details.settings.conf.fhfr,
        flowMeasuredCoolingCall: currSystemDetails.details.settings.conf.fcfr,
        flowMeasuredFanOnlyCall: currSystemDetails.details.settings.conf.fffr,
        requiredContinuousAirFlow: currSystemDetails.details.settings.conf.carq,
        exhaustFanFlowRate: currSystemDetails.details.settings.conf.effr,
      });

      setSystemHours({
        hourStart: currSystemDetails.details.settings.conf.hrsv,
        hourStop: currSystemDetails.details.settings.conf.hstv,
      });

      setDropdownsSelections({}, true);

      setUpdateQuitting(true);

      signalUpdate(true);

      toggleEdit();
    } else {
      return;
    }
  };

  useEffect(() => {
    let validateEmpty: any = functions.handleEmptyFields(inputValues);
    setEmptyFields(validateEmpty);
  }, [inputValues]);

  useEffect(() => {
    const checkErrors: any = Object.values(errors).filter(
      (err: any) => err.length > 0,
    );
    setErrorsList(checkErrors);
  }, [errors]);

  return (
    <div
      className={`container update-system${
        disabled ? `-disabled` : `-enabled`
      } system-details-page`}
    >
      <div className={`row system--details--r`}>
        <div className={`col system-details-c-cols`}>
          <BackSection
            spec={`no-left-space`}
            changeTab={changeTab}
            section={`Back to user details`}
            id="origin-sys-screen"
          />

          {currentVentSetup &&
          currentVentSetup.length > 0 &&
          modeAfterComparison ? (
            <div className="jumbotron system-jumbo">
              <h4 style={{ marginLeft: '15px' }}> {devName}</h4>
              <p className="lead sm--lead" style={{ marginLeft: '15px' }}>
                {' '}
                {`Update or add information about ${devName}`}
              </p>
              <hr className="my-4" />

              <div
                className="row sys-fields system--details--r"
                ref={systemSetupRef}
                key={systemKey}
              >
                <SystemId
                  originalVSet={originalVSet}
                  config={config}
                  primaryDefault={primaryDefault}
                  disabled={disabled}
                  primaryControl={primaryControl}
                  addSensorLabels={addSensorLabels}
                  ventilationOpts={config.ventOptions}
                  onChangeValues={onChangeValues}
                  errors={errors}
                  loadedList={loadedList}
                  systemDetails={systemDetails}
                  inputValues={inputValues}
                  currentVentSetup={currentVentSetup}
                  devStatus={devStatus}
                  hoursOfOp={hoursOfOp}
                  loadedSysDetails={loadedSysDetails}
                />
                <div className="col-xs-12 col-sm-12 col-md-12 col-lg-6 system-details-c-cols">
                  <VentilationSetup
                    selectVentSetup={selectVentSetup}
                    calculatedTime={config.calculatedTime}
                    disabled={disabled}
                    primaryControl={primaryControl}
                    onChangeValues={onChangeValues}
                    errors={errors}
                    systemDetails={systemDetails}
                    hoursOfOp={hoursOfOp}
                    calculatedFlow={config.calculatedFlow}
                    balancedMode={config.balancedMode}
                    config={config}
                    cdvs={cdvs}
                    ventSetupValues={ventSetupValues}
                    timeinHoursAndMins={timeinHoursAndMins}
                    loadedSysDetails={loadedSysDetails}
                    userQuitSystemUpdate={userQuitSystemUpdate}
                  />

                  <HoursOfOperation
                    hoursOfOp={hoursOfOp}
                    hours={config.hours}
                    disabled={disabled}
                    onChangeValues={onChangeValues}
                    errors={errors}
                    systemDetails={systemDetails}
                    timeToStartStop={timeToStartStop}
                    loadedSysDetails={loadedSysDetails}
                  />
                </div>
              </div>

              <div className="row ft--row footer--1">
                <div className="col-12 footer--r--1">
                  <div className="row alert-div--1">
                    <div className="col-xs-12 col-sm-12 col-md-7 alert-div--1">
                      {emptyFields.length > 0 || errorsList.length > 0 ? (
                        <div className="alert-not-empty-fields-allowed">
                          <CiWarning className="warning-alert-ic" />{' '}
                          <span className="lead sm-lead--1">
                            {config?.strings?.warning}
                          </span>
                        </div>
                      ) : null}
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-5 alert-div--1 save--menu">
                      <div className="alert-row--1"></div>
                      <Button
                        className="small-button dismiss-btn ft-btns"
                        onClick={cancel}
                      >
                        <span className="label-i btn-child-ft">
                          <HiOutlineXCircle className="cancel-i" /> CANCEL
                        </span>
                      </Button>
                      {emptyFields.length === 0 && errorsList.length === 0 && (
                        <Button
                          className="small-button ok ft-btns"
                          dataToggle="modal"
                          dataTarget="#update-system"
                        >
                          <span className="label-i btn-child-ft">
                            {' '}
                            <RiSave3Fill className="edit-save" /> SAVE
                          </span>
                        </Button>
                      )}
                      <Button
                        className="small-button dismiss-btn ft-btns"
                        onClick={toggleEdit}
                      >
                        <span className="label-i btn-child-ft">
                          <RiEdit2Line className="edit-save" />
                          EDIT
                        </span>
                      </Button>{' '}
                      {!disabled && (
                        <Modal
                          config={config}
                          id={`update-system`}
                          title={config.modal.edit.title}
                          icon={<RiSave3Fill className="edit" />}
                          body={config?.modalStrings?.aboutToUpdate}
                          onClick={confirmUpdate}
                          onClickCancel={cancelUpdate}
                          //this will be true / false according
                          //to the handleModalState function return val:
                          modalMustClose={false}
                          handleModalState={() => {
                            //automatic modal close after update
                          }}
                          label={config?.modalStrings?.labelOK}
                          className={`small-button ok`}
                          bodyClass={`alert alert-info`}
                          sureToProceed={config?.modalStrings?.sureToProceed}
                          hasConfirmBtn={true}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <div className="container spinner-box">
              <div className="row spinner--r">
                <div className="col-12">
                  <Spinner />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { adminState, adminDevicesState, adminSectionsState } = state;
  return {
    userQuitSystemUpdate: adminDevicesState.userQuitSystemUpdate,
    systemKey: adminState.systemKey,
    currSystemDetails: adminDevicesState.currSystemDetails,
    loadedSysDetails: adminDevicesState.loadedSysDetails,
    connectedDevsDesignations: adminDevicesState.connectedDevsDesignations,
    sensorsList: adminDevicesState.sensorsList,
    sensorsAreLoaded: adminDevicesState.sensorsAreLoaded,
    designationsAreLoaded: adminDevicesState.designationsAreLoaded,
    systemsList: adminDevicesState.systemsList,
    selectVentSetup: adminDevicesState.selectVentSetup,
    selectTimezone: adminDevicesState.selectTimezone,
    inputValues: adminDevicesState.inputValues,
    ventSetupValues: adminDevicesState.ventSetupValues,
    devStatus: adminDevicesState.devStatus,
    hoursOfOp: adminDevicesState.hoursOfOp,
    timeToStartStop: adminDevicesState.timeToStartStop,
    errors: adminDevicesState.errors,
    originalVSet: adminDevicesState.originalVSet,
    devDetailsAreLoaded: adminSectionsState.devDetailsAreLoaded,
    mergedSystems: adminSectionsState.mergedSystems,
    mergedSystemsAreLoaded: adminSectionsState.mergedSystemsAreLoaded,
    timeinHoursAndMins: adminDevicesState.timeinHoursAndMins,
    defaultIcon: adminDevicesState.defaultIcon,
    time: adminDevicesState.time,
    modalMustClose: adminSectionsState.modalMustClose,
  };
}

function mapDispatchToProps(dispatch: any) {
  const {
    adminDevicesState,
    adminSectionsState,
    loader,
    adminState,
  } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    changeTab: adminSectionsState.changeTab,
    setTimeInHoursAndMins: adminDevicesState.setTimeInHoursAndMins,
    saveSystemDetails: adminDevicesState.saveSystemDetails,
    showSensorsListPerAccount: adminDevicesState.showSensorsListPerAccount,
    saveList: adminDevicesState.saveList,
    showSystemsListPerAccount: adminDevicesState.showSystemsListPerAccount,
    getDeviceSettings: adminSectionsState.getDeviceSettings,
    storeSystemSelectData: adminDevicesState.storeSystemSelectData,
    setUpdateQuitting: adminDevicesState.setUpdateQuitting,
    setInputValues: adminDevicesState.setInputValues,
    setDevStatus: adminDevicesState.setDevStatus,
    setVentSetupValues: adminDevicesState.setVentSetupValues,
    setHoursOfOp: adminDevicesState.setHoursOfOp,
    setSystemHours: adminDevicesState.setSystemHours,
    setErrors: adminDevicesState.setErrors,
    saveOriginalVSet: adminDevicesState.saveOriginalVSet,
    setDevicesDetails: adminSectionsState.setDevicesDetails,
    markPrimaryCtrAsVC: adminDevicesState.markPrimaryCtrAsVC,
    getNoMergedAdminSystemSettings:
      adminSectionsState.getNoMergedAdminSystemSettings,
    getAdminSystemSettingsList: adminSectionsState.getAdminSystemSettingsList,
    setIconToShow: adminDevicesState.setIconToShow,
    setTimezoneStr: adminDevicesState.setTimezoneStr,
    defCurrentSelectBox: adminDevicesState.defCurrentSelectBox,
    setDropdownsSelections: adminDevicesState.setDropdownsSelections,
    signalUpdate: adminDevicesState.signalUpdate,
    setSystemKey: adminState.setSystemKey,
    handleModalState: adminSectionsState.handleModalState,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(SystemDetails);
