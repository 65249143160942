import React from 'react';
import { NativeSelect, Grid } from '@material-ui/core';
import { formatInTimeZone } from 'date-fns-tz';
import _ from 'lodash';
import PeripheralOptional from '../Optional';
import RangeTime from '../RangeTime';
import { Input, InputPlus } from '../../../../../../../react-components';
import { parseNumber } from '../../helpers';
import './styles.scss';


interface BalancedModeProps {
  config: any;
  onChangeState: (name: string, value: any) => void;
  errors: any;
  setErrors: any;
  handleSetTimezone: any;
  fieldsState: any;
  isVentConnected: boolean;
}

const BalancedMode: React.FC<BalancedModeProps> = ({
  config,
  onChangeState,
  errors,
  setErrors,
  handleSetTimezone,
  fieldsState,
  isVentConnected,
}) => {
  const TIMEZONE: any = config?.timezone ? _.cloneDeep(config.timezone) : [];
  const CONFIG_FIELDS: any = config?.fields ? _.cloneDeep(config.fields) : {};


  return (
    <div id="balance-mode">
      <Grid item className="section-1 calc-mode">
        <div className="calc-mode--wrapper">
          <InputPlus
            handleChange={(value: any) => {
              onChangeState('mnsf', parseNumber(value));
            }}
            handleChangeError={(err: any) => {}}
            type={CONFIG_FIELDS.mnsf.type}
            label={CONFIG_FIELDS.mnsf.label}
            value={`${fieldsState?.mnsf}`}
            units={CONFIG_FIELDS.mnsf.units}
            max={CONFIG_FIELDS?.mnsf?.margings?.max}
            min={CONFIG_FIELDS?.mnsf?.margings?.min}
            error={errors?.mnsf}
            name={'mnsf'}
          />
        </div>
      </Grid>
      {isVentConnected && (
        <>
          {fieldsState?.primary_control === 'fanConnect' && (
            <Grid item className="section-1 calc-mode">
              <div className="calc-mode--wrapper">
                <InputPlus
                  handleChange={(value: any) => {
                    onChangeState('mner', parseNumber(value));
                  }}
                  handleChangeError={(err: any) => {}}
                  type={CONFIG_FIELDS.mner.type}
                  label={CONFIG_FIELDS.mner.label}
                  value={`${fieldsState?.mner}`}
                  units={CONFIG_FIELDS.mner.units}
                  max={CONFIG_FIELDS?.mner?.margings?.max}
                  min={CONFIG_FIELDS?.mner?.margings?.min}
                  error={errors?.mner}
                  name={'mner'}
                />
              </div>
            </Grid>
          )}
          {fieldsState?.primary_control !== 'fanConnect' && (
            <Grid item className="section-1 calc-mode">
              <div className="calc-mode--wrapper">
                <InputPlus
                  handleChange={(value: any) => {
                    onChangeState('mvcr', parseNumber(value));
                  }}
                  handleChangeError={(err: any) => {}}
                  disabled={false}
                  type={CONFIG_FIELDS.mvcr.type}
                  label={CONFIG_FIELDS.mvcr.label}
                  value={`${fieldsState?.mvcr}`}
                  units={CONFIG_FIELDS.mvcr.units}
                  max={CONFIG_FIELDS?.mvcr?.margings?.max}
                  min={CONFIG_FIELDS?.mvcr?.margings?.min}
                  error={errors?.mvcr}
                  name={'mvcr'}
                />
              </div>
            </Grid>
          )}
        </>
      )}
      {isVentConnected && (
        <>
          {fieldsState?.primary_control === 'fanConnect' && (
            <Grid item className="section-1 calc-mode">
              <div className="calc-mode--wrapper">
                <InputPlus
                  handleChange={(value: any) => {
                    onChangeState('efdt', parseNumber(value));
                  }}
                  handleChangeError={(err: any) => {}}
                  type={CONFIG_FIELDS.efdt.type}
                  label={CONFIG_FIELDS.efdt.label}
                  value={`${fieldsState?.efdt}`}
                  units={CONFIG_FIELDS.efdt.units}
                  max={CONFIG_FIELDS?.efdt?.margings?.max}
                  min={CONFIG_FIELDS?.efdt?.margings?.min}
                  error={errors?.efdt}
                  name={'efdt'}
                />
              </div>
            </Grid>
          )}
          {fieldsState?.primary_control !== 'fanConnect' && (
            <Grid item className="section-1 calc-mode">
              <div className="calc-mode--wrapper">
                <InputPlus
                  handleChange={(value: any) => {
                    onChangeState('vcdt', parseNumber(value));
                  }}
                  handleChangeError={(err: any) => {}}
                  disabled={false}
                  type={CONFIG_FIELDS.vcdt.type}
                  label={CONFIG_FIELDS.vcdt.label}
                  value={`${fieldsState?.vcdt}`}
                  units={CONFIG_FIELDS.vcdt.units}
                  max={CONFIG_FIELDS?.vcdt?.margings?.max}
                  min={CONFIG_FIELDS?.vcdt?.margings?.min}
                  error={errors?.vcdt}
                  name={'vcdt'}
                />
              </div>
            </Grid>
          )}
        </>
      )}

      <Grid item className="section-1 calc-mode">
        <div className="calc-mode--wrapper extra-mt">
          <InputPlus
            handleChange={(value: any) => {
              onChangeState('mixt', parseNumber(value));
            }}
            handleChangeError={(err: any) => {}}
            type={CONFIG_FIELDS.mixt.type}
            label={CONFIG_FIELDS.mixt.label}
            value={`${fieldsState?.mixt}`}
            units={CONFIG_FIELDS.mixt.units}
            max={CONFIG_FIELDS?.mixt?.margings?.max}
            min={CONFIG_FIELDS?.mixt?.margings?.min}
            error={errors?.mixt}
            name={'mixt'}
          />
        </div>
      </Grid>
      <Grid item className={`section-1 calc-mode--op-h ${isVentConnected ? '' : 'no-ventConnected'}`}>
        <div className={`hours-operation bl-extra-mt bl--${!fieldsState?.hroa ? 'close' : 'open'}`}>
          <PeripheralOptional
            id="hroa"
            label="Hours of operation:"
            value={fieldsState?.hroa ? fieldsState?.hroa : 0}
            options={[
              { value: 1, label: 'Yes' },
              { value: 0, label: 'No' },
            ]}
            onChange={(event: any) =>
              onChangeState('hroa', parseInt(event.target.value))
            }
          />
          {fieldsState?.hroa === 1 && (
            <RangeTime
              onChangeState={onChangeState}
              errors={errors}
              fieldsState={fieldsState}
              config={config}
            />
          )}
        </div>
      </Grid>
      <Grid item className="section-1 calc-mode">
        <div className="calc-mode--tz">
          <div className="container-timezone">
            <label className="inputLabel">Timezone</label>
            <div className="device-zone--select">
              <NativeSelect
                className="select"
                value={TIMEZONE[fieldsState.tmzn]?.label}
                onChange={handleSetTimezone}
              >
                {TIMEZONE.map((tm: any) => (
                  <option key={tm.label} value={tm.label}>
                    {tm.label}
                  </option>
                ))}
              </NativeSelect>
            </div>
          </div>
        </div>
      </Grid>
      <Grid item className="section-1 calc-mode">
        <div className="calc-mode--wrapper">
          <Input
            disabled
            handleChangeError={(err: any) => {}}
            handleChange={() => {}}
            type="text"
            label={CONFIG_FIELDS.tmzn.label}
            value={`${formatInTimeZone(
              new Date(),
              `${TIMEZONE[fieldsState.tmzn]?.timezone}`,
              'HH:mm',
            )}`}
            error={errors?.tmzn}
            name={'tmzn'}
          />
        </div>
      </Grid>
    </div>
  );
};

export default BalancedMode;
