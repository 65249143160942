import React from 'react';
import { IoCheckmark } from 'react-icons/io5';
import { MdOutlineCancelPresentation } from 'react-icons/md';

export default {
  strings: {
    breadcrumbEdit: `Edit client`,
    tooltipTitle: {
      'user-id': 'Get a new Client ID',
      'token-id': 'Get a new Client token',
    },
    tooltipTxt: {
      'user-id':
        'Generate a different Client ID by clicking on the button GENERATE CLIENT ID',
      'token-id':
        'Generate a different Client token by clicking on the button GENERATE TOKEN ID',
    },
    warning: `Please make sure all the required fields are filled to proceed!`,
  },
  emailRegex: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  zipRegex: '[0-9][0-9\\- ]{0,10}[0-9]$',
  errorMsg: {
    first_name: 'First name should have at least 3 characters.',
    last_name: 'Last name should have at least 3 characters.',
    company: 'Company should have at least 2 characters.',
    address: 'Address should have at least 5 characters.',
    email: 'Invalid e-mail.',
    phone: 'Invalid phone number.',
    state: 'State is too short.',
    zipcode: 'Invalid zipcode.',
    city: 'City is too short',
  },
  modalStrings: {
    confirm: ``,
    warning: `Information`,
    alertStr: ``,
    alertStr2: ``,
    sureToProceed: `Are you sure you wish to proceed?`,
    confirmation: `Are you sure you wish to proceed?`,
  },
  notifications: {
    edit: {
      success: 'Client information edited successfully!',
      error: 'Something went wrong! The user could not be edited.',
    },
    code: {
      success: `Code created successfully`,
      error: `Something went wrong. Please, try to generate a new code later`,
    },
  },
  titles: {
    edit__user: `Edit client`,
  },
  icons: {
    edit__user: <IoCheckmark className="edit" />,
    cancel__op: <MdOutlineCancelPresentation className="edit" />,
  },
  bodies: {
    edit__user: `You are about to edit this client.`,
  },
  labels: {
    edit__user: ` OK`,
  },
  bodyClass: {
    edit__user: `alert alert-danger`,
  },
  genLabel: {
    'user-id': `GENERATE CLIENT ID`,
    'token-id': 'GENERATE TOKEN ID',
  },
  userEdit: {
    mainTitle: `Edit client`,
    pText: `Edit the client information below.`,
    title: `Edit client`,
    breadcrumbItem: `/admin/dashboard/clients/client/edit`,
    class: `jumbotron container-jumbo jmb-min-h--1`,
  },
  client: {
    email: '',
    first_name: '',
    last_name: '',
    company: '',
    phone: '',
    address: '',
  },
  dataArrs: {
    states: [],
    cities: [],
    zips: [],
  },
  suggestions: {
    client_state: '',
    client_city: '',
    client_zipcode: '',
  },
  fields: [
    {
      outerClass: 'form-group',
      label: 'First name',
      htmlFor: 'exampleInputCompNumber',
      type: 'text',
      id: 'user-first-name-edit',
      ariaDescribedby: 'emailHelp',
      placeholder: 'Enter first name',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'first_name',
      disabled: false,
      isCode: false,
      isPhone: false,
      showRequiredSign: true,
    },
    {
      outerClass: 'form-group',
      label: 'Last name',
      htmlFor: 'exampleInputCompNumber',
      type: 'text',
      id: 'user-last-name-edit',
      ariaDescribedby: 'emailHelp',
      placeholder: 'Enter last name',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'last_name',
      disabled: false,
      isCode: false,
      isPhone: false,
      showRequiredSign: true,
    },
    {
      outerClass: 'form-group',
      label: 'Company',
      htmlFor: 'exampleInputCompNumber',
      type: 'text',
      id: 'client-company-edit',
      ariaDescribedby: 'emailHelp',
      placeholder: 'Enter Company',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'company',
      disabled: false,
      isCode: false,
      isPhone: false,
      showRequiredSign: true,
    },
    {
      outerClass: 'form-group',
      label: 'E-mail',
      htmlFor: 'exampleInputCompNumber',
      type: 'e-mail',
      id: 'client-email-edit',
      ariaDescribedby: 'emailHelp',
      placeholder: 'Enter e-mail',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'email',
      disabled: false,
      isCode: false,
      isPhone: false,
      showRequiredSign: true,
    },
    {
      outerClass: 'form-group',
      label: 'Client ID',
      htmlFor: 'exampleInputCompNumber',
      type: 'text',
      id: 'user-id-edit',
      ariaDescribedby: 'emailHelp',
      placeholder: 'Generated ID',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'user-id',
      disabled: true,
      isCode: true,
      showRequiredSign: true,
    },
    {
      outerClass: 'form-group',
      label: 'Token',
      htmlFor: 'exampleInputCompNumber',
      type: 'text',
      id: 'token-id-edit',
      ariaDescribedby: 'emailHelp',
      placeholder: 'Generated Token',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'token-id',
      disabled: true,
      isCode: true,
      showRequiredSign: true,
    },
    {
      outerClass: 'form-group',
      label: 'Phone',
      htmlFor: 'exampleInputCompNumber',
      type: 'text',
      id: 'client-phone-edit',
      ariaDescribedby: 'emailHelp',
      placeholder: 'Enter phone',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'phone',
      disabled: false,
      isCode: false,
      isPhone: true,
    },
    {
      outerClass: 'form-group',
      label: 'Redirect URL',
      htmlFor: 'exampleInputCompNumber',
      type: 'text',
      id: 'client-redirect-url-edit',
      ariaDescribedby: 'emailHelp',
      placeholder: 'Enter Redirect URL',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'redirectUrl',
      disabled: false,
      isCode: false,
      isPhone: false,
      showRequiredSign: false,
    },
    {
      outerClass: 'form-group',
      label: 'Address',
      htmlFor: 'exampleInputCompNumber',
      type: 'text',
      id: 'client-address-edit',
      ariaDescribedby: 'emailHelp',
      placeholder: 'Enter address',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'address',
      disabled: false,
      isCode: false,
      isPhone: false,
      showRequiredSign: true,
    },
  ],
  formConfig: [
    {
      label: 'State',
      className: '',
      placeholder: 'State',
      id: 'client-state',
      type: 'auto-suggest',
      field: 'client_state',
      fields: {
        label: 'Client Location - State',
        type: 'dropdown',
        title: 'Enter State',
        placeholder: 'Enter client state',
      },
      name: `state`,
    },
    {
      label: 'City',
      className: '',
      placeholder: 'City',
      id: 'client-city',
      type: 'auto-suggest',
      field: 'client_city',
      fields: {
        label: 'Client Location - City',
        type: 'dropdown',
        title: 'Enter City',
        placeholder: 'Enter client city',
      },
      name: `city`,
    },
    {
      label: 'Zip Code',
      className: '',
      placeholder: 'Zip Code',
      id: 'client-zip-code',
      type: 'auto-suggest',
      field: 'client_zipcode',
      fields: {
        label: 'Client Location - Zip Code',
        type: 'dropdown',
        title: 'Enter zip code',
        placeholder: 'Enter client zip code',
      },
      name: `zipcode`,
    },
  ],
  states: {
    formValues: {
      client_city: {},
      client_state: {},
      client_zipcode: {},
    },
  },
  constraints: {
    first_name: {
      presence: true,
      length: {
        minimum: 3,
      },
    },
    last_name: {
      presence: true,
      length: {
        minimum: 3,
      },
    },
    company: {
      presence: true,
      length: {
        minimum: 2,
      },
    },
    email: {
      email: true,
      presence: true,
    },
    redirectUrl: {
      url: {
        schemes: ['ftp', 'http', 'https'],
        allowDataUrl: true,
        allowLocal: true,
      },
    },
    address: {
      presence: true,
      length: {
        minimum: 5,
      },
    },
    phone: {
      presence: true,
      length: {
        minimum: 11,
        maximum: 11,
      },
    },
    state: {
      presence: true,
      length: (
        _value: any,
        attributes: any,
        attributeName: any,
        options: any,
        constraints: any,
      ) => {
        const {
          countryCode,
          isoCode,
          label,
          latitude,
          longitude,
          name,
          value,
        } = _value;
        return !(
          countryCode &&
          isoCode &&
          label &&
          latitude &&
          longitude &&
          name &&
          value
        );
      },
    },
    city: {
      presence: true,
      length: (
        _value: any,
        attributes: any,
        attributeName: any,
        options: any,
        constraints: any,
      ) => {
        const {
          countryCode,
          label,
          latitude,
          longitude,
          name,
          stateCode,
          value,
        } = _value;
        return !(
          countryCode &&
          label &&
          latitude &&
          longitude &&
          name &&
          stateCode &&
          value
        );
      },
    },
    zipcode: {
      presence: true,
      length: (
        _value: any,
        attributes: any,
        attributeName: any,
        options: any,
        constraints: any,
      ) => {
        const {
          city,
          country,
          label,
          latitude,
          longitude,
          value,
          zip,
        } = _value;
        return !(
          city &&
          country &&
          label &&
          latitude &&
          longitude &&
          value &&
          zip
        );
      },
    },
  },
};
