//@ts-nocheck
import React from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { oneDark } from 'react-syntax-highlighter/dist/esm/styles/prism';

const CodeSnippet = props => {
  return (
    <SyntaxHighlighter language="javascript" style={oneDark}>
      {props.code}
    </SyntaxHighlighter>
  );
};

export default CodeSnippet;
