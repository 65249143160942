import React from 'react';
import config from './config';
import { MCConfigsProps } from './interface';
import { Switch } from '@material-ui/core';

const MCConfigs: React.FC<MCConfigsProps> = ({ dev, index, returnLabel }) => {
  const hideItem = (item: any) => {
    switch (item) {
      case 'status':
      case 'account_id':
      case 'system_id':
      case 'pressureConnect':
      case 'cfcm':
        return true;
      default:
        return false;
    }
  };
  return (
    <div className="card mc-card" key={index}>
      <div className="card-body switches--mc--1">
        <div className="col-12 d-flex justify-content-center">
          <h5 className="card-title lead card-title-sys">{dev?.system_name}</h5>
        </div>
        <div className="col-12 d-flex justify-content-center">
          <h6 className="card-subtitle mb-2 text-muted fw--500">
            {config.strings.title}
          </h6>
        </div>
        {Object.entries(dev?.config).map(([item, value]: [any, any], i) => {
          return hideItem(item) ? null : (
            <div className="row mc--switches" key={i}>
              {item === 'cdvs' ? (
                value && value?.length > 0 ? (
                  value.map((vc: any, i: number) => {
                    return (
                      <div className="container values-mc" key={i}>
                        <div className="row">
                          <div className="col label-side">{vc.cdid}</div>
                          <div className="col-auto">
                            <Switch
                              id={``}
                              checked={!!value.cdst}
                              color="primary"
                            />
                          </div>
                        </div>{' '}
                      </div>
                    );
                  })
                ) : null
              ) : (
                <div className="container values-mc" key={i}>
                  <div className="row">
                    <div className="col label-side">{returnLabel(item)}</div>
                    <div className="col-auto">
                      <Switch id={``} checked={!!value} color="primary" />
                    </div>
                  </div>{' '}
                </div>
              )}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default MCConfigs;
