import React from 'react';
import { functions } from './helpers';
import { formatInTimeZone } from 'date-fns-tz';
import { SelectProps } from './interface';
import { IoIosArrowDown } from 'react-icons/io';
import './styles.scss';

const Select: React.FC<SelectProps> = ({
  storeSystemSelectData,
  placeholder,
  data,
  id,
  disabled,
  origin,
  dropdownLabel,
  setDevType,
  iconToShow,
  defCurrentSelectBox,
  setIconToShow,
  setSelectType,
  setUserSelected,
  setSelectedOption,
  markPrimaryCtrAsVC,
  setTimeInHoursAndMins,
}) => {
  const processSelection = (elem: any, origin?: string) => {
    if (elem.hasOwnProperty('timezone')) {
      setTimeInHoursAndMins(
        `${formatInTimeZone(new Date(), `${elem.timezone}`, 'HH:mm')}`,
      );
    }

    if (origin === `prim_ctrl` && elem.startsWith(`AirCycler`)) {
      markPrimaryCtrAsVC(true);
    }

    if (origin && origin === `add_sens`) {
      //define the icon

      let icon: any = functions.determineIconFn(elem.device_type);
      setIconToShow(icon);
    }

    if (origin && origin === `vent_setup`) {
      defCurrentSelectBox(origin);
    }

    setUserSelected(true);

    setSelectedOption(
      (origin && origin === `add_sens`) || (origin && origin === `vent_setup`)
        ? elem.name
        : id === `timezone-select`
        ? elem.label
        : origin === `prim_ctrl` && elem.startsWith('AirCycler')
        ? elem
        : elem,
    );

    setSelectType(
      id === `timezone-select`
        ? `timezone`
        : origin && origin === `add_sens`
        ? `add_sens`
        : origin && origin === `vent_setup`
        ? `vent_setup`
        : `primary_control`,
    );

    storeSystemSelectData(elem);

    if (origin && origin === `add_sens`) {
      defCurrentSelectBox(origin);
      setDevType && setDevType(elem.device_type);
    }
  };

  return (
    <div className={`dropdown system-dropdown`}>
      <div className="row ml-row">
        <span className="lead smaller-font styles-lead-span-2- sys-mb0">
          {placeholder}
        </span>
      </div>

      <button
        className={`btn dropdown-toggle sys--drop ${
          disabled ? `grey-backg` : `white-backg`
        }`}
        type="button"
        id="dropdownMenuButton1"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
      >
        <div className={`container dd--label ${disabled ? `dd-disabled` : ``}`}>
          <div className="row dd--label row-dd-label-margins">
            <div className="col-10 col-sys-dd dd-def">
              {iconToShow} {dropdownLabel}
            </div>
            <div
              className={`col-2 col-2--reg ${disabled ? `dd-disabled` : ``}`}
            >
              <IoIosArrowDown className={'select-arrow'} />
            </div>
          </div>
        </div>
      </button>

      <div
        className={`dropdown-menu ${disabled ? `hidden--dd` : ``}`}
        aria-labelledby="dropdownMenuButton"
      >
        {disabled
          ? null
          : data.map((elem: any, index: number) => {
              return (
                <button
                  className="dropdown-item dd-item"
                  key={index}
                  onClick={() => {
                    processSelection(elem, origin);
                  }}
                >
                  {functions.handleSelection(origin, elem)}
                </button>
              );
            })}
      </div>
    </div>
  );
};

export default Select;
