import React, {
  useState,
  useCallback,
  useRef,
  useEffect,
  InputHTMLAttributes,
} from 'react';
import validate from 'validate.js';
import { Col } from 'react-bootstrap';
import cn from 'classnames';
import styles from './index.module.scss';



interface InputPlusProps extends InputHTMLAttributes<HTMLInputElement> {
  name: string;
  classname?: any;
  label?: string;
  handleChangeError: Function;
  cols?: {
    col?: number;
    sm?: number;
    md?: number;
    lg?: number;
    lx?: number;
  };
  handleChange: Function;
  handleBlur?: any;
  maximum?: any;
  error?: any;
  constraint?: any;
  constraintEquality?: any;
  units?: any;
  max?: string;
  min?: number;
}

const InputPlus: React.FC<InputPlusProps> = ({
  name,
  classname,
  label,
  handleChangeError,
  cols,
  handleChange,
  handleBlur,
  value,
  maximum,
  type,
  error,
  autoFocus,
  constraint,
  constraintEquality,
  placeholder,
  units,
  min,
  max,
  ...rest
}) => {
  const inputRef: any = useRef(null);
  const [isFocused, setIsFocused] = useState(false);



  const handleChangeString = useCallback(
    (event: any) => {
      if (maximum) {
        event.target.value.length - 1 < maximum &&
          handleChange(event.target.value);
      } else {
        handleChange(event.target.value);
      }
      handleChangeError(false);
    },
    // eslint-disable-next-line
    [],
  );

  const handleInputFocus = useCallback(() => {
    setIsFocused(true);
  }, []);

  const handleInputBlur = useCallback(async () => {
    setIsFocused(false);
    if (constraint) {
      const inputValue =
        type === 'number'
          ? parseInt(inputRef.current.value)
          : inputRef.current.value;
      await validate
        .async({ [name]: inputValue }, { [name]: constraint })
        .then(null, err => {
          handleChangeError(err[name][0]);
        });
    }
    // eslint-disable-next-line
  }, []);




  useEffect(() => {
    if (autoFocus === true) {
      inputRef.current.focus();
    }
  }, [autoFocus]);


  useEffect(() => {
    return () => {};
  }, []);



  return (
    <Col
      sm={cols?.sm || cols?.col}
      md={cols?.md || cols?.col}
      lg={cols?.lg || cols?.col}
      className={cn('form-group', styles.formGroup, classname)}
    >
      <div className={styles.inputLabelwrapper}>
        <label className={styles.inputLabel}>{label}</label>
        {units !== null && units !== undefined ? <label className={styles.separator}>|</label> : <></>}
        {units !== null && units !== undefined ? <label className={styles.inputUnits}>Enter {units}</label> : <></>}
      </div>
      <input
        ref={inputRef}
        id={name}
        onChange={event => handleChangeString(event)}
        className={cn(
          'form-control',
          styles.input,
          {
            [styles[`input--isFocused`]]: isFocused === true,
          },
          {
            [styles[`input--isError`]]: error,
          },
        )}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        value={value ? value : ''}
        type={type}
        placeholder={placeholder}
        max={max}
        min={min}
        {...rest}
      ></input>
      {error && (
        <span
          className={cn(styles.input, {
            [styles[`input__span--isError`]]: error,
          })}
        >
          {error}
        </span>
      )}
    </Col>
  );
};

export default InputPlus;
