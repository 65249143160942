export default {
  strings: {
    warning: `Please make sure all the required fields are filled to proceed!`,
  },
  ed: {
    title: `Edit device`,
    breadcrumbItem: `Edit device`,
    mainTitle: `Device details`,
    pText: `Update this installers' device information.`,
    route: `/admin/dashboard/company/installers/devices/edit-add-device`,
  },
  zipRegex: '[0-9][0-9\\- ]{0,10}[0-9]$',
  macAddress: '/^[0-9a-f]{1,2}([.:-])[0-9a-f]{1,2}(?:\x01[0-9a-f]{1,2}){4}$/i',
  modalStrings: {
    edit: `Update device`,
    confirm: ``,
    warning: `Information`,
    alertStr: ``,
    alertStr2: ``,
    confirmation: ``,
    sureToProceed: `Are you sure you wish to proceed?`,
    noUndo: ``,
    aboutToUpdate: `You are about to update this device.`,
    labelOK: ` OK`,
  },
  errorMsg: {
    name: 'Device name should not be blank.',
    state: 'State should be at least 2 characters long.',
    city: 'City should be at least 2 characters long.',
    macaddress: 'Mac Address is invalid.',
    zipCode: 'Invalid zipcode.',
  },
  devState: {
    name: '',
    macaddress: '',
  },
  fields: [
    {
      outerClass: 'form-group',
      label: 'Name',
      htmlFor: 'exampleInputEmail1',
      type: 'text',
      id: 'device-name1-edit',
      ariaDescribedby: 'inputHelp',
      placeholder: 'Enter name',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'name',
      disabled: '',
    },
    {
      outerClass: 'form-group',
      label: 'Mac address',
      htmlFor: 'exampleInputEmail1',
      type: 'text',
      id: 'device-macaddress1-edit',
      ariaDescribedby: 'emailHelp',
      placeholder: 'Enter mac address',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'macaddress',
      disabled: '',
    },
  ],
  formConfig: [
    {
      label: 'State',
      className: 'col-xs-12 col-sm-12 cont--1--p auto-sug-field1',
      placeholder: 'State',
      id: 'state',
      type: 'auto-suggest',
      field: 'device_state',
      fields: {
        label: 'Device Location - State',
        type: 'dropdown',
        title: 'Enter State',
        placeholder: 'Enter device state',
      },
    },
    {
      label: 'City',
      className: 'col-xs-12 col-sm-12 cont--1--p auto-sug-field1',
      placeholder: 'City',
      id: 'city',
      type: 'auto-suggest',
      field: 'device_city',
      fields: {
        label: 'Device Location - City',
        type: 'dropdown',
        title: 'Enter City',
        placeholder: 'Enter device city',
      },
    },
    {
      label: 'Zip Code',
      className: 'col-xs-12 col-sm-12 cont--1--p auto-sug-field1',
      placeholder: 'Zip Code',
      id: 'zip_code',
      type: 'auto-suggest',
      field: 'device_zipcode',
      fields: {
        label: 'Device Location - Zip Code',
        type: 'dropdown',
        title: 'Enter zip code',
        placeholder: 'Enter device zip code',
      },
    },
  ],
  states: {
    formValues: {
      device_city: {},
      device_state: {},
      device_zipcode: {},
    },
  },
  defaults: {
    state: {
      name: 'Massachusetts',
      isoCode: 'MA',
      countryCode: 'US',
      latitude: '42.40721070',
      longitude: '-71.38243740',
      label: 'Massachusetts',
      value: 'MA',
    },
    city: {
      name: 'Boston',
      countryCode: 'US',
      stateCode: 'MA',
      latitude: '42.35843000',
      longitude: '-71.05977000',
      label: 'Boston',
      value: 'Boston',
    },
  },
  constraints: {
    name: {
      presence: true,
      length: {
        minimum: 1,
      },
    },
    email: {
      email: true,
      presence: true,
    },
    state: {
      presence: true,
      length: {
        minimum: 2,
      },
    },
    city: {
      presence: true,
      length: {
        minimum: 2,
      },
    },
    zip: {
      presence: true,
      length: {
        minimum: 5,
        maximum: 10,
      },
      format: {
        pattern: '[0-9][0-9\\- ]{0,10}[0-9]$',
        message: 'is invalid',
      },
    },
    macAddress: {
      presence: true,
      length: {
        minimum: 17,
        maximum: 17,
      },
    },
  },
};
