export default {
  strings: {
    main: `Client overview`,
    lead: `Check some information about `,
    cardTitle: `Main data`,
    cardSubtitle: `If you wish to update the current data, please select the Edit option (Clients List menu).`,
  },
  keys: {
    status: 'Status',
    createdAt: `Created at`,
    updatedAt: `Updated at`,
    client_id: 'Client ID',
    email: 'E-mail',
    company: 'Company',
    _id: 'ID',
    details: 'Profile Details',
    client_user: 'Client User',
    client_secret: 'Client token',
    __v: '__v',
    first_name: 'First name',
    last_name: 'Last name',
    address: 'Address',
    phone: 'Phone number',
    state: 'State',
    city: 'City',
    zip_code: 'Zip Code',
  },
  tooltip: {
    title: `Grab the code`,
    txt: `Copy the code to clipboard`,
  },
};
