import React from 'react';
import { IoCheckmark } from 'react-icons/io5';
import { MdOutlineCancelPresentation } from 'react-icons/md';

export default {
  strings: {
    breadcrumbCreate: `Add a new record`,
    tooltipTitle: `Get a new ID`,
    tooltipTxt: `Generate a different ID number by clicking on the button GENERATE ID`,
    tooltipTitle1: `The company ID`,
    tooltipTxt1: `This is the company ID that has been generated when the company was created. This field is immutable.`,
    warning: `Please make sure all the required fields are filled to proceed!`,
    emptyFields: `All the fields must be filled.`,
  },
  modalStrings: {
    confirm: ``,
    warning: `Information`,
    alertStr: ``,
    alertStr2: ``,
    sureToProceed: `Are you sure you wish to proceed?`,
    confirmation: `Are you sure you wish to proceed?`,
  },
  notifications: {
    create: {
      success: 'Company created successfully!',
      error: 'Something went wrong! The company could not be created.',
    },
  },
  titles: {
    create__comp: `Create company`,
  },
  icons: {
    create__comp: <IoCheckmark className="edit" />,
    cancel__op: <MdOutlineCancelPresentation className="edit" />,
  },
  bodies: {
    create__comp: `You are about to create a company.`,
  },
  labels: {
    create__comp: ` OK`,
  },
  bodyClass: {
    create__comp: `alert alert-info`,
    edit__comp: `alert alert-danger`,
  },
  genLabel: {
    create: `GENERATE ID`,
  },
  companyCreate: {
    mainTitle: `New company`,
    pText: `Add the company information below.`,
    title: `Create company`,
    breadcrumbItem: `/admin/dashboard/company/add-edit`,
    class: `jumbotron container-jumbo jmb-min-h--1`,
  },
  fields: [
    {
      outerClass: 'form-group',
      label: 'Company Name',
      htmlFor: 'exampleInputEmail1',
      type: 'text',
      id: 'company-name',
      ariaDescribedby: 'inputHelp',
      placeholder: 'Enter name',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'name',
      disabled: '',
      isPhone: false,
      isCode: false,
    },
    {
      outerClass: 'form-group',
      label: 'E-mail address',
      htmlFor: 'exampleInputEmail1',
      type: 'email',
      id: 'company-email',
      ariaDescribedby: 'emailHelp',
      placeholder: 'Enter e-mail address',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'email',
      disabled: '',
      isPhone: false,
      isCode: false,
    },
    {
      outerClass: 'form-group',
      label: 'Phone number',
      htmlFor: 'exampleInputPassword1',
      type: 'text',
      id: 'telephone-input-spec',
      ariaDescribedby: '',
      placeholder: 'Phone number',
      className: 'form-control mb-2 phone--cls',
      labelClass: 'lead label-create-user',
      name: 'phone',
      disabled: '',
      isPhone: true,
      isCode: false,
    },
    {
      outerClass: 'form-group',
      label: 'Company ID',
      htmlFor: 'exampleInputCompNumber',
      type: 'text',
      id: 'company-id',
      ariaDescribedby: 'emailHelp',
      placeholder: 'Generated ID',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'company-id',
      disabled: true,
      isPhone: false,
      isCode: true,
    },
  ],
  formConfig: [
    {
      label: 'State',
      className: 'col-xs-12 col-sm-12 cont--1--p auto-sug-field1',
      placeholder: 'State',
      id: 'state',
      type: 'auto-suggest',
      field: 'device_state',
      fields: {
        label: 'Company Location - State',
        type: 'dropdown',
        title: 'Enter State',
        placeholder: 'Enter company state',
      },
    },
    {
      label: 'City',
      className: 'col-xs-12 col-sm-12 cont--1--p auto-sug-field1',
      placeholder: 'City',
      id: 'city',
      type: 'auto-suggest',
      field: 'device_city',
      fields: {
        label: 'Company Location - City',
        type: 'dropdown',
        title: 'Enter City',
        placeholder: 'Enter company city',
      },
    },
    {
      label: 'Zip Code',
      className: 'col-xs-12 col-sm-12 cont--1--p auto-sug-field1',
      placeholder: 'Zip Code',
      id: 'zip_code',
      type: 'auto-suggest',
      field: 'device_zipcode',
      fields: {
        label: 'Company Location - Zip Code',
        type: 'dropdown',
        title: 'Enter zip code',
        placeholder: 'Enter company zip code',
      },
    },
  ],
  constraints: {
    name: {
      presence: true,
      length: {
        minimum: 1,
      },
    },
    email: {
      email: true,
      presence: true,
    },
    state: {
      presence: true,
      length: {
        minimum: 2,
      },
    },
    city: {
      presence: true,
      length: {
        minimum: 2,
      },
    },
    zipcode: {
      presence: true,
      length: {
        minimum: 5,
        maximum: 10,
      },
      format: {
        pattern: '[0-9][0-9\\- ]{0,10}[0-9]$',
        message: 'is invalid',
      },
    },
    phone: {
      presence: true,
      length: {
        minimum: 11,
        maximum: 11,
      },
    },
    device_name: {
      presence: true,
      length: {
        minimum: 6,
      },
    },
    status: {
      presence: false,
    },
    device_state: {
      presence: true,
      length: (
        _value: any,
        attributes: any,
        attributeName: any,
        options: any,
        constraints: any,
      ) => {
        const {
          countryCode,
          isoCode,
          label,
          latitude,
          longitude,
          name,
          value,
        } = _value;
        return !(
          countryCode &&
          isoCode &&
          label &&
          latitude &&
          longitude &&
          name &&
          value
        );
      },
    },

    device_city: {
      presence: true,
      length: (
        _value: any,
        attributes: any,
        attributeName: any,
        options: any,
        constraints: any,
      ) => {
        const {
          countryCode,
          label,
          latitude,
          longitude,
          name,
          stateCode,
          value,
        } = _value;
        return !(
          countryCode &&
          label &&
          latitude &&
          longitude &&
          name &&
          stateCode &&
          value
        );
      },
    },
    device_zipcode: {
      presence: true,
      length: (
        _value: any,
        attributes: any,
        attributeName: any,
        options: any,
        constraints: any,
      ) => {
        const {
          city,
          country,
          label,
          latitude,
          longitude,
          value,
          zip,
        } = _value;
        return !(
          city &&
          country &&
          label &&
          latitude &&
          longitude &&
          value &&
          zip
        );
      },
    },
  },
  emailRegex: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  states: {
    formValues: {
      device_city: {},
      device_state: {},
      device_zipcode: {},
    },
  },
  defaults: {
    state: {
      name: 'Massachusetts',
      isoCode: 'MA',
      countryCode: 'US',
      latitude: '42.40721070',
      longitude: '-71.38243740',
      label: 'Massachusetts',
      value: 'MA',
    },
    city: {
      name: 'Boston',
      countryCode: 'US',
      stateCode: 'MA',
      latitude: '42.35843000',
      longitude: '-71.05977000',
      label: 'Boston',
      value: 'Boston',
    },
  },
};
