import React, { useEffect, useState } from 'react';
import './styles.scss';

interface DetailsProps {
  array: any;
  currentPeripheral: any;
  config: any;
  returnValue: Function;
}

const Details: React.FC<DetailsProps> = ({
  array,
  currentPeripheral,
  config,
  returnValue,
}) => {
  const [filteredArr, setFilteredArr] = useState<any>([]);
  const [wattProps, setWattProps] = useState<any>([]);

  useEffect(() => {
    setWattProps(
      array.filter(
        (item: any) =>
          currentPeripheral.hasOwnProperty(item.term) &&
          (item.term === 'vrms' || item.term === 'irms'),
      ),
    );

    setFilteredArr(
      array.filter(
        (item: any) =>
          currentPeripheral.hasOwnProperty(item.term) &&
          (item.term !== 'vrms' || item.term !== 'irms'),
      ),
    );
  }, [array, currentPeripheral]);

  return (
    <div className="container system--details--information">
      <div className="row pdg">
        <div className="col-xs-12 col-sm-12 col-md-6 pdg-left">
          {wattProps.map((element: any, index: number) => {
            if (currentPeripheral.hasOwnProperty(element.term)) {
              return (
                <div className="container labels--terms2" key={index}>
                  <div className="row labels--terms label-row">
                    <div className={`col labels--terms2`}>
                      <div className="container col-p-0">
                        <div className="row col-p-0">
                          <div className="col-auto col-p-0 data-name">
                            <span className={`information- lead`}>
                              {
                                config.objectLabels[
                                  element.term as keyof typeof config.objectLabels
                                ]
                              }
                            </span>{' '}
                          </div>
                          <div className="col-2 col-p-0">
                            <span className={`badge term--pill sm-left-mg`}>
                              {element.term}
                            </span>{' '}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={`col-4 labels--terms2 align--r`}>
                      <span className={`information- lead`}>
                        {returnValue(element.term)}
                      </span>{' '}
                    </div>
                  </div>
                </div>
              );
            } else {
              return null;
            }
          })}

          {filteredArr
            .slice(0, filteredArr.length / 2 + 1)
            .map((element: any, index: number) => {
              if (currentPeripheral.hasOwnProperty(element.term)) {
                return (
                  <div className="container labels--terms2" key={index}>
                    <div className="row labels--terms label-row">
                      <div className={`col labels--terms2`}>
                        <div className="container col-p-0">
                          <div className="row col-p-0">
                            <div className="col-auto col-p-0 data-name">
                              <span className={`information- lead`}>
                                {
                                  config.objectLabels[
                                    element.term as keyof typeof config.objectLabels
                                  ]
                                }
                              </span>{' '}
                            </div>
                            <div className="col-2 col-p-0">
                              <span className={`badge term--pill sm-left-mg`}>
                                {element.term}
                              </span>{' '}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`col-4 labels--terms2 align--r`}>
                        <span className={`information- lead`}>
                          {returnValue(element.term)}
                        </span>{' '}
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
        </div>
        <div className="col-xs-12 col-sm-12 col-md-6 pdg-right">
          {filteredArr
            .slice(filteredArr.length / 2 + 1)
            .map((element: any, index: number) => {
              if (currentPeripheral.hasOwnProperty(element.term)) {
                return (
                  <div className="container labels--terms2" key={index}>
                    <div className="row labels--terms label-row">
                      <div className={`col labels--terms2`}>
                        <div className="container col-p-0">
                          <div className="row col-p-0">
                            <div className="col-auto col-p-0 data-name">
                              <span className={`information- lead`}>
                                {
                                  config.objectLabels[
                                    element.term as keyof typeof config.objectLabels
                                  ]
                                }
                              </span>{' '}
                            </div>
                            <div className="col-2 col-p-0">
                              <span className={`badge term--pill sm-left-mg`}>
                                {element.term}
                              </span>{' '}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className={`col-4 labels--terms2 align--r`}>
                        <span className={`information- lead`}>
                          {returnValue(element.term)}
                        </span>{' '}
                      </div>
                    </div>
                  </div>
                );
              } else {
                return null;
              }
            })}
        </div>
      </div>
    </div>
  );
};

export default Details;
