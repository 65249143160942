import { ConnectionBroker } from '../../core';

export const apiState = {
  state: {
    currentLanguage: 'JavaScript - Fetch',
  },
  reducers: {
    updateLanguageChoice(state: any, payload: string) {
      return {
        ...state,
        currentLanguage: payload,
      };
    },
  },
  effects: (dispatch: any) => ({}),
};
