const config = {
  strings: {
    title: 'User details',
  },
  states: {
    user: {
      addresses: [],
      email: '',
      first_name: '',
      last_name: '',
      phones_numbers: [],
    },
    address: {
      address_line_one: '',
      address_line_two: '',
      zip_code: '',
      city: '',
      state: '',
    },
    errors: {
      emailError: '',
      phoneError: '',
      first_name: '',
      last_name: '',
      address_line_one: '',
      city: '',
      state: '',
      zip_code: '',
    },
  },
  regex: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  tabs: [
    { id: 'dev', label: 'Devices', action: '', goToSection: '', active: true },
    {
      id: 'sens',
      label: 'Sensors',
      action: '',
      goToSection: '',
      active: false,
    },
    {
      id: 'sys',
      label: 'System Settings',
      action: '',
      goToSection: '',
      active: false,
    },
    {
      id: 'oa',
      label: 'Outside Air',
      action: '',
      goToSection: '',
      active: false,
    },
    {
      id: 'ia',
      label: 'Indoor Air',
      action: '',
      goToSection: '',
      active: false,
    },
    {
      id: 'pc',
      label: 'Pressure Connect',
      action: '',
      goToSection: '',
      active: false,
    },
    {
      id: 'mc',
      label: 'Manual Control',
      action: '',
      goToSection: '',
      active: false,
    },
  ],
  tabsObject: {
    dev: 'dev',
    sens: 'sens',
    sys: 'sys',
    oa: 'oa',
    ia: 'ia',
    pc: 'pc',
    fc: 'fc',
    mc: 'mc',
  },
  formFields: [
    {
      outerClass: 'form-group',
      label: 'First name',
      htmlFor: 'exampleInputEmail1',
      type: 'text',
      id: 'first_name_1',
      ariaDescribedby: 'inputHelp',
      placeholder: 'Enter name',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'first_name',
      disabled: true,
    },
    {
      outerClass: 'form-group',
      label: 'Last name',
      htmlFor: 'exampleInputEmail1',
      type: 'text',
      id: 'last_name_1',
      ariaDescribedby: 'inputHelp',
      placeholder: 'Enter surname',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'last_name',
      disabled: true,
    },
    {
      outerClass: 'form-group',
      label: 'E-mail',
      htmlFor: 'exampleInputCompNumber',
      type: 'text',
      id: 'email_1',
      ariaDescribedby: 'emailHelp',
      placeholder: 'Enter e-mail',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'email',
      disabled: true,
    },

    {
      outerClass: 'form-group',
      label: 'Phone number',
      htmlFor: 'exampleInputPassword1',
      type: 'text',
      id: 'user_profile_phone1',
      ariaDescribedby: ' ',
      placeholder: 'Phone number',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'phone',
      disabled: true,
    },
    {
      outerClass: 'form-group',
      label: 'Address - line 1',
      htmlFor: 'exampleInputState',
      type: 'text',
      id: 'address_1',
      ariaDescribedby: 'inputHelp',
      placeholder: 'Enter Address',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'address_line_one',
      disabled: true,
    },
    {
      outerClass: 'form-group',
      label: 'Address - line 2',
      htmlFor: 'exampleInputState',
      type: 'text',
      id: 'address_2',
      ariaDescribedby: 'inputHelp',
      placeholder: 'Enter Address (cont.)',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'address_line_two',
      disabled: true,
    },
    {
      outerClass: 'form-group',
      label: 'State',
      htmlFor: 'exampleInputState',
      type: 'text',
      id: 'state_edit1',
      ariaDescribedby: 'inputHelp',
      placeholder: 'Enter State',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'state',
      disabled: true,
    },
    {
      outerClass: 'form-group',
      label: 'City',
      htmlFor: 'exampleInputState',
      type: 'text',
      id: 'city_edit1',
      ariaDescribedby: 'inputHelp',
      placeholder: 'Enter City',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'city',
      disabled: true,
    },
    {
      outerClass: 'form-group',
      label: 'Zip Code',
      htmlFor: 'exampleInputZip',
      type: 'text',
      id: 'zip_edit1',
      ariaDescribedby: 'inputHelp',
      placeholder: 'Enter zip code',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'zip_code',
      disabled: true,
    },
  ],
  constraints: {
    first_name: {
      presence: true,
      length: {
        minimum: 1,
      },
    },
    last_name: {
      presence: true,
      length: {
        minimum: 1,
      },
    },
    email: {
      presence: true,
      email: true,
    },
    phone: {
      presence: true,
    },
    address_line_one: {
      presence: true,
      length: {
        minimum: 3,
      },
    },
    address_line_two: {
      presence: false,
    },
    zip_code: {
      presence: true,
      length: {
        minimum: 5,
        maximum: 10,
      },
      format: {
        pattern: '[0-9][0-9\\- ]{0,10}[0-9]$',
        message: 'is invalid',
      },
    },
    city: {
      presence: true,
      length: {
        minimum: 2,
      },
    },
    state: {
      presence: true,
      length: {
        minimum: 2,
      },
    },
  },
  sections: {
    dev: 'Devices',
    sens: 'Sensors Settings',
    sys: 'System Settings',
    oa: 'Outside Air',
    ia: 'Indoor Air',
    fc: 'Fresh Connect',
    pc: 'Pressure Connect',
    mc: 'Manual Control',
  },
  leads: {
    dev: `Main information on devices.`,
    sens: `Main information on sensors.`,
    sys: `Main information on Systems.`,
    oa: `More about Outside Air.`,
    ia: `More about  Indoor Air.`,
    pc: `More about  Pressure Connect.`,
    fc: `More about Fresh Connect.`,
    mc: `Details on Manual Control.`,
  },
  variables: {
    GLOBAL_SCOPE: {
      currentHour: new Date().getHours(),
      _devices: [],
      devices: {
        new: [],
        old: [],
        oldInit: false,
      },
      charts: [],
      devicesUpdated: [],
    },
    WEBSOCKETS: {},
    STACKS: [],
    TIME_INTERVALS: {},
    TIME_VALUES: {},
  },
  state: {
    allData: {
      sensors: [],
      devices: [],
      sensorsLoaded: false,
      devsLoaded: false,
    },
  },
  gridData: [
    {
      title: `Devices`,
      subtitle: `Devices:`,
    },
    {
      title: `Details`,
      subtitle: `This user`,
    },
    {
      title: `Location`,
      subtitle: `User is in`,
    },
    {
      title: `Account`,
      subtitle: `3rd party devices?`,
    },
  ],
  modalStrings: {
    edit: `Update user data`,
    confirm: ``,
    warning: `Information`,
    alertStr: ``,
    alertStr2: ``,
    confirmation: ``,
    sureToProceed: `Are you sure you wish to proceed?`,
    noUndo: ``,
    aboutToUpdate: `You are about to edit the user information.`,
    labelOK: ` OK`,
  },
};

export { config };
