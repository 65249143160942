import React, { useState, useEffect } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { DeleteRequestsProps } from './interface';
import { helpers } from './helpers';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import {
  Modal,
  Notification,
  Tabs,
  Template,
} from 'business/modules/admin/common';
import { RequestList } from './components';
import './styles.scss';

const DeleteRequests: React.FC<DeleteRequestsProps> = ({
  getDeleteRequests,
  deleteRequestList,
  deleteRequestsLoaded,
  orderType,
  defaultOrder,
  selectedOrder,
  handleCounterAndListOrder,
  intendedCounter,
  counter,
  loading,
  defineOrderType,
  selectListItem,
  handleMenuAction,
  actionToPerformOnRequest,
  selectedRequest,
  deletePendingRequest,
  cancelPendingRequest,
  modalMustClose,
  handleModalState,
  loadingErrors,
}) => {
  const [currentlyActive, setCurrentlyActive] = useState<string>(
    'all-requests',
  );

  const incremented: any = {
    name: intendedCounter.name + 1,
    date: intendedCounter.date + 1,
    status: intendedCounter.status + 1,
  };

  const changeTab = (tab: string) => {
    setCurrentlyActive(tab);
  };

  const retrieveList = (currentlyActive: string) => {
    switch (currentlyActive) {
      case 'all-requests':
        return deleteRequestList;
      case 'await-confirmation':
        return deleteRequestList.filter((item: any) => !item.confirmed);
      case 'confirmed-reqs':
        return deleteRequestList.filter(
          (item: any) => item.confirmed && !item.deletedBy,
        );
      case 'deleted-reqs':
        return deleteRequestList.filter((item: any) => item.deletedBy);
      default:
        return deleteRequestList;
    }
  };

  const fetchRequests = async () => {
    await getDeleteRequests();
  };

  const performOperation = async (actionToPerformOnRequest: any) => {
    if (actionToPerformOnRequest === `delete__req`) {
      const data: any = {
        id: selectedRequest?.id,
        userId: selectedRequest?.account_id,
      };

      let response: any = await deletePendingRequest(data);

      if (response && response?.statusCode === 200) {
        Notification({
          title: 'Success!',
          message: config?.notifications?.delete?.success,
          type: 'success',
        });
      } else {
        Notification({
          title: 'Warning!',
          message: config?.notifications?.delete?.error,
          type: 'warning',
        });
      }
    } else if (actionToPerformOnRequest === `cancel__req`) {
      let response: any = await cancelPendingRequest({
        id: selectedRequest?.id,
      });

      if (response && response?.statusCode === 200) {
        //success
        Notification({
          title: 'Success!',
          message: config?.notifications?.cancel?.success,
          type: 'success',
        });
      } else {
        //error
        Notification({
          title: 'Warning!',
          message: config?.notifications?.cancel?.error,
          type: 'warning',
        });
      }
    }

    await fetchRequests();
  };

  useEffect(() => {
    fetchRequests();
  }, []);

  useEffect(() => {
    return () => {
      handleMenuAction(`init`);
    };
  }, []);

  useEffect(() => {
    if (!deleteRequestsLoaded) {
      loading.start(config?.strings?.delReqs);
    } else {
      loading.stop();
    }
  }, [deleteRequestsLoaded]);

  return (
    <Template id="delete-reqs-list" title={config.strings.title}>
      <BreadcrumbsItem to={config?.strings?.breadcrumbItemTo}>
        {config?.strings?.breadcrumbTitle}
      </BreadcrumbsItem>
      <div className="container">
        <div className="row">
          <Tabs
            tabs={config?.tabs}
            currentlyActive={currentlyActive}
            changeTab={changeTab}
            spaced={'spec-cols'}
          />

          <RequestList
            loadingErrors={loadingErrors}
            selectListItem={selectListItem}
            list={retrieveList(currentlyActive)}
            loaded={deleteRequestsLoaded}
            itemsPerPage={10}
            orderType={orderType}
            defaultOrder={defaultOrder}
            selectedOrder={selectedOrder}
            handleCounterAndListOrder={handleCounterAndListOrder}
            intendedCounter={intendedCounter}
            counter={counter}
            defineOrderType={defineOrderType}
            handleMenuAction={handleMenuAction}
            actionToPerformOnRequest={actionToPerformOnRequest}
            incremented={incremented}
          />
        </div>
      </div>
      <Modal
        config={config}
        bodyClass={helpers.handleOpts(actionToPerformOnRequest, 'bodyClass')}
        className={
          actionToPerformOnRequest === `cf-delete-acc-req`
            ? `small-button nok`
            : `small-button ok`
        }
        onClick={() => {
          performOperation(actionToPerformOnRequest);
        }}
        title={helpers.handleOpts(actionToPerformOnRequest, `title`)}
        id={actionToPerformOnRequest}
        icon={helpers.handleOpts(actionToPerformOnRequest, `icon`)}
        body={helpers.handleOpts(actionToPerformOnRequest, 'bodies')}
        modalMustClose={modalMustClose}
        handleModalState={handleModalState}
        label={
          config.labels[actionToPerformOnRequest as keyof typeof config.labels]
        }
        hasConfirmBtn={true}
        sureToProceed={config.modalStrings.confirmation}
      />
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminRequestsState } = state;
  return {
    selectedRequest: adminRequestsState.selectedRequest,
    actionToPerformOnRequest: adminRequestsState.actionToPerformOnRequest,
    deleteRequestList: adminRequestsState.deleteRequestList,
    deleteRequestsLoaded: adminRequestsState.deleteRequestsLoaded,
    orderType: adminRequestsState.orderType,
    defaultOrder: adminRequestsState.defaultOrder,
    selectedOrder: adminRequestsState.selectedOrder,
    intendedCounter: adminRequestsState.intendedCounter,
    counter: adminRequestsState.counter,
    modalMustClose: adminRequestsState.modalMustClose,
    loadingErrors: adminRequestsState.loadingErrors,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminRequestsState, loader } = dispatch;

  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    cancelPendingRequest: adminRequestsState.cancelPendingRequest,
    deletePendingRequest: adminRequestsState.deletePendingRequest,
    handleMenuAction: adminRequestsState.handleMenuAction,
    selectListItem: adminRequestsState.selectListItem,
    defineOrderType: adminRequestsState.defineOrderType,
    getDeleteRequests: adminRequestsState.getDeleteRequests,
    handleCounterAndListOrder: adminRequestsState.handleCounterAndListOrder,
    handleModalState: adminRequestsState.handleModalState,
    handleError: adminRequestsState.handleError,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeleteRequests);
