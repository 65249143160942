import React, { useEffect, useState } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { AdminUsersProps } from './interface';
import { RiImageEditLine } from 'react-icons/ri';
import { useHistory } from 'react-router-dom';
import { MdDelete } from 'react-icons/md';
import { HiOutlinePlus } from 'react-icons/hi';
import {
  List,
  Modal,
  Template,
  Notification,
} from 'business/modules/admin/common';
import {
  ConnectedIcon,
  DisconnectedIcon,
} from '../../../../../../assets/device-icons';
import { MdErrorOutline } from 'react-icons/md';
import './styles.scss';

const AdminUsers: React.FC<AdminUsersProps> = ({
  listOfAdminUsers,
  admUsersAreLoaded,
  loading,
  getListOfAdminUsers,
  adminOrders,
  adminIntendedCounter,
  adminCounter,
  adminsOrderedList,
  handleAdminListCounterAndOrder,
  updateAdmOrderState,
  storeClickedUser,
  adminUser,
  deleteAdminUser,
  modalMustClose,
  handleModalState,
  handleErr,
  loadingErrors,
}) => {
  const history = useHistory();
  const [current, setCurrent] = useState('');
  const [unorderedDefault, setUnorderedDefault] = useState([]);
  const [users, setUsers] = useState([]);

  const incremented: any = {
    email: adminIntendedCounter.email + 1,
    status: adminIntendedCounter.status + 1,
  };

  const deleteAdmUser = async () => {
    let response: any = await deleteAdminUser(adminUser?.account_id);

    if (response && response?.statusCode === 200) {
      Notification({
        title: 'Success!',
        message: config?.notifications?.delete?.success,
        type: 'success',
      });
    } else {
      Notification({
        title: 'Error!',
        message: config?.notifications?.delete?.error,
        type: 'error',
      });
    }

    await fetchData();
  };

  const createAdminUser = () => {
    history.push(`/admin/dashboard/create-user`);
  };

  const handleView = (user: any) => {
    history.push(`/admin/dashboard/admin-user-details/${user.account_id}`, {
      id: user.account_id,
      user,
    });
  };

  const returnSuitableData = (key: string, item: any) => {
    return item[key] || '';
  };

  const retrieveColValue = (item: any, position: string) => {
    switch (position) {
      case '1':
        return item.username;
      case '2':
        return (
          <>
            <span className="lead spec-typog">
              {item?.status === 'active' ? (
                <ConnectedIcon className="icon-style2308" />
              ) : (
                <DisconnectedIcon className="icon-style2308" />
              )}
            </span>

            <span className="lead custom-lead1 bold-ft">
              {item?.status.toUpperCase()}
            </span>
          </>
        );

      default:
        return ``;
    }
  };

  const clickToUpload = () => {
    return document?.getElementById('file-input-id-d')?.click();
  };

  const handleImgErr = (event: any) => {
    event.target.src = '/avatar.png';
  };

  const returnAccordionDetails = (item: any) => {
    return (
      <div id="admin--user--details" className={`container user-data-contents`}>
        <div className={`row`}>
          <div className="col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-4 pdg-r15 pdgl0 pt--10">
            <div className={`row d-flex justify-content-center mb--1`}>
              <div className="row upload-01" id="upload-input-d">
                <input
                  onError={handleImgErr}
                  type="file"
                  id="file-input-id-d"
                  name="avatar"
                  accept="image/png, image/jpeg"
                />
                <div className="col-5"> </div>
                <div className="col-2 btn--cont--up">
                  <button
                    className="btn btn-sm upload-btn btn-up-small-button"
                    onClick={clickToUpload}
                    value="Choose file"
                  >
                    <RiImageEditLine className="edit-img" />
                  </button>
                </div>
              </div>

              <div className="circle-avt">
                <span className="span--adm--acc">
                  {item.username[0].toUpperCase()}
                </span>
                ;
              </div>
            </div>

            {config.formInfo.map((elem: any, i: number) => {
              return (
                <div className="container pdg" key={elem.info}>
                  <div className="row ptpb2">
                    <div className="col-1 lead the--icon--side">
                      {elem.icon}
                    </div>
                    <div className="col-11 pdg lead custom-lead word--br--all">
                      {returnSuitableData(elem.info, item)}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>

          <div
            className={`col-xs-12 col-sm-12 col-md-10 col-lg-6 col-xl-8 pt--10 pdg`}
          >
            <div className="card-body table--card--bd">
              <h5 className="card-title">{`Basic information`}</h5>
              <h6 className="card-subtitle mb-2 text-muted">{`Details`}</h6>
              <p className="card-text lead custom-lead">{`Some content / data about this admin account.`}</p>

              <div id="details--adm--table">
                <table className="table table--mb0">
                  <thead>
                    <tr>
                      {config.adminData.map((item: any, index: number) => {
                        return (
                          <th scope="col table--details" key={index}>
                            {item.label}
                          </th>
                        );
                      })}
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      {config.adminData.map((item: any, index: number) => {
                        return (
                          <td className="custom-lead lead tbl--p" key={index}>
                            {item.value}
                          </td>
                        );
                      })}
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>

            <div className="card-body btn--section--adm">
              {config.buttons.map((btn: any, index: number) => {
                return (
                  <button className={btn.className} key={index}>
                    {btn.icon}
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const fetchData = async () => {
    let savedDefaultArr: any = [];
    loading.start('Loading Users...');

    const response: any = await getListOfAdminUsers();

    if (
      response?.statusCode === 200 &&
      response?.body &&
      response?.body?.data
    ) {
      if (
        Array.isArray(response?.body?.data) &&
        response?.body?.data.length > 0
      ) {
        let res = response?.body?.data.forEach((item: any) => {
          savedDefaultArr.push(item);
        });
        setUnorderedDefault(savedDefaultArr);
      }
    } else {
      handleErr('adminUsersListErr');
      setUnorderedDefault(savedDefaultArr);
    }

    loading.stop();
  };

  useEffect(() => {
    if (adminOrders.orderByUserName) {
      setCurrent(`email`);
    } else if (adminOrders.orderByStatus) {
      setCurrent(`status`);
    } else if (
      adminsOrderedList &&
      admUsersAreLoaded &&
      unorderedDefault.length > 0
    ) {
      setCurrent(``);
    }
  }, [unorderedDefault, adminOrders, adminIntendedCounter, adminCounter]);

  useEffect(() => {
    return () => {
      handleAdminListCounterAndOrder(config.resettedCounter, 0);
    };
  }, []);

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    if (listOfAdminUsers && listOfAdminUsers.length > 0 && admUsersAreLoaded) {
      setUsers(listOfAdminUsers);
    }
  }, [listOfAdminUsers]);

  useEffect(() => {
    return () => {};
  }, []);

  return (
    <Template
      id="dashboard-admin"
      title={config.strings.title1}
      hasRightBtn={true}
      fun={createAdminUser}
      icon={<HiOutlinePlus className="svg-refresh" />}
    >
      {loadingErrors &&
      loadingErrors?.adminUsersListErr &&
      loadingErrors?.adminUsersListErr?.length > 0 ? (
        <div id="errors-display--3">
          <div className="alert alert-info" role="alert">
            <MdErrorOutline className="alert-icon" />
            <span className="lead err-string">
              {loadingErrors?.adminUsersListErr}
            </span>
          </div>
        </div>
      ) : (
        <List
          id="users-list-data02"
          rowClasses={config.rowClasses}
          itemsPerPage={10}
          list={users}
          loaded={admUsersAreLoaded}
          current={current}
          orders={adminOrders}
          manageListOrder={handleAdminListCounterAndOrder}
          orderedList={adminsOrderedList}
          sectionCounter={adminIntendedCounter}
          sectionCount={adminCounter}
          updateOrderState={updateAdmOrderState}
          incremented={incremented}
          unorderedDefault={unorderedDefault}
          handles={{}}
          config={config}
          firstFun={(item: any) => {
            handleView(adminUser);
          }}
          setItem={(item: any) => {
            storeClickedUser(item);
          }}
          retrieveColValue={retrieveColValue}
          returnAccordionDetails={returnAccordionDetails}
          helperOrderData={[]}
        />
      )}

      <Modal
        config={config}
        id={`delete-admin-user0101`}
        title={config.modal.delete.title}
        icon={<MdDelete className="edit" />}
        body={config?.modalStrings?.aboutToDel}
        modalMustClose={modalMustClose}
        handleModalState={handleModalState}
        onClick={() => {
          deleteAdmUser();
        }}
        label={config?.modalStrings?.labelOK}
        className={`small-button nok`}
        bodyClass={`alert alert-danger`}
        sureToProceed={config?.modalStrings?.sureToProceed}
        hasConfirmBtn={true}
        warning={config?.modalStrings?.noUndo}
      />
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminUsersState, adminState } = state;
  return {
    adminUser: adminState.adminUser,
    currentUser: adminUsersState.currentUser,
    listOfAdminUsers: adminUsersState.listOfAdminUsers,
    admUsersAreLoaded: adminUsersState.admUsersAreLoaded,
    adminIntendedCounter: adminUsersState.adminIntendedCounter,
    adminCounter: adminUsersState.adminCounter,
    adminOrders: adminUsersState.adminOrders,
    adminsOrderedList: adminUsersState.adminsOrderedList,
    modalMustClose: adminState.modalMustClose,
    loadingErrors: adminUsersState.loadingErrors,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader, adminState, adminUsersState } = dispatch;

  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    handleErr: adminUsersState.handleErr,
    handleModalState: adminState.handleModalState,
    deleteAdminUser: adminState.deleteAdminUser,
    storeClickedUser: adminState.storeClickedUser,
    getListOfAdminUsers: adminUsersState.getListOfAdminUsers,
    updateAdmOrderState: adminUsersState.updateAdmOrderState,
    handleAdminListCounterAndOrder:
      adminUsersState.handleAdminListCounterAndOrder,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminUsers);
