import React from 'react';
import { FiLogOut, FiHome } from 'react-icons/fi';
import { MdOutlineAdminPanelSettings } from 'react-icons/md';
import { MdOutlineFormatListBulleted } from 'react-icons/md';
import { AiOutlineUserDelete } from 'react-icons/ai';
import { TbNumbers } from 'react-icons/tb';
import { SiPostman } from 'react-icons/si';
import { TbApiApp } from 'react-icons/tb';

export default {
  navItems: [
    {
      id: `dashboard-option-01`,
      liClassName: `sidebar-item`,
      btnClassName: `sidebar-link selected sidebar-link`,
      route: `/admin/dashboard`,
      icon: <FiHome className="menu-zones-icon" />,
      iconClassName: ``,
      spanClassName: `hide-menu pl-2`,
      spanLabel: `Dashboard`,
      liDivider: `list-divider`,
    },
    {
      id: `adm-list-option-01`,
      liClassName: `sidebar-item`,
      btnClassName: `sidebar-link selected sidebar-link`,
      route: `/admin/dashboard/admin-list`,
      icon: <MdOutlineAdminPanelSettings className="menu-zones-icon" />,
      iconClassName: ``,
      spanClassName: `hide-menu pl-2`,
      spanLabel: `List of Admins`,
      liDivider: `list-divider`,
    },
    {
      id: `co-list-option-01`,
      liClassName: `sidebar-item`,
      btnClassName: `sidebar-link selected sidebar-link`,
      route: `/admin/dashboard/companies-list`,
      icon: <MdOutlineFormatListBulleted className="menu-zones-icon" />,
      iconClassName: ``,
      spanClassName: `hide-menu pl-2`,
      spanLabel: `List of Companies`,
      liDivider: `list-divider`,
    },

    {
      id: `dev-list-option-01`,
      liClassName: `sidebar-item`,
      btnClassName: `sidebar-link selected sidebar-link`,
      route: `/admin/dashboard/clients`,
      icon: <TbApiApp className="menu-zones-icon" />,
      iconClassName: ``,
      spanClassName: `hide-menu pl-2`,
      spanLabel: `Clients`,
      liDivider: `list-divider`,
    },

    {
      id: `fmw-option-01`,
      liClassName: `sidebar-item`,
      btnClassName: `sidebar-link selected sidebar-link`,
      route: `/admin/dashboard/firmware-version`,
      icon: <TbNumbers className="menu-zones-icon" />,
      iconClassName: ``,
      spanClassName: `hide-menu pl-2`,
      spanLabel: `Firmware`,
      liDivider: `list-divider`,
    },
    {
      id: `del-reqs-option-01`,
      liClassName: `sidebar-item`,
      btnClassName: `sidebar-link selected sidebar-link`,
      route: `/admin/dashboard/delete-requests`,
      icon: <AiOutlineUserDelete className="menu-zones-icon" />,
      iconClassName: ``,
      spanClassName: `hide-menu pl-2`,
      spanLabel: `Delete Requests`,
      liDivider: `list-divider`,
    },
    {
      id: `api-request-option`,
      liClassName: `sidebar-item`,
      btnClassName: `sidebar-link selected sidebar-link`,
      route: `/admin/dashboard/api-request`,
      icon: <SiPostman className="api-request-icon" />,
      iconClassName: ``,
      spanClassName: `hide-menu pl-2`,
      spanLabel: `Api Request`,
      liDivider: `list-divider`,
    },
    {
      id: `logout-option-0`,
      liClassName: `sidebar-item menu-logout`,
      btnClassName: `sidebar-link sidebar-link`,
      route: ``,
      icon: <FiLogOut className="menu-zones-icon" />,
      iconClassName: ``,
      spanClassName: `hide-menu pl-2`,
      spanLabel: `Logout`,
      liDivider: `sidebar-item menu-footer`,
    },
  ],
};
