import React from 'react';
import config from './config';
import moment from 'moment-timezone';

const formatDate = (date: any, key: string) => {
  if (key === `createdAt` || key === `updatedAt`) {
    return date ? moment(new Date(date)).format('MM-DD-YYYY, hh:mm A') : '';
  }
};

const returnLabel = (key: string) => {
  return (
    <span className="badge badge-info spec-info-bdg">
      {transformKeyString(key)}
    </span>
  );
};

const transformKeyString = (key: string) => {
  if (config.keys && config.keys.hasOwnProperty(key)) {
    return config.keys[key as keyof typeof config.keys];
  }
};

const keyIsDate = (key: string) => {
  return key === `createdAt` || key === `updatedAt` ? true : false;
};

export const functions = {
  formatDate: formatDate,
  returnLabel: returnLabel,
  keyIsDate: keyIsDate,
};
