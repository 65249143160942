import config from './config';

const handleErrors = (name: string, value: any) => {
  let macAddressRegex = /^([0-9a-fA-F]{2}[:.-]?){5}[0-9a-fA-F]{2}$/;

  switch (name) {
    case 'name':
      return value.trim().length === 0 ? config.errorMsg[name] : '';
    case 'state':
      return value.trim().length < 2 ? config.errorMsg[name] : '';
    case 'city':
      return value.trim().length < 2 ? config.errorMsg[name] : '';
    case 'macaddress':
      return !value.match(macAddressRegex) ? config.errorMsg[name] : '';
    case 'zipCode':
      return !value.match(config.zipRegex) ||
        value.trim().length < 5 ||
        value.trim().length > 10
        ? config.errorMsg[name]
        : '';
    case 'city':
      return value.trim().length < 2 ? config.errorMsg[name] : '';
    default:
      return '';
  }
};

const formatLabel = (label: string) => {
  if (label === `macaddress`) {
    return `Mac Address`;
  }

  let labelArray: any = label.split(/(?=[A-Z])/);

  return labelArray && labelArray.length === 1
    ? formatStr(labelArray[0], 1)
    : formatStr(labelArray[0], 2, labelArray[1]);
};

const formatStr = (fragment: string, len: number, secFragment?: string) => {
  return len === 1
    ? fragment[0].toUpperCase().concat(fragment.substring(1))
    : secFragment &&
        fragment[0]
          .toUpperCase()
          .concat(fragment.substring(1))
          .concat(' ')
          .concat(secFragment);
};

const returnPlaceholder = (item: string) => {
  switch (item) {
    case '':
      return '';
    default:
      return '';
  }
};

const isDev = (item: string) => {
  if (item === 'device') {
    return `name`;
  }
  return ``;
};

export const functions = {
  formatLabel: formatLabel,
  returnPlaceholder: returnPlaceholder,
  isDev: isDev,
  handleErrors: handleErrors,
};
