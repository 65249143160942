// @ts-nocheck
import config from './config';
import _ from 'lodash';

const isValueInit = (value: number, prop: string) => {
  return (
    value[prop] && value[prop].hasOwnProperty('current') && value[prop].init
  );
};

const validateTag = (jsonTag: string) => {
  if (
    jsonTag !== 'account_id' &&
    jsonTag !== 'status' &&
    jsonTag !== 'device_id'
  ) {
    if (config.dictionary.hasOwnProperty(jsonTag)) {
      return config.dictionary[jsonTag];
    } else {
      return null;
    }
  }
};

const mapPeripheralProp = (prop: string) => {
  switch (prop) {
    case 'damper':
      return 'dmst';
    case 'centralFan':
      return 'cfst';
    case 'fanConnect':
      return 'fcst';
    default:
      return;
  }
};

const validateIfArray = (element: any) => {
  if (Array.isArray(element)) {
    return true;
  } else {
    return false;
  }
};

const extractPeripherals = (_details: any) => {
  const { conf } = _details?.settings || {};

  const cdvsClone: any = conf?.cdvs ? _.cloneDeep(conf.cdvs) : [];

  return {
    ventConnect: cdvsClone.filter((item: any) =>
      item.cdid.startsWith('AirCyclerVC'),
    ),
    fanConnect: conf?.fcsp === 1 || conf?.fcsp === '1',
    damper: true,
    pressureConnect: cdvsClone.filter((item: any) =>
      item.cdid.startsWith('AirCyclerPC'),
    ),
    freshConnect: cdvsClone.filter(
      (item: any) =>
        item.cdid.startsWith('AirCyclerFH') ||
        item.cdid.startsWith('AirCyclerFT'),
    ),
    centralFan: true,
  };
};

const prepareManualControlData = (device: any, periphs: any) => {
  const { device_id } = device || {};
  let newData: any = {
    system_id: device_id,
    setup: {},
    status: false,
  };

  if (periphs && Object.keys(periphs).length > 0) {
    for (const key in periphs) {
      if (
        key !== 'ventConnect' &&
        key !== 'freshConnect' &&
        key !== 'pressureConnect'
      ) {
        if (periphs[key]) {
          let mappedProp: any = mapPeripheralProp(key);
          if (mappedProp) {
            newData.setup[mappedProp] = 0;
          }
        }
      } else {
        if (periphs[key] && periphs[key].length > 0) {
          if (!newData.setup.hasOwnProperty('cdvs')) {
            newData.setup.cdvs = [];
          }
          for (const vc of periphs[key]) {
            const { cdid, cidx, dmac, cdst } = vc || {};
            const tempVcData: any = { cdid, cidx, dmac, cdst };
            newData.setup.cdvs.push(tempVcData);
          }
        }
      }
    }
  }

  return newData;
};

const fillTheStates = (
  widgetCkStates: any,
  devDataIds: any,
  _type: string,
  value: any,
) => {
  const statesClone: any = _.cloneDeep(widgetCkStates);

  if (_type) {
    switch (_type) {
      case 'fanConnect':
      case 'centralFan':
      case 'damper':
        statesClone.setup[mapPeripheralProp(_type)] = value ? 1 : 0;
        break;
      case 'ventConnect':
      case 'freshConnect':
      case 'pressureConnect':
      default:
        let index: number = devDataIds[2] ? parseInt(devDataIds[2]) : 0;

        if (
          index !== null &&
          index !== undefined &&
          statesClone.setup &&
          statesClone.setup.cdvs
        ) {
          if (!isNaN(index) && index > -1) {
            index = statesClone.setup.cdvs.findIndex(
              (item: any) => item?.cidx === index,
            );
          }

          if (index > -1) {
            statesClone.setup.cdvs[index].cdst = value ? 3 : 0;
          }
        }
        break;
    }
  }

  return statesClone;
};

const isDefinedValue = (value: any) => {
  if (value !== null && value !== undefined) {
    return true;
  } else return false;
};

const handleSetup = (devSetup: any, userId: any) => {
  return {
    setup: devSetup,
    id: devSetup?.account_id,
    status: devSetup?.status,
    system_id: devSetup?.system_id,
    user_id: userId,
  };
};

const fillElementsArray = (data: any) => {
  let tempEl: any = [];

  if (data?.ventConnect) {
    data.ventConnect.forEach((periph: any, i: number) => {
      const { cdid, cidx } = periph;
      tempEl.push({
        name: `VentConnect ${i + 1}`,
        type: 'ventConnect',
        id: cdid,
        cidx,
        label: `VentConnect`,
      });
    });
  }

  if (data?.damper) {
    tempEl.push({
      name: `Damper Open`,
      type: 'damper',
      id: 'dmst',
      label: ``,
    });
  }

  if (data?.fanConnect) {
    tempEl.push({
      name: `FanConnect`,
      type: 'fanConnect',
      id: 'fcst',
      label: ``,
    });
  }

  if (data?.pressureConnect) {
    data.pressureConnect.forEach((periph: any, i: number) => {
      const { cdid, cidx } = periph;
      tempEl.push({
        name: `PressureConnect`,
        type: 'pressureConnect',
        id: cdid,
        cidx,
        label: ``,
      });
    });
  }

  if (data?.freshConnect) {
    data.freshConnect.forEach((periph: any, i: number) => {
      const { cdid, cidx } = periph;
      tempEl.push({
        name: 'FreshConnect',
        type: 'freshConnect',
        id: cdid,
        cidx,
        label: `FreshConnect`,
      });
    });
  }

  if (data?.centralFan) {
    tempEl.push({
      name: `Central Fan`,
      type: 'centralFan',
      id: 'cfst',
      label: ``,
    });
  }

  return tempEl;
};

const retrieveValue = (_type: any, _ckData: any, _id: any) => {
  const { setup } = _ckData;
  switch (_type) {
    case 'damper':
    case 'fanConnect':
    case 'centralFan':
      return setup.hasOwnProperty(_id) && setup[_id] === 1;
    case 'ventConnect':
    case 'freshConnect':
    case 'pressureConnect':
    default:
      const vcItem: any =
        setup?.cdvs && setup?.cdvs?.length > 0
          ? setup.cdvs.find((item: any) => item.cdid === _id)
          : null;

      if (vcItem) {
        return vcItem.cdst === 3;
      }
  }
  return false;
};

const getTheIndex = (array: any) => {
  if (array.length % 2 === 0) {
    return array.length / 2;
  } else {
    return array.length / 2 + 1;
  }
};

export const helpers = {
  retrieveValue: retrieveValue,
  isValueInit: isValueInit,
  validateTag: validateTag,
  mapPeripheralProp: mapPeripheralProp,
  validateIfArray: validateIfArray,
  extractPeripherals: extractPeripherals,
  prepareManualControlData: prepareManualControlData,
  fillTheStates: fillTheStates,
  isDefinedValue: isDefinedValue,
  handleSetup: handleSetup,
  fillElementsArray: fillElementsArray,
  getTheIndex: getTheIndex,
};
