import React from 'react';
import {
  HeadsetSolid,
  MobileAltSolid,
  Propeller,
  LandingPageLogo,
} from 'business/assets/landing-page';
import {
  PlayStoreBadge,
  AppStoreBadge,
} from 'business/assets/mobile-badge';
import config from './config';
import './styles.scss';



const DEFAULT_STR: any = { ...config.strings };
const STORES_URL: any = { ...config.stores };


const LandingPage: React.FC = () => {



  const mapStoreURL = (_type: string) => {
    switch (_type) {
      case 'apple':
      case 'google':
        return STORES_URL[_type];
      default:
        return '';
    }
  };

  const onClickStore = (_type: string, event: any) => {
    try {
      switch (_type) {
        case 'apple':
          window.open(mapStoreURL(_type), '_blank', 'noreferrer');
          break;
        default:
          break;
      }
    } catch (error) {
      console.log('ERROR, ', error);
    }
  };


  return (
    <div className="landing-page">
      <div className="row landing-page-card">
        <div className="col-12 col-md-6 col-lg-6 d-flex justify-content-center align-items-center">
          <img src={LandingPageLogo}  alt="Logo" width='90%' className='landing-page-logo' />
        </div>
        <div className="col-12 col-md-6 col-lg-6 content">
          <h1 className='title'>
            {DEFAULT_STR?.title}
          </h1>
          <h2  className='subtitle'>
            {DEFAULT_STR?.subtitle}
          </h2>
          <button className="btn bg-white btn-lg button-knowmore">
            {DEFAULT_STR?.btnTitle}
          </button>
          <div className="row boxs d-flex justify-content-center">
            <div className="col-3 box">
              <img src={Propeller}  alt="Smart" className='smart-ventilation' />
              <p>{DEFAULT_STR?.ventTitle}</p>
            </div>
            <div className="col-3 box">
              <img src={MobileAltSolid}  alt="Mobile" />
              <p>{DEFAULT_STR?.mobTitle}</p>
            </div>
            <div className="col-3 box">
              <img src={HeadsetSolid}  alt="Customer Support" />
              <p>{DEFAULT_STR?.supportTitle}</p>
            </div>
          </div>
        </div>
      </div>
      <div className="row landing-page--badge-wrapper">
        <div className="col-12 col-md-6 col-lg-6">
        </div>
        <div className="col-12 col-md-6 col-lg-6 badge-container">
          <img
            src={PlayStoreBadge}
            alt="PlayStore"
            className='play-store'
            onClick={onClickStore.bind(null, 'google')}
          />
          <img
            src={AppStoreBadge}
            alt="AppStore"
            className='app-store'
            onClick={onClickStore.bind(null, 'apple')}
          />
        </div>
      </div>
    </div>
  );
};

export default LandingPage;
