//@ts-nocheck
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { config } from './config';
import { useHistory } from 'react-router-dom';
import { UserDetailsProps } from './interface';
import { helpers } from './helpers';
import configs from 'business/config/config';
import moment from 'moment';
import validate from 'validate.js';
import { DevicesUtils } from './utils';
import _ from 'lodash';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { Section } from './components';
import {
  Template,
  DetailsGrid,
  Tabs,
  Upload,
  Button,
  Input,
  Label,
  Phone,
  Modal,
  DetailsHeader,
} from 'business/modules/admin/common';
import { isValidPhoneNumber } from 'react-phone-number-input';
import { RiEdit2Line, RiSave3Fill } from 'react-icons/ri';
import { HiOutlineXCircle } from 'react-icons/hi';
import './styles.scss';

const SOCKET_URL: any = configs?.apis?.g3iot?.baseSocketURLs[0];
const WEATHER_TIMER_VAL: number = 1000 * 60 * 30;
const WIFI_CONN_DIFF: number = 30; // in seconds

const UserDetails: React.FC<UserDetailsProps> = ({
  saveSystemsWithConnectionInfo,
  getDeviceLogs,
  allTheDevs,
  allTheDevsLoaded,
  currentlyActiveTab,
  getDeviceSettings,
  user,
  defUser,
  loading,
  profileDataFieldsDisabled,
  editUserProfile,
  updateDisabledState,
  updatingUser,
  setUser,
  changeTab,
  devicesPerUser,
  devicesPerUserAreLoaded,
  loadingErrors,
  showSystemsListPerAccount,
  showSensorsListPerAccount,
  getDevicesPerUser,
  clearListOnLeaving,
  sensorsList,
  sensorsAreLoaded,
  clearDevs,
  setEnteredInUserPage,
  enteredUserPage,
  systemsList,
  systemsListIsLoaded,
  getOutsideAirDetailsAdmin,
  getIndoorAirDetailsAdmin,
  storeIDToAccessLogs,
  storeDevLocationTmz,
  defineDevToGetLogs,
  gatherAllDevs,
  saveSettings,
  getAdminWeather,
  updateG3Reads,
  getManualCtrlDetailsAdmin,
  thereWasMCUpdate,
  setNumberOfDevs,
  outsideAirValuesUpdated,
  outSettingsLoaded,
  handleSensorsDisplay,
  markListAsLoaded,
  indoorAirSensorsDisplay,
  pressureConnectSettingsAreLoaded,
  updateWeatherAdmin,
  resetAllTheDevs,
  getSystemCurrentStatus,
  clearStatus,
}) => {
  const history = useHistory();
  const [sysWithConnectionInfo, setSysWithConnectionInfo]: any = useState([]);
  const [systemsQuantity, setSystemsQuantity] = useState<number>(0);
  const [userDevicesInit, setUserDevicesInit]: any = useState(false);
  const [allData, setAllData]: any = useState(config.state.allData);
  const [hasFoobotConn, setHasFoobotConn]: any = useState(true);
  const [sensors, setSensors]: any = useState([]);
  const [sensorsLoaded, setSensorsLoaded]: any = useState(false);
  const [sysSetups, setSysSetups]: any = useState({});
  const [errors, setErrors]: any = useState({});
  const [outsideAir, setOutsideAir]: any = useState([]);
  const [outDataLoaded, setOutDataLoaded] = useState<boolean>(false);
  const [indoorAir, setIndoorAir]: any = useState([]);
  const [indDataLoaded, setIndDataLoaded] = useState<boolean>(false);
  const [manualCtrlSettings, setMCSettings] = useState<any>([]);
  const [mcLoaded, setMcLoaded] = useState<boolean>(false);
  const [weatherReqFlag, setWeatherReqFlag]: any = useState(true);

  //remove locals except OMNI
  const removeLocalSensors = (sensorsList: any) => {
    let filteredSensors: any =
      sensorsAreLoaded &&
      sensorsList.filter((sensor: any) => {
        return (
          sensor?.device_type !== 'AirCyclerAQ123456' &&
          (sensor.device_type === 'awair-omni' ||
            sensor?.details?.name !== 'LOCAL' ||
            !sensor?.details?.status.includes('local'))
        );
      });
    return filteredSensors;
  };

  const sectionData: any = {
    dev: {
      loadingStr: 'Loading User Details...',
      loadedList: devicesPerUserAreLoaded,
      list: devicesPerUser,
      error: loadingErrors.devicesPerUserErr,
      handleChangeID: 'dev-admin',
      summaryId: 'widget--ds',
      title: `Looking for information about a specific device? `,
      target: `#device-show`,
      id: `device-show`,
      className: `widget--acc-section-dev`,
      hasInternalHeader: true,
    },
    sens: {
      loadingStr: 'Loading Sensors Details...',
      loadedList: sensorsAreLoaded,
      list: removeLocalSensors(sensorsList),
      error: loadingErrors.sensorsErr,
      handleChangeID: 'sensors-adm',
      summaryId: 'widget--sensors',
      title: `Looking for information about a specific sensor? `,
      target: `#sens-show`,
      id: `sens-show`,
      className: `widget--sensors-section`,
      hasInternalHeader: false,
    },
    sys: {
      loadingStr: 'Loading Systems Details...',
      loadedList: systemsListIsLoaded,
      list: systemsList,
      error: '',
      handleChangeID: 'system-section',
      summaryId: 'widget--ds2',
      className: `widget--sys-section`,
      hasInternalHeader: false,
    },
    oa: {
      loadingStr: 'Loading Outside Air details...',
      loadedList: devicesPerUserAreLoaded,
      list: devicesPerUser,
      error: '',
      handleChangeID: 'outside-air-adm',
      summaryId: 'widget--outside-air-adm',
      className: `widget--outside-air-adm`,
      configs: outsideAir,
      hasInternalHeader: true,
    },
    ia: {
      loadingStr: 'Loading Indoor Air details...',
      loadedList: devicesPerUserAreLoaded,
      list: devicesPerUser,
      error: '',
      handleChangeID: 'indoor-air',
      summaryId: 'widget--ia-adm2',
      className: `widget--indoor-air-adm2`,
      configs: indoorAir,
      hasInternalHeader: true,
    },
    pc: {
      loadingStr: 'Loading Pressure Connect Details...',
      loadedList: devicesPerUserAreLoaded,
      list: devicesPerUser,
      error: '',
      handleChangeID: 'pressure-conn-admin',
      summaryId: 'widget--pc--adm1',
      className: `widget--pressure-conn-admin`,
      hasInternalHeader: true,
    },
    mc: {
      loadingStr: 'Loading Manual Control Details...',
      loadedList: devicesPerUserAreLoaded,
      list: devicesPerUser,
      error: '',
      handleChangeID: 'mc-admin',
      summaryId: 'widget--mc-admin',
      className: `widget--mc-admin`,
      configs: manualCtrlSettings,
      loadedConfigs: mcLoaded,
      hasInternalHeader: true,
    },
  };

  const gridContent: any = {
    0: devicesPerUserAreLoaded && devicesPerUser.length,
    1: user?.details?.customer ? `is a customer` : `is not a customer.`,
    2: user?.addresses && user?.addresses[0].state,
    3: user?.details?.hasOwnProperty('thirdParty') ? 'Yes' : 'No',
  };

  const requestSystemsSetup = async (_devices: any) => {
    loading.start('Loading settings...');

    let devSettings: any = [];

    let tempSetups: any = _.cloneDeep(sysSetups);

    if (_devices && _devices.length > 0) {
      for (let index = 0; index < _devices.length; index++) {
        const { device_id } = _devices[index] || {};

        //Calling each system status:

        const systemStatus: any = await getSystemCurrentStatus({
          id: localStorage.getItem('currentAccount'),
          device_id: device_id,
        });

        const setupResp: any = await getDeviceSettings({
          device_id: device_id,
          id: localStorage.getItem('currentAccount'),
        });

        if (setupResp?.statusCode === 200) {
          //this is to get the small values on indoor air sliders
          devSettings.push(setupResp);

          if (devicesPerUser.length === devSettings.length) {
            saveSettings(devSettings, 'out'); //set oa data loaded to avoid delayed state
            saveSettings(devSettings, 'i-settings'); //save indoor data
            saveSettings(devSettings, 'p-settings'); // save pressure data
          } else {
            saveSettings(devSettings, 'out-clear');
          }
        }

        if (!tempSetups[device_id as keyof typeof tempSetups]) {
          tempSetups[device_id] = {};
        }
        tempSetups[device_id] = { ...setupResp };
      }
    }

    setSysSetups({ ...tempSetups });

    loading.stop();
  };

  const toggleActive = (current: string) => {
    let currTab = helpers.toggleActive(current);

    changeTab(currTab);
  };

  const handleImgErr = (event: any) => {
    event.target.src = '/avatar.png';
  };

  const toggleEdit = () => {
    editUserProfile(!profileDataFieldsDisabled);
  };

  const cancelChanges = () => {
    updateDisabledState(true);

    const savedUser = localStorage.getItem('user');

    if (savedUser) {
      defUser(JSON.parse(savedUser));

      //on page loading, set the updating state to current values (form fields)
      let parsed: any = JSON.parse(savedUser);

      let formFieldsUser: any = {
        last_name: parsed?.last_name,
        first_name: parsed?.first_name,
        picture: parsed?.picture?.profile?.url,
        phone: parsed?.phones_numbers[0]?.phone,
        address_line_one: parsed?.addresses[0]?.address_line_one,
        address_line_two: parsed?.addresses[0]?.address_line_two,
        zip_code: parsed?.addresses[0]?.zip_code,
        city: parsed?.addresses[0]?.city,
        state: parsed?.addresses[0]?.state,
        email: parsed?.emails[0]?.email,
      };

      setUser(formFieldsUser);

      setErrors({});
    }
  };

  const fillIndoorAirDetails = (sensorsList: any) => {
    let indoorAirDetails: any = [];
    sensorsList.forEach((element: any) => {
      /* handle the sensor without data object - SENSOR-AIRCYCLERAQ123456 */
      indoorAirDetails.push({
        deviceId: element.device_id,
        pm:
          element.details.data &&
          element.details.data.datapoints &&
          element.details.data.datapoints.length > 0
            ? element?.details?.data?.datapoints[0][1]
            : 0,
        co2:
          element.details.data &&
          element.details.data.datapoints &&
          element.details.data.datapoints.length > 0
            ? element?.details?.data?.datapoints[0][4]
            : 0,
        tVoc:
          element.details.data &&
          element.details.data.datapoints &&
          element.details.data.datapoints.length > 0
            ? element?.details?.data?.datapoints[0][5]
            : 0,
      });
    });

    return indoorAirDetails;
  };

  const handlePageRefresh = async (account_id: any) => {
    const sensorsData: any = await showSensorsListPerAccount(account_id);
    const dData: any = await getDevicesPerUser(account_id);
    const systems: any = await showSystemsListPerAccount(account_id);

    if (helpers.isOKResponse(sensorsData)) {
      const iaRes = fillIndoorAirDetails(sensorsData?.body?.data);

      saveSettings(iaRes, 'ia-details');
      setAllData((prevState: any) => {
        return {
          ...prevState,
          sensors: sensorsData?.body?.data,
          sensorsLoaded: true,
        };
      });
    } else {
      saveSettings([], 'ia-details');
    }

    if (helpers.isOKResponse(dData)) {
      setAllData((prevState: any) => {
        return {
          ...prevState,
          devices: dData?.body?.data,
          devsLoaded: true,
        };
      });
      //todo handle error
    }

    setEnteredInUserPage(true);
  };

  const defineOnClickValues = (device: any) => {
    storeDevLocationTmz(device?.details?.settings?.conf?.tmzn);
    storeIDToAccessLogs(device?.details?.id);
    defineDevToGetLogs(device);
  };

  const setWsDevice = (device: object) => {
    const { api_key } = device;
    const skUrl = `${SOCKET_URL}?x-iot-key=${api_key}`;
    let socket: any = new WebSocket(skUrl);
    socket.addEventListener('message', receiveWsMessage.bind(null, device));
    socket.addEventListener(
      'close',
      handleWsConnectionClosed.bind(null, device),
    );
    return socket;
  };

  const receiveWsMessage = (_device: object, event: any) => {
    if (event?.data) {
      let deviceClone = { ..._device };
      const receivedData: any = JSON.parse(event.data);

      // for development use, then delete
      const { device_name, device_id } = deviceClone;
      const { time } = receivedData;

      if (time) {
        handleTimeUpdates(receivedData);
      }
    }
  };

  const handleTimeUpdates = (message: any) => {};

  const handleWsConnectionClosed = (_device: object, event: any) => {
    if (event?.reason === 'Going away') {
      const { device_id } = _device;
      const newSocket: any = setWsDevice(_device);
      config.variables.WEBSOCKETS[device_id] = newSocket;
    }
  };

  const hasValidDate = (elem: any) => {
    const { createdDate } = elem;
    const nowDate: any = moment();
    const devDate: any = moment(new Date(createdDate));
    return nowDate.diff(devDate, 'seconds') < WIFI_CONN_DIFF;
  };

  const isSystemConnected = async (sys: any) => {
    const { api_key } = sys;
    const response: any = await getDeviceLogs({ deviceKey: api_key });
    return response && response?.Items?.length > 0
      ? hasValidDate(response.Items[0])
      : false;
  };

  //appends the isWifiConn prop to each systems elem
  const initSystemsStates = async (systems: any) => {
    let temporaryArrayOfSystems: any = [];

    for (let i = 0; i < systems.length; i++) {
      let isConnect: boolean = await isSystemConnected(systems[i]);
      let systemCopy: any = _.cloneDeep(systems[i]);

      systemCopy.isWifiConn = isConnect ? 'active' : 'inactive';
      temporaryArrayOfSystems.push(systemCopy);
    }

    setSysWithConnectionInfo(temporaryArrayOfSystems);
  };

  const handleWeatherRequestsGate = () => {
    setWeatherReqFlag(false);

    setTimeout(() => {
      setWeatherReqFlag(true);
    }, WEATHER_TIMER_VAL);
  };

  const startDevicesWeather = async (aircycler: any) => {
    for (let index = 0; index < aircycler.length; index++) {
      const { device_id, device_zipcode, device_city } = aircycler[index];

      const isProbeConn: any = DevicesUtils.isProbeConnected(aircycler[index]);

      if (!!device_zipcode || (!!device_city && weatherReqFlag)) {
        let devWeather: any = await getAdminWeather({
          device_id: device_id,
          id: localStorage.getItem('currentAccount'),
        });

        if (devWeather) {
          updateWeatherAdmin({ [device_id]: { ...devWeather?.body?.data } });
        }
      }

      if (isProbeConn?.connected) {
        let probeValues: any = DevicesUtils.selectProbeValues(aircycler[index]);

        updateG3Reads({ ...probeValues }, aircycler[index].device_id);
      }
    }
    if (weatherReqFlag) {
      handleWeatherRequestsGate();
    }
  };

  const handleChangeVals = async (event: any) => {
    let name: any = event.target.name;
    let currentFields: any = { [name]: event.target.value };

    let currentConstraints: any = {
      [name]: {
        ...config.constraints[name as keyof typeof config.constraints],
      },
    };

    setUser({ ...updatingUser, [event.target.name]: event.target.value });

    await validate
      .async(currentFields, currentConstraints)
      .then((res: any) => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, [event.target.name]: '' };
        });
      })
      .catch((err: any) => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, [name]: err[name] };
        });
      });
  };

  const handlePhoneChange = (value: any, country: any, formattedValue: any) => {
    setErrors({ ...errors, phone: '' });
    setUser({ ...updatingUser, phone: '+' + value });

    if (!isValidPhoneNumber('+' + value)) {
      setErrors({ ...errors, phone: 'Invalid phone number.' });
    } else {
      setErrors({ ...errors, phone: '' });
    }
  };

  const handleErrs = (errors: any) => {
    const falseValues = (element: any) =>
      (Array.isArray(element) && element.length > 0) ||
      (typeof element === 'string' && element.length > 0);

    if (Object.values(errors).some(falseValues)) {
      return true;
    } else {
      return false;
    }
  };

  //extract the mc, ia, and oa calls
  const getSectionsValues = async () => {
    let data: any = [];
    let iaData: any = [];
    let mcData: any = [];

    devicesPerUser.forEach(async (dev: any) => {
      let outsideResult: any = await getOutsideAirDetailsAdmin({
        device_id: dev.device_id,
        id: user.account_id,
      });

      if (outsideResult?.body?.data?.message) {
        //no configurations, so set to default values
        data.push({
          device_name: dev.device_name,
          account_id: user.account_id,
          aqi: 100,
          cfm: 50,
          device_id: dev.device_id,
          hum: 80,
          temp: {
            max: 75,
            min: 10,
          },
          turnedOn: false,
        });
      } else {
        let res: any = {
          device_name: dev.device_name,
          ...outsideResult?.body?.data,
        };

        data.push(res);
      }

      if (devicesPerUser.length === data.length && devicesPerUserAreLoaded) {
        setOutsideAir(data);
        setOutDataLoaded(true);
      }

      let indoorResult: any = await getIndoorAirDetailsAdmin({
        device_id: dev.device_id,
        id: user.account_id,
      });

      if (indoorResult?.body?.data?.message) {
        //handle defaults

        let defaultV: any = {
          device_name: dev.device_name,
          slider: {
            pm25: 140,
            co2: 1500,
            tVoc: 1500,
          },
          checkbox: {
            pm25: {
              fanConnect: false,
              dmst: false,
              cfcm: false,
            },
            co2: {
              fanConnect: false,
              dmst: false,
              cfcm: false,
            },
            tVoc: {
              fanConnect: false,
              dmst: false,
              cfcm: false,
            },
          },
          turnedOn: false,
          device_id: dev.device_id,
          account_id: user.account_id,
          current: {},
        };

        iaData.push(defaultV);
      } else {
        let res: any = {
          device_name: dev.device_name,
          ...indoorResult?.body?.data,
        };

        iaData.push(res);
      }

      if (devicesPerUser.length === iaData.length) {
        setIndoorAir(iaData);
        setIndDataLoaded(true);
      }

      let mcResult: any = await getManualCtrlDetailsAdmin({
        device_id: dev.device_id,
        id: user.account_id,
      });

      if (mcResult?.body?.data?.message) {
        //handle default

        let defaultMc: any = {
          account_id: user.account_id,
          cdvs: [],
          cfst: 0,
          dmst: 0,
          fcst: 0,
          status: false,
          system_id: dev.device_id,
        };

        mcData.push(defaultMc);
      } else {
        mcData.push({ ...mcResult?.body?.data });
      }
      if (devicesPerUser.length === mcData.length) {
        setMCSettings(mcData);
        setMcLoaded(true);
      }
    });
  };

  const callUpdatedOA = async () => {
    let user: any = localStorage.getItem('currentAccount');

    devicesPerUser.forEach(async (dev: any) => {
      await getOutsideAirDetailsAdmin({
        device_id: dev.device_id,
        id: user,
      });
    });
  };

  const gatherSensorData = async () => {
    for (let i = 0; i < devicesPerUser.length; i++) {
      const setupResp: any = await getDeviceSettings({
        device_id: devicesPerUser[i].device_id,
        id: localStorage.getItem('currentAccount'),
      });

      if (setupResp?.body?.data?.settings) {
        let sensorsInformation: any = helpers.handleSettings(
          setupResp,
          sensorsList,
          sensorsAreLoaded,
        );

        let _name;
        let _status;
        let isLocal;
        let datapoints: any;

        sensorsList.forEach((sens: any) => {
          if (sens.device_id === sensorsInformation.sensorId) {
            datapoints = sens.details.data.datapoints;
            _name = sens?.details?.name;
            _status = sens?.details?.status;
            isLocal =
              (sens._name && sens._name === 'LOCAL') ||
              (sens._status && sens._status.includes('local'));
          }
        });

        handleSensorsDisplay({
          id: devicesPerUser[i].device_id,
          name: devicesPerUser[i].device_name,
          datapoints: datapoints,
          _name: _name,
          _status: _status,
          isLocal: isLocal,
          ...sensorsInformation,
        });
      } else if (setupResp?.body?.data?.message) {
        let sensorsInformation: any = helpers.handleSettings(
          setupResp,
          sensorsList,
          sensorsAreLoaded,
        );

        handleSensorsDisplay({
          id: devicesPerUser[i].device_id,
          name: devicesPerUser[i].device_name,
          datapoints: [],
          _name: undefined,
          _status: undefined,
          isLocal: undefined,
          ...sensorsInformation,
        });
      }
    }
  };

  const mapSensorType = (devType: string) => {
    switch (devType) {
      case 'aircycler-local':
        return 'aircycler-sensor';
      default:
        return devType;
    }
  };

  const filterDevicesByType = (_devices: any) => {
    let filterdDevices: any = {};

    if (_devices && Array.isArray(_devices)) {
      _devices.forEach((_device: any) => {
        const tempType: string = mapSensorType(_device?.device_type);

        if (!filterdDevices[tempType]) {
          filterdDevices[tempType] = [];
        }
        filterdDevices[tempType].push({ ..._device });
      });
    }

    return filterdDevices;
  };

  useEffect(() => {
    if (systemsListIsLoaded && systemsList && systemsList.length > 0) {
      initSystemsStates(systemsList);
    }
    return () => {};
  }, [systemsList, systemsListIsLoaded]);

  useEffect(() => {
    if (systemsListIsLoaded) {
      setSystemsQuantity(systemsList.length);
    }
  }, [systemsQuantity, systemsList, systemsListIsLoaded]);

  useEffect(() => {
    if (helpers.connSysReady(sysWithConnectionInfo, systemsQuantity)) {
      saveSystemsWithConnectionInfo(sysWithConnectionInfo);
    }
  }, [systemsQuantity, sysWithConnectionInfo]);

  useEffect(() => {
    const savedUser: any = localStorage.getItem('user');

    loading.start('Loading User Details...');

    if (savedUser) {
      defUser(JSON.parse(savedUser));

      //on page loading, set the updating state to current values (form fields)
      let parsed: any = JSON.parse(savedUser);

      let formFieldsUser: any = {
        last_name: parsed?.last_name,
        first_name: parsed?.first_name,
        picture: parsed?.picture?.profile?.url,
        phone: parsed?.phones_numbers[0]?.phone,
        address_line_one: parsed?.addresses[0]?.address_line_one,
        address_line_two: parsed?.addresses[0]?.address_line_two,
        zip_code: parsed?.addresses[0]?.zip_code,
        city: parsed?.addresses[0]?.city,
        state: parsed?.addresses[0]?.state,
        email: parsed?.emails[0]?.email,
      };

      setUser(formFieldsUser);
    }

    loading.stop();
  }, []);

  useEffect(() => {
    //gather sensors and devs in an unique list
    if (allData.sensorsLoaded && allData.devsLoaded) {
      let _sensors: any = allData.sensors;
      let _devs: any = allData.devices;

      let _allUnited: any = _sensors.concat(_devs);
      gatherAllDevs(_allUnited);
    }
  }, [allData]);

  useEffect(() => {
    return () => {
      resetAllTheDevs();
    };
  }, []);

  useEffect(() => {
    //handle page refresh
    let persistentUser: any = localStorage.getItem('user');
    if (persistentUser) {
      let acc: any = JSON.parse(persistentUser);

      handlePageRefresh(acc.account_id);
    }
  }, []);

  //clear data on leaving
  useEffect(() => {
    return () => {
      clearListOnLeaving();
      clearDevs();
    };
  }, []);

  useEffect(() => {
    //Call OA, IA and other data to display on the sections
    if (devicesPerUserAreLoaded && enteredUserPage) {
      setNumberOfDevs(devicesPerUser.length);

      if (devicesPerUser.length === 0) {
        setIndDataLoaded(true);
        setOutDataLoaded(true);
      } else {
        getSectionsValues();
      }
    }
  }, [devicesPerUserAreLoaded, devicesPerUser, enteredUserPage, user]);

  useEffect(() => {
    let tempDevices: any = filterDevicesByType(allTheDevs);

    const { aircycler } = tempDevices;

    if (aircycler && aircycler.length > 0) {
      requestSystemsSetup(aircycler);
    }

    const getTheLatestSensorData = async () => {
      let persistentUser: any = localStorage.getItem('user');
      if (persistentUser) {
        let acc: any = JSON.parse(persistentUser);

        const sensorsData: any = await showSensorsListPerAccount(
          acc.account_id,
        );

        if (helpers.isOKResponse(sensorsData)) {
          const iaRes = fillIndoorAirDetails(sensorsData?.body?.data);

          saveSettings(iaRes, 'ia-details');
        } else {
          saveSettings([], 'ia-details');
        }
      }
    };
    getTheLatestSensorData();
  }, [currentlyActiveTab]);

  useEffect(() => {
    if (allTheDevsLoaded && allTheDevs.length > 0) {
      let tempDevices: any = filterDevicesByType(allTheDevs);
      let tempProbCtrl: any = {};
      let allSensors: any = [];

      const {
        aircycler,
        foobot,
        airthings,
        awair,
        aircyclerSensor,
      }: any = tempDevices;

      if (aircycler && aircycler.length > 0) {
        requestSystemsSetup(aircycler);

        aircycler.forEach(async (_device: any) => {
          const { device_name, device_id } = _device;

          if (
            !config.variables.WEBSOCKETS[
              device_id as keyof typeof config.variables.WEBSOCKETS
            ]
          ) {
            let tSocket: any = setWsDevice(_device);
            config.variables.WEBSOCKETS[device_id] = tSocket;
          }

          if (!tempProbCtrl.hasOwnProperty(device_id)) {
            tempProbCtrl[device_id] = false;
          }
        });

        if (foobot && foobot.length > 0) {
          allSensors = allSensors.concat(foobot);
        }

        if (airthings && airthings.length > 0) {
          allSensors = allSensors.concat(airthings);
        }

        if (awair && awair.length > 0) {
          allSensors = allSensors.concat(awair);
        }

        if (
          tempDevices['awair-element'] &&
          tempDevices['awair-element'].length > 0
        ) {
          allSensors = allSensors.concat(tempDevices['awair-element']);
        }

        if (tempDevices['awair-omni'] && tempDevices['awair-omni'].length > 0) {
          allSensors = allSensors.concat(tempDevices['awair-omni']);
        }

        if (
          tempDevices['aircycler-sensor'] &&
          tempDevices['aircycler-sensor'].length > 0
        ) {
          allSensors = allSensors.concat(tempDevices['aircycler-sensor']);
        }

        if (allSensors && allSensors.length > 0) {
          setSensors(allSensors);
          setHasFoobotConn(true);
        }
      }
    }

    return () => {};
  }, [devicesPerUser, allTheDevs, allTheDevsLoaded]);

  useEffect(() => {
    return () => {
      changeTab(`dev`);
    };
  }, []);

  useEffect(() => {
    if (devicesPerUserAreLoaded && devicesPerUser.length > 0) {
      startDevicesWeather(devicesPerUser);

      if (!userDevicesInit) {
        for (const dev of devicesPerUser) {
          const { details } = dev;

          if (details) {
            const { id, settings } = details;
            handleTimeUpdates({
              device_id: id,
              details: settings,
            });
          }
        }

        setUserDevicesInit(true);
      } else if (devicesPerUserAreLoaded && devicesPerUser.length === 0) {
        //there are no devs and so there is no sys setup
      }

      if (indoorAirSensorsDisplay.length === devicesPerUser.length) {
        markListAsLoaded(true);
      }
    }
  }, [devicesPerUser, devicesPerUserAreLoaded, indoorAirSensorsDisplay]);

  useEffect(() => {
    if (thereWasMCUpdate) {
      let mcData: any = [];
      if (devicesPerUserAreLoaded) {
        devicesPerUser.forEach(async (dev: any) => {
          let mcResult: any = await getManualCtrlDetailsAdmin({
            device_id: dev.device_id,
            id: user.account_id,
          });
          if (mcResult?.body?.data?.message) {
            //handle default
            let defaultMc: any = {
              account_id: user.account_id,
              cdvs: [],
              cfst: 0,
              dmst: 0,
              fcst: 0,
              status: false,
              system_id: dev.device_id,
            };
            mcData.push(defaultMc);
          } else {
            mcData.push({ ...mcResult?.body?.data });
          }
          if (devicesPerUser.length === mcData.length) {
            setMCSettings(mcData);
            setMcLoaded(true);
          }
        });
      }
    }
  }, [thereWasMCUpdate]);

  useEffect(() => {
    if (outsideAirValuesUpdated) {
      callUpdatedOA();
    }
  }, [outsideAirValuesUpdated]);

  useEffect(() => {
    if (!outSettingsLoaded) {
      loading.start('Loading settings...');
    } else {
      loading.stop();
    }
  }, [outSettingsLoaded]);

  useEffect(() => {
    if (devicesPerUserAreLoaded) {
      gatherSensorData();
    }
    if (
      devicesPerUserAreLoaded &&
      indoorAirSensorsDisplay.length === devicesPerUser.length
    ) {
      markListAsLoaded(true);
    }
  }, [devicesPerUserAreLoaded, devicesPerUser]);

  //clear the devs on View exiting:

  useEffect(() => {
    return () => {
      clearStatus();
    };
  }, []);

  return (
    <Template
      id="user-details"
      title={config.strings.title}
      goBack={() => {
        history.push(`/admin/dashboard`);
      }}
    >
      <BreadcrumbsItem to={`/admin/dashboard/`}>
        {config?.strings?.title}
      </BreadcrumbsItem>

      <div id="view-user-details">
        <DetailsGrid
          gridData={config.gridData}
          user={user}
          gridContent={gridContent}
          dataLoaded={
            Object.keys(user).length > 0 && devicesPerUserAreLoaded
              ? true
              : false
          }
        />
        <div className="row flex-column-reverse flex-md-row">
          <div className="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 spec-cols">
            <div className="container" id="screen-left-side">
              <div className="row">
                <div className="col" id="sections--details">
                  <Tabs
                    tabs={config.tabs}
                    currentlyActive={currentlyActiveTab}
                    changeTab={toggleActive}
                  />
                  <div className="col-12 spec-cols">
                    <Section
                      connection={hasFoobotConn}
                      sensors={sensors}
                      sensorsLoaded={sensorsLoaded}
                      changeTab={toggleActive}
                      currentUser={user}
                      sysSetups={sysSetups}
                      enteredUserPage={enteredUserPage}
                      functions={helpers}
                      currentlyActive={currentlyActiveTab}
                      loadedList={sectionData[currentlyActiveTab].loadedList}
                      list={sectionData[currentlyActiveTab].list}
                      loadingStr={sectionData[currentlyActiveTab].loadingStr}
                      title={sectionData[currentlyActiveTab].title}
                      target={sectionData[currentlyActiveTab].target}
                      sectionData={sectionData}
                      setItem={(item: any) => {
                        defineOnClickValues(item);
                      }}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div
            className={`col-xs-12 col-sm-12 col-md-4 col-lg-4 cl-xl-4 profile`}
          >
            <div className={`row`}>
              <div className={`col-12`}>
                <div className="d-flex justify-content-center">
                  <Upload
                    type={`file`}
                    id={`file-input-id-c`}
                    name={`avatar`}
                    accept={`image/png, image/jpeg`}
                    onClick={() => {}}
                    imgElement={
                      <>
                        {user?.picture?.profile?.url ? (
                          <img
                            onError={handleImgErr}
                            src={user?.picture?.profile?.url}
                            alt="Card image cap"
                            className={`shared-img-styles ${`dm-width`}`}
                          />
                        ) : (
                          <div className={`circle-avt dbmainb01`}>
                            <span className="init-styles-01">
                              {user &&
                                user.hasOwnProperty('first_name') &&
                                user?.first_name[0]}
                              {user &&
                                user.hasOwnProperty('last_name') &&
                                user?.last_name[0]}
                            </span>
                          </div>
                        )}
                      </>
                    }
                    rowUpl={`row upload-01`}
                    idUpl={`input-upload-c`}
                    cClass={`col-5`}
                  />
                </div>

                <div className={`container mb-2`}>
                  <div className="row">
                    <div className="col-12">
                      <Button
                        className="small-button1 ok1 posit-menu-btn"
                        onClick={toggleEdit}
                      >
                        <RiEdit2Line className="ed--icon--2" />
                      </Button>

                      {!profileDataFieldsDisabled && (
                        <>
                          {!handleErrs(errors) && (
                            <Button
                              dataToggle="modal"
                              dataTarget="#handle--save--1"
                              type={`button`}
                              className={`small-button1 ok1 posit-menu-btn`}
                            >
                              <RiSave3Fill className="ed--icon--2" />
                            </Button>
                          )}
                          <Button
                            className={`small-button1 nok1 posit-menu-btn`}
                            onClick={cancelChanges}
                          >
                            <HiOutlineXCircle className="cancel-icon--02" />
                          </Button>
                        </>
                      )}
                    </div>

                    <Modal
                      config={config}
                      id={`handle--save--1`}
                      title={config.modalStrings.edit}
                      icon={<RiEdit2Line className="edit" />}
                      body={config?.modalStrings?.aboutToUpdate}
                      label={config?.modalStrings?.labelOK}
                      className={`small-button ok`}
                      bodyClass={`alert alert-info`}
                      sureToProceed={config?.modalStrings?.sureToProceed}
                      hasConfirmBtn={true}
                      warning={config?.modalStrings?.noUndo}
                      onClickCancel={cancelChanges}
                      onClick={() => {
                        /*Todo: this will confirm user info edition */
                      }}
                      //handle modal automatic closing when
                      //there is a real update going on:
                      /* modalMustClose={modalMustClose}
                      handleModalState={handleModalState}*/
                      modalMustClose={false}
                      handleModalState={() => {}}
                    />
                  </div>
                </div>

                <div className="container user-details-form">
                  <div className="row">
                    <div className="col-12">
                      {config.formFields.map((elem: any, index: number) => {
                        if (elem.id === `user_profile_phone1`) {
                          return (
                            <div className="form-group the-fields" key={index}>
                              <Label>{elem.label}</Label>
                              <div className="input-group">
                                <Phone
                                  className="input-group"
                                  key={elem.name}
                                  onChange={(
                                    value: any,
                                    country: any,
                                    e: any,
                                    formattedValue: any,
                                  ) =>
                                    handlePhoneChange(
                                      value,
                                      country,
                                      formattedValue,
                                    )
                                  }
                                  name={elem.name}
                                  label={elem.label}
                                  value={updatingUser[elem.name] || ''}
                                  disabled={profileDataFieldsDisabled}
                                />
                              </div>
                              <span className="error-alert">
                                {errors && errors[elem.name]}
                              </span>
                            </div>
                          );
                        } else {
                          return (
                            <div className="form-group the-fields" key={index}>
                              <Label>{elem.label}</Label>
                              <div className="input-group">
                                <Input
                                  type={elem.type}
                                  className={elem.className}
                                  id={elem.id}
                                  name={elem.name}
                                  ariaDescribedBy={elem.ariaDescribedBy}
                                  placeholder={elem.placeholder}
                                  children={elem.label}
                                  value={updatingUser[elem.name] || ''}
                                  onChange={handleChangeVals}
                                  disabled={profileDataFieldsDisabled}
                                />
                              </div>
                              <span className="error-alert">
                                {errors && errors[elem.name]}
                              </span>
                            </div>
                          );
                        }
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminUsersState, adminSectionsState, adminDevicesState } = state;
  return {
    thereWasMCUpdate: adminSectionsState.thereWasMCUpdate,
    currentlyActiveTab: adminSectionsState.currentlyActiveTab,
    updatingUser: adminUsersState.updatingUser,
    user: adminUsersState.user,
    profileDataFieldsDisabled: adminUsersState.profileDataFieldsDisabled,
    loadingErrors: adminUsersState.loadingErrors,
    devicesPerUser: adminUsersState.devicesPerUser,
    devicesPerUserAreLoaded: adminUsersState.devicesPerUserAreLoaded,
    sensorsList: adminDevicesState.sensorsList,
    sensorsAreLoaded: adminDevicesState.sensorsAreLoaded,
    enteredUserPage: adminUsersState.enteredUserPage,
    systemsList: adminDevicesState.systemsList,
    systemsListIsLoaded: adminDevicesState.systemsListIsLoaded,
    allTheDevsLoaded: adminDevicesState.allTheDevsLoaded,
    allTheDevs: adminDevicesState.allTheDevs,
    outsideAirValuesUpdated: adminSectionsState.outsideAirValuesUpdated,
    outSettingsLoaded: adminSectionsState.outSettingsLoaded,
    indoorAirSensorsDisplay: adminDevicesState.indoorAirSensorsDisplay,
    pressureConnectSettingsAreLoaded:
      adminSectionsState.pressureConnectSettingsAreLoaded,
  };
}

function mapDispatchToProps(dispatch: any) {
  const {
    adminUsersState,
    loader,
    adminSectionsState,
    adminDevicesState,
    adminDataLoggingState,
    logs,
    adminState,
  } = dispatch;
  return {
    setNumberOfDevs: adminSectionsState.setNumberOfDevs,
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    updateG3Reads: adminState.updateG3Reads,
    getAdminWeather: adminSectionsState.getAdminWeather,
    gatherAllDevs: adminDevicesState.gatherAllDevs,
    getDeviceLogs: logs.getDeviceLogs,
    changeTab: adminSectionsState.changeTab,
    defUser: adminUsersState.defUser,
    editUserProfile: adminUsersState.editUserProfile,
    updateDisabledState: adminUsersState.cancelUserProfileUpdates,
    setUser: adminUsersState.setUser,
    getDevicesPerUser: adminUsersState.getDevicesPerUser,
    showSensorsListPerAccount: adminDevicesState.showSensorsListPerAccount,
    showSystemsListPerAccount: adminDevicesState.showSystemsListPerAccount,
    clearListOnLeaving: adminDevicesState.clearListOnLeaving,
    clearDevs: adminUsersState.clearDevs,
    setEnteredInUserPage: adminUsersState.setEnteredInUserPage,
    getOutsideAirDetailsAdmin: adminSectionsState.getOutsideAirDetailsAdmin,
    getIndoorAirDetailsAdmin: adminSectionsState.getIndoorAirDetailsAdmin,
    storeIDToAccessLogs: adminDevicesState.storeIDToAccessLogs,
    storeDevLocationTmz: adminDevicesState.storeDevLocationTmz,
    defineDevToGetLogs: adminDataLoggingState.defineDevToGetLogs,
    getDeviceSettings: adminSectionsState.getDeviceSettings,
    saveSettings: adminSectionsState.saveSettings,
    getManualCtrlDetailsAdmin: adminSectionsState.getManualCtrlDetailsAdmin,
    saveSystemsWithConnectionInfo:
      adminDevicesState.saveSystemsWithConnectionInfo,
    handleSensorsDisplay: adminDevicesState.handleSensorsDisplay,
    markListAsLoaded: adminDevicesState.markListAsLoaded,
    updateWeatherAdmin: adminSectionsState.updateWeatherAdmin,
    resetAllTheDevs: adminDevicesState.resetAllTheDevs,
    getSystemCurrentStatus: adminDevicesState.getSystemCurrentStatus,
    clearStatus: adminDevicesState.clearStatus,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(UserDetails);
