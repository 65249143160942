import React from 'react';
import { BsPerson } from 'react-icons/bs';
import { AiOutlineMail } from 'react-icons/ai';
import { HiOutlineHashtag, HiOutlineIdentification } from 'react-icons/hi';
import { TbReportAnalytics } from 'react-icons/tb';

export default {
  strings: {
    title: 'Order list',
    title1: 'Admin users',
    txt: `Return to default order by clicking here`,
  },
  resettedCounter: {
    email: 0,
    status: 0,
  },
  dataHeader: [
    {
      colClass: `col-xs-12 col-sm-12 col-md-3 users--data-header--1`,
      spanClass: `lead header-label name-span`,
      spanClass2: `lead header-label name-span1`,
      headerDesignationCol: `Users`,
    },
    {
      colClass: `col-xs-12 col-sm-12 col-md-6 users--data-header--2`,
      spanClass: `lead header-label`,
      spanClass2: `lead header-label mb4-1`,
      headerDesignationCol: `Status`,
    },
    {
      colClass: `col-xs-12 col-sm-12 col-md-2 users--data-header--3`,
      spanClass: `lead header-label actions--spanclass`,
      spanClass2: `lead header-label mb4-1`,
      headerDesignationCol: `Actions`,
    },
  ],
  rowClasses: {
    col1: `col-xs-12 col-sm-12 col-md-5 col-lg-4 user--col`,
    span1: `lead lead-adm bolder-ft`,
    col2: `col-xs-12 col-sm-12 col-md-6 col-lg-6 status--col`,
    span2: `lead lead-adm`,
    col3: `col-xs-12 col-sm-12 col-md-2 col-lg-2 inv`,
    col4: `col-xs-12 col-sm-12 col-md-2  col-lg-2 inv`,
    colMenu: `col-xs-12 col-sm-12 col-md-1 col-lg-2 expand--side`,
  },
  menu: [
    {
      id: `firstOpt`,
      label: `View`,
      dataTarget: '',
      dataToggle: ``,
    },
    {
      id: `secondOpt`,
      label: `Delete User`,
      dataTarget: `#delete-admin-user0101`,
      dataToggle: `modal`,
    },
  ],
  formInfo: [
    {
      info: 'account_id',
      type: 'text',
      label: 'Account ID',
      placeholder: '',
      icon: <HiOutlineIdentification style={{ fontSize: '1rem' }} />,
    },
    {
      info: 'username',
      type: 'text',
      label: 'Username',
      placeholder: '',
      icon: <HiOutlineHashtag style={{ fontSize: '0.9rem' }} />,
    },
    {
      info: 'status',
      type: 'text',
      label: 'Status',
      placeholder: '',
      icon: <BsPerson style={{ fontSize: '0.9rem' }} />,
    },
    {
      info: 'email',
      type: 'email',
      label: 'Email',
      placeholder: '',
      icon: <AiOutlineMail style={{ fontSize: '1rem' }} />,
    },
  ],
  adminData: [
    {
      label: 'Member since',
      labelClass: 'label-table',
      value: '2020/05/07',
      type: 'text',
    },
    {
      label: 'Location',
      labelClass: 'label-table',
      value: 'United States',
      type: 'text',
    },
    {
      label: 'Alt. contact',
      labelClass: 'label-table',
      value: '+12345990000',
      type: 'text',
    },
  ],
  buttons: [
    {
      btnLabel: 'Message',
      className: 'btn btn-sm personalized01',
      icon: (
        <AiOutlineMail style={{ fontSize: '1.3rem', paddingBottom: '2px' }} />
      ),
    },
    {
      btnLabel: 'Other',
      className: 'btn btn-sm personalized02',
      icon: (
        <TbReportAnalytics
          style={{ fontSize: '1.3rem', paddingBottom: '2px' }}
        />
      ),
    },
    {
      btnLabel: 'More',
      className: 'btn btn-sm personalized03',
      icon: (
        <TbReportAnalytics
          style={{ fontSize: '1.3rem', paddingBottom: '2px' }}
        />
      ),
    },
  ],
  modal: {
    view: {
      title: `View User`,
      action: ``,
      bodyText: ``,
      btns: {
        labelOk: ``,
        labelCancel: ``,
      },
      modalHeaderId: `view-user2`,
      sectionTitle: `User details`,
      sectionSubtitle: `Some user data`,
    },
    delete: {
      title: `Delete user`,
      action: ``,
      bodyText: ``,
      btns: {
        labelOk: ``,
        labelCancel: ``,
      },
      modalHeaderId: `delete-user2`,
    },
  },
  modalStrings: {
    confirm: ``,
    warning: `Information`,
    alertStr: ``,
    alertStr2: ``,
    confirmation: ``,
    sureToProceed: `Are you sure you wish to proceed?`,
    noUndo: `This action is irreversible.`,
    aboutToDel: `You are about to delete this user.`,
    labelOK: ` OK`,
  },
  notifications: {
    delete: {
      success: 'Admin user deleted successfully!',
      error: 'Something went wrong. The request was not fulfilled.',
    },
  },
};
