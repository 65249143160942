import React from 'react';
import { LabelProps } from './interface';
import './styles.scss';

const Label: React.FC<LabelProps> = ({ children, className }) => {
  return (
    <label
      className={className ? className : `label-cmp-1`}
      htmlFor="labelForm1"
    >
      {children}
    </label>
  );
};

export default Label;
