import React from 'react';
import moment from 'moment-timezone';
import config from './config';
import { ConnectedIcon, DisconnectedIcon } from '../../../../../../assets';

const returnExtendedDesignation = (str: string) => {
  if (config.dictionary && config.dictionary.hasOwnProperty(str)) {
    return config.dictionary[str as keyof typeof config.dictionary];
  } else {
    return str;
  }
};

const formatDateWithoutTzChange = (date: any) => {
  return date
    ? moment(new Date(date)).format(config.strings.formattedTimeStr)
    : '';
};

const isTimeString = (timeZone: any) => {
  if (
    timeZone !== null &&
    timeZone !== undefined &&
    timeZone !== '' &&
    timeZone !== config.strings.defaultTzStr
  ) {
    return true;
  } else return false;
};

const formatDate = (date: any, timeZone: any) => {
  if (isTimeString(timeZone)) {
    return date
      ? moment(new Date(date))
          .tz(timeZone)
          .format(config.strings.formattedTimeStr)
      : '';
  }
  return date
    ? moment(new Date(date)).format(config.strings.formattedTimeStr)
    : '';
};

const formatDateOrTime = (date: any, timezone: any, selection: string) => {
  switch (selection) {
    case 'date':
      if (isTimeString(timezone)) {
        return date
          ? moment(new Date(date))
              .tz(timezone)
              .format(config.strings.formattedDateOnly)
          : '';
      }
      return date
        ? moment(new Date(date)).format(config.strings.formattedDateOnly)
        : '';

    case 'time':
      if (isTimeString(timezone)) {
        return date
          ? moment(new Date(date))
              .tz(timezone)
              .format(config.strings.formattedTimeOnly)
          : '';
      }
      return date
        ? moment(new Date(date)).format(config.strings.formattedTimeOnly)
        : '';

    default:
      return '';
  }
};

const filterConnDevStatus = (values: any) => {
  let isConnected: boolean = false;

  values.forEach((val: any) => {
    if (typeof val === 'object' && val.hasOwnProperty('cdst')) {
      isConnected = val.cdst > 0;
    }
  });

  return !isConnected;
};

const returnKey = (item: any) => {
  let label;
  if (config.dictionary.hasOwnProperty(item)) {
    label = config.dictionary[item as keyof typeof config.dictionary];
  }
  return label;
};

const returnValue = (item: any) => {
  const disconnected: boolean =
    item && Array.isArray(item) ? filterConnDevStatus(item) : item === 0;

  return disconnected ? (
    <DisconnectedIcon className="status-icon" />
  ) : (
    <ConnectedIcon className="status-icon" />
  );
};

const returnExtendedString = (str: string) => {
  if (
    str &&
    str.length > 0 &&
    config.dictionary &&
    config.dictionary.hasOwnProperty(str)
  ) {
    return config.dictionary[str as keyof typeof config.dictionary];
  } else return str;
};

export const functions = {
  returnExtendedString: returnExtendedString,
  formatDate: formatDate,
  filterConnDevStatus: filterConnDevStatus,
  returnKey: returnKey,
  returnValue: returnValue,
  formatDateWithoutTzChange: formatDateWithoutTzChange,
  returnExtendedDesignation: returnExtendedDesignation,
  formatDateOrTime: formatDateOrTime,
};
