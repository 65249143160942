import React from 'react';
import { NativeSelect } from '@material-ui/core';
import { SelectProps } from './interface';



const Select: React.FC<SelectProps> = ({ value, config, onChange }) => {
  const CONFIG_FIELDS: any = { ...config.fields };
  const VENTILATIONSELECT = [...config.ventilationSelect];

  return (
    <div id="st-select-edit-users" className="st-select-edit-users">
      <label className="inputLabel">
        {CONFIG_FIELDS?.ventilation_setup?.label}
      </label>
      <div className="configuration--select" id="edit-conf-select-native-users">
        <NativeSelect className="select" value={value} onChange={onChange}>
          {VENTILATIONSELECT.map((ventilation: any) => (
            <option key={ventilation?.name} value={ventilation?.name}>
              {ventilation?.name}
            </option>
          ))}
        </NativeSelect>
      </div>
    </div>
  );
};

export default Select;
