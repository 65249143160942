import React, { useState, useEffect, ChangeEvent, Fragment } from 'react';
import config from './config';
import { functions } from './helpers';
import { RequestListProps } from './interface';
import { RequestRow } from './components';
import { ListHeader } from 'business/modules/admin/common/List/components';
import Pagination from '@material-ui/lab/Pagination';
import { MdErrorOutline } from 'react-icons/md';
import './styles.scss';

const RequestList: React.FC<RequestListProps> = ({
  loaded,
  list,
  itemsPerPage,
  orderType,
  defaultOrder,
  handleCounterAndListOrder,
  intendedCounter,
  counter,
  defineOrderType,
  selectListItem,
  handleMenuAction,
  actionToPerformOnRequest,
  selectedOrder,
  incremented,
  loadingErrors,
}) => {
  const [defaultList, setDefaultList] = useState([]);
  const [currentItems, setCurrentItems] = useState<any>([]);
  const [pageCount, setPageCount] = useState<number>(0);
  const [itemOffset, setItemOffset] = useState<number>(0);

  const handlePaginate = (items: any) => {
    const endOffset = itemOffset + itemsPerPage;
    setCurrentItems(items.slice(itemOffset, endOffset));
    setPageCount(Math.ceil(items.length / itemsPerPage));
  };

  const handlePageClick = (event: ChangeEvent<unknown>, value = 0) => {
    let calculatedNumber = value - 1;
    const newOffset = (calculatedNumber * itemsPerPage) % list.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    if (loaded && list && list.length > 0) {
      setDefaultList(list);
      handlePaginate(list);
    }
  }, [itemOffset, itemsPerPage, list, loaded]);

  useEffect(() => {
    if (defaultOrder) {
      handlePaginate([...defaultList]);
    }
    let result =
      functions.orderListByCriteria(
        functions.handleOrderCriteria(selectedOrder),
        currentItems,
      ) || defaultList;

    if (
      functions.counterIsEvenAndNotZero(
        functions.handleOrderCounter(selectedOrder, intendedCounter),
      )
    ) {
      let reversed = result.reverse();
      handlePaginate([...reversed]);
    } else if (
      !functions.counterIsEvenAndNotZero(
        functions.handleOrderCounter(selectedOrder, intendedCounter),
      )
    ) {
      handlePaginate([...result]);
    }
  }, [
    orderType,
    defaultOrder,
    intendedCounter,
    counter,
    defaultList,
    selectedOrder,
  ]);

  useEffect(() => {
    return () => {
      handleCounterAndListOrder(config.resettedCounter, 0);
    };
  }, []);

  return (
    <div className="container" id="request-lists">
      {!loaded ? (
        <></>
      ) : loaded &&
        list &&
        list.length === 0 &&
        loadingErrors &&
        loadingErrors?.requests &&
        loadingErrors?.requests?.length > 0 ? (
        <div id="errors-display--reqs">
          <div className="alert alert-info" role="alert">
            <MdErrorOutline className="alert-icon" />
            <span className="lead err-string">{loadingErrors?.requests}</span>
          </div>
        </div>
      ) : loaded &&
        list &&
        list.length === 0 &&
        loadingErrors &&
        loadingErrors?.requests?.length === 0 ? (
        <div id="empty--list-display--reqs">
          <div className="alert alert-info" role="alert">
            <span className="lead err-string">{config?.strings?.noReqStr}</span>
          </div>{' '}
        </div>
      ) : (
        <>
          <ListHeader
            id="accordion-header-container7"
            handleCounterAndListOrder={handleCounterAndListOrder}
            defineOrderType={defineOrderType}
            counter={counter}
            intendedCounter={intendedCounter}
            incremented={incremented}
            config={config}
          />
          <div className="row">
            <div className="rendered--list">
              {currentItems.map((item: any, index: number) => {
                return (
                  <RequestRow
                    item={item}
                    key={index}
                    selectListItem={selectListItem}
                    handleMenuAction={handleMenuAction}
                    actionToPerformOnRequest={actionToPerformOnRequest}
                  />
                );
              })}
            </div>

            <Fragment>
              <hr className="top-hr mx-w" />
              <div className="d-flex justify-content-center">
                <Pagination count={pageCount} onChange={handlePageClick} />
              </div>
            </Fragment>
          </div>
        </>
      )}
    </div>
  );
};

export default RequestList;
