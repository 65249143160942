import React, { Fragment } from 'react';
import './styles.scss';

interface RequestDetailsProps {
  title: string;
  data: any;
}

const RequestDetails: React.FC<RequestDetailsProps> = ({ title, data }) => {
  return (
    <div className="container request-detailed-section">
      <div className="row mlmr01">
        <div className="col-12  details-title">{title}</div>

        {/* commented for now, while not putting here the relevant content */}
        {/* {data.map((elem: any, index: number) => {
          return (
            <div className="container pdg displayed-data" key={index}>
              <div className="row mlmr01 ptpb6">
                {Object.entries(elem).map<any>(
                  ([key, value]: [any, any], index) => {
                    return (
                      <Fragment key={index}>
                        <div className="col-6 pdg lead left-col-label">
                          {key}
                        </div>
                        <div className="col-6 lead pdg right-col-label">
                          {value}
                        </div>
                      </Fragment>
                    );
                  },
                )}
              </div>
            </div>
          );
        })} */}
      </div>
    </div>
  );
};

export default RequestDetails;
