import React, { useState, useEffect } from 'react';
import { EnterDataProps } from './interface';
import { functions } from './helpers';
import { Input } from 'business/modules/admin/common';
import './styles.scss';

const EnterDataCalculatedTime: React.FC<EnterDataProps> = ({
  placeholder,
  id,
  disabled,
  label,
  checkIfNumeric,
  onChangeValues,
  errors,
  userQuitSystemUpdate,
  value,
}) => {
  const [userSelected, setUserSelected] = useState<boolean>(false);

  useEffect(() => {
    if (userQuitSystemUpdate) {
      setUserSelected(false);
    }
  }, [userSelected, userQuitSystemUpdate]);

  return (
    <div className="calculated-time-fields">
      <div className="container white-c fields-margin">
        <div className={`row set-spec-style1`}>
          <div className={`col set-spec-style1`}>
            <div className={`row styles-row1`}>
              <div className={`col-auto additional-label stronger-ft`}>
                <span className="lead smaller-font styles-lead-span-2- stronger-ft">
                  {label} {functions.renderLabel(id)}
                </span>
              </div>
            </div>

            <Input
              className={errors[id] ? `error` : ``}
              name={id}
              id={id}
              disabled={disabled}
              type={checkIfNumeric}
              placeholder={id === `currentTime` ? value : placeholder}
              onChange={onChangeValues}
              value={value}
            />
            <div className="render-error">{errors[id]} </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterDataCalculatedTime;
