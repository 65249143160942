import React from 'react';

interface PageSectionProps {
  className: string;
  id: string;
  cardTitle: string;
  href: string;
  aTitle: string;
  p: any;
}

const PageSection: React.FC<PageSectionProps> = ({
  id,
  className,
  cardTitle,
  href,
  aTitle,
  p,
}) => {
  return (
    <div className={className} id={id}>
      <div className="card-body card-body-middle-section">
        <h4 className="card-title mb-less lead spec-ww">{cardTitle}</h4>
        <a
          className="card-subtitle mb-2 text-muted back-to-the-top-a"
          href={href}
        >
          {aTitle}
        </a>
        <div className="card-text documentation-section-text">{p}</div>
      </div>
    </div>
  );
};

export default PageSection;
