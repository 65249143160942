import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ClientsList, CreateClient, EditClient } from './pages';

const base_path = '/admin/dashboard/clients';

const ClientRoutes: React.FC = ({ ...props }: any) => {
  return (
    <>
      <Switch>
        <Route exact path={`${base_path}`} component={ClientsList} />
        <Route
          exact
          path={`${base_path}/client/add`}
          component={CreateClient}
        />
        <Route exact path={`${base_path}/client/edit`} component={EditClient} />
      </Switch>
    </>
  );
};

export default ClientRoutes;
