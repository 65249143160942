import React from 'react';
import foobot_dd from '../../../../../../../../assets/small/foobot_dd.png';
import airthings from '../../../../../../../../assets/small/airthings.jpg';
import awair from '../../../../../../../../assets/small/awair.png';
import { AirCyclerLogoSVG } from '../../../../../../../../assets/device-icons';

const determineIconFn = (type: any) => {
  if (type === 'airthings') {
    return (
      <img
        src={airthings}
        style={{ width: '22px', height: '24px', marginRight: '0.2rem' }}
      />
    );
  }
  if (type === 'foobot') {
    return <img src={foobot_dd} />;
  }
  if (type === 'awair-element' || type === 'awair-omni') {
    return (
      <img
        src={awair}
        style={{ width: '24px', height: '26px', marginRight: '0.35rem' }}
      />
    );
  }

  if (type === 'aircycler-local' || type.startsWith('AirCyclerAQ')) {
    return (
      <AirCyclerLogoSVG
        style={{ width: '25px', height: '25px', marginRight: '4px' }}
      />
    );
  }
};

const checkTypeOfAircycler = (airc: any) => {
  let typeOfAirC: string = '';

  if (typeof airc === 'string') {
    if (airc[9] === 'V') {
      typeOfAirC = 'VentConnect';
    } else if (airc[9] === 'F') {
      typeOfAirC = 'FreshConnect';
    } else if (airc[9] === 'A') {
      typeOfAirC = 'Local';
    }
  } else {
    //handle when temporarily undefined
    let char = airc?.cdid[9];

    if (char === 'V') {
      typeOfAirC = 'VentConnect';
    } else if (char === 'F') {
      typeOfAirC = 'FreshConnect';
    } else if (char === 'A') {
      typeOfAirC = 'Local';
    }
  }

  return typeOfAirC;
};

export const functions = {
  determineIconFn: determineIconFn,
  checkTypeOfAircycler: checkTypeOfAircycler,
};
