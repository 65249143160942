import React from 'react';
import { Route, Switch } from 'react-router-dom';
import CompanyRoutes from './Companies';
import DashboardAdminRoutes from './DashboardAdmin';
import DeleteRequestsRoutes from './DeleteRequests';
import FirmwareRoutes from './FirmwareVersion';
import ClientRoutes from './Clients';
import { ApiRequestRoutes } from './';

const base_path = '/admin/dashboard';

const DashboardRoutes: React.FC = ({ ...props }: any) => {
  return (
    <Switch>
      <Route path={`${base_path}/clients`} component={ClientRoutes} />

      <Route path={`${base_path}/companies-list`} component={CompanyRoutes} />

      <Route
        exact
        path={`${base_path}/delete-requests`}
        component={DeleteRequestsRoutes}
      />

      <Route
        exact
        path={`${base_path}/firmware-version`}
        component={FirmwareRoutes}
      />

      <Route
        exact
        path={`${base_path}/api-request`}
        component={ApiRequestRoutes}
      />

      <Route path={base_path} component={DashboardAdminRoutes} />
    </Switch>
  );
};

export default DashboardRoutes;
