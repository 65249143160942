import React from 'react';
import { ViewUserDetailsProps } from './interface';
import { ListGroup, ListContainer } from './components';
import { keys } from './config';
import { functions } from './helpers';

const ViewUserDetails: React.FC<ViewUserDetailsProps> = ({
  id,
  config,
  item,
}) => {
  const classes: any = {
    class1: `container-info pdg`,
    class2: `row`,
    role: ``,
    class3: `modal-content pdg`,
  };

  return (
    <ListContainer config={config} id={id}>
      <ListGroup
        keys={keys}
        item={item}
        returnIcon={functions.returnIcon}
        returnKey={functions.returnKey}
        returnValue={functions.returnValue}
        classes={classes}
      />
    </ListContainer>
  );
};

export default ViewUserDetails;
