import React, { useState, useEffect, useRef } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { DeviceInformationProps } from './interface';
import { Button, Select, Spinner } from 'business/modules/admin/common';
import { HiCheck } from 'react-icons/hi';
import { IoIosFlash } from 'react-icons/io';
import { HiOutlineXCircle } from 'react-icons/hi';
import { TbListDetails } from 'react-icons/tb';
import { GrLinkTop } from 'react-icons/gr';
import './styles.scss';

const DeviceInformation: React.FC<DeviceInformationProps> = ({
  devicesPerUser,
  devicesPerUserAreLoaded,
  showDeviceDetailsById,
  deviceDetails,
  setDevModalKey,
  devModalKey,
  resetDevDetailsToShow,
}) => {
  const modalTopOne = useRef<any>(null);
  const devModalRef = useRef<any>(null);
  const [device_id, setDeviceId] = useState('');
  const [id, setAccountId] = useState('');
  const [devInfoIsLoaded, setDevInfoIsLoaded] = useState(false);
  const [requestWasMade, setRequestWasMade] = useState(false);
  const [resetMode, setResetMode] = useState(true);

  const resetKeys: any = {
    button: 'other-dev-key',
    close: 'change-key',
    default: 'other-dev-key2',
  };

  const resetKey = (comesFrom: string) => {
    setDeviceId('');
    switch (comesFrom) {
      case 'button':
      case 'close':
        setDevModalKey(resetKeys[comesFrom]);
        setResetMode(true);
        break;

      default:
        setDevModalKey('other-dev-key2');
        setResetMode(true);
        break;
    }
    // clear saved details
    resetDevDetailsToShow();
  };

  const selectDevId = (event: any) => {
    if (event.target.value === `Select a device`) return;
    setDeviceId(event.target.value);
  };

  const searchDevById = async () => {
    //no reset mode here because will show results, not reset state
    setResetMode(false);
    setRequestWasMade(true);
    if (id && device_id) {
      let response = await showDeviceDetailsById({ id, device_id });

      if (response && response?.statusCode === 200) {
        setDevInfoIsLoaded(true);
      }
    } else {
      return;
    }
  };

  const pushToTheTop = () =>
    modalTopOne.current.scrollIntoView({ behavior: 'smooth' });

  const validateIfObject = (element: any) => {
    if (
      typeof element === 'object' &&
      !Array.isArray(element) &&
      element !== null
    ) {
      for (const [key, value] of Object.entries(element)) {
        return { key: value };
      }
      return true;
    } else {
      return false;
    }
  };

  const returnLabel = (elem: string) => {
    let label = [];
    let lbl;
    let lower = elem?.match(/[a-z]+|[0-9]+/g);
    let upper = elem?.match(/[A-Z][a-z]+|[0-9]+/g);

    if (lower) {
      label.push(lower[0]);
    }
    if (upper) {
      label.push(upper.join(' '));
    }
    lbl = label.join(' ').toLowerCase();
    return lbl.charAt(0).toUpperCase() + lbl.slice(1);
  };

  const returnDesignation = (code: string) => {
    if (config.dictionary.hasOwnProperty(code)) {
      return config.dictionary[code as keyof typeof config.dictionary];
    } else {
      return returnLabel(code);
    }
  };

  const renderValue = (value: any) => {
    Object.entries(value).map((key: any, val: any) => {
      if (key[0] === 'cdvs') {
        return key[1].map((elem: any, index: number) => {});
      } else {
        return (
          <span key={key}>
            {key}: {value}
          </span>
        );
      }
    });
  };

  const defineIfShowAcronym = (key: string, dictionaryKeys: any) => {
    if (dictionaryKeys.includes(key)) {
      return true;
    }
    return false;
  };

  const returnValue = (id: string, ob: any) => {
    if (ob && Object.keys(ob).length > 0 && ob.hasOwnProperty(id)) {
      return ob[id];
    } else {
      return 'No values.';
    }
  };

  useEffect(() => {
    let account: any = localStorage.getItem('currentAccount');
    if (account) {
      setAccountId(account);
    }
  }, []);

  useEffect(() => {
    if (device_id) {
      searchDevById();
    }
  }, [device_id]);

  useEffect(() => {
    const handleClick = (event: any) => {
      if (devModalRef.current?.contains(event.target)) {
        if (event.target === devModalRef.current) {
          resetKey('backdrop-dev');
          setDeviceId('');
        }
      }
    };
    window.addEventListener('click', handleClick);
    return () => window.removeEventListener('click', handleClick);
  }, [devModalRef]);

  return (
    <div
      ref={devModalRef}
      key={devModalKey}
      className="modal fade"
      id="device-show"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="exampleModalCenterTitle"
      aria-hidden="true"
    >
      <div className="modal-dialog modal-dialog-centered" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title" id={`exampleModalLongTitle5`}>
              {`Show device details`}
            </h5>
            <Button
              type="button"
              className="close"
              dataDismiss="modal"
              onClick={() => resetKey('button')}
              id="close-modal6"
            >
              <span aria-hidden="true">&times;</span>
            </Button>
          </div>

          <div className="modal-body data-container">
            {!devicesPerUserAreLoaded ? (
              <Spinner />
            ) : (
              <Select
                optionValue={`Select a device`}
                inputClass={`input-group mb-3`}
                selectClass={`custom-select`}
                selectId={`devs_id`}
                onChange={selectDevId}
                title={`Select a device`}
                array={devicesPerUser}
                resetMode={resetMode}
              />
            )}

            {!devInfoIsLoaded && requestWasMade ? (
              <Spinner />
            ) : resetMode ? (
              <div className="card reset-mode-1">
                <div className="card-body pdg-tb">
                  <h5 className="card-title centered">{`Device details`}</h5>
                  <h6
                    className="card-subtitle mb-2 text-muted centered"
                    style={{ paddingBottom: '6px' }}
                  >
                    <TbListDetails style={{ fontSize: '1.2rem' }} />
                  </h6>
                  <p className="card-text lead smlead centered">{`Device details will show here.`}</p>
                </div>
              </div>
            ) : (
              deviceDetails &&
              Object.keys(deviceDetails).length > 0 && (
                <div className="container outer--cont" ref={modalTopOne}>
                  <div className="row">
                    <div className="col-12 pdg">
                      <h5 className="card-title">{`Device details`}</h5>
                      <h6 className="card-subtitle mb-2 text-muted">
                        {deviceDetails?.device_name}
                      </h6>
                    </div>
                    <div className="col-12 pdg">
                      <div className="card-text lead dev-details-info">
                        <div className="container pdg">
                          <div className="row no-spaced-c each-r">
                            <div className="col no-spaced-c pdg"></div>
                            <div className="col-auto no-spaced-c pdg">
                              <span className="tw-ft-sz">
                                {deviceDetails?.isSystem && <HiCheck />} System{' '}
                              </span>
                            </div>
                          </div>
                          <div className="row no-spaced-c each-r">
                            <div className="col no-spaced-c pdg">
                              <span className="dev-labels dsd-span-ft--1">
                                Status
                              </span>
                            </div>
                            <div className="col-auto no-spaced-c pdg">
                              {deviceDetails?.status === 'active' ? (
                                <span className="tw-ft-sz">
                                  {' '}
                                  <IoIosFlash /> active{' '}
                                </span>
                              ) : (
                                <span className="tw-ft-sz">
                                  {' '}
                                  <IoIosFlash /> inactive{' '}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row no-spaced-c each-r">
                            <div className="col no-spaced-c pdg">
                              <span className="dev-labels dsd-span-ft--1">
                                ID
                              </span>
                            </div>
                            <div className="col-auto no-spaced-c pdg">
                              <span className="tw-ft-sz">
                                {' '}
                                {deviceDetails?._id}
                              </span>
                            </div>
                          </div>

                          {deviceDetails &&
                            Object.entries(deviceDetails?.details).map(
                              (item: any, index: any) => {
                                if (!validateIfObject(item[1])) {
                                  return (
                                    <div className="container pdg" key={index}>
                                      <div className="row each-r">
                                        <div className="col no-spaced-c pdg">
                                          <span className="dev-labels dsd-span-ft--1">
                                            {returnDesignation(item[0])}{' '}
                                          </span>
                                        </div>
                                        <div className="col-auto no-spaced-c pdg">
                                          <span className="tw-ft-sz">
                                            : {item[1]}
                                          </span>
                                        </div>
                                      </div>{' '}
                                    </div>
                                  );
                                } else {
                                  return (
                                    <div key={index}>
                                      {Object.entries(item[1]).map(
                                        ([key, value], i) => {
                                          if (key === 'conf') {
                                            return (
                                              <div
                                                className="container pdg"
                                                key={i}
                                              >
                                                <div className="row each-r">
                                                  <div className="col no-spaced-c pdg">
                                                    <span className="devs-label-spec mt-1">
                                                      {returnDesignation(key)}{' '}
                                                    </span>
                                                  </div>
                                                  <div className="col-auto no-spaced-c pdg">
                                                    <span className="tw-ft-sz">
                                                      : {renderValue(value)}
                                                    </span>
                                                  </div>
                                                </div>{' '}
                                              </div>
                                            );
                                          } else {
                                            return (
                                              <div className="container pdg">
                                                <div className="row no-spaced-c each-r">
                                                  <div className="col no-spaced-c pdg">
                                                    <span className="dev-labels dsd-span-ft--1">
                                                      {returnDesignation(key)}{' '}
                                                      {defineIfShowAcronym(
                                                        key,
                                                        Object.keys(
                                                          config.dictionary,
                                                        ),
                                                      ) ? (
                                                        <span className="dsd-acronym">
                                                          [{key}]
                                                        </span>
                                                      ) : null}
                                                      :
                                                    </span>
                                                  </div>
                                                  <div className="col-auto no-spaced-c pdg">
                                                    <span className="tw-ft-sz">
                                                      {value} <br />
                                                    </span>
                                                  </div>
                                                </div>{' '}
                                              </div>
                                            );
                                          }
                                        },
                                      )}
                                    </div>
                                  );
                                }
                              },
                            )}
                          <span className="devs-label-spec mt-2">
                            User and device data
                          </span>
                          <div className="container pdg">
                            <div className="row no-spaced-c each-r">
                              <div className="col no-spaced-c pdg">
                                <span className="dev-labels dsd-span-ft--1">
                                  User ID:{' '}
                                </span>
                              </div>
                              <div className="col-auto no-spaced-c pdg">
                                <span className="tw-ft-sz">
                                  {' '}
                                  {deviceDetails?.user_id}
                                </span>
                              </div>
                            </div>
                          </div>
                          <div className="container pdg">
                            <div className="row no-spaced-c each-r">
                              <div className="col no-spaced-c pdg">
                                <span className="dev-labels dsd-span-ft--1">
                                  Device ID:
                                </span>
                              </div>
                              <div className="col-auto no-spaced-c pdg">
                                <span className="tw-ft-sz">
                                  {' '}
                                  {deviceDetails?.device_id}
                                </span>
                              </div>
                            </div>
                          </div>
                          {config.cityDetails.map(
                            (label: any, index: number) => {
                              return (
                                <div className="container pdg" key={index}>
                                  <div className="row no-spaced-c each-r">
                                    <div className="col no-spaced-c pdg">
                                      {' '}
                                      <span className="dev-labels dsd-span-ft--1">
                                        {returnDesignation(label)}:{' '}
                                      </span>
                                    </div>
                                    <div className="col-auto no-spaced-c pdg">
                                      <span className="tw-ft-sz">
                                        {' '}
                                        {returnValue(
                                          label,
                                          deviceDetails?.device_city,
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            },
                          )}
                          {config.stateDetails.map(
                            (label: any, index: number) => {
                              return (
                                <div className="container pdg" key={index}>
                                  <div className="row no-spaced-c each-r">
                                    <div className="col no-spaced-c pdg">
                                      <span className="dev-labels dsd-span-ft--1">
                                        {returnDesignation(label)}:{' '}
                                      </span>
                                    </div>
                                    <div className="col-auto no-spaced-c pdg">
                                      <span className="tw-ft-sz">
                                        {returnValue(
                                          label,
                                          deviceDetails?.device_state,
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            },
                          )}
                          {config.zipDetails.map(
                            (label: any, index: number) => {
                              return (
                                <div className="container pdg" key={index}>
                                  <div className="row no-spaced-c each-r">
                                    <div className="col no-spaced-c pdg">
                                      <span className="dev-labels dsd-span-ft--1">
                                        {' '}
                                        {returnDesignation(label)}:
                                      </span>{' '}
                                    </div>
                                    <div className="col-auto no-spaced-c pdg">
                                      <span className="tw-ft-sz">
                                        {' '}
                                        {returnValue(
                                          label,
                                          deviceDetails?.device_zipcode,
                                        )}
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              );
                            },
                          )}
                          <br />
                        </div>
                      </div>
                    </div>
                    <div className="col-12 pdg center-content">
                      <button
                        className="card-link back-to-top mt-2"
                        onClick={pushToTheTop}
                      >
                        <span className="lead btn-label">
                          {' '}
                          <GrLinkTop
                            style={{
                              fontSize: '0.8rem',
                              marginRight: '0.1rem',
                            }}
                          />{' '}
                          {`Back to the top`}
                        </span>
                      </button>
                    </div>
                  </div>
                </div>
              )
            )}
          </div>

          <div className="modal-footer" id="check-devs-modal-footer">
            <Button
              onClick={() => {
                resetKey('close');
              }}
              className="small-button dismiss-btn"
              dataDismiss={`modal`}
            >
              <HiOutlineXCircle className="cancel-v" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { adminState, adminDevicesState, adminUsersState } = state;
  return {
    deviceDetails: adminDevicesState.deviceDetails,
    devModalKey: adminState.devModalKey,
    devicesPerUser: adminUsersState.devicesPerUser,
    devicesPerUserAreLoaded: adminUsersState.devicesPerUserAreLoaded,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminState, adminDevicesState } = dispatch;
  return {
    showDeviceDetailsById: adminDevicesState.showDeviceDetailsById,
    resetDevDetailsToShow: adminDevicesState.resetDevDetailsToShow,
    setDevModalKey: adminState.setDevModalKey,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceInformation);
