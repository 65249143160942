import React from 'react';

interface SectionProps {
  id: string;
  h6: string;
  specH6: string;
  description: string;
}

const Section: React.FC<SectionProps> = ({ id, h6, specH6, description }) => {
  return (
    <div className="container pdg" id={id}>
      <div className="row mlmr0">
        <div className="col-auto pdg">
          <h6 className="auth-header-h6">{h6}</h6>
        </div>
        {'  '}|{'  '}
        <div className="col-auto pdg">
          <h6 className="spec-h6">{specH6}</h6>
        </div>
        <div className="col-12 pdg">
          <span>{description}</span>
        </div>
      </div>
    </div>
  );
};

export default Section;
