import React from 'react';
import config from '../../config';
import { Dropdown } from './components';
import { LandingPageLogo } from 'business/assets/landing-page';
import './styles.scss';

interface NavbarProps {}

const Navbar: React.FC<NavbarProps> = () => {
  return (
    <div className="fixed-nb">
      <nav className="navbar navbar-expand-lg navbar navbar-light main-navbar-doc">
        <a className="navbar-brand" href="#">
          <img src={LandingPageLogo} alt="Logo" width="40%" />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#documentation-navbar-content"
          aria-controls="documentation-navbar-content"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="collapse navbar-collapse"
          id="documentation-navbar-content"
        >
          <ul className="navbar-nav mr-auto"></ul>
          <div className="form-inline my-2 my-lg-0">
            <div className="form-control mr-sm-2 text1"> </div>
          </div>
        </div>
      </nav>

      <nav className="navbar navbar-expand-lg navbar navbar-light bg-light lower-navbar">
        {Object.entries(config?.lowerNavData).map(([key, val], i) => {
          return (
            <div className="row mlmr0" key={i}>
              <div className="col pdg">
                <span className="nav-keys">{key.toUpperCase()} </span>
              </div>
              <div className="col">
                <Dropdown options={val} />
              </div>
            </div>
          );
        })}
      </nav>
    </div>
  );
};

export default Navbar;
