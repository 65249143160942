// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import _ from 'lodash';
import config from '../../config';
import './styles.scss';



const LOCAL_SENSORS_STR: any = { ...config.strings.localSensors };
let CHARTS_COLORS: any = { ...config.chartsColors };


interface ComplementaryLegendProps {
  id?: any;
  data: any;
  system?: any;
  chartHoverCfItems?: any;
  chartHoverExItems?: any;
  chartHoveringCfState: any;
  chartHoveringExState: any;
  setHoveringState: Function;
}

const ComplementaryLegend: React.FC<ComplementaryLegendProps> = ({
  id,
  data,
  system,
  chartHoveringCfState,
  chartHoveringExState,
  chartHoverCfItems,
  chartHoverExItems,
}) => {
  const [legendCfData, setLegendCfData]: any = useState();
  const [legendExData, setLegendExData]: any = useState();
  const [cfLegentBoxState, setCfLegendBoxState]: any = useState(false);
  const [exLegentBoxState, setExLegendBoxState]: any = useState(false);
  const [hasAirLocalSensor, setHasLocalAir]: boolean = useState(false);
  const [localSensor, setLocalSensor]: any = useState();



  const returnTitle = (option: string) => {
    switch (option) {
      case 'timeEllapsed':
      case 'mixTimeEllapsedB':
        return 'Time Ellapsed';
      case 'timeRemain':
      case 'mixTimeRemainB':
        return 'Time Remaining';
      case 'aircyclerEllapsed':
        return 'AirCycler Ventilation (Done)';
      case 'aircyclerRemain':
        return 'AirCycler Ventilation';
      case 'thermostatEllapsed':
        return 'Thermostat Event';
      case 'bathroomExhaust':
        return 'VentConnect Event';
      case 'mixTimeEllapsed':
        return 'Mix Time Ellapsed';
      case 'mixTimeRemain':
        return 'Mix Time Remain';
      default:
        return option;
    }
  };

  const returnDescription = (option: string, value: any) => {
    switch (option) {
      case 'timeEllapsed':
      case 'mixTimeEllapsedB':
        return `Time ellapsed in the period.`;
      case 'timeRemain':
      case 'mixTimeRemainB':
        return `Time that is left to the end the period.`;
      case 'aircyclerEllapsed':
        return `Required ventilation in the period that is already done.`;
      case 'aircyclerRemain':
        return `Required ventialtion in the period defined by the user.`;
      case 'thermostatEllapsed':
        return 'G3 device detected a thermostat call.';
      case 'bathroomExhaust':
        return `Exhaust ventilation event been on.`;
      case 'mixTimeEllapsed':
        return `Additional ventilation time in the period that is already done.`;
      case 'mixTimeRemain':
        return `Additional ventilation time remaining in the period to reach user defined mix time.`;
      default:
        return returnTitle(option);
    }
  };

  const findLocalAirSensor = (_system: any) => {
    let hasAnyLocal: boolean = false;
    let localSensor: any;

    if(_system?.details){
      const { conf }: any = _system?.details?.settings || {};
      if(
        conf !== null &&
        conf !== undefined
      ){
        const cdvsClone: any =
          conf.cdvs && conf.cdvs.length > 0 ? _.cloneDeep(conf.cdvs) : [];
        const tempLocalSensor: any = cdvsClone.find((item: any) => {
          const { cdid }: any = item || {};
          return cdid !== null && cdid !== undefined && cdid.includes('AirCyclerAQ');
        });

        hasAnyLocal = tempLocalSensor !== null && tempLocalSensor !== undefined;
        localSensor = _.cloneDeep(tempLocalSensor);
      }
    }

    return {
      hasLocal: hasAnyLocal,
      sensor: localSensor,
    };
  };


  const getLocalSensorValue = (sensorData: any, option: string) => {
    const { aqsh, aqst }: any = sensorData || {};

    switch (option) {
      case 'temp':
        return aqst;
      case 'humd':
        return aqsh;
      default:
        return 0;
    }
  };




  useEffect(() => {
    if(
      system !== null &&
      system !== undefined
    ){
      const { hasLocal, sensor }: any = findLocalAirSensor(system);

      setHasLocalAir(hasLocal);
      setLocalSensor(sensor);
    }

    return () => {
      setHasLocalAir(false);
      setLocalSensor();
    };
  }, [system]);


  useEffect(() => {
    if(chartHoverCfItems && chartHoverCfItems[id]){
      setLegendCfData(chartHoverCfItems[id]);
    }
  }, [chartHoverCfItems]);

  useEffect(() => {
    if(chartHoverExItems && chartHoverExItems[id]){
      setLegendExData(chartHoverExItems[id]);
    }
  }, [chartHoverExItems]);


  useEffect(() => {
    if(chartHoveringCfState){
      setCfLegendBoxState(
        !!(chartHoveringCfState && chartHoveringCfState[id])
      );
    }
  }, [chartHoveringCfState]);

  useEffect(() => {
    if(chartHoveringExState){
      setExLegendBoxState(
        !!(chartHoveringExState && chartHoveringExState[id])
      );
    }
  }, [chartHoveringExState]);


  return (
    <>
      {exLegentBoxState && <div
        id="complementary-legend-wrapper--central-fan"
        className="complementary-legend-wrapper"
      >
        <div className="console-title">
          Exhaust
        </div>
        {legendExData &&
          <div className="console-body">
            <span style={{ fontSize: '0.7rem', fontWeight: 'bold', flex: 1 }}>
              {returnTitle(legendExData.x)}
            </span>

            <span style={{ fontSize: '0.7rem', flex: 4, padding: '4px 10px' }}>
              {returnDescription(legendExData.x, legendExData?.y || 0)}
            </span>
          </div>
        }
      </div>}

      {cfLegentBoxState && <div
        id="complementary-legend-wrapper--exhaust"
        className="complementary-legend-wrapper"
      >
        <div className="console-title">
          Central Fan
        </div>
        {legendCfData &&
          <div className="console-body">
            <span style={{ fontSize: '0.7rem', fontWeight: 'bold', flex: 1 }}>
              {returnTitle(legendCfData.x)}
            </span>

            <span style={{ fontSize: '0.7rem', flex: 4, padding: '4px 10px' }}>
              {returnDescription(legendCfData.x, legendCfData?.y || 0)}
            </span>
          </div>
        }
      </div>}
      {
        hasAirLocalSensor &&
          <div id="iqa-container" className="iqa-sensor-container" >
            <div className="iqa--temperature">
              <span className="iqa--temperature-value">
                {getLocalSensorValue(localSensor, 'temp')}
              </span>
              <span className="iqa--temperature-symbol">
                {LOCAL_SENSORS_STR?.tempSymbol}
              </span>
              <span className="iqa--temperature-label">
                {LOCAL_SENSORS_STR?.temp}
              </span>
            </div>
            <div className="iqa--humidity">
              <span className="iqa--humidity-value">
                {getLocalSensorValue(localSensor, 'humd')}
              </span>
              <span className="iqa--humidity-symbol">
                {LOCAL_SENSORS_STR?.humdSymbol}
              </span>
              <span className="iqa--humidity-label">
                {LOCAL_SENSORS_STR?.humd}
              </span>
            </div>
          </div>
      }
    </>
  );
};


function mapStateToProps(state: any) {
  const { navigationalState } = state;

  return {
    chartHoveringCfState: navigationalState.chartHoveringCfState,
    chartHoveringExState: navigationalState.chartHoveringExState,
    chartHoverCfItems: navigationalState.chartHoverCfItems,
    chartHoverExItems: navigationalState.chartHoverExItems,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { navigationalState } = dispatch;

  return {
    setHoveringState: navigationalState.setHoveringState,
  };
}


export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ComplementaryLegend);
