import { ConnectionBroker } from '../../core';

export const adminRequestsState = {
  state: {
    actionToPerformOnRequest: 'init',
    selectListItem: {},
    orderType: `Default`,
    defaultOrder: true,
    selectedOrder: {
      byName: false,
      byDate: false,
      byStatus: false,
    },
    intendedCounter: {
      name: 0,
      date: 0,
      status: 0,
    },
    counter: 0,
    deleteRequestList: [],
    deleteRequestsLoaded: false,
    deleteResponseReceived: false,
    cancelResponseReceived: false,
    modalMustClose: false,
    loadingErrors: {
      requests: '',
    },
  },
  reducers: {
    handleCounterAndListOrder(state: any, payload: any, counter: number) {
      return {
        ...state,
        intendedCounter: payload,
        counter: counter,
      };
    },
    defineOrderType(state: any, payload: string) {
      switch (payload) {
        case 'Default':
          return {
            ...state,
            orderType: payload,
            defaultOrder: true,
            selectedOrder: {
              byName: false,
              byDate: false,
              byStatus: false,
            },
          };
        case 'Requester':
          return {
            ...state,
            orderType: payload,
            defaultOrder: false,
            selectedOrder: {
              byName: true,
              byDate: false,
              byStatus: false,
            },
          };
        case 'Request date':
          return {
            ...state,
            orderType: payload,
            defaultOrder: false,
            selectedOrder: {
              byName: false,
              byDate: true,
              byStatus: false,
            },
          };
        case 'Status':
          return {
            ...state,
            orderType: payload,
            defaultOrder: false,
            selectedOrder: {
              byName: false,
              byDate: false,
              byStatus: true,
            },
          };
      }
    },
    handleMenuAction(state: any, payload: string) {
      return {
        ...state,
        actionToPerformOnRequest: payload,
      };
    },
    selectListItem(state: any, payload: any) {
      return { ...state, selectedRequest: payload };
    },
    setDelReqs(state: any, payload: any) {
      return {
        ...state,
        deleteRequestList: payload,
        deleteRequestsLoaded: true,
      };
    },
    handleModalState(state: any, payload: any) {
      return {
        ...state,
        modalMustClose: payload,
      };
    },
    handleCancelState(state: any, payload: any) {
      return {
        ...state,
        cancelResponseReceived: payload,
      };
    },
    handleLoadingErrors(state: any, payload: any) {
      return {
        ...state,
        loadingErrors: {
          ...state.loadingErrors,
          [payload]:
            'Something went wrong while loading the requests. Please, try again later!',
        },
      };
    },
  },
  effects: (dispatch: any) => ({
    async getDeleteRequests() {
      const that: any = this;
      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.getDeleteReqs`,
      );
      if (response?.statusCode === 200) {
        that.setDelReqs(response?.body?.data);
      } else {
        //handle errors
        that.handleLoadingErrors('requests');
        that.setDelReqs([]);
      }

      return response;
    },
    async deletePendingRequest(data: any) {
      const that: any = this;

      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.deleteRequestToDeleteAccount`,
        data,
        null,
      );
      if (response?.statusCode === 200) {
        that.handleModalState(true);
      } else if (response?.statusCode !== 200) {
        that.handleModalState(true);
      }

      return response;
    },
    async cancelPendingRequest(data: any) {
      const that: any = this;

      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.cancelRequestToDeleteAccount',
        {
          id: data.id,
        },
      );
      if (response?.statusCode === 200) {
        that.handleModalState(true);
      } else {
        that.handleModalState(true);
      }

      return response;
    },
  }),
};
