import { ConnectionBroker } from '../../core';
import { functions } from '../helpers';

export const adminDevicesState = {
  state: {
    endedLoadingSysSetts: false,
    timeinHoursAndMins: '',
    deviceDetails: {},
    sensorsList: [],
    sensorsAreLoaded: false,
    sensorDetails: {},
    systemsList: [],
    systemsListIsLoaded: false,
    systemsWithConnData: [],
    loadedSystemsWithConnData: false,
    currentPeripheral: {},
    commandsList: [],
    commandsListIsLoaded: false,
    currentAccount: '',
    currentDev: '',
    g3Device: null,
    currentlySelectedTimezone: '',
    deviceShowDetailsAdm: [],
    deviceDetailsLoaded: false,
    connectedDevsDesignations: [],
    periphIsLoaded: false,
    selectedSystem: '',
    currSystemDetails: {},
    loadedSysDetails: false,
    designationsAreLoaded: false,
    devNameLocationData: {
      name: '',
      city: '',
      state: '',
      zip: '',
      status: undefined,
    },
    editDataKey: 'dev-update-modal-key',
    userCancelledUpdate: false,
    willDeleteId: '',
    willDeleteName: '',
    selectAddSens: '',
    selectAddType: '',
    selectVentSetup: '',
    selectTimezone: '',
    selectPrimaryControl: '',
    userQuitSystemUpdate: false,
    inputValues: {
      systemName: '',
      systemLocationState: '',
      systemLocationCity: '',
      systemLocationZipCode: '',
    },
    ddUpdatingData: {
      timezone: '',
      vent_setup: '',
      add_sens: '',
      primary_control: '',
    },
    devStatus: false,
    iconToShow: undefined,
    defaultIcon: undefined,
    time: '',
    timeZoneStr: '',
    hoursOfOp: false,
    //vent setup state:
    ventSetupValues: {
      minutesPerHourSupplyFlow: 0,
      minutesPerHourExhaustRunFC: 0,
      minutesPerHourExhaustRunVC: 0,
      exhaustFanDelayTime: 0,
      additionalMixTime: 0,
      flowMeasuredHeatCall: 0,
      flowMeasuredCoolingCall: 0,
      flowMeasuredFanOnlyCall: 0,
      requiredContinuousAirFlow: 0,
      exhaustFanFlowRate: 0,
    },
    timeToStartStop: { hourStart: '', hourStop: '' },
    errors: {
      hourError: '',
      endHourError: '',
      adMxError: '',
      exhaustFanDelayTime: '',
      minutesPerHourSupplyFlow: '',
      minutesPerHourExhaustRun: '',
      flowMeasuredHeatCall: '',
      flowMeasuredCoolingCall: '',
      flowMeasuredFanOnlyCall: '',
      requiredContinuousAirFlow: '',
      exhaustFanFlowRate: '',
    },
    currentSelectBox: '',
    isInitialRender: true,
    currPrimCtrIsVent: false,
    dName: '',
    allTheDevs: [],
    allTheDevsLoaded: false,
    currDevIDLogs: undefined,
    deviceLocationTimezone: undefined,
    loadingErrors: {
      sensorsErr: '',
      sensorsErrPerAcc: '',
    },
    peripheralNewName: '',
    indoorAirSensorsDisplay: [],
    sensorsDisplayLoaded: false,
    orders: {
      orderByDate: false,
      orderByEvents: false,
      orderByStatus: false,
    },
    intendedCounter: {
      date: 0,
      events: 0,
      status: 0,
    },
    counter: 0,
    orderedList: false,
    userStartedToType: false,
    systemsCurrentStatus: {},
  },
  reducers: {
    clearStatus(state: any) {
      return { ...state, systemsCurrentStatus: {} };
    },
    saveStatus(state: any, payload: any) {
      return {
        ...state,
        systemsCurrentStatus: {
          ...state.systemsCurrentStatus,

          [payload.dev_id]: {
            stopped: payload.status,
            statusIsLoaded: payload.statusIsLoaded,
          },
        },
      };
    },
    updateOrderState(state: any, payload: string) {
      switch (payload) {
        case 'Command Date':
          return {
            ...state,
            orderedList: false,
            orders: {
              orderByDate: true,
              orderByEvents: false,
              orderByStatus: false,
            },
          };
        case 'Events':
          return {
            ...state,
            orderedList: false,
            orders: {
              orderByDate: false,
              orderByEvents: true,
              orderByStatus: false,
            },
          };
        case 'Status':
          return {
            ...state,
            orderedList: false,
            orders: {
              orderByDate: false,
              orderByEvents: false,
              orderByStatus: true,
            },
          };
        case 'Default':
          return {
            ...state,
            orderedList: true,
            orders: {
              orderByDate: false,
              orderByEvents: false,
              orderByStatus: false,
            },
          };
        default:
          return {
            ...state,
            orderedList: payload,
            orders: {
              orderByDate: false,
              orderByEvents: false,
              orderByStatus: false,
            },
          };
      }
    },
    handleCounterAndListOrder(state: any, payload: any, counter: number) {
      return {
        ...state,
        intendedCounter: payload,
        counter: counter,
      };
    },
    markListAsLoaded(state: any, payload: boolean) {
      return {
        ...state,
        sensorsDisplayLoaded: payload,
      };
    },
    handleSensorsDisplay(state: any, payload: any) {
      return {
        ...state,
        indoorAirSensorsDisplay: state.indoorAirSensorsDisplay.concat(payload),
      };
    },
    handleUserTyped(state: any, payload: boolean) {
      return {
        ...state,
        userStartedToType: payload,
      };
    },
    updateName(state: any, payload: string) {
      return {
        ...state,
        peripheralNewName: payload,
        userStartedToType: true,
      };
    },
    clearListOnLeaving(state: any) {
      return {
        ...state,
        sensorsList: [],
        sensorsAreLoaded: false, //and then, add the other situations too (systems, etc)
      };
    },
    endedLoading(state: any, payload: boolean, type: string) {
      switch (type) {
        case 'sys-set':
          return {
            ...state,
            endedLoadingSysSetts: payload,
          };
        default:
          return {
            ...state,
          };
      }
    },
    storeDevLocationTmz(state: any, payload: any) {
      const tmz = payload;
      //store this on localStorage so we can access it on page reloading
      localStorage.setItem('@deviceLocalTimezone', tmz);

      return {
        ...state,
        deviceLocationTimezone: tmz,
      };
    },
    storeIDToAccessLogs(state: any, payload: any) {
      localStorage.setItem('currDevIDLogs', payload);
      let currentIDLogs = localStorage.getItem('currDevIDLogs');
      if (currentIDLogs) {
        return {
          ...state,
          currDevIDLogs: currentIDLogs,
        };
      } else {
        return {
          ...state,
          currDevIDLogs: payload,
        };
      }
    },
    gatherAllDevs(state: any, payload: any) {
      return {
        ...state,
        allTheDevs: payload,
        allTheDevsLoaded: true,
      };
    },
    //added to clear list
    resetAllTheDevs(state: any) {
      return {
        ...state,
        allTheDevs: [],
        allTheDevsLoaded: false,
      };
    },

    resetCompleteDevsList(state: any) {
      return {
        ...state,
        allTheDevs: [],
        allTheDevsLoaded: false,
      };
    },
    setTimeInHoursAndMins(state: any, payload: string) {
      return { ...state, timeinHoursAndMins: payload };
    },
    markPrimaryCtrAsVC(state: any, payload: any) {
      return {
        ...state,
        currPrimCtrIsVent: payload,
      };
    },
    saveSystemsWithConnectionInfo(state: any, payload: any) {
      return {
        ...state,
        systemsWithConnData: payload,
        loadedSystemsWithConnData: true,
      };
    },
    signalUpdate(state: any, payload: boolean) {
      return {
        ...state,
        isInitialRender: payload,
      };
    },
    setDropdownsSelections(state: any, payload: any, resetAll?: boolean) {
      if (state.isInitialRender) {
        return {
          ...state,
          ddUpdatingData: payload,
          isInitialRender: false,
        };
      }
      if (resetAll) {
        return {
          ...state,
          ddUpdatingData: {
            timezone: '',
            vent_setup: '',
            add_sens: '',
            primary_control: '',
          },
        };
      }
      if (payload.hasOwnProperty('timezone')) {
        return {
          ...state,
          ddUpdatingData: {
            ...state.ddUpdatingData,
            timezone: payload.timezone,
          },
        };
      }
      if (payload.hasOwnProperty('add_sens')) {
        return {
          ...state,
          ddUpdatingData: {
            ...state.ddUpdatingData,
            add_sens: payload.add_sens,
          },
        };
      }
      if (payload.hasOwnProperty('primary_control')) {
        return {
          ...state,
          ddUpdatingData: {
            ...state.ddUpdatingData,
            primary_control: payload.primary_control,
          },
        };
      }
      if (payload.hasOwnProperty('vent_setup')) {
        return {
          ...state,
          ddUpdatingData: {
            ...state.ddUpdatingData,
            vent_setup: payload.vent_setup,
          },
        };
      } else {
        return { ...state };
      }
    },
    defCurrentSelectBox(state: any, payload: string) {
      return {
        ...state,
        currentSelectBox: payload,
      };
    },
    saveOriginalVSet(state: any, payload: string) {
      return {
        ...state,
        originalVSet: payload,
      };
    },
    setErrors(state: any, payload: any) {
      if (payload.resetAll && !payload.type) {
        return {
          ...state,
          errors: {
            ...state.errors,
            hourError: '',
            endHourError: '',
            adMxError: '',
            exhaustFanDelayTime: '',
            minutesPerHourSupplyFlow: '',
            minutesPerHourExhaustRun: '',
            flowMeasuredHeatCall: '',
            flowMeasuredCoolingCall: '',
            flowMeasuredFanOnlyCall: '',
            requiredContinuousAirFlow: '',
            exhaustFanFlowRate: '',
            hourStart: '',
            hourStop: '',
          },
        };
      }
      if (payload.type && payload.hasError) {
        return {
          ...state,
          errors: {
            ...state.errors,
            [payload.type]: functions.renderSuitableError(
              payload.type,
              payload.hasError,
            ),
          },
        };
      }
      if (payload.type && !payload.hasError) {
        return {
          ...state,
          errors: {
            ...state.errors,
            [payload.type]: '',
          },
        };
      }
      return {
        ...state,
      };
    },
    setSystemHours(state: any, payload: any) {
      return {
        ...state,
        timeToStartStop: {
          hourStart: payload.hourStart,
          hourStop: payload.hourStop,
        },
      };
    },
    setHoursOfOp(state: any, payload: boolean) {
      return {
        ...state,
        hoursOfOp: payload,
      };
    },
    setVentSetupValues(state: any, payload: any) {
      return {
        ...state,
        ventSetupValues: payload,
      };
    },
    setTime(state: any, payload: any) {
      return {
        ...state,
        time: payload,
      };
    },
    setTimezoneStr(state: any, payload: any) {
      return {
        ...state,
        timeZoneStr: payload,
      };
    },
    setDefaultIconToShow(state: any, payload: any) {
      return {
        ...state,
        defaultIcon: payload,
      };
    },
    setIconToShow(state: any, payload: any) {
      return {
        ...state,
        iconToShow: payload,
      };
    },
    setDevStatus(state: any, payload: boolean) {
      return {
        ...state,
        devStatus: payload,
      };
    },
    setInputValues(state: any, payload: any) {
      return {
        ...state,
        inputValues: payload,
      };
    },
    setUpdateQuitting(state: any, payload: boolean) {
      return {
        ...state,
        userQuitSystemUpdate: payload,
      };
    },
    storeSystemSelectData(state: any, payload: any) {
      if (typeof payload === 'string') {
        return {
          ...state,
          selectPrimaryControl: payload,
        };
      }
      if (Object.keys(payload).length === 1 && payload.hasOwnProperty('name')) {
        return {
          ...state,
          selectVentSetup: payload.name,
        };
      }
      if (payload.hasOwnProperty('device_type')) {
        return {
          ...state,
          selectAddSens: payload.name,
          selectAddType: payload.device_type,
        };
      }
      if (payload.hasOwnProperty('timezone')) {
        return {
          ...state,
          selectTimezone: payload.timezone,
        };
      }
      return {
        ...state,
      };
    },
    setDataToDelete(state: any, payload: any) {
      return {
        ...state,
        willDeleteId: payload.deviceId,
        willDeleteName: payload.devName,
      };
    },
    setCancellation(state: any, payload: boolean) {
      return {
        ...state,
        userCancelledUpdate: payload,
      };
    },
    setUpdateFormKey(state: any, payload: any) {
      return { ...state, editDataKey: payload };
    },
    updateDeviceLocationNameData(state: any, payload: any) {
      return {
        ...state,
        devNameLocationData: {
          name: payload.inputFields.name,
          city: payload.inputFields.city,
          state: payload.inputFields.state,
          zip: payload.inputFields.zip,
          status: payload.status,
        },
      };
    },
    saveSystemDetails(state: any, payload: any) {
      localStorage.setItem('currentSystemNameSysPage', payload?.device_name);
      localStorage.setItem('currSystemDetailsData', JSON.stringify(payload));
      const det = localStorage.getItem('currSystemDetailsData');
      let parsed;

      if (det) {
        parsed = JSON.parse(det);
      }

      return {
        ...state,
        currSystemDetails: parsed,
        loadedSysDetails: true,
      };
    },
    defineSelectedSystem(state: any, payload: string) {
      return {
        ...state,
        selectedSystem: payload,
      };
    },
    saveDevDesignations(state: any, payload: any) {
      let concatData = state.connectedDevsDesignations.concat(payload);

      localStorage.setItem(
        'connectedDevsDesignations',
        JSON.stringify(concatData),
      );
      const designationsFromLocalSt = localStorage.getItem(
        'connectedDevsDesignations',
      );
      let parsedData;
      if (designationsFromLocalSt) {
        parsedData = JSON.parse(designationsFromLocalSt);
      }

      return {
        ...state,
        connectedDevsDesignations: parsedData,
        designationsAreLoaded: true,
      };
    },
    saveDevDetailsToShow(state: any, payload: any) {
      return {
        ...state,
        deviceDetails: payload,
      };
    },
    resetDevDetailsToShow(state: any) {
      return {
        ...state,
        deviceDetails: {},
      };
    },
    saveSensorDetailsToShow(state: any, payload: any) {
      return {
        ...state,
        sensorDetails: payload,
      };
    },
    saveList(state: any, payload: any, list: string) {
      switch (list) {
        case 'sensors':
          return {
            ...state,
            sensorsList: payload,
            sensorsAreLoaded: true,
          };

        case 'systems':
          return {
            ...state,
            systemsList: payload,
            systemsListIsLoaded: true,
          };

        default:
          return {
            ...state,
          };
      }
    },
    setDataInStore(state: any, payload: any, type: string) {
      switch (type) {
        case 'name':
          return { ...state, dName: payload };

        case 'currPer':
          if (payload) {
            localStorage.setItem(
              'currentPeripheralSetup',
              JSON.stringify(payload),
            );
            return {
              ...state,
              currentPeripheral: payload,
              periphIsLoaded: true,
            };
          } else return { ...state };

        default:
          return {
            ...state,
          };
      }
    },
    setCommandsList(state: any, payload: any) {
      return {
        ...state,
        commandsList: payload,
        commandsListIsLoaded: true,
      };
    },
    resetCommandsList(state: any, payload: any) {
      return {
        ...state,
        commandsList: payload,
        commandsListIsLoaded: false,
      };
    },
    setDataToListCommands(state: any, payload: any) {
      return {
        ...state,
        currentAccount: payload.currentAccount,
        currentDev: payload.currentDev,
        g3Device: payload.g3Device,
      };
    },
    setCurrentlySelectedTz(state: any, payload: any) {
      return {
        ...state,
        currentlySelectedTimezone: payload,
      };
    },
    saveDeviceDetailsAdm(state: any, payload: any) {
      return {
        ...state,
        deviceShowDetailsAdm: payload,
        deviceDetailsLoaded: true,
      };
    },
    handleErr(state: any, payload: any) {
      if (payload === 'sensorsErr') {
        return {
          ...state,
          loadingErrors: {
            ...state.loadingErrors,
            sensorsErr:
              'Something went wrong while loading sensor information. Please try again later!',
          },
        };
      }
      if (payload === 'sensorsErrPerAcc') {
        return {
          ...state,
          loadingErrors: {
            ...state.loadingErrors,
            sensorsErrPerAcc:
              'Something went wrong while loading information. Please try again later!',
          },
        };
      }

      return {
        ...state,
      };
    },
  },
  effects: (dispatch: any) => ({
    async showDeviceDetailsById(devInfo: any) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `devices.show`,
        null,
        devInfo,
      );
      if (response?.statusCode === 200) {
        that.saveDevDetailsToShow(response.body);
      } else {
        // TODO: add error handler
      }
      return response;
    },
    async showSensorsListPerAccount(accountId: any) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.sensorsList`,
        null,
        { id: accountId },
      );

      if (response?.statusCode === 200) {
        //without the local sensors and without SENSOR-AIRCYCLERAQ123456:
        let filteredSensors: any = response?.body?.data.filter(
          (sensor: any) => {
            return (
              sensor?.device_type !== 'AirCyclerAQ123456' &&
              (sensor.device_type === 'awair-omni' ||
                sensor?.details?.name !== 'LOCAL' ||
                !sensor?.details?.status.includes('local'))
            );
          },
        );

        //with local sensors and without SENSOR-AIRCYCLERAQ123456:
        //with locals included - indoor air needs these
        let withLocalSensors: any = response?.body?.data.filter(
          (sensor: any) => sensor?.device_type !== 'AirCyclerAQ123456',
        );

        that.saveList(withLocalSensors, 'sensors');
      } else {
        that.handleErr('sensorsErr');
        that.saveList([], 'sensors');
      }

      return response;
    },
    async showSensorDetailsById(devInfo: any) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.sensorsShow`,
        null,
        devInfo,
      );
      if (response?.statusCode === 200) {
        that.saveSensorDetailsToShow(response?.body?.data);
      }

      return response;
    },
    async showSystemsListPerAccount(accountId: any) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.devicesListPerUser`,
        null,
        { id: accountId },
      );

      if (response?.statusCode === 200) {
        that.saveList(response?.body?.data || [], 'systems');
      }
      if (response?.statusCode !== 200) {
        that.handleErr('sensorsErrPerAcc');
      }

      return response;
    },
    async getCommandsList(data: any) {
      const that: any = this;
      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.commandsList`,
        null,
        data,
      );
      if (response?.statusCode === 200) {
        that.setCommandsList(response?.body.data.items);
      }
      return response;
    },
    async showDeviceDetailsAdm(data: any) {
      const that: any = this;

      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.adminDevicesShow`,
        null,
        data,
      );
      if (response?.statusCode === 200) {
        that.saveDeviceDetailsAdm(response?.body?.data);
      }

      return response;
    },
    async getSystemCurrentStatus(data: any) {
      const that: any = this;

      let response: any = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.getSystemStatus',
        null,
        data,
      );

      if (
        response?.statusCode === 200 &&
        response?.body &&
        response?.body?.data &&
        response?.body?.data.hasOwnProperty('stoped')
      ) {
        that.saveStatus({
          dev_id: data?.device_id,
          status: response?.body?.data?.stoped,
          statusIsLoaded: true,
        });
      } else {
        that.saveStatus({
          dev_id: data?.device_id,
          status: false,
          statusIsLoaded: false,
        });
      }

      return response || {};
    },
  }),
};
