import React from 'react';
import { FooterProps } from './interface';
import { Button } from 'business/modules/admin/common';
import { HiOutlineXCircle } from 'react-icons/hi';
import { RiSave3Fill } from 'react-icons/ri';
import './styles.scss';

const FormFooter: React.FC<FooterProps> = ({
  onClick,
  onClickValidate,
  dataTarget,
  emptyFields,
  disabled,
  className,
  rowClass,
}) => {
  return (
    <div className="container id-create-company-form--012">
      <div className={rowClass}>
        <div className={className}>
          <Button
            onClick={onClick}
            className="small-button dismiss-btn float-right-btn"
            dataDismiss={`modal`}
            spanClass={`btn-child-ft`}
          >
            <HiOutlineXCircle className="cancel" /> CANCEL
          </Button>
          {!emptyFields && (
            <Button
              className={`small-button ok float-right-btn ${
                disabled ? `temp-disabled` : ``
              }`}
              onClick={onClickValidate}
              dataToggle="modal"
              dataTarget={dataTarget}
              type="button"
              disabled={disabled}
              spanClass={`btn-child-ft`}
            >
              <RiSave3Fill className="edit" /> SAVE
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default FormFooter;
