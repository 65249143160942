import config from '../../../../config/config';
import states from './states';

export default {
  strings: {
    ...config.strings,
    title: 'Create Account',
    confirm: 'Register',
    cancel: 'Back',
  },
  fieldsState: {
    picture: '',
    account_type: 'home',
    first_name: '',
    last_name: '',
    email: '',
    company: '',
    mobile_number: '',
    password: '',
    confirm_password: '',
    address_line_one: '',
    address_line_two: '',
    zip_code: '',
    city: '',
    state: ''
  },
  fieldsFocus: {
    username: false,
  },
  fields: {
    picture: {
      label: 'picture',
      type: 'input-avatar',
      placeholder: 'picture',
      cols: {
        col: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
    },
    account_type: {
      label: 'Account Type',
      type: 'radio-option',
      placeholder: 'Account Type',
      options: [
        {label: 'Homeowner', value: 'home'},
        {label: 'Installer', value: 'installer'},
      ],
      cols: {
        col: 6,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
      },
    },
    first_name: {
      label: 'First Name',
      type: 'text',
      placeholder: 'First Name',
      cols: {
        col: 6,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
      },
    },
    last_name: {
      label: 'Last Name',
      type: 'text',
      placeholder: 'Last Name',
      cols: {
        col: 6,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
      },
    },
    email: {
      label: 'E-mail',
      type: 'email',
      placeholder: 'E-mail',
      cols: {
        col: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
    },
    company: {
      label: 'Company Code',
      type: 'text-option',
      placeholder: 'Company',
      cols: {
        col: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
    },
    mobile_number: {
      label: 'Mobile number',
      type: 'phone',
      placeholder: 'Mobile number',
      cols: {
        col: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
    },
    password: {
      label: 'Password',
      type: 'password',
      placeholder: 'Password',
      cols: {
        col: 6,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
      },
    },
    confirm_password: {
      label: 'Confirm Password',
      type: 'password',
      subtype: 'confirm_password',
      placeholder: 'Confirm Password',
      cols: {
        col: 6,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
      },
    },
    address_line_one: {
      label: 'Address line 1',
      type: 'text',
      placeholder: 'Address line 1',
      cols: {
        col: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
    },
    address_line_two: {
      label: 'Address line 2',
      type: 'text',
      placeholder: 'Address line 2',
      cols: {
        col: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
    },
    zip_code: {
      label: 'Zip Code',
      type: 'text',
      placeholder: 'Zip Code',
      cols: {
        col: 12,
        sm: 12,
        md: 12,
        lg: 12,
        xl: 12,
      },
    },
    city: {
      label: 'City',
      type: 'text',
      placeholder: 'City',
      cols: {
        col: 6,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
      },
    },
    state: {
      label: 'State',
      type: 'dropdown',
      title:'Select state',
      placeholder: 'State',
      cols: {
        col: 6,
        sm: 12,
        md: 12,
        lg: 6,
        xl: 6,
      },
      data:states
    },
  },
  constraints: {
    account_type: {
      presence: true,
    },
    first_name: {
      presence: true,
      format: {
        pattern: "^[\\w'\\-,.][^0-9_!¡?÷?¿/\\\\+=@#$%ˆ&*(){}|~<>;:[\\]]{2,}$",
      },
    },
    last_name: {
      presence: true,
      format: {
        pattern: "^[\\w'\\-,.][^0-9_!¡?÷?¿/\\\\+=@#$%ˆ&*(){}|~<>;:[\\]]{2,}$",
      },
    },
    email: {
      presence: true,
      email: true,
    },
    company: {
      presence: true,
      length: {
        minimum: 6,
        maximun: 6,
      },
    },
    mobile_number: {
      presence: true,
    },
    password: {
      presence: true,
      format: {
        pattern:
          '^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$',
        message:
          'should be at least 8 characters long and include one uppercase letter, one lowercase letter, numbers and special character',
      },
    },
    confirm_password: {
      presence: true,
      equality: {
        attribute: 'password',
        message: '^ Confirm password does not match password',
      },
    },
    address_line_one: {
      presence: true,
      length: {
        minimum: 3,
      },
    },
    zip_code: {
      presence: true,
      format: {
        pattern: '[a-z0-9][a-z0-9\\- ]{0,10}[a-z0-9]$',
        message: 'is invalid',
      },
    },
    city: {
      presence: true,
      length: {
        minimum: 2,
      },
    },
    state: {
      presence: true,
      length: {
        minimum: 2,
      },
    },
   },
};
