const handleLangProp = (lang: string) => {
  switch (lang) {
    case 'cURL - cURL':
      return 'curl';
    case 'JavaScript - Fetch':
      return 'jsFetch';
    case 'JavaScript XHR':
      return 'jsXhr';
    default:
      return lang;
  }
};

export const functions = {
  handleLangProp: handleLangProp,
};
