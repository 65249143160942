import React from 'react';
import foobot_dd from '../../../../../../../../assets/small/foobot_dd.png';
import airthings from '../../../../../../../../assets/small/airthings.jpg';
import awair from '../../../../../../../../assets/small/awair.png';

const determineIconFn = (type: any) => {
  if (type === 'airthings') {
    return (
      <img
        src={airthings}
        style={{ width: '22px', height: '24px', marginRight: '0.2rem' }}
      />
    );
  }
  if (type === 'foobot') {
    return <img src={foobot_dd} />;
  }
  if (type === 'awair-element' || type === 'awair-omni') {
    return (
      <img
        src={awair}
        style={{ width: '24px', height: '26px', marginRight: '0.35rem' }}
      />
    );
  }
};

const renderSuitableLabel = (elem: any) => {
  let str = elem.substr(0, 10);
  let distinctiveChar = str[9];

  switch (distinctiveChar) {
    case 'V':
      return `(VentConnect)`;
    case 'F':
      return `(FreshConnect)`;
    case 'A':
      return `(Local)`;
    default:
      return '';
  }
};

const handleSelection = (origin: string, elem: any) => {
  switch (origin) {
    case `add_sens`:
      return (
        <div className="opt--style">
          {' '}
          {elem.icon}
          {elem.name}
        </div>
      );
    case 'vent_setup':
      return <>{elem.name}</>;

    case 'timezone-select':
    case 'tmz':
      return <>{elem.label}</>;
    default:
      return (
        <>
          {elem}
          <span className="smaller-label">{renderSuitableLabel(elem)}</span>
        </>
      );
  }
};

export const functions = {
  determineIconFn: determineIconFn,
  renderSuitableLabel: renderSuitableLabel,
  handleSelection: handleSelection,
};
