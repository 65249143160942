import { ConnectionBroker } from '../../core';
import config from '../config/config';

export const adminCompaniesState = {
  state: {
    currentCompanyToEdit: {},
    installersList: [],
    loadedInstallers: false,
    generatedCode: '',
    userCleared: true,
    mandatoryFieldsMissing: true,
    errors: { mandatoryFieldsMissing: 'Please fill all the required data.' },
    mandatoryEditionFieldsMissing: false,
    editionErrors: {
      mandatoryEditionFieldsMissing: 'Please fill all the required data.',
    },
    companiesList: [],
    companiesListIsLoaded: false,
    isACompanyEdition: false,
    companyWasDeleted: false,
    compItem: {},
    currentlyEmptyFieldsEdition: false,
    userClearedEd: false,
    updatedSuccess: undefined,
    thereWasAlreadyUpd: false,
    mustResetToUpdatedState: false,
    companiesIntendedCounter: { name: 0, location: 0, code: 0, instCount: 0 },
    companiesCounter: 0,
    orderedList: false,
    defaultOrder: true,
    orders: {
      orderByLocation: false,
      orderByCode: false,
      orderByName: false,
      orderByInstCount: false,
    },
    modalMustClose: false,
    loadingErrors: {
      companies: '',
    },
    //add company data
    newCompany: {
      name: '',
      email: '',
      phone: '',
      code: '',
    },
    companyData: {
      name: '',
      email: '',
      phone: '',
      code: '',
    },
  },
  reducers: {
    saveNewCompany(state: any, payload: any) {
      return { ...state, newCompany: payload, userCleared: false };
    },
    clearNewCompany(state: any) {
      return {
        ...state,
        userCleared: true,
        newCompany: {
          name: '',
          email: '',
          phone: '',
          code: '',
        },
      };
    },
    setCompanyData(state: any, payload: any) {
      return {
        ...state,
        companyData: {
          name: payload.name,
          email: payload.email,
          phone: payload.phone,
          code: payload.code,
        },
      };
    },
    handleLoadingErrors(state: any, payload: any) {
      return {
        ...state,
        loadingErrors: {
          ...state.loadingErrors,
          [payload]:
            'Something went wrong while loading the companies list. Please try again later!',
        },
      };
    },
    defineCompanyToEdit(state: any, payload: any) {
      localStorage.setItem('@currentCompanyToEdit', JSON.stringify(payload));

      return {
        ...state,
        currentCompanyToEdit: payload,
      };
    },
    handleModalState(state: any, payload: any) {
      return {
        ...state,
        modalMustClose: payload,
      };
    },
    setInstallersList(state: any, payload: any) {
      return {
        ...state,
        installersList: payload,
        loadedInstallers: true,
      };
    },
    resetToUpdatedState(state: any, payload: boolean) {
      return {
        ...state,
        mustResetToUpdatedState: payload,
      };
    },
    userQuitsEditing(state: any) {
      return {
        ...state,
        userClearedEd: true,
      };
    },
    detectEmptyFieldsOnEditing(state: any, payload: boolean) {
      return {
        ...state,
        currentlyEmptyFieldsEdition: payload,
      };
    },
    saveCompItemGlobally(state: any, payload: any) {
      localStorage.setItem('@companyItem', JSON.stringify(payload));
      const persistentCompany = localStorage.getItem('@companyItem');
      let parsed;

      if (persistentCompany) {
        parsed = JSON.parse(persistentCompany);
      }
      return {
        ...state,
        compItem: parsed,
      };
    },
    setDefaultOrder(state: any, payload: boolean) {
      return {
        ...state,
        defaultOrder: payload,
      };
    },
    handleCompaniesListCounterAndOrder(
      state: any,
      payload: any,
      counter: number,
    ) {
      return {
        ...state,
        companiesIntendedCounter: payload,
        companiesCounter: counter,
      };
    },
    setDeleteFlagState(state: any, payload: boolean) {
      return { ...state, companyWasDeleted: payload };
    },
    setIsACompanyEdition(state: any, payload: boolean) {
      return {
        ...state,
        isACompanyEdition: payload,
      };
    },
    storeCompaniesList(state: any, payload: any) {
      return {
        ...state,
        companiesList: payload,
        companiesListIsLoaded: true,
      };
    },
    setError(state: any, payload: any) {
      if (payload === '') {
        return {
          ...state,
          mandatoryFieldsMissing: false,
        };
      }
      if (payload === 'mandatoryFieldsMissing') {
        return {
          ...state,
          mandatoryFieldsMissing: true,
          errors: {
            mandatoryFieldsMissing:
              config.adminCompaniesState.mandatoryFieldsMissing,
          },
        };
      }
      return {
        ...state,
      };
    },
    setEditionError(state: any, payload: any) {
      if (payload === '') {
        return {
          ...state,
          mandatoryEditionFieldsMissing: false,
        };
      }
      if (payload === 'mandatoryEditionFieldsMissing') {
        return {
          ...state,
          mandatoryEditionFieldsMissing: true,
          errors: {
            mandatoryEditionFieldsMissing:
              config.adminCompaniesState.mandatoryFieldsMissing,
          },
        };
      }
    },
    saveGeneratedCode(state: any, payload: any) {
      return {
        ...state,
        generatedCode: payload,
      };
    },
    updateOrderState(state: any, payload: any) {
      switch (payload) {
        case 'Name':
          return {
            ...state,
            defaultOrder: false,
            orderedList: false,
            orders: {
              orderByLocation: false,
              orderByCode: false,
              orderByName: true,
              orderByInstCount: false,
            },
          };
        case 'Location':
          return {
            ...state,
            defaultOrder: false,
            orderedList: false,
            orders: {
              orderByLocation: true,
              orderByInstCount: false,
              orderByCode: false,
              orderByName: false,
            },
          };
        case 'Code':
          return {
            ...state,
            orderedList: false,
            defaultOrder: false,
            orders: {
              orderByLocation: false,
              orderByInstCount: false,
              orderByCode: true,
              orderByName: false,
            },
          };
        case 'Installers':
          return {
            ...state,
            defaultOrder: false,
            orderedList: false,
            orders: {
              orderByLocation: false,
              orderByInstCount: true,
              orderByCode: false,
              orderByName: false,
            },
          };
        case 'Default': {
          return {
            ...state,
            orderedList: true,
            defaultOrder: true,
            orders: {
              orderByLocation: false,
              orderByInstCount: false,
              orderByCode: false,
              orderByName: false,
            },
          };
        }
        default:
          return {
            ...state,
            orderedList: payload,
            defaultOrder: true,
            orders: {
              orderByLocation: false,
              orderByInstCount: false,
              orderByCode: false,
              orderByName: false,
            },
          };
      }
    },
  },
  effects: (dispatch: any) => ({
    async getCompanyGeneratedCode() {
      const that: any = this;
      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        `admin.getGeneratedCode`,
        null,
      );
      if (response?.statusCode === 200) {
        that.saveGeneratedCode(response?.body?.data?.code);
      } else if (response?.statusCode !== 200) {
        //todo handle error
      }

      return response;
    },
    async createANewCompany(createdCompanyData: any) {
      const that: any = this;

      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.createCompany',
        createdCompanyData,
      );
      if (response?.statusCode === 200) {
        if (response?.body?.data?.message) {
          that.handleModalState(true);
        } else {
          that.getCompanyGeneratedCode();

          that.handleModalState(true);
        }
      } else if (response?.statusCode !== 200) {
        that.handleModalState(true);
      }

      return response;
    },
    async updateCurrCompany(data: any) {
      const that: any = this;

      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.updateACompany',
        data.data,
        { id: data.id },
      );
      if (response?.statusCode === 200) {
        that.handleModalState(true);
      } else if (response?.statusCode !== 200) {
        //handle error msg
        that.handleModalState(true);
      }

      return response;
    },
    async getTheCompaniesList() {
      const that: any = this;

      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.getCompaniesList',
      );
      if (response?.statusCode === 200) {
        that.storeCompaniesList(response?.body?.data);
      } else if (response?.statusCode !== 200) {
        that.handleLoadingErrors('companies');
      }

      return response;
    },
    async deleteACompany(data: any) {
      const that: any = this;

      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.deleteCompany',
        null,
        { id: data.id },
      );
      if (response?.statusCode === 200) {
        that.handleModalState(true);
      } else if (response?.statusCode !== 200) {
        that.handleModalState(true);
      }
      return response;
    },
    async showCompanyDetails(data: any) {
      const that: any = this;

      let response = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.showCompany',
        null,
        { id: data.id },
      );
      if (response?.statusCode === 200) {
        if (response?.body?.data && response?.body?.data?.length > 0) {
        } else {
        }
      } else if (response?.statusCode !== 200) {
      }
      return response;
    },
  }),
};
