import { init } from '@rematch/core';
import { env } from './models/_env';
import { loader } from './models/_loader';
import { profile } from './models/_profile';
import { aside } from './models/_aside';
import { devices } from './models/_devices';
import { zones } from './models/_zones';
import { foobot } from './models/_foobot';
import { logs } from './models/_logs';
import { systemSettings } from './models/_systemSettings';
import { manualControl } from './models/_manualControl';
import { alerts } from './models/_alerts';
import { airthings } from './models/_airthings';
import { awair } from './models/_awair';
import { adminState } from './models/_admin';
import { adminUsersState } from './models/_adminUsers';
import { adminDevicesState } from './models/_adminDevices';
import { adminSectionsState } from './models/_adminSections';
import { adminLoaderState } from './models/_adminLoader';
import { searchUsersState } from './models/_searchUsers';
import { navigationalState } from './models/_navigationalState';
import { dataLogging } from './models/_dataLogging';
import { firmware } from './models/_firmware';
import { adminLogsState } from './models/_adminLogs';
import { adminCompaniesState } from './models/_adminCompanies';
import { installerConfigs } from './models/_installerConfigs';
import { installerSystems } from './models/_installerSystems';
import { userConfigs } from './models/_userConfigs';
import { requestDelete } from './models/_requestDelete';
import { adminInstallerDevsState } from './models/_adminInstallerDevs';
import { adminInstallersState } from './models/_adminInstallers';
import { adminDataLoggingState } from './models/_adminDataLogging';
import { adminRequestsState } from './models/_adminRequests';
import { adminApiRequests } from './models/_adminApiRequests';
import { airCyclerLocalSensor } from './models/_aircyclerLocal';
import { adminDevelopmentState } from './models/_adminDevelopment';
import { apiState } from './models/_apiState';

const store = init({
  models: {
    env,
    loader,
    profile,
    aside,
    devices,
    zones,
    foobot,
    logs,
    systemSettings,
    alerts,
    manualControl,
    airthings,
    awair,
    airCyclerLocalSensor,
    adminState,
    adminUsersState,
    adminDevicesState,
    adminSectionsState,
    adminLoaderState,
    searchUsersState,
    navigationalState,
    dataLogging,
    firmware,
    adminLogsState,
    installerSystems,
    installerConfigs,
    adminCompaniesState,
    userConfigs,
    requestDelete,
    adminInstallerDevsState,
    adminInstallersState,
    adminDataLoggingState,
    adminRequestsState,
    adminApiRequests,
    adminDevelopmentState,
    apiState,
  },
});

export default store;
