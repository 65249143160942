import React from 'react';
import { IoCheckmark } from 'react-icons/io5';

export default {
  strings: {
    stLabel: '@indoor-air::',
    hide: `Hide setup`,
    show: `Show setup`,
    title: `Indoor Air Quality (IAQ)`,
    str1: `For the system to work as expected it is necessary to configure a sensor in order to have reference values.`,
    p: `IAQ parameters and data sourced from connected air quality monitor.`,
    p2: `Set preferred parameter limits and select how your AirCycler® Connect System responds to IAQ events.`,
    confirmation: `You are about to update the Indoor Air configurations.`,
  },
  modalStrings: {
    warning: 'Information',
  },
  titles: {
    ia: `Update configuration`,
  },
  icons: {
    ia: <IoCheckmark className="edit" />,
  },
  labels: {
    ia: ` OK`,
  },
  bodies: {
    ia: ``,
  },
  bodyClass: {
    ia: `alert alert-danger`,
  },
  tooltip: {
    title: `System Settings Warning`,
    str1: `For the system to work as expected it is necessary to configure a sensor in order to have reference values.`,
    str2: `Please configure the sensor in "System Settings". You can click on the icon and you will go to the configuration page.`,
    title1: `Enable / disable section`,
    str3: `These changes will affect the user's configurations. Are you sure you want to proceed?`,
  },
  indoorAir: {
    strings: {
      title: 'Indoor Air Quality',
      noFoobots:
        'Connect an indoor air quality sensor and manage the air quality in your home.',
    },
    alerts: {
      save: {
        question:
          'Are you sure you want to save the new Inddor Air configuration?',
        btn: {
          confirm: 'Yes, save it!',
        },
      },
    },
    formats: {
      ppm: '0,0',
      ugm3: '0,0',
      ppb: '0,0',
    },
    units: {
      ugm: `ug/m`,
      ppm: `ppm`,
      ppb: `ppb`,
      pm25: `PM 2.5`,
      co: `CO`,
      tVoc: `tVOC`,
    },
    model: {
      max: -101,
      min: -101,
      current: 0,
      init: false,
    },
    ckModel: {
      exct: false,
      dmst: false,
      frcn: false,
      ahuf: false,
    },
    defaults: {
      maxs: {
        pm25: 140,
        co2: 1500,
        tVoc: 1500,
      },
    },
    sliderValues: {
      pm25: {
        max: 180,
        min: 0,
      },
      co2: {
        max: 4000,
        min: 0,
      },
      tVoc: {
        max: 2000,
        min: 0,
      },
    },
  },
  formats: {
    ppm: '0,0',
    ugm3: '0,0',
    ppb: '0,0',
  },
  units: {
    ugm: `ug/m`,
    ppm: `ppm`,
    ppb: `ppb`,
    pm25: `PM 2.5`,
    co: `CO`,
    tVoc: `tVOC`,
  },
  model: {
    max: -101,
    min: -101,
    current: 0,
    init: false,
  },
  ckModel: {
    exct: false,
    dmst: false,
    frcn: false,
    ahuf: false,
  },
  defaults: {
    maxs: {
      pm25: 140,
      co2: 1500,
      tVoc: 1500,
    },
  },
  sliders_defaults: {
    pm25: 150,
    co2: 750,
    tVoc: 500,
  },
  notifications: {
    update: {
      success: 'Indoor Air setup was saved.',
      error: 'Indoor Air setup was not updated. Please, try again later.',
    },
  },
  sliderValues: {
    pm25: {
      max: 500,
      min: 0,
    },
    co2: {
      max: 4000,
      min: 0,
    },
    tVoc: {
      max: 2000,
      min: 0,
    },
  },
  showViewInitState: {
    co2: true,
    tVoc: true,
    pm25: true,
  },
  noIndoorValues: [
    {
      id: 'left_label',
      divClass: `col-4 left-side acc-row`,
      spanClass: `values-label-v-001 left-labels`,
      child: (
        <>
          _ _ ug/m <sup>3</sup>
        </>
      ),
    },
    {
      id: 'middle_label',
      divClass: `col-4 acc-row`,
      spanClass: `values-label-v-001`,
      child: <>_ _ ppm</>,
    },
    {
      id: 'right_label',
      divClass: `col-4 right-side acc-row`,
      spanClass: `values-label-v-001 right-labels`,
      child: <> _ _ ppb</>,
    },
  ],
  indoorAirLabels: [
    {
      id: 'first_label',
      divClass: `col-4 left-side acc-row`,
      spanClass: `sensor-vals left-labels`,
      child: `PM 2.5`,
    },
    {
      id: 'second_label',
      divClass: `col-4 acc-row`,
      spanClass: `sensor-vals`,
      child: (
        <>
          CO <sub style={{ bottom: '6px' }}>2</sub>
        </>
      ),
    },
    {
      id: 'third_label',
      divClass: `col-4 right-side acc-row`,
      spanClass: `sensor-vals right-labels`,
      child: `tVOC`,
    },
  ],
};
