import { ConnectionBroker } from '../../core';

export const adminDevelopmentState = {
  state: {
    orderedList: false,
    defaultOrder: true,
    orders: {
      orderByDate: false,
      orderByCompany: false,
      orderByEmail: false,
      orderByClient: false,
    },
    modalMustClose: false,
    devsIntendedCounter: {
      date: 0,
      company: 0,
      email: 0,
      client: 0,
    },
    devsCounter: 0,
    generatedClientID: '',
    generatedClientToken: '',
    currentDevUser: {},
    mandatoryFieldsMissing: true,
    editFieldsMissing: false,
  },
  reducers: {
    handleMissingFields(state: any, payload: any) {
      if (payload === '') {
        return {
          ...state,
          editFieldsMissing: false,
        };
      }
      if (payload === 'mandatoryFieldsMissing') {
        return {
          ...state,
          editFieldsMissing: true,
        };
      }
      return {
        ...state,
      };
    },
    handleMandatoryFields(state: any, payload: any) {
      if (payload === '') {
        return {
          ...state,
          mandatoryFieldsMissing: false,
        };
      }
      if (payload === 'mandatoryFieldsMissing') {
        return {
          ...state,
          mandatoryFieldsMissing: true,
        };
      }
      return {
        ...state,
      };
    },
    setCurrentDevUser(state: any, payload: any) {
      localStorage.setItem('@currentDevUser', JSON.stringify(payload));
      return {
        ...state,
        currentDevUser: payload,
      };
    },

    updateOrderState(state: any, payload: any) {
      switch (payload) {
        case 'Date':
          return {
            ...state,
            defaultOrder: false,
            orderedList: false,
            orders: {
              orderByDate: true,
              orderByCompany: false,
              orderByEmail: false,
              orderByClient: false,
            },
          };
        case 'Company':
          return {
            ...state,
            defaultOrder: false,
            orderedList: false,
            orders: {
              orderByDate: false,
              orderByCompany: true,
              orderByEmail: false,
              orderByClient: false,
            },
          };
        case 'E-mail':
          return {
            ...state,
            defaultOrder: false,
            orderedList: false,
            orders: {
              orderByDate: false,
              orderByCompany: false,
              orderByEmail: true,
              orderByClient: false,
            },
          };
        case 'Client':
          return {
            ...state,
            defaultOrder: false,
            orderedList: false,
            orders: {
              orderByDate: false,
              orderByCompany: false,
              orderByEmail: false,
              orderByClient: true,
            },
          };
        case 'Default': {
          return {
            ...state,
            orderedList: true,
            defaultOrder: true,
            orders: {
              orderByDate: false,
              orderByCompany: false,
              orderByEmail: false,
              orderByClient: false,
            },
          };
        }
        default:
          return {
            ...state,
            orderedList: payload,
            defaultOrder: true,
            orders: {
              orderByClientID: false,
            },
          };
      }
    },
    handleDevsListCounterAndOrder(state: any, payload: any, counter: number) {
      return {
        ...state,
        devsIntendedCounter: payload,
        devsCounter: counter,
      };
    },
    handleModalState(state: any, payload: any) {
      return {
        ...state,
        modalMustClose: payload,
      };
    },
  },
  effects: (dispatch: any) => ({
    async getClientsList() {
      let response: any = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.getClientsList',
        {},
        null,
      );
      return response;
    },
    async generateSecretAndUser() {
      let response: any = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.getClientUserAndSecret',
      );

      return response;
    },
    async createAClient(clientData: any) {
      const _this: any = this;

      let response: any = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.createClient',
        clientData,
        null,
      );
      if (response?.statusCode === 200) {
        _this.handleModalState(true);
      } else {
        _this.handleModalState(true);
      }
      return response;
    },
    async deleteAClient(userData: any) {
      const _this: any = this;

      let response: any = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.deleteClient',
        null,
        { client_id: userData.client_id },
      );

      if (response?.statusCode === 200) {
        _this.handleModalState(true);
      } else {
        _this.handleModalState(true);
      }

      return response;
    },
    async editClientInfo(userData: any) {
      const _this: any = this;

      const data: any = {
        email: userData.email,
        first_name: userData.first_name,
        last_name: userData.last_name,
        company: userData.company,
        phone: userData.phone,
        address: userData.address,
        state: userData.state,
        city: userData.city,
        zip_code: userData.zip_code,
        client_user: userData.client_user,
        client_secret: userData.client_secret,
      };

      let response: any = await ConnectionBroker.fetchAdminAPI(
        'jitBE',
        'admin.editClient',
        data,
        { client_id: userData.client_id },
      );

      if (response?.statusCode === 200) {
        //save response?.body as current localStorage user

        _this.setCurrentDevUser(response?.body);

        _this.handleModalState(true);
      } else {
        _this.handleModalState(true);
      }
      return response;
    },
  }),
};
