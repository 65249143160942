import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ThroughProvider } from 'react-through';
import AppProvider from './hooks';
import { ToastContainer } from 'react-toastify';
import '../business/styles/app/app.scss';
import Routes from './routes/index';
import PolicyRoutes from './routes/policy.routes';
import ClientRoutes from './routes/client.routes';

const App: React.FC = ({ ...props }) => {
  return (
    <BrowserRouter>
      <AppProvider>
        <ThroughProvider>
          <Routes />
        </ThroughProvider>
      </AppProvider>
      <ToastContainer />
      <PolicyRoutes />
      <ClientRoutes />
    </BrowserRouter>
  );
};

export default App;
