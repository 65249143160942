import React from 'react';
import { HiOutlineXCircle } from 'react-icons/hi';
import { RiSave3Fill } from 'react-icons/ri';
import { IoCheckmark } from 'react-icons/io5';

export default {
  strings: {
    stLabel: '@manual-ctrl::',
    manualCtr: 'Manual Control settings details.',
    title: `Enable / disable section`,
    conf: `These changes will affect the user's configurations. Are you sure you want to proceed?`,
    confirmation: `You are about to update the Manual Control configurations.`,
    cardTitle: `Manual Control`,
    settings: `Settings details`,
  },
  modalStrings: {
    warning: 'Information',
  },
  titles: {
    mc: `Update configuration`,
  },
  icons: {
    mc: <IoCheckmark className="edit" />,
  },
  bodies: {
    mc: ``,
  },
  bodyClass: {
    mc: `alert alert-danger`,
  },
  labels: {
    temp: 'ºF',
    rhum: '% RH',
    cfm: 'CFM',
    airq: 'AQI',
  },
  dictionary: {
    aqse: 'Air Quality Sensor Control Flag',
    ccmd: 'Connected Device Command',
    oacm: 'Outside Air Damper Relay Set Command',
    cfcm: 'Central Fan Relay Set Command',
    cidx: 'Connected Device Index Pos On MQTT Broker',
    cdst: 'Connected Device State',
    excm: 'Exhaust Relay Set Command',
    oaqe: 'Outdoor Air Quality Event Control Flag',
    g3id: 'AircyclerG3 device id',
    dmac: 'G3 Device Mac Addresses',
    uptm: 'uptime',
    fmwv: 'Firmware version',
    pcbv: 'PCB Revision',
    vpct: 'Ventilation Percentage Complete',
    thsa: 'Thermostat Call Active',
    uctr: 'User Control Flag',
    vcta: 'Ventilation Control Cycle Active',
    evrt: 'Exhaust Vent Required On Time',
    evrl: 'Exhaust Vent Required On Time Left',
    mhsv: 'Minute in Hour Start Exhaust Vent',
    cfrt: 'Central Fan Required On Time',
    cfrl: 'Central Fan Required On Time Left',
    mhsf: 'Minute in Hour Start Central Fan',
    mplf: 'Minutes in Period Left',
    evot: 'Fan Connect (wired exhaust) On Time in Minutes',
    errc: 'Error count',
    conf: 'configuration',
    fmwu: 'Firmware url',
    fmwm: 'Firmware md5',
    lfdt: 'Last foreground time',
    crdt: 'current_date_time',
    resl: 'result',
    smth: 'Supply Method',
    fffr: 'Fan Only Flow Rate CFM',
    fhfr: 'Fan Heat Flow Rate CFM',
    fcfr: 'Fan Cool Flow Rate CFM',
    exct: 'Exhaust Control Target',
    fcsp: 'Fan Connect Switch Present',
    fcst: 'Fan Connect',
    fcds: 'Fan Connect Delay State',
    fcdr: 'Fan Connect Delay Minutes Remaining',
    effr: 'Exhaust Fan Flow Rate (fan connect)',
    efdt: 'Exhaust Fan Delay Time (fan connect)',
    mner: 'Minutes Per Hour Exhaust Run (fan connect)',
    exwm: 'Exhaust Worker Mode (fan connect)',
    carq: 'Constant Air Required CFM',
    mixt: 'Mix Time',
    hroa: 'Hours of Operation Active',
    hrsv: 'Hour Start Ventilation',
    hstv: 'Hour Stop Ventilation',
    dmst: 'Damper',
    cfst: 'Central Fan',
    tpmn: 'Temperature Probe Minimum',
    tpmx: 'Temperature Probe Maximum',
    ctmp: 'Current Temperature Reading',
    mxrh: 'Relative Humidity Probe Maximum Relative Humidity',
    crhr: 'Current Relative Humidity Reading',
    ddia: 'Air Duct Diameter',
    cflw: 'Current Flow Reading',
    mnsf: 'Minutes per Hour Supply Flow',
    lcap: 'Last Connected Access Point SSID',
    tmzn: 'Current Set Timezone',
    prmd: 'Pair Mode',
    pmto: 'Pair Mode Timeout',
    blto: 'Bluetooth LE Timeout',
    gail: 'G3 Device Alias',
    fdrs: 'Factory Default Reset',
    cdvc: 'Connected Device Count',
    cdvs: 'Connected Devices',
    cdid: 'Connected Device ID',
    cail: 'Connected Device Alias',
    cpri: 'Connected Device Display Priority',
    vcct: 'Vent Connect Control Target Flag',
    vcfr: 'Exhaust Fan Flow Rate (vent connect)',
    vcdt: 'Exhaust Fan Delay Time (vent connect)',
    mvcr: 'Minutes per Hour Exhaust Run (vent connect)',
    vcwm: 'Exhaust Worker Mode (vent connect)',
    slst: 'Switch Status Light State',
    fcon: 'Force On',
    frte: 'Fan runtime elapsed',
    pcst: 'Probe Connection Status',
    none: 'Probe Status',
    dfps: 'Differential Pressure',
    stmp: 'Sensor Temperature',
    faep: 'Forward Active Energy Pulses',
    raep: 'Reverse Active Energy Pulses',
    aaep: 'Absolute Active Energy Pulses',
    frep: 'Forward Reactive Energy Pulses',
    rrep: 'Reverse Reactive Energy Pulses',
    arep: 'Absolute Reactive Energy Pulses',
    estt: 'Energy Read Status',
    irms: 'RMS Current',
    vrms: 'RMS Voltage',
    mnap: 'Mean Active Power',
    mnrp: 'Mean Reactive Power',
    vfrq: 'Voltage Frequency',
    pfct: 'Power Factor',
    phas: 'Phase Angle',
    mapp: 'Mean AP Power',
    prun: 'probe run',
  },
  confirmationMenu: [
    {
      sentence: `Proceed on saving changes on `,
      className: `col-xs-12 col-sm-12 col-md-8`,
      isBtn: false,
      children: ``,
      action: '',
    },
    {
      sentence: ``,
      className: 'col-xs-6 col-sm-6 col-md-2',
      isBtn: true,
      children: (
        <>
          <RiSave3Fill
            style={{
              fill: 'currentColor',
              width: '1.4rem',
              height: '1.4rem',
              display: 'inline-block',
              fontSize: '11px',
              transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              flexShrink: 0,
            }}
          />{' '}
          SAVE{' '}
        </>
      ),
      action: 'save',
    },
    {
      sentence: `Are you sure you wish to save these changes?`,
      className: 'col-xs-6 col-sm-6 col-md-2',
      isBtn: true,
      children: (
        <>
          <HiOutlineXCircle
            style={{
              width: '1.4rem',
              height: '1.4rem',
              display: 'inline-block',
              fontSize: '11px',
              transition: 'fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
              flexShrink: 0,
            }}
          />
          CANCEL
        </>
      ),
      action: 'cancel',
    },
  ],

  notifications: {
    update: {
      success: 'Manual Control setup was saved.',
      error: 'Manual Control setup was not updated. Please, try again later.',
    },
  },
};
