import React, { useState } from 'react';
import { RequestRowProps } from './interface';
import { helpers } from './helpers';
import { RowMenu } from '..';
import { IoInformationCircleOutline } from 'react-icons/io5';
import { TooltipView, GenTooltip } from 'business/modules/admin/common';
import './styles.scss';

const RequestRow: React.FC<RequestRowProps> = ({
  item,
  selectListItem,
  handleMenuAction,
  actionToPerformOnRequest,
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: any) => {
    selectListItem(item);
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-xs-12 col-sm-12 col-md-4 col-lg-4 req-circle">
          <div className="circle">
            <span className="initials">
              {helpers.returnChar(item, 'first')}
            </span>
            <span className="initials">
              {' '}
              {helpers.returnChar(item, 'second')}
            </span>
          </div>

          <span className={`lead lead-adm bolder-ft the-name-with-the-circle`}>
            {item?.details?.name?.first_name} {item?.details?.name?.last_name}
          </span>
        </div>

        <div className={`col-xs-12 col-sm-12 col-md-4 col-lg-3 date-col1`}>
          <span className={`lead lead-adm`}>
            {helpers.handleDate(item.createdAt)}
          </span>
        </div>

        <div
          className={`col-xs-12 col-sm-12 col-md-2 col-lg-3 display-centered`}
        >
          <span className={`lead lead-adm`}>
            {JSON.stringify(item.confirmed) === 'false'
              ? `in progress`
              : `confirmed`}
          </span>
        </div>
        <div className="col-xs-12 col-sm-12 col-md-2 col-lg-2 btn-vab1">
          {item?.confirmed && !item.deletedBy ? (
            <RowMenu
              isConfirmed={item.confirmed}
              btnId={`exp-reqs-id`}
              onClick2={handleClick}
              ariaExpanded={open ? 'true' : undefined}
              anchorEl={anchorEl}
              open={open}
              onClose={handleClose}
              handleView={() => {}}
              handleClose={handleClose}
              handleMenuAction={handleMenuAction}
              option1={`delete__req`}
              option2={`cancel__req`}
              actionToPerformOnRequest={actionToPerformOnRequest}
            />
          ) : !item.confirmed ? (
            <TooltipView
              title={
                <GenTooltip
                  title={`This request awaits user confirmation.`}
                  textOne={`Currently, only cancel is possible.`}
                />
              }
            >
              <div>
                <RowMenu
                  isConfirmed={item.confirmed}
                  btnId={`exp-reqs-id`}
                  onClick2={handleClick}
                  ariaExpanded={open ? 'true' : undefined}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  handleView={() => {}}
                  handleClose={handleClose}
                  handleMenuAction={handleMenuAction}
                  option1={`delete__req`}
                  option2={`cancel__req`}
                  actionToPerformOnRequest={actionToPerformOnRequest}
                />
              </div>
            </TooltipView>
          ) : /*request is confirmed and already deleted, no actions allowed */
          item?.confirmed && item?.deletedBy ? (
            <TooltipView
              title={
                <GenTooltip
                  title={`No actions to perform!`}
                  textOne={`This request was deleted.`}
                />
              }
            >
              <span className="informative">
                <IoInformationCircleOutline
                  style={{ width: '1.2em', height: '1.2em' }}
                />
              </span>
            </TooltipView>
          ) : null}
        </div>
        <hr className="userlist-item-divider" />
      </div>
    </div>
  );
};

export default RequestRow;
