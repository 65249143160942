import React, { useState, useCallback, useRef, Fragment } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { isValidPhoneNumber } from 'react-phone-number-input';

interface InputProps {
  name: string;
  label?: string;
  handleChangeError: Function;
  handleChange: any;
  handleBlur?: any;
  maximum?: any;
  error?: any;
  constraint?: any;
  constraintEquality?: any;
  value: string;
  type?: string;
  disabled?: boolean;
}
const InputPhone: React.FC<InputProps> = ({
  name,
  label,
  handleChangeError,
  handleChange,
  handleBlur,
  value,
  maximum,
  type,
  error,
  disabled,
  constraint,
  constraintEquality,
  ...rest
}) => {
  const inputRef = useRef(null);
  const [edited, setEdited] = useState(false);

  const handleInputBlur = useCallback(() => {
    if (!disabled) {
      if (!isValidPhoneNumber('+' + value) && edited) {
        handleChangeError('Invalid phone number');
      } else {
        handleChangeError(false);
      }
    }
    // eslint-disable-next-line
  }, [disabled, value, inputRef, edited]);

  const handleChangeInput = useCallback(
    (value, country, formattedValue) => {
      if (!disabled) {
        handleChange(value);

        if (isValidPhoneNumber('+' + value)) {
          handleChangeError(false);
        }
        setEdited(true);
      }
    },
    // eslint-disable-next-line
    [disabled, value],
  );

  return (
    <Fragment>
      <PhoneInput
        disabled={disabled}
        country={'us'}
        value={value}
        onBlur={() => handleInputBlur()}
        onChange={(value, country, e, formattedValue) =>
          handleChangeInput(value, country, formattedValue)
        }
        onlyCountries={['us']}
        inputClass={error ? `class-err--input` : ``}
        inputProps={{
          name: 'phone',
          autoFocus: false,
        }}
      />
      {error && <div className="error-alert">{error}</div>}
    </Fragment>
  );
};

export default InputPhone;
