import config from './config';

const handleErrors = (name: string, value: any) => {
  switch (name) {
    case 'name':
      return value.trim().length < 1 ? config.errorMsg[name] : '';
    case 'email':
      return !value.match(config.emailRegex) ? config.errorMsg[name] : '';
    case 'phone':
      return value.trim().length !== 11 ? config.errorMsg[name] : '';
    case 'state':
      return value.trim().length < 2 ? config.errorMsg[name] : '';
    case 'zip':
      return !value.match(config.zipRegex) ||
        value.trim().length < 5 ||
        value.trim().length > 10
        ? config.errorMsg[name]
        : '';
    case 'city':
      return value.trim().length < 2 ? config.errorMsg[name] : '';
    default:
      return '';
  }
};

export const functions = {
  handleErrors: handleErrors,
};
