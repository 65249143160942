import React, { Fragment } from 'react';
import { NoPhoneNorCodeProps } from './interface';
import { Input, Label } from 'business/modules/admin/common';
import './styles.scss';

const NoPhoneNorCode: React.FC<NoPhoneNorCodeProps> = ({
  index,
  elem,
  field,
  data,
  getUserInput,
  err,
  required,
  className
}) => {
  const returnValue = (elem: any) => {
    return data[elem.name] || '';
  };

  return (
    <Fragment>
      <div className="form-group diff-input" key={index}>
        <Label className={className}>
          {field.label} {required}
        </Label>
        <div className="input-group">
          <Input
            type={elem.type}
            className={`${elem.className} ${err ? `class-err` : ``}`}
            id={elem.id}
            name={elem.name}
            ariaDescribedBy={elem.ariaDescribedBy}
            placeholder={elem.placeholder}
            children={elem.label}
            value={returnValue(elem)}
            onChange={getUserInput}
          />
        </div>
        {err && <span className="error-alert">{err}</span>}
      </div>
    </Fragment>
  );
};

export default NoPhoneNorCode;
