import React from 'react';
import { Section, RequestDetails, CodeSnippet } from './components';
import diagram from '../../assets/diagram.png';
import './styles.scss';

const path = `https://aircycler.rwi-cloud.com`;

export default {
  lowerNavData: {
    language: ['cURL - cURL', 'JavaScript - Fetch', 'JavaScript XHR'], //examples
  },
  sidebarOpts: [
    {
      str: 'Welcome',
      href: `#introduction`,
      internalData: [],
      additionalData: [],
    },
    {
      str: 'How to start',
      href: `#starting`,
      internalData: [],
      additionalData: [],
    },
    {
      str: 'How to use',
      href: `#how-to-use`,
      internalData: [],
      additionalData: [],
    },
    {
      str: 'OAuth 2', //OAuth2
      href: `#oauth2`,
      internalData: [],
      additionalData: [],
    },
    {
      str: 'Local API',
      href: `#local-api`,
      internalData: [],
      additionalData: [],
    },
  ],
  textsStrings: {
    introduction: `This is the AirCycler Developer API Documentation, and below we will guide you through the steps to start using our API.`,
    starting: (
      <div>
        <p>
          {' '}
          Please, contact the AirCycler Support via e-mail ({' '}
          <span style={{ fontWeight: 'bold' }}>support@aircycler.com </span>)
          and ask for a Client ID and a Client User in order to use our API.
        </p>
        <p>
          Also, please provide to the AirCycler support some needed data: your
          first and last name, your company name, a phone number, and complete
          address (with city, state, and zipcode).
        </p>
        <p>
          After handling your request, The AirCycler support will provide the
          Client ID and the Client Secret to you.
        </p>
      </div>
    ),
    'how-to-use': `Read below the following steps to continue the process.`,
    oauth2: (
      <div className="container pdg oauth-explained">
        <div className="row mlmr0">
          <p className="pdg">
            The new users that want to use the AirCycler APi will need to
            contact AirCycler Admin Support to request authorization. The new
            Client has to provide some data so his request can be approved, then
            will receive a new CLIENT ID and a CLIENT SECRET that are private
            and non-transferable. The CLIENT ID and CLIENT SECRET will be needed
            in order to use the AirCycler API. The AirCycler API use OAuth 2.0
            authentication process that will be described below.
          </p>
          <p className="pdg">
            This diagram represents the authentication process and the exchanged
            data while it is happening.{' '}
          </p>
          <img src={diagram} className="img-fluid" alt="Responsive image" />

          <p className="pdg">
            1. After you receive the AirCycler support response to your request
            with a Client ID and a Client Secret (please, refer to the "How to
            start" step), your App will have to perform a redirect to our Login
            page. The Client ID that you just received will be needed at this
            stage to be sent on the redirect process.{' '}
          </p>
          <p className="pdg">
            2. On the login page you will have to enter your e-mail and password
            to proceed. If the login is successful, you will receive, on your
            App, your USER id and a 6 digit CODE (please, keep in mind that this
            code is valid just once). In this case, you will be redirected to
            the redirect_url that was initially provided. However, if the login
            is not successful, you will remain at the login page. If something
            else goes wrong, you will be redirected to an error page.{' '}
          </p>
          <p className="pdg">
            3. The CODE and the ID that you received on the previous step will
            be used to confirm the request, and then you will receive a token
            and a refresh token. Here, the Client ID will also be needed .
            Please, save the tokens in a safe place, because they will be needed
            afterwards.{' '}
          </p>
          <p className="pdg">
            Since the token is not valid permanently, when it expires, a request
            to refresh the token must be made.
          </p>
        </div>
      </div>
    ),
    'local-api': `Local API usage details`,
    auth: (
      <Section
        id="auth-header-docs"
        h6="Authorization"
        specH6="Inherit Auth from parent"
        description="This request does not require authorization."
      />
    ),
    users: (
      <>
        <Section
          id="users-header-docs"
          h6="Authorization"
          specH6="Bearer Token"
          description="Some smaller text explaining about the Token"
        />
      </>
    ),
    systems: (
      <Section
        id="system-header-docs"
        h6="Authorization"
        specH6="Bearer Token"
        description="Some smaller text explaining about the Token"
      />
    ),
  },
};

export const headers: any = {
  commonHeaders: [
    { Host: '<calculated when request is sent>' },
    { 'User-Agent': 'Value will be here' }, // <--- check which is the value
    { Accept: '*/*' },
    { 'Accept-Encoding': 'gzip, deflate, br' },
    { Connection: 'keep-alive' },
  ],
  commonCompleteHeaders: [
    { 'Content-Type': 'application/json' },
    { 'Content-Length': '<calculated when request is sent>' },
    { Host: '<calculated when request is sent>' },
    { 'User-Agent': 'Value will be here' }, // <--- check which is the value
    { Accept: '*/*' },
    { 'Accept-Encoding': 'gzip, deflate, br' },
    { Connection: 'keep-alive' },
  ],
};

export const bodies: any = {
  auth: [
    { field: 'user' },
    { type: 'string' },
    { description: 'The user to authenticate' },
    {
      field: 'secret',
    },
    { type: 'string' },
    { description: 'The secret which matches the given user' },
  ],
  register: [
    {
      field: 'email',
      type: 'string',
      description: 'An e-mail address to register an account',
    },
    { field: 'first_name', type: 'string', description: "Users' first name" },

    { field: 'last_name', type: 'string', description: "Users' last name" },
    { field: 'company', type: 'string', description: "Tha company's name" },
    {
      field: 'phone',
      type: 'string',
      description: 'A phone number to add to the account',
    },
    { field: 'address', type: 'string', description: "The company's address" },
    { field: 'state', type: 'string', description: 'Address state' },
    { field: 'city', type: 'string', description: 'Address city' },
    { field: 'zip_code', type: 'string', description: 'Address zipcode' },
  ],
  userUpdate: [
    {
      field: 'email',
      type: 'string',
      description: 'An e-mail address to update an account',
    },
    { field: 'first_name', type: 'string', description: "Users' first name" },
    { field: 'last_name', type: 'string', description: "Users' last name" },

    {
      field: 'phone',
      type: 'string',
      description:
        'A phone number to update the existing number in the account',
    },
    { field: 'address', type: 'string', description: "The company's address" },
    { field: 'state', type: 'string', description: 'Address state' },
    { field: 'city', type: 'string', description: 'Address city' },
    { field: 'zip_code', type: 'string', description: 'Address zipcode' },
  ],
  code: [
    {
      field: 'email',
      type: 'string',
      description: 'The account e-mail address',
    },

    {
      field: 'password',
      type: 'string',
      description: 'The account password',
    },
    {
      field: 'client_id',
      type: 'string',
      description: 'The client id provided by the AirCycler support',
    },
  ],
  usersLogin: [
    {
      field: 'client_id',
      type: 'string',
      description: "The users' client id",
    },

    {
      field: 'client_secret',
      type: 'string',
      description: "The users' client secret",
    },

    {
      field: 'code',
      type: 'string',
      description: 'The code that the user received previously',
    },

    {
      field: 'id',
      type: 'string',
      description: 'The id that the user received previously',
    },
  ],
  usersRefresh: [
    {
      field: 'client_id',
      type: 'string',
      description: "The users' client id",
    },

    {
      field: 'client_secret',
      type: 'string',
      description: "The users' client secret",
    },

    {
      field: 'refresh_token',
      type: 'string',
      description: 'The refresh token to revalidate the session',
    },

    {
      field: 'id',
      type: 'string',
      description: 'The id that the user received previously',
    },
  ],
  systemsCreate: [
    {
      field: 'account_id',
      type: 'string',
      description: "The user's account id",
    },
    { field: 'alias', type: 'string', description: "The System's alias" },

    { field: 'mac', type: 'string', description: "The System's Mac Address" },

    { field: 'name', type: 'string', description: "The System's name" },

    { field: 'system_id', type: 'string', description: "The System's id" },
    { field: 'details', type: 'object', description: "The System's details" },
  ],

  systemUpdate: [
    { field: 'alias', type: 'string', description: "The System's alias" },

    { field: 'name', type: 'string', description: "The System's name" },

    { field: 'system_name', type: 'string', description: "The System's name" },
    { field: 'details', type: 'object', description: "The System's details" },
  ],

  systemSettingsCreate: [
    { field: 'system_id', type: 'string', description: "The System's Id" },
    {
      field: 'account_id',
      type: 'string',
      description:
        "The Account Id in which the system's settings will be created",
    },
    {
      field: 'settings',
      type: 'object',
      description: 'The settings details to provide',
    },
  ],

  systemSettingsUpdate: [
    {
      field: 'system_id',
      type: 'string',
      description: "The System's Id to update",
    },
    {
      field: 'account_id',
      type: 'string',
      description:
        "The Account Id in which the system's settings will be updated",
    },
    {
      field: 'settings',
      type: 'object',
      description: 'The settings details to provide',
    },
  ],
};

export const _params: any = {
  usersShow: [
    { field: 'account_id' },
    { type: 'string' },
    { description: 'The account_id which identifies the user to show' },
  ],

  usersUpdate: [
    { field: 'account_id' },
    { type: 'string' },
    { description: 'The account_id which identifies the user to update' },
  ],

  usersDelete: [
    { field: 'account_id' },
    { type: 'string' },
    { description: 'The account_id which identifies the user to delete' },
  ],

  systemsList: [
    { field: 'account_id' },
    { type: 'string' },
    {
      description:
        "The account_id which identifies the account to get the systems's list",
    },
  ],

  systemsShow: [
    { field: 'system_id' },
    { type: 'string' },
    {
      description:
        'The system_id which identifies the system which details must be shown',
    },
  ],

  systemsDelete: [
    { field: 'system_id' },
    { type: 'string' },
    {
      description: 'The system_id which identifies the system to delete',
    },
  ],

  systemsUpdate: [
    { field: 'system_id' },
    { type: 'string' },
    {
      description: 'The system_id which identifies the system to update',
    },
  ],

  systemsDataChart: [
    { field: 'system_id' },
    { type: 'string' },
    {
      description: 'The system_id which refers to the intended Data Chart',
    },
  ],

  systemsProbe: [
    { field: 'system_id' },
    { type: 'string' },
    {
      description: 'The system_id which refers to the intended probe values',
    },
  ],

  systemsProbeLast: [
    { field: 'system_id' },
    { type: 'string' },
    {
      description: 'The system_id from which the last probe values must be get',
    },
  ],

  systemsStatus: [
    { field: 'system_id' },
    { type: 'string' },
    {
      description: 'The system_id to get the systems status',
    },
  ],

  sysSettingsCreate: [
    { field: 'system_id' },
    { type: 'string' },
    {
      description:
        'The system_id to identify the system in which the settings will be created',
    },
  ],

  sysSettingsShow: [
    { field: 'system_id' },
    { type: 'string' },
    {
      description:
        'The system_id to identify the system from which the settings must be shown',
    },
  ],
};

export const requestSections: any = [
  {
    str: 'Auth',
    href: `#auth`,
    internalData: [
      {
        str: 'Client Session Authentication',
        href: `#client-session-auth`,
        internalData: [],
        additionalData: [],
        reqMethod: `POST`,
        reqDescription: `Authenticate Client`,
        endpoint: `${path}/client/session/auth`,

        text: (
          <>
            <RequestDetails
              title="Headers"
              data={headers.commonCompleteHeaders}
            />
            <RequestDetails title="Body" data={bodies.auth} />
          </>
        ),
      },
    ],
    additionalData: [],
  },
  {
    str: 'Users',
    href: `#users`,
    internalData: [
      {
        str: 'Register User',
        href: `#register-user`,
        internalData: [],
        additionalData: [],
        reqMethod: `POST`,
        reqDescription: `Register User`,
        endpoint: `${path}/client/users/register`,

        text: (
          <>
            <RequestDetails
              title="Headers"
              data={headers.commonCompleteHeaders}
            />
            <RequestDetails title="Body" data={bodies.register} />
          </>
        ),
      },

      {
        str: 'List users',
        href: `#list-users`,
        internalData: [],
        additionalData: [],
        reqMethod: `GET`,
        reqDescription: `List Users`,
        endpoint: `${path}/client/users/list`,
        text: (
          <>
            <RequestDetails title="Headers" data={headers.commonHeaders} />
            {/* <RequestDetails title="Body" data={[]} /> */}
          </>
        ),
      },

      {
        str: 'Show users',
        href: `#show-users`,
        internalData: [],
        additionalData: [],
        reqMethod: `GET`,
        reqDescription: `Show Users details`,
        endpoint: `${path}/client/users/show`,
        text: (
          <>
            <RequestDetails title="Headers" data={headers.commonHeaders} />
            {/* <RequestDetails title="Body" data={[]} /> */}
            <RequestDetails title="Params" data={_params.usersShow} />
          </>
        ),
      },

      // {
      //   str: 'Update users',
      //   href: `#update-users`,
      //   internalData: [],
      //   additionalData: [],
      //   reqMethod: `PUT`,
      //   reqDescription: `Update User`,
      //   endpoint: `${path}/client/users/update`,

      //   text: (
      //     <>
      //       <RequestDetails
      //         title="Headers"
      //         data={headers.commonCompleteHeaders}
      //       />
      //       <RequestDetails title="Body" data={bodies.userUpdate} />
      //       <RequestDetails title="Params" data={_params.usersUpdate} />
      //     </>
      //   ),
      // },
      // {
      //   str: 'Delete users',
      //   href: `#delete-users`,
      //   internalData: [],
      //   additionalData: [],
      //   reqMethod: `DELETE`,
      //   reqDescription: `Delete User`,
      //   endpoint: `${path}/client/users/delete`,
      //   text: (
      //     <>
      //       <RequestDetails title="Headers" data={headers.commonHeaders} />
      //       {/* <RequestDetails title="Body" data={[]} /> */}
      //       <RequestDetails title="Params" data={_params.usersDelete} />
      //     </>
      //   ),
      // },

      {
        str: 'Obtain the code',
        href: `#generate-one-time-code`,
        internalData: [],
        additionalData: [],
        reqMethod: `POST`,
        reqDescription: `Obtain the one time code`,
        endpoint: `${path}/client/users/code`,
        text: (
          <>
            <RequestDetails title="Headers" data={headers.commonHeaders} />
            <RequestDetails title="Body" data={bodies.code} />
          </>
        ),
      },

      {
        str: 'Login',
        href: `#login-doc`,
        internalData: [],
        additionalData: [],
        reqMethod: `POST`,
        reqDescription: `Login`,
        endpoint: `${path}/client/users/login`,
        text: (
          <>
            <RequestDetails
              title="Headers"
              data={headers.commonCompleteHeaders}
            />
            <RequestDetails title="Body" data={bodies.usersLogin} />
          </>
        ),
      },

      {
        str: 'Refresh token',
        href: `#refresh-token-doc`,
        internalData: [],
        additionalData: [],
        reqMethod: `POST`,
        reqDescription: `Refresh token`,
        endpoint: `${path}/client/users/refresh`,
        text: (
          <>
            <RequestDetails
              title="Headers"
              data={headers.commonCompleteHeaders}
            />
            <RequestDetails title="Body" data={bodies.usersRefresh} />
          </>
        ),
      },
    ],
    additionalData: [],
  },
  {
    str: 'Systems',
    href: `#systems`,
    internalData: [
      {
        str: 'Create System',
        href: `#create-system`,
        internalData: [],
        additionalData: [],
        reqMethod: `POST`,
        reqDescription: `Create system`,
        endpoint: `${path}/client/system/create`,

        text: (
          <>
            <RequestDetails
              title="Headers"
              data={headers.commonCompleteHeaders}
            />
            <RequestDetails title="Body" data={bodies.systemsCreate} />
          </>
        ),
      },
      {
        str: 'Client systems list',
        href: `#list-systems`,
        internalData: [],
        additionalData: [],
        reqMethod: `GET`,
        reqDescription: `List systems`,
        endpoint: `${path}/client/system/list`,
        text: (
          <>
            <RequestDetails title="Headers" data={headers.commonHeaders} />
            {/* <RequestDetails title="Body" data={[]} /> */}
            <RequestDetails title="Params" data={_params.systemsList} />
          </>
        ),
      },
      {
        str: 'Client System show',
        href: `#show-system-details`,
        internalData: [],
        additionalData: [],
        reqMethod: `GET`,
        reqDescription: `Show system details`,
        endpoint: `${path}/client/system/show`,
        text: (
          <>
            <RequestDetails title="Headers" data={headers.commonHeaders} />
            {/* <RequestDetails title="Body" data={[]} /> */}
            <RequestDetails title="Params" data={_params.systemsShow} />
          </>
        ),
      },

      {
        str: 'Delete System',
        href: `#delete-system-doc`,
        internalData: [],
        additionalData: [],
        reqMethod: `DELETE`,
        reqDescription: `Delete System`,
        endpoint: `${path}/client/system/delete`,
        text: (
          <>
            <RequestDetails title="Headers" data={headers.commonHeaders} />
            {/* <RequestDetails title="Body" data={[]} /> */}
            <RequestDetails title="Params" data={_params.systemsDelete} />
          </>
        ),
      },

      {
        str: 'Update System',
        href: `#update-system-doc`,
        internalData: [],
        additionalData: [],
        reqMethod: `PUT`,
        reqDescription: `Update System`,
        endpoint: `${path}/client/system/update`,

        text: (
          <>
            <RequestDetails
              title="Headers"
              data={headers.commonCompleteHeaders}
            />
            <RequestDetails title="Body" data={bodies.systemUpdate} />
            <RequestDetails title="Params" data={_params.systemsUpdate} />
          </>
        ),
      },

      // {
      //   str: 'Get Data Chart',
      //   href: `#data-chart-doc`,
      //   internalData: [],
      //   additionalData: [],
      //   reqMethod: `GET`,
      //   reqDescription: `Get data chart`,
      //   endpoint: `${path}/client/system/data-chart`,
      //   text: (
      //     <>
      //       <RequestDetails title="Headers" data={headers.commonHeaders} />
      //       {/* <RequestDetails title="Body" data={[]} /> */}
      //       <RequestDetails title="Params" data={_params.systemsDataChart} />
      //     </>
      //   ),
      // },

      // {
      //   str: 'Get system probe',
      //   href: `#system-probe-doc`,
      //   internalData: [],
      //   additionalData: [],
      //   reqMethod: `GET`,
      //   reqDescription: `Get system probe`,
      //   endpoint: `${path}/client/system/probe/show`,
      //   text: (
      //     <>
      //       <RequestDetails title="Headers" data={headers.commonHeaders} />
      //       {/* <RequestDetails title="Body" data={[]} /> */}
      //       <RequestDetails title="Params" data={_params.systemsProbe} />
      //     </>
      //   ),
      // },

      // {
      //   str: 'Get probe last values',
      //   href: `#system-probe-last-doc`,
      //   internalData: [],
      //   additionalData: [],
      //   reqMethod: `GET`,
      //   reqDescription: `Get probe last values`,
      //   endpoint: `${path}/client/system/probe/last-values`,
      //   text: (
      //     <>
      //       <RequestDetails title="Headers" data={headers.commonHeaders} />
      //       {/* <RequestDetails title="Body" data={[]} /> */}
      //       <RequestDetails title="Params" data={_params.systemsProbeLast} />
      //     </>
      //   ),
      // },

      // {
      //   str: 'Get System status',
      //   href: `#system-status-doc`,
      //   internalData: [],
      //   additionalData: [],
      //   reqMethod: `GET`,
      //   reqDescription: `Get System status`,
      //   endpoint: `${path}/client/system/status`,
      //   text: (
      //     <>
      //       <RequestDetails title="Headers" data={headers.commonHeaders} />
      //       {/* <RequestDetails title="Body" data={[]} /> */}
      //       <RequestDetails title="Params" data={_params.systemsStatus} />
      //     </>
      //   ),
      // },
    ],
    additionalData: [],
  },
  {
    str: 'system-settings',
    href: '#system-settings',
    internalData: [
      {
        str: 'Create system settings',
        href: `#create-sys-settings-doc`,
        internalData: [],
        additionalData: [],
        reqMethod: `POST`,
        reqDescription: `Create System settings`,
        endpoint: `${path}/client/system/settings/create`,
        text: (
          <>
            <RequestDetails
              title="Headers"
              data={headers.commonCompleteHeaders}
            />
            <RequestDetails title="Body" data={bodies.systemSettingsCreate} />
            <RequestDetails title="Params" data={_params.sysSettingsCreate} />
          </>
        ),
      },

      {
        str: 'Update system settings',
        href: `#update-sys-settings-doc`,
        internalData: [],
        additionalData: [],
        reqMethod: `PUT`,
        reqDescription: `Update System settings`,
        endpoint: `${path}/client/system/settings/update`,
        text: (
          <>
            <RequestDetails
              title="Headers"
              data={headers.commonCompleteHeaders}
            />
            <RequestDetails title="Body" data={bodies.systemSettingsUpdate} />
            <RequestDetails title="Params" data={_params.sysSettingsShow} />
          </>
        ),
      },

      {
        str: 'Show system settings',
        href: `#show-sys-settings-doc`,
        internalData: [],
        additionalData: [],
        reqMethod: `GET`,
        reqDescription: `Show System settings`,
        endpoint: `${path}/client/system/settings/show`,
        text: (
          <>
            <RequestDetails title="Headers" data={headers.commonHeaders} />
            {/* <RequestDetails title="Body" data={[]} /> */}
            <RequestDetails title="Params" data={[]} />
          </>
        ),
      },

      //-----------------------------------------------------
      //WIP
      //show system setup, create system setup, delete system setup
      {
        str: 'Show system setup',
        href: `#show-system-setup`,
        internalData: [],
        additionalData: [],
        reqMethod: `GET`,
        reqDescription: `Show System settings setup`,
        endpoint: `${path}/client/system/setup/show`,
        text: (
          <>
            <RequestDetails title="Headers" data={headers.commonHeaders} />
            {/* <RequestDetails title="Body" data={[]} /> */}
            <RequestDetails title="Params" data={[]} />
          </>
        ),
      },
      {
        str: 'Create system setup',
        href: `#create-system-setup`,
        internalData: [],
        additionalData: [],
        reqMethod: `POST`,
        reqDescription: `Create System settings setup`,
        endpoint: `${path}/client/system/setup/create`,
        text: (
          <>
            <RequestDetails title="Headers" data={headers.commonHeaders} />
            <RequestDetails title="Body" data={[]} />
            {/* <RequestDetails title="Params" data={[]} /> */}
          </>
        ),
      },
      {
        str: 'Update system setup',
        href: `#update-system-setup`,
        internalData: [],
        additionalData: [],
        reqMethod: `PUT`,
        reqDescription: `Update System settings setup`,
        endpoint: `${path}/client/system/setup/update`,
        text: (
          <>
            <RequestDetails title="Headers" data={headers.commonHeaders} />
            <RequestDetails title="Body" data={[]} />
            {/* <RequestDetails title="Params" data={[]} /> */}
          </>
        ),
      },

      //---------------------------------------
    ],
    additionalData: [],
  },

  // {
  //   str: `system-logs`,
  //   href: `#system-logs`,
  //   internalData: [
  //     {
  //       str: 'System logs',
  //       href: `#create-sys-log-doc`,
  //       internalData: [],
  //       additionalData: [],
  //       reqMethod: `POST`,
  //       reqDescription: `Create System log`,
  //       endpoint: `${path}/client/system/logs/create`,
  //       text: (
  //         <>
  //           <RequestDetails
  //             title="Headers"
  //             data={headers.commonCompleteHeaders}
  //           />
  //           <></>
  //         </>
  //       ),
  //     },
  //   ],
  //   additionalData: [],
  // },

  // {
  //   str: `manual-control-doc`,
  //   href: `#manual-control-doc`,
  //   internalData: [],
  //   additionalData: [],
  // },
];

export const treeview: any = [
  {
    requests: [
      {
        method: 'POST',
        endpoint: 'Client Session Auth', ///client/session/auth
        href: `#client-session-auth`,
      },
    ],
    mainFolder: {
      href: '#auth',
      str: `Auth`,
    },
  },
  {
    requests: [
      {
        method: 'POST',
        endpoint: 'Register User',
        href: `#register-user`,
      },

      {
        method: 'GET',
        endpoint: 'Users List',
        href: `#list-users`,
      },
      {
        method: 'GET',
        endpoint: 'Show users',
        href: `#show-users`,
      },
      // {
      //   method: 'PUT',
      //   endpoint: 'Update users',
      //   href: `#update-users-doc`,
      // },

      // {
      //   method: 'DELETE',
      //   endpoint: 'Delete users',
      //   href: `#delete-users-doc`,
      // },

      {
        method: 'POST',
        endpoint: 'Activate the code',
        href: `#generate-one-time-code`,
      },

      {
        method: 'POST',
        endpoint: 'Login', //
        href: `#login-doc`,
      },

      {
        method: 'POST',
        endpoint: 'Refresh token', //
        href: `#refresh-token-doc`,
      },
    ],
    mainFolder: {
      href: '#users',
      str: `Users`,
    },
  },
  {
    requests: [
      {
        method: 'POST',
        endpoint: 'Create System', // /client/system/create
        href: `#create-system`,
      },
      {
        method: 'GET',
        endpoint: 'Client systems list',
        href: `#list-systems`,
      },
      {
        method: 'GET',
        endpoint: 'Client System show',
        href: `#show-system-details`,
      },

      {
        method: 'DELETE',
        endpoint: 'Delete System',
        href: `#delete-system-doc`,
      },

      {
        method: 'PUT',
        endpoint: 'Update System',
        href: `#update-system-doc`,
      },
      //commented for now:
      // {
      //   method: 'GET',
      //   endpoint: 'Get data chart',
      //   href: `#data-chart-doc`,
      // },

      // {
      //   method: 'GET',
      //   endpoint: 'Get system probe',
      //   href: `#system-probe-doc`,
      // },

      // {
      //   method: 'GET',
      //   endpoint: 'Get probe last values',
      //   href: `#system-probe-last-doc`,
      // },
      // {
      //   method: 'GET',
      //   endpoint: 'Get System status',
      //   href: `#system-status-doc`,
      // },
    ],
    mainFolder: {
      href: '#systems',
      str: `Systems`,
    },
  },

  {
    requests: [
      {
        method: 'POST',
        endpoint: 'Create system settings', //
        href: `#create-sys-settings-doc`,
      },

      {
        method: 'PUT',
        endpoint: 'Update system settings', //
        href: `#update-sys-settings-doc`,
      },

      {
        method: 'GET',
        endpoint: 'Show system settings', //
        href: `#show-sys-settings-doc`,
      },

      {
        method: 'GET',
        endpoint: 'Show system setup', // SETUP
        href: `#show-system-setup`,
      },
      {
        method: 'POST',
        endpoint: 'Create system setup', //// SETUP
        href: `#create-system-setup`,
      },
      {
        method: 'PUT',
        endpoint: 'Update system setup', //// SETUP
        href: `#update-system-setup`,
      },
    ],
    mainFolder: {
      href: `#system-settings`,
      str: `System Settings`,
    },
  },
  //commented for now:
  // {
  //   requests: [
  //     {
  //       method: 'POST',
  //       endpoint: 'Create system log',
  //       href: `#create-sys-log-doc`,
  //     },

  //     {
  //       method: 'GET',
  //       endpoint: 'List system log',
  //       href: `#list-sys-log-doc`,
  //     },

  //     {
  //       method: 'GET',
  //       endpoint: 'List system commands logs',
  //       href: `#list-sys-cmd-doc`,
  //     },
  //   ],
  //   mainFolder: {
  //     href: `#system-logs`,
  //     str: `System Logs`,
  //   },
  // },
  // {
  //   requests: [
  //     {
  //       method: 'POST',
  //       endpoint: 'Create Manual Control', //
  //       href: `#create-man-ctrl-doc`,
  //     },

  //     {
  //       method: 'GET',
  //       endpoint: 'Show Manual Control', //
  //       href: `#show-man-ctrl-doc`,
  //     },

  //     {
  //       method: 'PUT',
  //       endpoint: 'Update Manual Control', //
  //       href: `#update-man-ctrl-doc`,
  //     },

  //     {
  //       method: 'DELETE',
  //       endpoint: 'Delete Manual Control', //
  //       href: `#delete-man-ctrl-doc`,
  //     },

  //     {
  //       method: 'GET',
  //       endpoint: 'List Manual Control', //
  //       href: `#list-man-ctrl-doc`,
  //     },
  //   ],
  //   mainFolder: {
  //     href: `#manual-control-doc`,
  //     str: `Manual Control`,
  //   },
  // },
];

export const snippets: any = {
  'client-session-auth': {
    id: 'auth-client-codeblock1',
    className: `reg-clt-codeblock1`,
    req: {
      curl: {
        code: `curl --location --globoff '{{HOST}}/client/session/auth' 
\--header 'Content-Type: application/json' 
\--data '{
    "user": "ZMVMNDE-ZMZM3MZM-1MMVLOD-FKZTA1O",
    "secret": "4BC6547-07CEF38C-6566458-776E865"
}'`,
      },
      jsFetch: {
        code: `const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

const raw = JSON.stringify({
  user: "ZMVMNDE-ZMZM3MZM-1MMVLOD-FKZTA1O",
  secret: "4BC6547-07CEF38C-6566458-776E865",
});

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow",
};

fetch("{{HOST}}/client/session/auth", requestOptions)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));
`,
      },
      jsXhr: {
        code: `// WARNING: For POST requests, body is set to null by browsers.
var data = JSON.stringify({
  user: "ZMVMNDE-ZMZM3MZM-1MMVLOD-FKZTA1O",
  secret: "4BC6547-07CEF38C-6566458-776E865",
});

var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === 4) {
    console.log(this.responseText);
  }
});

xhr.open("POST", "{{HOST}}/client/session/auth");
xhr.setRequestHeader("Content-Type", "application/json");

xhr.send(data);
`,
      },
    },
    res: {
      body: `{
  "user": "ZMVMNDE-ZMZM3MZM-1MMVLOD-FKZTA1O",
  "secret": "4BC6547-07CEF38C-6566458-776E865"
  }`,
      headers: (
        <div className="container pdg8 ptpb5">
          {Object.entries(headers.commonCompleteHeaders).map(
            ([key, value]: [any, any], idx: number) => {
              return (
                <div className="row mlmr0 ptpb1" key={idx}>
                  <div className="col-6 pdg headers-sm-label">
                    {Object.keys(value)}
                  </div>
                  <div className="col-6 pdg values-sm-label">
                    {' '}
                    {Object.values(value)}
                  </div>
                </div>
              );
            },
          )}
        </div>
      ),
    },
  },
  'register-user': {
    id: 'register-client-codeblock1',
    className: `reg-clt-codeblock1`,
    req: {
      curl: {
        code: `curl --location --globoff '{{HOST}}/client/users/register' 
\
--header 'Content-Type: application/json' 
\
--header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cC
I6IkpXVCJ9.eyJyb2xlcyI6ImNsaWVudC1hcGkiLCJ1c2VySWQiOiJZVE
RITlpELUtNWkRLWldJLVlaSkxJTUotR1dNVEtYTiIsImNsaWVudElkIjoi
ZmYzNzI5ZDQtZDcwYi0yMTM4LTQxZjMtZDU5ZmNiNTkzMTg0IiwiaWF0Ij
oxNzMxNDQxNDQ3LCJleHAiOjE3NzQ2NDE0NDd9.lAocXihf5Wa_dpkm9Mz
7l6qdNYggtl1KLDUBDJVumrU' \
--data-raw '{
    "email": "email@example.com",
    "first_name": "Test",
    "last_name": "Client",
    "company": "Company name",
    "phone": "+11111111111",
    "address": "Example Street, 21",
    "state": "Massachusetts",
    "city": "Marshfield",
    "zip_code": "02050"
}'`,
      },
      jsFetch: {
        code: `const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "••••••");

const raw = JSON.stringify({
  email: "example@email.com",
  first_name: "Name",
  last_name: "Last Name",
  company: "Company Name",
  phone: "+10000000000",
  address: "Example Street, 21",
  state: "Massachusetts",
  city: "Marshfield",
  zip_code: "02050",
});

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow",
};

fetch("{{HOST}}/client/users/register", requestOptions)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));`,
      },
      jsXhr: {
        code: `// WARNING: For POST requests, body is set to null by browsers.
var data = JSON.stringify({
  email: "example@email.com",
  first_name: "Name",
  last_name: "Last Name",
  company: "Company Name",
  phone: "+10000000000",
  address: "Example Street, 21",
  state: "Massachusetts",
  city: "Marshfield",
  zip_code: "02050",
});

var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === 4) {
    console.log(this.responseText);
  }
});

xhr.open("POST", "{{HOST}}/client/users/register");
xhr.setRequestHeader("Content-Type", "application/json");
xhr.setRequestHeader("Authorization", "••••••");

xhr.send(data);
`,
      },
    },
    res: {
      body: `{
  "email": "email@example.com",
  "first_name": "Name",
  "last_name": "Last name",
  "company": "Company Name",
  "phone": "+10000000000",
  "address": "Example Street, 21",
  "state": "Massachusetts",
  "city": "Marshfield",
  "zip_code": "02050"
  }`,
      headers: (
        <div className="container pdg8 ptpb5">
          {Object.entries(headers.commonCompleteHeaders).map(
            ([key, value]: [any, any], idx: number) => {
              return (
                <div className="row mlmr0 ptpb1" key={idx}>
                  <div className="col-6 pdg headers-sm-label">
                    {Object.keys(value)}
                  </div>
                  <div className="col-6 pdg values-sm-label">
                    {' '}
                    {Object.values(value)}
                  </div>
                </div>
              );
            },
          )}
        </div>
      ),
    },
  },
  'list-users': {
    id: 'users-list-codeblock1',
    className: `reg-clt-codeblock1`,
    req: {
      curl: {
        code: `curl --location --globoff '{{HOST}}/client/users/list'
\
--header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9
.eyJyb2xlcyI6ImN1c3RvbWVyLWFwaSIsInVzZXJJZCI6IjFkNzgyNGQ5LTQ4NjAtYWQ
3MS00OGNiLTQ1ODNlOWNiY2EwOCIsImNsaWVudElkIjoiWTJZV05KQS00TURWTU5aQS
0zWkpJMU1KLURNTVdZMk4iLCJpYXQiOjE3MzIyOTQxNzIsImV4cCI6MTc3NTQ5NDE3Mn
0.FCUtaUgSWg-cllN8_lGiQuDaOG7MszCxDOrrDI1rbPo'`,
      },
      jsFetch: {
        code: `const myHeaders = new Headers();
myHeaders.append("Authorization", "••••••");

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow",
};

fetch("{{HOST}}/client/users/list", requestOptions)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));
`,
      },
      jsXhr: {
        code: `// WARNING: For GET requests, body is set to null by browsers.

var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === 4) {
    console.log(this.responseText);
  }
});

xhr.open("GET", "{{HOST}}/client/users/list");
xhr.setRequestHeader("Authorization", "••••••");

xhr.send();
`,
      },
    },
    res: {
      body: ``,
      headers: (
        <div className="container pdg8 ptpb5">
          {Object.entries(headers.commonHeaders).map(
            ([key, value]: [any, any], idx: number) => {
              return (
                <div className="row mlmr0 ptpb1" key={idx}>
                  <div className="col-6 pdg headers-sm-label">
                    {Object.keys(value)}
                  </div>
                  <div className="col-6 pdg values-sm-label">
                    {' '}
                    {Object.values(value)}
                  </div>
                </div>
              );
            },
          )}
        </div>
      ),
    },
  },
  'show-users': {
    id: 'show-users-codeblock1',
    className: `reg-clt-codeblock1`,
    req: {
      curl: {
        code: `curl --location --globoff '{{HOST}}/client/users/show?account_id=
        bfd4a586-cb24-5d74-3906-01ceb08adc4f' 
\
--header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIs
InR5cCI6IkpXVCJ9.eyJyb2xlcyI6ImN1c3RvbWVyLWFwaSIsIn
VzZXJJZCI6IjFkNzgyNGQ5LTQ4NjAtYWQ3MS00OGNiLTQ1ODNlOW
NiY2EwOCIsImNsaWVudElkIjoiWTJZV05KQS00TURWTU5aQS0zW
kpJMU1KLURNTVdZMk4iLCJpYXQiOjE3MzIyOTQxNzIsImV4cCI6M
Tc3NTQ5NDE3Mn0.FCUtaUgSWg-cllN8_lGiQuDaOG7MszCxDOrrDI1rbPo'`,
      },
      jsFetch: {
        code: `const myHeaders = new Headers();
myHeaders.append("Authorization", "••••••");

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow",
};

fetch(
  "{{HOST}}/client/users/show?\naccount_id=bfd4a586-cb24-5d74-3906-01ceb08adc4f",
  requestOptions
)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));
`,
      },
      jsXhr: {
        code: `// WARNING: For GET requests, body is set to null by browsers.

var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === 4) {
    console.log(this.responseText);
  }
});

xhr.open(
  "GET",
  "{{HOST}}/client/users/show?account_id=
  bfd4a586-cb24-5d74-3906-01ceb08adc4f"
);
xhr.setRequestHeader("Authorization", "••••••");

xhr.send();
`,
      },
    },
    res: {
      body: ``,
      headers: (
        <div className="container pdg8 ptpb5">
          {Object.entries(headers.commonHeaders).map(
            ([key, value]: [any, any], idx: number) => {
              return (
                <div className="row mlmr0 ptpb1" key={idx}>
                  <div className="col-6 pdg headers-sm-label">
                    {Object.keys(value)}
                  </div>
                  <div className="col-6 pdg values-sm-label">
                    {' '}
                    {Object.values(value)}
                  </div>
                </div>
              );
            },
          )}
        </div>
      ),
    },
  },
  'update-users': {
    id: 'update-client-codeblock1',
    className: `reg-clt-codeblock1`,
    req: {
      curl: {
        code: `curl --location --globoff --request PUT '{{HOST}}/client/users/update?account_id=
        8c7bcd9e-a504-6348-62d9-8a5b320602ea' 
\
--header 'Content-Type: application/json' 
\
--header 'Authorization: Bearer eyJhbGciOiJIUz
I1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlcyI6ImN1c3RvbWV
yLWFwaSIsInVzZXJJZCI6ImJmZDRhNTg2LWNiMjQtNWQ3N
C0zOTA2LTAxY2ViMDhhZGM0ZiIsImNsaWVudElkIjoiWT
JZV05KQS00TURWTU5aQS0zWkpJMU1KLURNTVdZMk4iLCJp
YXQiOjE3MzIyOTg4ODcsImV4cCI6MTc3NTQ5ODg4N30.8yz
UjZb6CENhYXrvYoMtWw6mpaY5sm9OC3idILY88s4' \
--data-raw '{
    "email": "email@example.com",
    "first_name": "Name",
    "last_name": "Last name",
    "address": "Street Main, 21",
    "state": "Massachusetts",
    "city": "Marshfield",
    "zip_code": "02050",
    "phone": "+11111111111"
}'`,
      },
      jsFetch: {
        code: `const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "••••••");

const raw = JSON.stringify({
  email: "example@email.com",
  first_name: "Name",
  last_name: "Last Name",
  address: "Street Main CLT",
  state: "Massachusetts",
  city: "Marshfield",
  zip_code: "02050",
  phone: "+11111111111",
});

const requestOptions = {
  method: "PUT",
  headers: myHeaders,
  body: raw,
  redirect: "follow",
};

fetch(
  "{{HOST}}/client/users/update?account_id=8c7bcd9e-a504-6348-62d9-8a5b320602ea",
  requestOptions
)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));
`,
      },
      jsXhr: {
        code: `var data = JSON.stringify({
  email: "example@email.com",
  first_name: "Name",
  last_name: "Last Name",
  address: "Street Main CLT",
  state: "Massachusetts",
  city: "Marshfield",
  zip_code: "02050",
  phone: "+11111111111",
});

var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === 4) {
    console.log(this.responseText);
  }
});

xhr.open(
  "PUT",
  "{{HOST}}/client/users/update?account_id=8c7bcd9e-a504-6348-62d9-8a5b320602ea"
);
xhr.setRequestHeader("Content-Type", "application/json");
xhr.setRequestHeader("Authorization", "••••••");

xhr.send(data);
`,
      },
    },
    res: {
      body: `{
  "email": "email@example.com",
  "first_name": "Name",
  "last_name": "Last Name",
  "address": "Example street, 21",
  "state": "Massachusetts",
  "city": "Marshfield",
  "zip_code": "02050",
  "phone": "+10000000000"
  }`,
      headers: (
        <div className="container pdg8 ptpb5">
          {Object.entries(headers.commonCompleteHeaders).map(
            ([key, value]: [any, any], idx: number) => {
              return (
                <div className="row mlmr0 ptpb1" key={idx}>
                  <div className="col-6 pdg headers-sm-label">
                    {Object.keys(value)}
                  </div>
                  <div className="col-6 pdg values-sm-label">
                    {' '}
                    {Object.values(value)}
                  </div>
                </div>
              );
            },
          )}
        </div>
      ),
    },
  },
  'delete-users': {
    id: 'delete-client-codeblock1',
    className: `reg-clt-codeblock1`,
    req: {
      curl: {
        code: `curl --location --globoff --request DELETE '{{HOST}}/client/users/delete?account_id=
        7edf8559-7609-7189-c3ab-cfaedce649f9'
\
--header 'Authorization: Bearer eyJhbGciOiJIUz
I1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlcyI6ImNsaWVudC1
hcGkiLCJ1c2VySWQiOiJZVERITlpELUtNWkRLWldJLVlaS
kxJTUotR1dNVEtYTiIsImNsaWVudElkIjoiZmYzNzI5ZDQ
tZDcwYi0yMTM4LTQxZjMtZDU5ZmNiNTkzMTg0IiwiaWF0I
joxNzMyMjk2ODQ0LCJleHAiOjE3NzU0OTY4NDR9.HMCU2P
dNokSC7ipYCSEANp0WqPdUdWUQLR8eST7OsR4'`,
      },
      jsFetch: {
        code: `const myHeaders = new Headers();
myHeaders.append("Authorization", "••••••");

const requestOptions = {
  method: "DELETE",
  headers: myHeaders,
  redirect: "follow",
};

fetch(
  "{{HOST}}/client/users/delete?account_id=7edf8559-7609-7189-c3ab-cfaedce649f9",
  requestOptions
)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));
`,
      },
      jsXhr: {
        code: `var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === 4) {
    console.log(this.responseText);
  }
});

xhr.open(
  "DELETE",
  "{{HOST}}/client/users/delete?account_id=7edf8559-7609-7189-c3ab-cfaedce649f9"
);
xhr.setRequestHeader("Authorization", "••••••");

xhr.send();
`,
      },
    },
    res: {
      body: ``,
      headers: (
        <div className="container pdg8 ptpb5">
          {Object.entries(headers.commonHeaders).map(
            ([key, value]: [any, any], idx: number) => {
              return (
                <div className="row mlmr0 ptpb1" key={idx}>
                  <div className="col-6 pdg headers-sm-label">
                    {Object.keys(value)}
                  </div>
                  <div className="col-6 pdg values-sm-label">
                    {' '}
                    {Object.values(value)}
                  </div>
                </div>
              );
            },
          )}
        </div>
      ),
    },
  },
  'generate-one-time-code': {
    id: 'get-code-codeblock1',
    className: `reg-clt-codeblock1`,
    req: {
      curl: {
        code: `curl --location --globoff '{{HOST}}/client/users/code' 
\
--header 'Content-Type: application/json' \
--data-raw '{
    "email": "airTest_2@usermail.com",
    "password": "airCycler#client#411",
    "client_id": "Y2YWNJA-4MDVMNZA-3ZJI1MJ-DMMWY2N"
}'`,
      },
      jsFetch: {
        code: `const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

const raw = JSON.stringify({
  email: "example@email.com",
  password: "examplePassword#1",
  client_id: "Y2YWNJA-4MDVMNZA-3ZJI1MJ-DMMWY2N",
});

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow",
};

fetch("{{HOST}}/client/users/code", requestOptions)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));
`,
      },
      jsXhr: {
        code: `// WARNING: For POST requests, body is set to null by browsers.
var data = JSON.stringify({
  email: "example@email.com",
  password: "examplePassword#1",
  client_id: "Y2YWNJA-4MDVMNZA-3ZJI1MJ-DMMWY2N",
});

var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === 4) {
    console.log(this.responseText);
  }
});

xhr.open("POST", "{{HOST}}/client/users/code");
xhr.setRequestHeader("Content-Type", "application/json");

xhr.send(data);
`,
      },
    },
    res: {
      body: `{
  "email": "email@example.com",
  "password": "examplePassword#1",
  "client_id": "Y2YWNJA-4MDVMNZA-3ZJI1MJ-DMMWY2N"
  }`,
      headers: (
        <div className="container pdg8 ptpb5">
          {Object.entries(headers.commonCompleteHeaders).map(
            ([key, value]: [any, any], idx: number) => {
              return (
                <div className="row mlmr0 ptpb1" key={idx}>
                  <div className="col-6 pdg headers-sm-label">
                    {Object.keys(value)}
                  </div>
                  <div className="col-6 pdg values-sm-label">
                    {' '}
                    {Object.values(value)}
                  </div>
                </div>
              );
            },
          )}
        </div>
      ),
    },
  },
  'login-doc': {
    id: 'login-codeblock1',
    className: `reg-clt-codeblock1`,
    req: {
      curl: {
        code: `curl --location --globoff '{{HOST}}/client/users/login'
\
--header 'Content-Type: application/json' \
--data '{
    "client_id": "Y2YWNJA-4MDVMNZA-3ZJI1MJ-DMMWY2N",
    "client_secret": "A0422EC-1297761D-48DF42B-7593EB4",
    "code": "874914",
    "id": "1d7824d9-4860-ad71-48cb-4583e9cbca08"
}'`,
      },
      jsFetch: {
        code: `const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

const raw = JSON.stringify({
  client_id: "Y2YWNJA-4MDVMNZA-3ZJI1MJ-DMMWY2N",
  client_secret: "A0422EC-1297761D-48DF42B-7593EB4",
  code: "874914",
  id: "1d7824d9-4860-ad71-48cb-4583e9cbca08",
});

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow",
};

fetch("{{HOST}}/client/users/login", requestOptions)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));
`,
      },
      jsXhr: {
        code: `// WARNING: For POST requests, body is set to null by browsers.
var data = JSON.stringify({
  client_id: "Y2YWNJA-4MDVMNZA-3ZJI1MJ-DMMWY2N",
  client_secret: "A0422EC-1297761D-48DF42B-7593EB4",
  code: "874914",
  id: "1d7824d9-4860-ad71-48cb-4583e9cbca08",
});

var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === 4) {
    console.log(this.responseText);
  }
});

xhr.open("POST", "{{HOST}}/client/users/login");
xhr.setRequestHeader("Content-Type", "application/json");

xhr.send(data);
`,
      },
    },
    res: {
      body: `{
  "client_id": "Y2YWNJA-4MDVMNZA-3ZJI1MJ-DMMWY2N",
  "client_secret": "A0422EC-1297761D-48DF42B-7593EB4",
  "code": "874914",
  "id": "1d7824d9-4860-ad71-48cb-4583e9cbca08"
  }`,
      headers: (
        <div className="container pdg8 ptpb5">
          {Object.entries(headers.commonCompleteHeaders).map(
            ([key, value]: [any, any], idx: number) => {
              return (
                <div className="row mlmr0 ptpb1" key={idx}>
                  <div className="col-6 pdg headers-sm-label">
                    {Object.keys(value)}
                  </div>
                  <div className="col-6 pdg values-sm-label">
                    {' '}
                    {Object.values(value)}
                  </div>
                </div>
              );
            },
          )}
        </div>
      ),
    },
  },
  'refresh-token-doc': {
    id: 'refresh-token-codeblock1',
    className: `reg-clt-codeblock1`,
    req: {
      curl: {
        code: `curl --location --globoff '{{HOST}}/client/users/refresh' 
\
--header 'Content-Type: application/json' \
--data '{
    "client_id": "Y2YWNJA-4MDVMNZA-3ZJI1MJ-DMMWY2N",
    "client_secret": "A0422EC-1297761D-48DF42B-7593EB4",
    "refresh_token": "32f3b1efb99722f550364819dff74cbcfec404af",
    "id": "bfd4a586-cb24-5d74-3906-01ceb08adc4f"
}'`,
      },
      jsFetch: {
        code: `const myHeaders = new Headers();
  myHeaders.append("Content-Type", "application/json");
  
  const raw = JSON.stringify({
  "client_id": "Y2YWNJA-4MDVMNZA-3ZJI1MJ-DMMWY2N",
  "client_secret": "A0422EC-1297761D-48DF42B-7593EB4",
  "refresh_token": "7182d4e6-ed6a-404f-994f-5d9ed62bc90f",
  "id": "1d7824d9-4860-ad71-48cb-4583e9cbca08"
  });
  
  const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow"
  };
  
  fetch("{{HOST}}/client/users/refresh", requestOptions)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));`,
      },
      jsXhr: {
        code: `// WARNING: For POST requests, body is set to null by browsers.
var data = JSON.stringify({
  client_id: "Y2YWNJA-4MDVMNZA-3ZJI1MJ-DMMWY2N",
  client_secret: "A0422EC-1297761D-48DF42B-7593EB4",
  refresh_token: "7182d4e6-ed6a-404f-994f-5d9ed62bc90f",
  id: "1d7824d9-4860-ad71-48cb-4583e9cbca08",
});

var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === 4) {
    console.log(this.responseText);
  }
});

xhr.open("POST", "{{HOST}}/client/users/refresh");
xhr.setRequestHeader("Content-Type", "application/json");

xhr.send(data);
`,
      },
    },
    res: {
      body: `{
  "client_id": "Y2YWNJA-4MDVMNZA-3ZJI1MJ-DMMWY2N",
  "client_secret": "A0422EC-1297761D-48DF42B-7593EB4",
  "refresh_token": "7182d4e6-ed6a-404f-994f-5d9ed62bc90f",
  "id": "1d7824d9-4860-ad71-48cb-4583e9cbca08"
  }`,
      headers: (
        <div className="container pdg8 ptpb5">
          {Object.entries(headers.commonCompleteHeaders).map(
            ([key, value]: [any, any], idx: number) => {
              return (
                <div className="row mlmr0 ptpb1" key={idx}>
                  <div className="col-6 pdg headers-sm-label">
                    {Object.keys(value)}
                  </div>
                  <div className="col-6 pdg values-sm-label">
                    {' '}
                    {Object.values(value)}
                  </div>
                </div>
              );
            },
          )}
        </div>
      ),
    },
  },
  'create-system': {
    id: 'create-system-codeblock1',
    className: `reg-clt-codeblock1`,
    req: {
      curl: {
        code: `curl --location --globoff '{{HOST}}/client/system/create'
\
--header 'Content-Type: application/json' 
\
--header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6I
kpXVCJ9.eyJyb2xlcyI6ImNsaWVudC1hcGkiLCJ1c2VySWQiOiJZVERITlpEL
UtNWkRLWldJLVlaSkxJTUotR1dNVEtYTiIsImNsaWVudElkIjoiZmYzNzI5ZD
QtZDcwYi0yMTM4LTQxZjMtZDU5ZmNiNTkzMTg0IiwiaWF0IjoxNzMxNTk5NjM
3LCJleHAiOjE3NzQ3OTk2Mzd9.sLlSuyrKKss61Bm0XsobJHWO-w_grKByJsf
poqaey28' 
\
--data '{
    "account_id": "bfd4a586-cb24-5d74-3906-01ceb08adc4f",
    "alias":"Aircycler_Client_Test_1",
    "mac":"C4:5B:BE:94:82:CA",
    "name": "AirCyclerG31149CA",
    "system_id": "AirCyclerG31149CA",
    "details": {}
}'`,
      },
      jsFetch: {
        code: `const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "••••••");

const raw = JSON.stringify({
  account_id: "bfd4a586-cb24-5d74-3906-01ceb08adc4f",
  alias: "Aircycler_Client_Test_1",
  mac: "C4:5B:BE:94:82:CA",
  name: "AirCyclerG31149CA",
  system_id: "AirCyclerG31149CA",
  details: {},
});

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow",
};

fetch("{{HOST}}/client/system/create", requestOptions)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));
`,
      },
      jsXhr: {
        code: `// WARNING: For POST requests, body is set to null by browsers.
var data = JSON.stringify({
  account_id: "bfd4a586-cb24-5d74-3906-01ceb08adc4f",
  alias: "Aircycler_Client_Test_1",
  mac: "C4:5B:BE:94:82:CA",
  name: "AirCyclerG31149CA",
  system_id: "AirCyclerG31149CA",
  details: {},
});

var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === 4) {
    console.log(this.responseText);
  }
});

xhr.open("POST", "{{HOST}}/client/system/create");
xhr.setRequestHeader("Content-Type", "application/json");
xhr.setRequestHeader("Authorization", "••••••");

xhr.send(data);
`,
      },
    },
    res: {
      body: `{
  "account_id": "bfd4a586-cb24-5d74-3906-01ceb08adc4f",
  "alias":"Aircycler_Client_Test_1",
  "mac":"C4:5B:BE:94:82:CA",
  "name": "AirCyclerG31149CA",
  "system_id": "AirCyclerG31149CA",
  "details": {}
  }`,
      headers: (
        <div className="container pdg8 ptpb5">
          {Object.entries(headers.commonCompleteHeaders).map(
            ([key, value]: [any, any], idx: number) => {
              return (
                <div className="row mlmr0 ptpb1" key={idx}>
                  <div className="col-6 pdg headers-sm-label">
                    {Object.keys(value)}
                  </div>
                  <div className="col-6 pdg values-sm-label">
                    {' '}
                    {Object.values(value)}
                  </div>
                </div>
              );
            },
          )}
        </div>
      ),
    },
  },
  'list-systems': {
    id: 'list-system-codeblock1',
    className: `reg-clt-codeblock1`,
    req: {
      curl: {
        code: `curl --location --globoff '{{HOST}}/client/system/list'
\
--header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9
.eyJyb2xlcyI6ImN1c3RvbWVyLWFwaSIsInVzZXJJZCI6ImJmZDRhNTg2LWNiMjQtNWQ
3NC0zOTA2LTAxY2ViMDhhZGM0ZiIsImNsaWVudElkIjoiWTJZV05KQS00TURWTU5aQS0
zWkpJMU1KLURNTVdZMk4iLCJpYXQiOjE3MzI2MzIxMzAsImV4cCI6MTc3NTgzMjEzMH
0.T07jtFyAWwiZtL-VXRO10uKY29DVL36ykyrFZEXggyY'`,
      },
      jsFetch: {
        code: `const myHeaders = new Headers();
myHeaders.append("Authorization", "••••••");

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow",
};

fetch("{{HOST}}/client/system/list", requestOptions)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));
`,
      },
      jsXhr: {
        code: `// WARNING: For GET requests, body is set to null by browsers.

var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === 4) {
    console.log(this.responseText);
  }
});

xhr.open("GET", "{{HOST}}/client/system/list");
xhr.setRequestHeader("Authorization", "••••••");

xhr.send();
`,
      },
    },
    res: {
      body: ``,
      headers: (
        <div className="container pdg8 ptpb5">
          {Object.entries(headers.commonHeaders).map(
            ([key, value]: [any, any], idx: number) => {
              return (
                <div className="row mlmr0 ptpb1" key={idx}>
                  <div className="col-6 pdg headers-sm-label">
                    {Object.keys(value)}
                  </div>
                  <div className="col-6 pdg values-sm-label">
                    {' '}
                    {Object.values(value)}
                  </div>
                </div>
              );
            },
          )}
        </div>
      ),
    },
  },
  'show-system-details': {
    id: 'show-system-codeblock1',
    className: `reg-clt-codeblock1`,
    req: {
      curl: {
        code: `curl --location --globoff '{{HOST}}/client/system/show?system_id=
        94c8a379-eeda-875e-8c50-ad9ade0aa351' 
\
--header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIs
InR5cCI6IkpXVCJ9.eyJyb2xlcyI6ImN1c3RvbWVyLWFwaSIsInV
zZXJJZCI6ImJmZDRhNTg2LWNiMjQtNWQ3NC0zOTA2LTAxY2ViMDh
hZGM0ZiIsImNsaWVudElkIjoiWTJZV05KQS00TURWTU5aQS0zWkpJ
MU1KLURNTVdZMk4iLCJpYXQiOjE3MzI2MzIxMzAsImV4cCI6MTc3N
TgzMjEzMH0.T07jtFyAWwiZtL-VXRO10uKY29DVL36ykyrFZEXggyY'`,
      },
      jsFetch: {
        code: `const myHeaders = new Headers();
myHeaders.append("Authorization", "••••••");

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow",
};

fetch(
  "{{HOST}}/client/system/show?system_id=
  94c8a379-eeda-875e-8c50-ad9ade0aa351",
  requestOptions
)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));
`,
      },
      jsXhr: {
        code: `// WARNING: For GET requests, body is set to null by browsers.

var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === 4) {
    console.log(this.responseText);
  }
});

xhr.open(
  "GET",
  "{{HOST}}/client/system/show?system_id=
  94c8a379-eeda-875e-8c50-ad9ade0aa351"
);
xhr.setRequestHeader("Authorization", "••••••");

xhr.send();
`,
      },
    },
    res: {
      body: ``,
      headers: (
        <div className="container pdg8 ptpb5">
          {Object.entries(headers.commonHeaders).map(
            ([key, value]: [any, any], idx: number) => {
              return (
                <div className="row mlmr0 ptpb1" key={idx}>
                  <div className="col-6 pdg headers-sm-label">
                    {Object.keys(value)}
                  </div>
                  <div className="col-6 pdg values-sm-label">
                    {' '}
                    {Object.values(value)}
                  </div>
                </div>
              );
            },
          )}
        </div>
      ),
    },
  },
  'delete-system-doc': {
    id: 'delete-system-codeblock1',
    className: `reg-clt-codeblock1`,
    req: {
      curl: {
        code: `curl --location --globoff --request DELETE 
'{{HOST}}/client/system/delete?system_id=
5e5baedb-fd7a-2697-fcb0-d999677890bf' 
\
--header 'Authorization: Bearer eyJhbGciOiJIUzI
1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlcyI6ImN1c3RvbWVyLW
FwaSIsInVzZXJJZCI6ImJmZDRhNTg2LWNiMjQtNWQ3NC0zOT
A2LTAxY2ViMDhhZGM0ZiIsImNsaWVudElkIjoiWTJZV05KQS0
0TURWTU5aQS0zWkpJMU1KLURNTVdZMk4iLCJpYXQiOjE3MzI2
MzIxMzAsImV4cCI6MTc3NTgzMjEzMH0.T07jtFyAWwiZtL-VX
RO10uKY29DVL36ykyrFZEXggyY'`,
      },
      jsFetch: {
        code: `const myHeaders = new Headers();
myHeaders.append("Authorization", "••••••");

const requestOptions = {
  method: "DELETE",
  headers: myHeaders,
  redirect: "follow",
};

fetch(
  "{{HOST}}/client/system/delete?system_id=
  5e5baedb-fd7a-2697-fcb0-d999677890bf",
  requestOptions
)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));
`,
      },
      jsXhr: {
        code: `var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === 4) {
    console.log(this.responseText);
  }
});

xhr.open(
  "DELETE",
  "{{HOST}}/client/system/delete?system_id=
  5e5baedb-fd7a-2697-fcb0-d999677890bf"
);
xhr.setRequestHeader("Authorization", "••••••");

xhr.send();
`,
      },
    },
    res: {
      body: ``,
      headers: (
        <div className="container pdg8 ptpb5">
          {Object.entries(headers.commonHeaders).map(
            ([key, value]: [any, any], idx: number) => {
              return (
                <div className="row mlmr0 ptpb1" key={idx}>
                  <div className="col-6 pdg headers-sm-label">
                    {Object.keys(value)}
                  </div>
                  <div className="col-6 pdg values-sm-label">
                    {' '}
                    {Object.values(value)}
                  </div>
                </div>
              );
            },
          )}
        </div>
      ),
    },
  },
  'update-system-doc': {
    id: 'update-system-codeblock1',
    className: `reg-clt-codeblock1`,
    req: {
      curl: {
        code: `curl --location --globoff --request PUT 
'{{HOST}}/client/system/update?system_id=
89046529-99d9-d471-0391-c0ec83ea1424' 
\
--header 'Content-Type: application/json' 
\
--header 'Authorization: Bearer eyJhbGciOiJIUzI1N
iIsInR5cCI6IkpXVCJ9.eyJyb2xlcyI6ImN1c3RvbWVyLWFwa
SIsInVzZXJJZCI6ImJmZDRhNTg2LWNiMjQtNWQ3NC0zOTA2LT
AxY2ViMDhhZGM0ZiIsImNsaWVudElkIjoiWTJZV05KQS00TUR
WTU5aQS0zWkpJMU1KLURNTVdZMk4iLCJpYXQiOjE3MzI2MzIx
MzAsImV4cCI6MTc3NTgzMjEzMH0.T07jtFyAWwiZtL-VXRO10
uKY29DVL36ykyrFZEXggyY' \
--data '{
    "alias":"Aircycler Client Test",
    "system_name":"Aircycler_Client_Test",
    "name": "AirCyclerG31149CA",
    "details": {
        "vpro": 0,
        "usul": 0,
        "dmac": "C4:5B:BE:94:82:58",
        "evrl": 30,
        "conf": {
            "cflw": 0,
            "effr": 60,
            "dmst": 0,
            "mnsf": 20,
            "mco2": 1000,
            "exct": 1,
            "pmto": 10,
            "mvoc": 500,
            "tpmx": 150,
            "mxrh": 100,
            "lcap": "Vodafone-24A516",
            "mp25": 35,
            "crhr": 47,
            "tmzn": 2,
            "hrsv": 6,
            "efdt": 10,
            "cp25": 0,
            "cdvc": 0,
            "fhfr": 90,
            "fffr": 90,
            "fcds": 0,
            "fcdr": 0,
            "fcfr": 90,
            "exwm": 0,
            "cvoc": 0,
            "cfst": 0,
            "mixt": 0,
            "tpmn": 0,
            "blto": 10,
            "hroa": 0,
            "cco2": 0,
            "prmd": 0,
            "cdvs": [],
            "fcst": 0,
            "carq": 30,
            "lsnm": "",
            "fcsp": 1,
            "wiaq": 0,
            "ddia": 6,
            "ctmp": 57,
            "smth": 0,
            "hstv": 18,
            "mner": 20
        },
        "cfrt": 0,
        "mhsv": 30,
        "thre": 0,
        "oaqe": 0,
        "fmwv": "0.7.8",
        "lusr": "",
        "hrss": -86,
        "vpct": 0,
        "evrt": 30,
        "cmpy": "",
        "mhsf": 60,
        "pcst": 1,
        "prob": 3,
        "uptm": 281284,
        "g3id": "AirCyclerG3948258",
        "vcta": 1,
        "mplf": 52,
        "aqse": 0,
        "errc": 0,
        "pcbv": 7,
        "cfrl": 0,
        "thsa": 0,
        "inst": "",
        "evot": 0,
        "uctr": 0
    }
}'`,
      },
      jsFetch: {
        code: `const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "••••••");

const raw = JSON.stringify({
  alias: "Aircycler Client Test",
  system_name: "Aircycler_Client_Test",
  name: "AirCyclerG31149CA",
  details: {
    vpro: 0,
    usul: 0,
    dmac: "C4:5B:BE:94:82:58",
    evrl: 30,
    conf: {
      cflw: 0,
      effr: 60,
      dmst: 0,
      mnsf: 20,
      mco2: 1000,
      exct: 1,
      pmto: 10,
      mvoc: 500,
      tpmx: 150,
      mxrh: 100,
      lcap: "Vodafone-24A516",
      mp25: 35,
      crhr: 47,
      tmzn: 2,
      hrsv: 6,
      efdt: 10,
      cp25: 0,
      cdvc: 0,
      fhfr: 90,
      fffr: 90,
      fcds: 0,
      fcdr: 0,
      fcfr: 90,
      exwm: 0,
      cvoc: 0,
      cfst: 0,
      mixt: 0,
      tpmn: 0,
      blto: 10,
      hroa: 0,
      cco2: 0,
      prmd: 0,
      cdvs: [],
      fcst: 0,
      carq: 30,
      lsnm: "",
      fcsp: 1,
      wiaq: 0,
      ddia: 6,
      ctmp: 57,
      smth: 0,
      hstv: 18,
      mner: 20,
    },
    cfrt: 0,
    mhsv: 30,
    thre: 0,
    oaqe: 0,
    fmwv: "0.7.8",
    lusr: "",
    hrss: -86,
    vpct: 0,
    evrt: 30,
    cmpy: "",
    mhsf: 60,
    pcst: 1,
    prob: 3,
    uptm: 281284,
    g3id: "AirCyclerG3948258",
    vcta: 1,
    mplf: 52,
    aqse: 0,
    errc: 0,
    pcbv: 7,
    cfrl: 0,
    thsa: 0,
    inst: "",
    evot: 0,
    uctr: 0,
  },
});

const requestOptions = {
  method: "PUT",
  headers: myHeaders,
  body: raw,
  redirect: "follow",
};

fetch(
  "{{HOST}}/client/system/update?system_id=
  89046529-99d9-d471-0391-c0ec83ea1424",
  requestOptions
)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));`,
      },
      jsXhr: {
        code: `var data = JSON.stringify({
  alias: "Aircycler Client Test",
  system_name: "Aircycler_Client_Test",
  name: "AirCyclerG31149CA",
  details: {
    vpro: 0,
    usul: 0,
    dmac: "C4:5B:BE:94:82:58",
    evrl: 30,
    conf: {
      cflw: 0,
      effr: 60,
      dmst: 0,
      mnsf: 20,
      mco2: 1000,
      exct: 1,
      pmto: 10,
      mvoc: 500,
      tpmx: 150,
      mxrh: 100,
      lcap: "Vodafone-24A516",
      mp25: 35,
      crhr: 47,
      tmzn: 2,
      hrsv: 6,
      efdt: 10,
      cp25: 0,
      cdvc: 0,
      fhfr: 90,
      fffr: 90,
      fcds: 0,
      fcdr: 0,
      fcfr: 90,
      exwm: 0,
      cvoc: 0,
      cfst: 0,
      mixt: 0,
      tpmn: 0,
      blto: 10,
      hroa: 0,
      cco2: 0,
      prmd: 0,
      cdvs: [],
      fcst: 0,
      carq: 30,
      lsnm: "",
      fcsp: 1,
      wiaq: 0,
      ddia: 6,
      ctmp: 57,
      smth: 0,
      hstv: 18,
      mner: 20,
    },
    cfrt: 0,
    mhsv: 30,
    thre: 0,
    oaqe: 0,
    fmwv: "0.7.8",
    lusr: "",
    hrss: -86,
    vpct: 0,
    evrt: 30,
    cmpy: "",
    mhsf: 60,
    pcst: 1,
    prob: 3,
    uptm: 281284,
    g3id: "AirCyclerG3948258",
    vcta: 1,
    mplf: 52,
    aqse: 0,
    errc: 0,
    pcbv: 7,
    cfrl: 0,
    thsa: 0,
    inst: "",
    evot: 0,
    uctr: 0,
  },
});

var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === 4) {
    console.log(this.responseText);
  }
});

xhr.open(
  "PUT",
  "{{HOST}}/client/system/update?system_id=
  89046529-99d9-d471-0391-c0ec83ea1424"
);
xhr.setRequestHeader("Content-Type", "application/json");
xhr.setRequestHeader("Authorization", "••••••");

xhr.send(data);
`,
      },
    },
    res: {
      body: `{
  "alias":"Aircycler Client Test",
  "system_name":"Aircycler_Client_Test",
  "name": "AirCyclerG31149CA",
  "details": {
    "vpro": 0,
    "usul": 0,
    "dmac": "C4:5B:BE:94:82:58",
    "evrl": 30,
    "conf": {
        "cflw": 0,
        "effr": 60,
        "dmst": 0,
        "mnsf": 20,
        "mco2": 1000,
        "exct": 1,
        "pmto": 10,
        "mvoc": 500,
        "tpmx": 150,
        "mxrh": 100,
        "lcap": "Vodafone-24A516",
        "mp25": 35,
        "crhr": 47,
        "tmzn": 2,
        "hrsv": 6,
        "efdt": 10,
        "cp25": 0,
        "cdvc": 0,
        "fhfr": 90,
        "fffr": 90,
        "fcds": 0,
        "fcdr": 0,
        "fcfr": 90,
        "exwm": 0,
        "cvoc": 0,
        "cfst": 0,
        "mixt": 0,
        "tpmn": 0,
        "blto": 10,
        "hroa": 0,
        "cco2": 0,
        "prmd": 0,
        "cdvs": [],
        "fcst": 0,
        "carq": 30,
        "lsnm": "",
        "fcsp": 1,
        "wiaq": 0,
        "ddia": 6,
        "ctmp": 57,
        "smth": 0,
        "hstv": 18,
        "mner": 20
    },
    "cfrt": 0,
    "mhsv": 30,
    "thre": 0,
    "oaqe": 0,
    "fmwv": "0.7.8",
    "lusr": "",
    "hrss": -86,
    "vpct": 0,
    "evrt": 30,
    "cmpy": "",
    "mhsf": 60,
    "pcst": 1,
    "prob": 3,
    "uptm": 281284,
    "g3id": "AirCyclerG3948258",
    "vcta": 1,
    "mplf": 52,
    "aqse": 0,
    "errc": 0,
    "pcbv": 7,
    "cfrl": 0,
    "thsa": 0,
    "inst": "",
    "evot": 0,
    "uctr": 0
  }
  }`,
      headers: (
        <div className="container pdg8 ptpb5">
          {Object.entries(headers.commonCompleteHeaders).map(
            ([key, value]: [any, any], idx: number) => {
              return (
                <div className="row mlmr0 ptpb1" key={idx}>
                  <div className="col-6 pdg headers-sm-label">
                    {Object.keys(value)}
                  </div>
                  <div className="col-6 pdg values-sm-label">
                    {' '}
                    {Object.values(value)}
                  </div>
                </div>
              );
            },
          )}
        </div>
      ),
    },
  },
  'create-sys-settings-doc': {
    id: 'system-settings-create-codeblock1',
    className: 'reg-clt-codeblock1',
    req: {
      curl: {
        code: `curl --location --globoff '{{HOST}}/client/system/settings/create'
\
--header 'Content-Type: application/json' 
\
--header 'Authorization: Bearer eyJhbGciOiJIUzI
1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlcyI6ImN1c3RvbWVyL
WFwaSIsInVzZXJJZCI6ImJmZDRhNTg2LWNiMjQtNWQ3NC0zO
TA2LTAxY2ViMDhhZGM0ZiIsImNsaWVudElkIjoiWTJZV05K
QS00TURWTU5aQS0zWkpJMU1KLURNTVdZMk4iLCJpYXQiOj
E3MzI2MzIxMzAsImV4cCI6MTc3NTgzMjEzMH0.T07jtFyAW
wiZtL-VXRO10uKY29DVL36ykyrFZEXggyY' \
--data '{
    "system_id": "dc6df422-ae3c-a471-5c83-006c50708b06",
    "account_id": "bfd4a586-cb24-5d74-3906-01ceb08adc4f",
    "settings": {
        "fhfr": 0,
        "fcfr": 0,
        "fffr": 0,
        "hroa": 0,
        "carq": 0,
        "mnsf": 0,
        "mixt": 0,
        "tmzn": 0,
        "hrsv": 0,
        "hstv": 0,
        "exct": 0,
        "vcfr": 0,
        "vcdt": 0,
        "mvcr": 0,
        "vcwm": 0,
        "effr": 0,
        "efdt": 0,
        "mner": 0,
        "exwm": 0,
        "sensor_name": "Office",
        "sensor_id": "7c979677-575f-58e6-592c-b1d2195d3232",
        "mode": "calc_flow",
        "target": "AirCyclerVC114708"
    }
}'`,
      },
      jsFetch: {
        code: `const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "••••••");

const raw = JSON.stringify({
  system_id: "89046529-99d9-d471-0391-c0ec83ea1424",
  account_id: "bfd4a586-cb24-5d74-3906-01ceb08adc4f",
  settings: {
    fhfr: 0,
    fcfr: 0,
    fffr: 0,
    hroa: 0,
    carq: 0,
    mnsf: 0,
    mixt: 0,
    tmzn: 0,
    hrsv: 0,
    hstv: 0,
    exct: 0,
    vcfr: 0,
    vcdt: 0,
    mvcr: 0,
    vcwm: 0,
    effr: 0,
    efdt: 0,
    mner: 0,
    exwm: 0,
    sensor_name: "Office",
    sensor_id: "7c979677-575f-58e6-592c-b1d2195d3232",
    mode: "calc_flow",
    target: "AirCyclerVC114708",
  },
});

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow",
};

fetch("{{HOST}}/client/system/settings/create", requestOptions)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));
`,
      },
      jsXhr: {
        code: `// WARNING: For POST requests, body is set to null by browsers.
var data = JSON.stringify({
  system_id: "89046529-99d9-d471-0391-c0ec83ea1424",
  account_id: "bfd4a586-cb24-5d74-3906-01ceb08adc4f",
  settings: {
    fhfr: 0,
    fcfr: 0,
    fffr: 0,
    hroa: 0,
    carq: 0,
    mnsf: 0,
    mixt: 0,
    tmzn: 0,
    hrsv: 0,
    hstv: 0,
    exct: 0,
    vcfr: 0,
    vcdt: 0,
    mvcr: 0,
    vcwm: 0,
    effr: 0,
    efdt: 0,
    mner: 0,
    exwm: 0,
    sensor_name: "Office",
    sensor_id: "7c979677-575f-58e6-592c-b1d2195d3232",
    mode: "calc_flow",
    target: "AirCyclerVC114708",
  },
});

var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === 4) {
    console.log(this.responseText);
  }
});

xhr.open("POST", "{{HOST}}/client/system/settings/create");
xhr.setRequestHeader("Content-Type", "application/json");
xhr.setRequestHeader("Authorization", "••••••");

xhr.send(data);
`,
      },
    },
    res: {
      body: `{
  "system_id": "89046529-99d9-d471-0391-c0ec83ea1424",
  "account_id": "bfd4a586-cb24-5d74-3906-01ceb08adc4f",
  "settings": {
    "fhfr": 0,
    "fcfr": 0,
    "fffr": 0,
    "hroa": 0,
    "carq": 0,
    "mnsf": 0,
    "mixt": 0,
    "tmzn": 0,
    "hrsv": 0,
    "hstv": 0,
    "exct": 0,
    "vcfr": 0,
    "vcdt": 0,
    "mvcr": 0,
    "vcwm": 0,
    "effr": 0,
    "efdt": 0,
    "mner": 0,
    "exwm": 0,
    "sensor_name": "Office",
    "sensor_id": "7c979677-575f-58e6-592c-b1d2195d3232",
    "mode": "calc_flow",
    "target": "AirCyclerVC114708"
  }
  }`,
      headers: (
        <div className="container pdg8 ptpb5">
          {Object.entries(headers.commonCompleteHeaders).map(
            ([key, value]: [any, any], idx: number) => {
              return (
                <div className="row mlmr0 ptpb1" key={idx}>
                  <div className="col-6 pdg headers-sm-label">
                    {Object.keys(value)}
                  </div>
                  <div className="col-6 pdg values-sm-label">
                    {' '}
                    {Object.values(value)}
                  </div>
                </div>
              );
            },
          )}
        </div>
      ),
    },
  },
  'update-sys-settings-doc': {
    id: 'system-settings-update-codeblock1',
    className: 'reg-clt-codeblock1',
    req: {
      curl: {
        code: `curl --location --globoff --request PUT 
'{{HOST}}/client/system/settings/update'
\
--header 'Content-Type: application/json' 
\
--header 'Authorization: Bearer eyJhbGciOiJIUzI
1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlcyI6ImN1c3RvbWVyLW
FwaSIsInVzZXJJZCI6ImJmZDRhNTg2LWNiMjQtNWQ3NC0zO
TA2LTAxY2ViMDhhZGM0ZiIsImNsaWVudElkIjoiWTJZV05K
QS00TURWTU5aQS0zWkpJMU1KLURNTVdZMk4iLCJpYXQiOjE
3MzI2MzIxMzAsImV4cCI6MTc3NTgzMjEzMH0.T07jtFyAWw
iZtL-VXRO10uKY29DVL36ykyrFZEXggyY' \
--data '{
    "system_id": "89046529-99d9-d471-0391-c0ec83ea1424",
    "account_id": "bfd4a586-cb24-5d74-3906-01ceb08adc4f",
    "settings": {
        "fhfr": 0,
        "fcfr": 0,
        "fffr": 0,
        "hroa": 0,
        "carq": 0,
        "mnsf": 0,
        "mixt": 0,
        "tmzn": 0,
        "hrsv": 0,
        "hstv": 0,
        "exct": 0,
        "vcfr": 0,
        "vcdt": 0,
        "mvcr": 0,
        "vcwm": 0,
        "effr": 0,
        "efdt": 0,
        "mner": 0,
        "exwm": 0,
        "sensor_name": "Office",
        "sensor_id": "7c979677-575f-58e6-592c-b1d2195d3232",
        "mode": "calc_time",
        "target": "fanConnect"
    }
}'`,
      },
      jsFetch: {
        code: `const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append("Authorization", "••••••");

const raw = JSON.stringify({
  system_id: "89046529-99d9-d471-0391-c0ec83ea1424",
  account_id: "bfd4a586-cb24-5d74-3906-01ceb08adc4f",
  settings: {
    fhfr: 0,
    fcfr: 0,
    fffr: 0,
    hroa: 0,
    carq: 0,
    mnsf: 0,
    mixt: 0,
    tmzn: 0,
    hrsv: 0,
    hstv: 0,
    exct: 0,
    vcfr: 0,
    vcdt: 0,
    mvcr: 0,
    vcwm: 0,
    effr: 0,
    efdt: 0,
    mner: 0,
    exwm: 0,
    sensor_name: "Office",
    sensor_id: "7c979677-575f-58e6-592c-b1d2195d3232",
    mode: "calc_time",
    target: "fanConnect",
  },
});

const requestOptions = {
  method: "PUT",
  headers: myHeaders,
  body: raw,
  redirect: "follow",
};

fetch("{{HOST}}/client/system/settings/update", requestOptions)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));
`,
      },
      jsXhr: {
        code: `var data = JSON.stringify({
  system_id: "89046529-99d9-d471-0391-c0ec83ea1424",
  account_id: "bfd4a586-cb24-5d74-3906-01ceb08adc4f",
  settings: {
    fhfr: 0,
    fcfr: 0,
    fffr: 0,
    hroa: 0,
    carq: 0,
    mnsf: 0,
    mixt: 0,
    tmzn: 0,
    hrsv: 0,
    hstv: 0,
    exct: 0,
    vcfr: 0,
    vcdt: 0,
    mvcr: 0,
    vcwm: 0,
    effr: 0,
    efdt: 0,
    mner: 0,
    exwm: 0,
    sensor_name: "Office",
    sensor_id: "7c979677-575f-58e6-592c-b1d2195d3232",
    mode: "calc_time",
    target: "fanConnect",
  },
});

var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === 4) {
    console.log(this.responseText);
  }
});

xhr.open("PUT", "{{HOST}}/client/system/settings/update");
xhr.setRequestHeader("Content-Type", "application/json");
xhr.setRequestHeader("Authorization", "••••••");

xhr.send(data);
`,
      },
    },
    res: {
      body: `{
  "system_id": "89046529-99d9-d471-0391-c0ec83ea1424",
  "account_id": "bfd4a586-cb24-5d74-3906-01ceb08adc4f",
  "settings": {
    "fhfr": 0,
    "fcfr": 0,
    "fffr": 0,
    "hroa": 0,
    "carq": 0,
    "mnsf": 0,
    "mixt": 0,
    "tmzn": 0,
    "hrsv": 0,
    "hstv": 0,
    "exct": 0,
    "vcfr": 0,
    "vcdt": 0,
    "mvcr": 0,
    "vcwm": 0,
    "effr": 0,
    "efdt": 0,
    "mner": 0,
    "exwm": 0,
    "sensor_name": "Office",
    "sensor_id": "7c979677-575f-58e6-592c-b1d2195d3232",
    "mode": "calc_time",
    "target": "fanConnect"
  }
  }`,
      headers: (
        <div className="container pdg8 ptpb5">
          {Object.entries(headers.commonCompleteHeaders).map(
            ([key, value]: [any, any], idx: number) => {
              return (
                <div className="row mlmr0 ptpb1" key={idx}>
                  <div className="col-6 pdg headers-sm-label">
                    {Object.keys(value)}
                  </div>
                  <div className="col-6 pdg values-sm-label">
                    {' '}
                    {Object.values(value)}
                  </div>
                </div>
              );
            },
          )}
        </div>
      ),
    },
  },
  'show-sys-settings-doc': {
    id: 'system-settings-show-codeblock1',
    className: 'reg-clt-codeblock1',
    req: {
      curl: {
        code: `curl --location --globoff 
'{{HOST}}/client/system/settings/show?system_id=
dc6df422-ae3c-a471-5c83-006c50708b06' 
\
--header 'Authorization: Bearer eyJhbGciOiJIUzI1
NiIsInR5cCI6IkpXVCJ9.eyJyb2xlcyI6ImN1c3RvbWVyLWFw
aSIsInVzZXJJZCI6ImJmZDRhNTg2LWNiMjQtNWQ3NC0zOTA2L
TAxY2ViMDhhZGM0ZiIsImNsaWVudElkIjoiWTJZV05KQS00TUR
WTU5aQS0zWkpJMU1KLURNTVdZMk4iLCJpYXQiOjE3MzI2MzIxM
zAsImV4cCI6MTc3NTgzMjEzMH0.T07jtFyAWwiZtL-VXRO10uK
Y29DVL36ykyrFZEXggyY'`,
      },
      jsFetch: {
        code: `const myHeaders = new Headers();
myHeaders.append("Authorization", "••••••");

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow",
};

fetch(
  "{{HOST}}/client/system/settings/show?system_id=
  89046529-99d9-d471-0391-c0ec83ea1424",
  requestOptions
)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));
`,
      },
      jsXhr: {
        code: `// WARNING: For GET requests, body is set to null by browsers.

var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === 4) {
    console.log(this.responseText);
  }
});

xhr.open(
  "GET",
  "{{HOST}}/client/system/settings/show?system_id=
  89046529-99d9-d471-0391-c0ec83ea1424"
);
xhr.setRequestHeader("Authorization", "••••••");

xhr.send();
`,
      },
    },
    res: {
      body: ``,
      headers: (
        <div className="container pdg8 ptpb5">
          {Object.entries(headers.commonHeaders).map(
            ([key, value]: [any, any], idx: number) => {
              return (
                <div className="row mlmr0 ptpb1" key={idx}>
                  <div className="col-6 pdg headers-sm-label">
                    {Object.keys(value)}
                  </div>
                  <div className="col-6 pdg values-sm-label">
                    {' '}
                    {Object.values(value)}
                  </div>
                </div>
              );
            },
          )}
        </div>
      ),
    },
  },
  'show-system-setup': {
    id: `show-system-setup-codeblock1`,
    className: `reg-clt-codeblock1`,
    req: {
      curl: {
        code: `curl --location --globoff 
'{{HOST}}/client/system/setup/show?system_id=
dc6df422-ae3c-a471-5c83-006c50708b06'
\
--header 'Authorization: Bearer eyJhbGciOiJIUzI1N
iIsInR5cCI6IkpXVCJ9.eyJyb2xlcyI6ImN1c3RvbWVyLWFwaSI
sInVzZXJJZCI6ImJmZDRhNTg2LWNiMjQtNWQ3NC0zOTA2LTAxY2
ViMDhhZGM0ZiIsImNsaWVudElkIjoiWTJZV05KQS00TURWTU5aQS
0zWkpJMU1KLURNTVdZMk4iLCJpYXQiOjE3MzI2MzIxMzAsImV4cC
I6MTc3NTgzMjEzMH0.T07jtFyAWwiZtL-VXRO10uKY29DVL36yky
rFZEXggyY'`,
      },
      jsFetch: {
        code: `const myHeaders = new Headers();
myHeaders.append("Authorization", "••••••");

const requestOptions = {
  method: "GET",
  headers: myHeaders,
  redirect: "follow",
};

fetch(
  "{{HOST}}/client/system/setup/show?system_id=
  dc6df422-ae3c-a471-5c83-006c50708b06",
  requestOptions
)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));
`,
      },
      jsXhr: {
        code: `// WARNING: For GET requests, body is set to null by browsers.

var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === 4) {
    console.log(this.responseText);
  }
});

xhr.open(
  "GET",
  "{{HOST}}/client/system/setup/show?system_id=
  dc6df422-ae3c-a471-5c83-006c50708b06"
);
xhr.setRequestHeader(
  "Authorization",
  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.
  eyJyb2xlcyI6ImN1c3RvbWVyLWFwaSIsInVzZXJJZCI6ImJ
  mZDRhNTg2LWNiMjQtNWQ3NC0zOTA2LTAxY2ViMDhhZGM0ZiI
  sImNsaWVudElkIjoiWTJZV05KQS00TURWTU5aQS0zWkpJMU
  1KLURNTVdZMk4iLCJpYXQiOjE3MzI2MzIxMzAsImV4cCI6MTc
  3NTgzMjEzMH0.T07jtFyAWwiZtL-VXRO10uKY29DVL36ykyr
  FZEXggyY"
);

xhr.send();
`,
      },
    },
    res: {
      body: ``,
      headers: <div></div>,
    },
  },
  'create-system-setup': {
    id: `create-system-setup-codeblock1`,
    className: `reg-clt-codeblock1`,
    req: {
      curl: {
        code: `curl --location --globoff 
'{{HOST}}/client/system/setup/create'
\
--header 'Content-Type: application/json'
\--header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5c
CI6IkpXVCJ9.eyJyb2xlcyI6ImN1c3RvbWVyLWFwaSIsInVzZXJJZCI6I
mJmZDRhNTg2LWNiMjQtNWQ3NC0zOTA2LTAxY2ViMDhhZGM0ZiIsImNsaW
VudElkIjoiWTJZV05KQS00TURWTU5aQS0zWkpJMU1KLURNTVdZMk4iLCJp
YXQiOjE3MzI2MzIxMzAsImV4cCI6MTc3NTgzMjEzMH0.T07jtFyAWwiZtL
-VXRO10uKY29DVL36ykyrFZEXggyY' 
\
--data '{
    "system_id": "dc6df422-ae3c-a471-5c83-006c50708b06",
    "setup": {
        "outsideAir": {
            "temp": {
                "max": 94,
                "min": 10
            },
            "hum": 90,
            "aqi": 100,
            "cfm": 50,
            "turnedOn": false
        },
        "indoorAir": {
            "slider": {
                "pm25": 35,
                "co2": 1000,
                "tVoc": 500
            },
            "checkbox": {
                "pm25": {
                    "fanConnect": false,
                    "dmst": false,
                    "cfcm": false
                },
                "co2": {
                    "fanConnect": false,
                    "dmst": false,
                    "cfcm": false
                },
                "tVoc": {
                    "fanConnect": false,
                    "dmst": false,
                    "cfcm": false
                }
            },
            "turnedOn": false
        }
    }
}'`,
      },
      jsFetch: {
        code: `const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append(
  "Authorization",
  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.
  eyJyb2xlcyI6ImN1c3RvbWVyLWFwaSIsInVzZXJJZCI6I  
  mJmZDRhNTg2LWNiMjQtNWQ3NC0zOTA2LTAxY2ViMDhhZGM
  0ZiIsImNsaWVudElkIjoiWTJZV05KQS00TURWTU5aQS0zWk
  pJMU1KLURNTVdZMk4iLCJpYXQiOjE3MzI2MzIxMzAsImV4
  cCI6MTc3NTgzMjEzMH0.T07jtFyAWwiZtL-VXRO10uKY29
  DVL36ykyrFZEXggyY"
);

const raw = JSON.stringify({
  system_id: "dc6df422-ae3c-a471-5c83-006c50708b06",
  setup: {
    outsideAir: {
      temp: {
        max: 94,
        min: 10,
      },
      hum: 90,
      aqi: 100,
      cfm: 50,
      turnedOn: false,
    },
    indoorAir: {
      slider: {
        pm25: 35,
        co2: 1000,
        tVoc: 500,
      },
      checkbox: {
        pm25: {
          fanConnect: false,
          dmst: false,
          cfcm: false,
        },
        co2: {
          fanConnect: false,
          dmst: false,
          cfcm: false,
        },
        tVoc: {
          fanConnect: false,
          dmst: false,
          cfcm: false,
        },
      },
      turnedOn: false,
    },
  },
});

const requestOptions = {
  method: "POST",
  headers: myHeaders,
  body: raw,
  redirect: "follow",
};

fetch("{{HOST}}/client/system/setup/create", requestOptions)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));

`,
      },
      jsXhr: {
        code: `curl --location --globoff '{{HOST}}/client/system/setup/create'
\
--header 'Content-Type: application/json' 
\
--header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsInR5cC
I6IkpXVCJ9.eyJyb2xlcyI6ImN1c3RvbWVyLWFwaSIsInVzZXJJZCI6ImJ
mZDRhNTg2LWNiMjQtNWQ3NC0zOTA2LTAxY2ViMDhhZGM0ZiIsImNsaWVud
ElkIjoiWTJZV05KQS00TURWTU5aQS0zWkpJMU1KLURNTVdZMk4iLCJpYXQ
iOjE3MzI2MzIxMzAsImV4cCI6MTc3NTgzMjEzMH0.T07jtFyAWwiZtL-VX
RO10uKY29DVL36ykyrFZEXggyY' \
--data '{
    "system_id": "dc6df422-ae3c-a471-5c83-006c50708b06",
    "setup": {
        "outsideAir": {
            "temp": {
                "max": 94,
                "min": 10
            },
            "hum": 90,
            "aqi": 100,
            "cfm": 50,
            "turnedOn": false
        },
        "indoorAir": {
            "slider": {
                "pm25": 35,
                "co2": 1000,
                "tVoc": 500
            },
            "checkbox": {
                "pm25": {
                    "fanConnect": false,
                    "dmst": false,
                    "cfcm": false
                },
                "co2": {
                    "fanConnect": false,
                    "dmst": false,
                    "cfcm": false
                },
                "tVoc": {
                    "fanConnect": false,
                    "dmst": false,
                    "cfcm": false
                }
            },
            "turnedOn": false
        }
    }
}'`,
      },
    },
    res: {
      body: ``,
      headers: <div></div>,
    },
  },
  'update-system-setup': {
    id: `update-system-setup-codeblock1`,
    className: `reg-clt-codeblock1`,
    req: {
      curl: {
        code: `curl --location --globoff --request PUT 
'{{HOST}}/client/system/setup/update' 
\
--header 'Content-Type: application/json' 
\
--header 'Authorization: Bearer eyJhbGciOiJIUzI1NiIsIn
R5cCI6IkpXVCJ9.eyJyb2xlcyI6ImN1c3RvbWVyLWFwaSIsInVzZX
JJZCI6ImJmZDRhNTg2LWNiMjQtNWQ3NC0zOTA2LTAxY2ViMDhhZGM
0ZiIsImNsaWVudElkIjoiWTJZV05KQS00TURWTU5aQS0zWkpJMU1KLU
RNTVdZMk4iLCJpYXQiOjE3MzI2MzIxMzAsImV4cCI6MTc3NTgzMjEzM
H0.T07jtFyAWwiZtL-VXRO10uKY29DVL36ykyrFZEXggyY' 
\
--data '{
    "system_id": "dc6df422-ae3c-a471-5c83-006c50708b06",
    "setup": {
        "outsideAir": {
            "temp": {
                "max": 97,
                "min": 10
            },
            "hum": 90,
            "aqi": 100,
            "cfm": 50,
            "turnedOn": false
        },
        "indoorAir": {
            "slider": {
                "pm25": 25,
                "co2": 1000,
                "tVoc": 500
            },
            "checkbox": {
                "pm25": {
                    "fanConnect": false,
                    "dmst": false,
                    "cfcm": false
                },
                "co2": {
                    "fanConnect": false,
                    "dmst": false,
                    "cfcm": false
                },
                "tVoc": {
                    "fanConnect": false,
                    "dmst": false,
                    "cfcm": false
                }
            },
            "turnedOn": false
        }
    }
}'`,
      },
      jsFetch: {
        code: `const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");
myHeaders.append(
  "Authorization",
  "Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.
  eyJyb2xlcyI6ImN1c3RvbWVyLWFwaSIsInVzZXJJZCI6I
  mJmZDRhNTg2LWNiMjQtNWQ3NC0zOTA2LTAxY2ViMDhhZG
  M0ZiIsImNsaWVudElkIjoiWTJZV05KQS00TURWTU5aQS0
  zWkpJMU1KLURNTVdZMk4iLCJpYXQiOjE3MzI2MzIxMzAs
  ImV4cCI6MTc3NTgzMjEzMH0.T07jtFyAWwiZtL-VXRO10
  uKY29DVL36ykyrFZEXggyY"
);

const raw = JSON.stringify({
  system_id: "dc6df422-ae3c-a471-5c83-006c50708b06",
  setup: {
    outsideAir: {
      temp: {
        max: 97,
        min: 10,
      },
      hum: 90,
      aqi: 100,
      cfm: 50,
      turnedOn: false,
    },
    indoorAir: {
      slider: {
        pm25: 25,
        co2: 1000,
        tVoc: 500,
      },
      checkbox: {
        pm25: {
          fanConnect: false,
          dmst: false,
          cfcm: false,
        },
        co2: {
          fanConnect: false,
          dmst: false,
          cfcm: false,
        },
        tVoc: {
          fanConnect: false,
          dmst: false,
          cfcm: false,
        },
      },
      turnedOn: false,
    },
  },
});

const requestOptions = {
  method: "PUT",
  headers: myHeaders,
  body: raw,
  redirect: "follow",
};

fetch("{{HOST}}/client/system/setup/update", requestOptions)
  .then((response) => response.text())
  .then((result) => console.log(result))
  .catch((error) => console.error(error));
`,
      },
      jsXhr: {
        code: `var data = JSON.stringify({
  system_id: "dc6df422-ae3c-a471-5c83-006c50708b06",
  setup: {
    outsideAir: {
      temp: {
        max: 97,
        min: 10,
      },
      hum: 90,
      aqi: 100,
      cfm: 50,
      turnedOn: false,
    },
    indoorAir: {
      slider: {
        pm25: 25,
        co2: 1000,
        tVoc: 500,
      },
      checkbox: {
        pm25: {
          fanConnect: false,
          dmst: false,
          cfcm: false,
        },
        co2: {
          fanConnect: false,
          dmst: false,
          cfcm: false,
        },
        tVoc: {
          fanConnect: false,
          dmst: false,
          cfcm: false,
        },
      },
      turnedOn: false,
    },
  },
});

var xhr = new XMLHttpRequest();
xhr.withCredentials = true;

xhr.addEventListener("readystatechange", function () {
  if (this.readyState === 4) {
    console.log(this.responseText);
  }
});

   xhr.open("PUT", "{{HOST}}/client/system/setup/update");
   xhr.setRequestHeader("Content-Type", "application/json");
   xhr.setRequestHeader("Authorization", "Bearer eyJhbGciOiJIUz
   I1NiIsInR5cCI6IkpXVCJ9.eyJyb2xlcyI6ImN1c3RvbWVyLWFwaSIsIn
   VzZXJJZCI6ImJmZDRhNTg2LWNiMjQtNWQ3NC0zOTA2LTAxY2ViMDhhZGM
   0ZiIsImNsaWVudElkIjoiWTJZV05KQS00TURWTU5aQS0zWkpJMU1KLURN
   TVdZMk4iLCJpYXQiOjE3MzI2MzIxMzAsImV4cCI6MTc3NTgzMjEzMH0.
   T07jtFyAWwiZtL-VXRO10uKY29DVL36ykyrFZEXggyY");

xhr.send(data);
`,
      },
    },
    res: {
      body: ``,
      headers: <div></div>,
    },
  },
};
