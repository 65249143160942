import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ApiDocsPage } from './pages';

const client_path = '/client';

const ClientHomeRoutes: React.FC = ({ ...props }) => {
  return (
    <Switch>
      <Route exact path={`${client_path}/docs`} component={ApiDocsPage} />
      <Route exact path={client_path} component={ApiDocsPage} />
    </Switch>
  );
};

export default ClientHomeRoutes;
