import React from 'react';
import { RowContentProps } from './interface';
import { RowMenu } from '..';
import { IconButton } from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import './styles.scss';

const RowContent: React.FC<RowContentProps> = ({
  id,
  item,
  anchorEl,
  open,
  handleView,
  handleClick,
  handleClose,
  expandedCtrl,
  handleChange,
  firstCol,
  secondCol,
  thirdCol,
  fourthCol,
  config,
  firstFun,
  secondFun,
  thirdFun,
  fourthFunction,
  rowClasses,
  noMenu,
  circle,
  onlyCollapse,
}) => {
  return (
    <div
      className="container list-item list--item--01-class"
      id={id}
      onClick={event => event.stopPropagation()}
    >
      <div className="row">
        <div className={rowClasses.col1}>
          {circle}

          <span className={rowClasses.span1}>{firstCol}</span>
        </div>

        <div className={rowClasses.col2}>
          <span className={rowClasses.span2}>{secondCol}</span>
        </div>

        <div className={rowClasses.col3} id={`${id}--third`}>
          <span className={rowClasses.span3}>{thirdCol}</span>
        </div>

        <div className={rowClasses.col4}>
          <span className={rowClasses.span4}>{fourthCol}</span>
        </div>

        <div className={rowClasses.colMenu}>
          {noMenu ? null : onlyCollapse ? (
            <IconButton
              id={`collapse--${id}`}
              aria-haspopup="false"
              aria-expanded={open ? 'true' : undefined}
              onClick={handleChange}
            >
              {expandedCtrl ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          ) : (
            <RowMenu
              btnId={`expand-comps`}
              id="more-button-comp"
              onClick={handleChange}
              ariaExpanded={open ? 'true' : undefined}
              expandedCtrl={expandedCtrl}
              onClick2={handleClick}
              anchorEl={anchorEl}
              open={open}
              onClose={() => {}}
              handleView={handleView}
              handleClose={handleClose}
              item={item}
              config={config}
              firstFun={firstFun}
              secondFun={secondFun}
              thirdFun={thirdFun}
              fourthFunction={fourthFunction}
              noMenu={noMenu}
            />
          )}
        </div>
      </div>
      <hr className="userlist-item-divider" />
    </div>
  );
};

export default RowContent;
