import React from 'react';
import { BsChat } from 'react-icons/bs';
import { TbSignature, TbNumbers } from 'react-icons/tb';
import { MdLocationCity } from 'react-icons/md';
import { HiOutlineIdentification } from 'react-icons/hi';
import { GiUsaFlag } from 'react-icons/gi';
import { AiOutlineNumber } from 'react-icons/ai';
import { IoDocumentTextOutline } from 'react-icons/io5';
import { BsCalendarDate } from 'react-icons/bs';
import { BsCalendar2DateFill } from 'react-icons/bs';
import { GrStatusGood } from 'react-icons/gr';
import { RxIdCard } from 'react-icons/rx';
import { MdAlternateEmail } from 'react-icons/md';
import { FaPhoneAlt } from 'react-icons/fa';

export default {
  strings: {
    title: 'Order list',
    txt: `Return to default order by clicking here`,
    title1: 'Company Installers list',
    noResults: 'No results found...',
    timeSlLabel: '',
    companyData: `Company data:`,
    companyID: `Company ID:`,
    btn: `ADD INSTALLER`,
    main: `Installer overview`,
    lead: `Check some information about `,
    cardTitle: `Main data`,
    cardSubtitle: `If you wish to update the current data, please select the Edit option (Installers List menu).`,
  },
  _data: [
    {
      liClass: `list-group-item break-w`,
      key: `phone1`,
      spanClass1: `svg-i`,
      spanFnArg: `phone`,
      spanClass2: `lead span-key-039`,
      keyArg: `phone`,
      label: `Phone`,
      spanClass3: `lead span-key-040`,
    },
    {
      liClass: `list-group-item break-w`,
      key: `email1`,
      spanClass1: `svg-i`,
      spanFnArg: `email`,
      spanClass2: `lead span-key-039`,
      keyArg: `email`,
      label: `E-mail`,
      spanClass3: `lead span-key-040`,
    },
  ],
  keys: {
    status: `Status`,
    createdAt: `Created at`,
    updatedAt: `Updated at`,
    name: `Name`,
    code: `Code`,
    contact: `Contact`,
    state: `State`,
    city: `City`,
    zipcode: `Zip code`,
    __v: `__v`,
    installedDevices: `Installed devices count`,
  },
  data: {
    name: `Company name`,
    company_id: `Company id`,
    code: `Company code`,
    contact: `Contact`,
    email: `E-mail`,
    phone: `Phone`,
    createdAt: `Created at`,
    updatedAt: `Updated at`,
    city: `City`,
    state: `State`,
    status: `Status`,
    zipcode: `Zip Code`,
    __v: `__v`,
    _id: `_id`,
  },
  relevantKeys: [
    'company_id',
    'code',
    'name',
    'contact',
    'city',
    'zipcode',
    'state',
  ],
  icons: {
    name: (
      <TbSignature
        className="right-icon"
        style={{
          marginBottom: '2px',
          marginRight: '2px',
        }}
      />
    ),
    code: (
      <AiOutlineNumber
        style={{
          marginBottom: '2px',
          marginRight: '2px',
        }}
      />
    ),
    contact: (
      <BsChat
        style={{
          marginBottom: '2px',
          marginRight: '2px',
        }}
      />
    ),
    state: (
      <GiUsaFlag
        className="right-icon"
        style={{
          marginBottom: '2px',
          marginRight: '4px',
        }}
      />
    ),
    city: (
      <MdLocationCity
        style={{
          marginBottom: '2px',
          marginRight: '2px',
        }}
      />
    ),
    zipcode: (
      <TbNumbers
        className="right-icon"
        style={{
          marginBottom: '2px',
          marginRight: '2px',
        }}
      />
    ),
    company_id: (
      <HiOutlineIdentification
        style={{
          marginBottom: '2px',
          marginRight: '2px',
        }}
      />
    ),
    document: <IoDocumentTextOutline />,
    status: (
      <GrStatusGood
        style={{
          marginBottom: '2px',
          marginRight: '2px',
        }}
      />
    ),
    updatedAt: (
      <BsCalendar2DateFill
        style={{
          marginBottom: '2px',
          marginRight: '4px',
        }}
      />
    ),
    createdAt: (
      <BsCalendarDate
        className="right-icon"
        style={{
          marginBottom: '2px',
          marginRight: '4px',
        }}
      />
    ),
    _id: (
      <RxIdCard
        style={{
          marginBottom: '2px',
          marginRight: '2px',
        }}
      />
    ),
    email: (
      <MdAlternateEmail
        className="right-icon"
        style={{
          marginBottom: '2px',
          marginRight: '2px',
          width: '12px',
          height: '12px',
        }}
      />
    ),
    phone: (
      <FaPhoneAlt
        style={{
          marginBottom: '2px',
          marginRight: '4px',
          width: '12px',
          height: '12px',
        }}
      />
    ),
  },
  resettedCounter: {
    name: 0,
    location: 0,
    devs: 0,
  },
  info: [
    {
      key: `ph`,
      icon: `phone`,
      spanCls: `lead--smaller ftw600`,
      label: `Phone`,
    },
    {
      key: `em`,
      icon: `email`,
      spanCls: `lead--smaller ftw600`,
      label: `E-mail`,
    },
  ],
  _info: [
    {
      col: `col-xs-12 col-sm-12 col-md-4 info--cols-install`,
      cardValuesCls: `lead lead-smaller1`,
      spanCls: `badge badge-info badge--styles`,
      spanLabel: `Phone`,
      spanCls2: `lead lead-smaller1`,
    },
    {
      col: `col-xs-12 col-sm-12 col-md-4 info--cols-install`,
      cardValuesCls: `lead lead-smaller1`,
      spanCls: `badge badge-info badge--styles`,
      spanLabel: `Email`,
      spanCls2: `lead lead-smaller1`,
    },
  ],
  rowClasses: {
    col1: `col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 comp-name`,
    span1: `lead lead-adm bolder-ft the-name-with-the-circle`,
    col2: `col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 comp-location`,
    span2: `lead lead-adm location-span1`,
    col3: `col-xs-12 col-sm-12 col-md-3 col-lg-2 col-xl-2 center--dv--1`,
    span3: `lead lead-adm third--col--list`,
    col4: `col-xs-12 col-sm-12 col-md-1 col-lg-2 col-xl-2 inv`,
    span4: `lead lead-adm spec-lead-adm1 inv`,
    colMenu: `col-xs-12 col-sm-12 col-md-1 col-lg-2 col-xl-2 btn-vab2 cent-col-contents1`,
  },
  dataHeader: [
    {
      colClass: `col-xs-12 col-sm-12 col-md-3 inst--data-header`,
      spanClass: `lead header-label name-span`,
      spanClass2: `lead header-label name-span1`,
      headerDesignationCol: `Name`,
    },
    {
      colClass: `col-xs-12 col-sm-12 col-md-3 inst--data-header`,
      spanClass: `lead header-label name-span`,
      spanClass2: `lead header-label mb4-1`,
      headerDesignationCol: `Location`,
    },
    {
      colClass: `col-xs-12 col-sm-12 col-md-3 inst--data-header`,
      spanClass: `lead header-label name-span`,
      spanClass2: `lead header-label mb4-1`,
      headerDesignationCol: `Devices`,
    },
    {
      colClass: `col-xs-12 col-sm-12 col-md-2 inst--data-header`,
      spanClass: `lead header-label pl2 name-span`,
      spanClass2: `lead header-label mb4-1 pl2 pl52`,
      headerDesignationCol: `Actions`,
    },
  ],
  menu: [
    {
      id: `firstOpt`,
      label: `View installer`,
      dataTarget: '#view-installer-modal-id',
      dataToggle: `modal`,
    },
    {
      id: `secondOpt`,
      label: `Edit installer`,
      dataTarget: ``,
      dataToggle: ``,
    },
    {
      id: `thirdOpt`,
      label: `Delete installer`,
      dataTarget: '#delete-installer-modal-id',
      dataToggle: `modal`,
    },
    {
      id: `fourthOpt`,
      label: `Installed devices`,
      dataTarget: ``,
      dataToggle: ``,
    },
  ],
  modal: {
    view: {
      title: `View installer`,
      action: ``,
      bodyText: ``,
      btns: {
        labelOk: ``,
        labelCancel: ``,
      },
      modalHeaderId: `view-installer1`,
      sectionTitle: `Installer details`,
      sectionSubtitle: `Some installer data`,
    },
    delete: {
      title: `Delete installer`,
      action: ``,
      bodyText: ``,
      btns: {
        labelOk: ``,
        labelCancel: ``,
      },
      modalHeaderId: `delete-installer1`,
    },
  },
  modalStrings: {
    confirm: ``,
    warning: `Information`,
    alertStr: ``,
    alertStr2: ``,
    confirmation: ``,
    sureToProceed: `Are you sure you wish to proceed?`,
    noUndo: `This action is irreversible.`,
    aboutToDel: `You are about to delete this installer.`,
    labelOK: ` OK`,
  },
  h6Title: `Company installers list:`,
  mockInstallers: {
    statusCode: 200,
    result: true,
    body: {
      status: 200,
      data: [
        {
          status: 'active',
          createdAt:
            'Thu Nov 23 2023 16:34:45 GMT+0000 (Coordinated Universal Time)',
          updatedAt:
            'Wed Dec 13 2023 15:10:53 GMT+0000 (Coordinated Universal Time)',
          _id: '6560e65b69cf2d0013f3000',
          name: 'Installer 1',
          code: '12344',
          contact: {
            phone: '1123456789',
            email: 'email@test.com',
          },
          state: 'Alabama',
          city: 'Abbeville',
          zipcode: '36310',
          installer_id: '67d5e2b9-fe31-d399-fd51-AAAAAAAAAA',
          __v: 0,
          installedDevices: [{}, {}, {}], //check if this will be an array of installed devs OR their count as a number
        },
        {
          status: 'active',
          createdAt:
            'Thu Nov 23 2023 16:35:43 GMT+0000 (Coordinated Universal Time)',
          updatedAt:
            'Mon Nov 27 2023 16:34:28 GMT+0000 (Coordinated Universal Time)',
          _id: '6560eba324547200144513e5',
          name: 'Other Installer',
          code: '1BEAF3',
          contact: {
            phone: '11234567',
            email: 'installer_mock_email@mail.com',
          },
          state: 'California',
          city: 'Los Angeles',
          zipcode: '90001',
          installer_id: '0f7e1a04-9101-34ab-1520-6810aa333820',
          __v: 0,
          installedDevices: [{}, {}, {}, {}, {}], //check if this will be an array of installed devs OR their count as a number
        },
        {
          status: 'active',
          createdAt:
            'Thu Nov 23 2023 16:35:43 GMT+0000 (Coordinated Universal Time)',
          updatedAt:
            'Mon Nov 27 2023 16:34:28 GMT+0000 (Coordinated Universal Time)',
          _id: '6560eba324547200144513e5',
          name: 'Third Installer',
          code: '1BEAF3',
          contact: {
            phone: '11234567',
            email: '222installer_mock_email@mail.com',
          },
          state: 'Massachusetts',
          city: 'Boston',
          zipcode: '02108',
          installer_id: '0101010101-9101-34ab-1520-6810aa333820',
          __v: 0,
          installedDevices: [{}, {}, {}, {}, {}, {}, {}, {}, {}], //check if this will be an array of installed devs OR their count as a number
        },
        {
          status: 'active',
          createdAt:
            'Thu Nov 23 2023 16:35:43 GMT+0000 (Coordinated Universal Time)',
          updatedAt:
            'Mon Nov 27 2023 16:34:28 GMT+0000 (Coordinated Universal Time)',
          _id: '6560eba324547200144513e5',
          name: 'Fourth Installer',
          code: '1CEAF3',
          contact: {
            phone: '11234567',
            email: '222installer_mock_email@mail.com',
          },
          state: 'Alabama',
          city: 'Al city',
          zipcode: '12345',
          installer_id: '0101010101-9101-34ab-1520-6810aa333820',
          __v: 0,
          installedDevices: [], //check if this will be an array of installed devs OR their count as a number
        },
        {
          status: 'active',
          createdAt:
            'Thu Nov 23 2023 16:35:43 GMT+0000 (Coordinated Universal Time)',
          updatedAt:
            'Mon Nov 27 2023 16:34:28 GMT+0000 (Coordinated Universal Time)',
          _id: '6560eba324547200144513e5',
          name: 'ABC Installer',
          code: '1CEAF3',
          contact: {
            phone: '11234567',
            email: '222installer_mock_email@mail.com',
          },
          state: 'New York',
          city: 'New York',
          zipcode: '12345',
          installer_id: '0101010101-9101-34ab-1520-6810aa333820',
          __v: 0,
          installedDevices: [{}, {}, {}, {}, {}, {}, {}], //check if this will be an array of installed devs OR their count as a number
        },
        {
          status: 'active',
          createdAt:
            'Thu Nov 23 2023 16:35:43 GMT+0000 (Coordinated Universal Time)',
          updatedAt:
            'Mon Nov 27 2023 16:34:28 GMT+0000 (Coordinated Universal Time)',
          _id: '6560eba324547200144513e5',
          name: 'DEFGHI Installer',
          code: '1CEAF3',
          contact: {
            phone: '11234567',
            email: '222installer_mock_email@mail.com',
          },
          state: 'South Carolina',
          city: 'City',
          zipcode: '12345',
          installer_id: '0101010101-9101-34ab-1520-6810aa333820',
          __v: 0,
          installedDevices: [{}], //check if this will be an array of installed devs OR their count as a number
        },
        {
          status: 'active',
          createdAt:
            'Thu Nov 23 2023 16:35:43 GMT+0000 (Coordinated Universal Time)',
          updatedAt:
            'Mon Nov 27 2023 16:34:28 GMT+0000 (Coordinated Universal Time)',
          _id: '6560eba324547200144513e5',
          name: 'ABC Installer',
          code: '1CEAF3',
          contact: {
            phone: '11234567',
            email: '222installer_mock_email@mail.com',
          },
          state: 'New York',
          city: 'New York',
          zipcode: '12345',
          installer_id: '0101010101-9101-34ab-1520-6810aa333820',
          __v: 0,
          installedDevices: [{}, {}, {}, {}, {}, {}, {}, {}, {}], //check if this will be an array of installed devs OR their count as a number
        },
        {
          status: 'active',
          createdAt:
            'Thu Nov 23 2023 16:35:43 GMT+0000 (Coordinated Universal Time)',
          updatedAt:
            'Mon Nov 27 2023 16:34:28 GMT+0000 (Coordinated Universal Time)',
          _id: '6560eba324547200144513e5',
          name: 'MNO Installer',
          code: '1CEAF3',
          contact: {
            phone: '11234567',
            email: '222installer_mock_email@mail.com',
          },
          state: 'New York',
          city: 'New York',
          zipcode: '12345',
          installer_id: '0101010101-9101-34ab-1520-6810aa333820',
          __v: 0,
          installedDevices: [{}, {}], //check if this will be an array of installed devs OR their count as a number
        },
        {
          status: 'active',
          createdAt:
            'Thu Nov 23 2023 16:35:43 GMT+0000 (Coordinated Universal Time)',
          updatedAt:
            'Mon Nov 27 2023 16:34:28 GMT+0000 (Coordinated Universal Time)',
          _id: '6560eba324547200144513e5',
          name: 'JKL Installer',
          code: '1CEAF3',
          contact: {
            phone: '11234567',
            email: '222installer_mock_email@mail.com',
          },
          state: 'New York',
          city: 'New York',
          zipcode: '12345',
          installer_id: '0101010101-9101-34ab-1520-6810aa333820',
          __v: 0,
          installedDevices: [{}, {}, {}, {}, {}, {}, {}, {}, {}], //check if this will be an array of installed devs OR their count as a number
        },
      ],
    },
  },
};
