import React from 'react';
import { connect } from 'react-redux';
import './styles.scss';

interface DropdownProps {
  options: String[];
  // defaultOpt: any;
  updateLanguageChoice: Function;
  currentLanguage: string;
}

const Dropdown: React.FC<DropdownProps> = ({
  options,
  // defaultOpt,
  updateLanguageChoice,
  currentLanguage,
}) => {
  const changeSelection = (index: number) => {
    updateLanguageChoice(options[index]);
  };

  return (
    <div className="documentation-dd">
      <div className="dropdown">
        <button
          className="btn btn-secondary dropdown-toggle dd-toggleop"
          type="button"
          data-toggle="dropdown"
          aria-expanded="false"
        >
          {currentLanguage}
        </button>
        <div className="dropdown-menu">
          {options.map((elem: any, index: number) => (
            <button
              className="dropdown-item dd-item"
              type="button"
              key={index}
              onClick={() => changeSelection(index)}
            >
              {elem}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { apiState }: any = state || {};
  return {
    currentLanguage: apiState.currentLanguage,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { apiState }: any = dispatch || {};

  return {
    updateLanguageChoice: apiState.updateLanguageChoice,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Dropdown);
