import { ConnectionBroker } from '../../core';
import _ from 'lodash';


export const dataLogging = {
  state: {
    data: [],
    totalCount: (60 * 24),
  },
  reducers: {
    updateState(state: any, payload: any, name: string) {
      return { ...state, [name]: payload };
    },
  },
  effects: (dispatch: any) => ({
    async getDataLogsBySystem(data: any, rootState: any){
      const that: any = this;
      const { systemId }: any = data || {};
      let result: any = [];

      if(systemId){
        let response = await ConnectionBroker.fetchAPI(
          'jitBE',
          'dataLogging.list',
          null,
          { system_id: systemId, items: 20 }
        );

        if(response?.statusCode === 200){
          const { Items, count }: any = response?.body?.data || {};

          if(Items && Array.isArray(Items) && Items.length > 0){
            result = _.cloneDeep(Items);
            that.updateState(_.cloneDeep(Items), 'data');
          }

          if(count?.hasOwnProperty('Count')){
            that.updateState(count.Count, 'totalCount');
          }
        }
      }

      return result;
    },
    async getSystemDataLogsByPage(data: any, rootSate: any){
      const that: any = this;
      const { systemId, page }: any = data;
      let result: any;

      if(systemId){
        let response: any = await ConnectionBroker.fetchAPI(
          'jitBE',
          'dataLogging.list',
          null,
          { system_id: systemId, page: page ? page : 1 }
        );

        if(response?.statusCode === 200){
          const { Items, count }: any = response?.body?.data || {};

          if(Items && Array.isArray(Items) && Items.length > 0){
            result = _.cloneDeep(Items);
            that.updateState(_.cloneDeep(Items), 'data');
          }

          if(count?.hasOwnProperty('Count')){
            that.updateState(count.Count, 'totalCount');
          }
        }else{
          result = response?.statusCode;
        }
      }

      return result;
    },

    async getSystemDataLogsByMuiltiPeriods(data: any, rootSate: any){
      const that: any = this;
      const { systemId, page, periods }: any = data;
      let result: any;

      if(systemId){
        let response: any = await ConnectionBroker.fetchAPI(
          'jitBE',
          'dataLogging.listByPeriods',
          null,
          { system_id: systemId, page, periods }
        );

        if(response?.statusCode === 200){
          const { Items, count }: any = response?.body?.data || {};

          if(Items && Array.isArray(Items) && Items.length > 0){
            result = _.cloneDeep(Items);
            that.updateState(_.cloneDeep(Items), 'data');
          }

          if(count?.hasOwnProperty('Count')){
            that.updateState(count.Count, 'totalCount');
          }
        }else{
          result = response?.statusCode;
        }

      }

      return result;
    },

    async requestCsvData(data: any, rootSate: any){
      const { systemId, page, periods }: any = data || {};
      let result: any = [];

      if(systemId){
        result = await ConnectionBroker.fetchAPI(
          'jitBE',
          'dataLogging.listByPeriods',
          null,
          { system_id: systemId, page, periods }
        );

        if(result?.statusCode === 200){
          const { Items }: any = result?.body?.data || {};

          return Items && Array.isArray(Items) && Items.length > 0 ? _.cloneDeep(Items) : [];
        }
      }

      return result;
    },

    async requestDatesCsvData(data: any, rootState: any){
      const that: any = this;
      const { systemId, end, start }: any = data || {};
      let result: any = {};

      if(systemId){
        result = await ConnectionBroker.fetchAPI(
          'jitBE',
          'dataLogging.listByDates',
          null,
          { system_id: systemId, start, end }
        );

        if(result?.statusCode === 200){
          const { Items }: any = result?.body?.data || {};

          return Items && Array.isArray(Items) && Items.length > 0 ? _.cloneDeep(Items) : [];
        }
      }

      return result;
    },

  }),
};
