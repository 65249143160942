import React, { useState, useEffect, Fragment } from 'react';
import { WattageProps } from './interface';
import config from './config';
import numeral from 'numeral';
import './styles.scss';

const Wattage: React.FC<WattageProps> = ({ per }) => {
  const [wattageCalc, setWattageCalc] = useState<number>();
  const [rmsValues, setRmsValues] = useState({
    vrms: null,
    irms: null,
  });

  useEffect(() => {
    const { irms, vrms }: any = per || {};

    if (
      irms !== null &&
      irms !== undefined &&
      vrms !== null &&
      vrms !== undefined
    ) {
      let wattageCalculation: number = irms * vrms;

      if (wattageCalculation > 0) {
        wattageCalculation = numeral(wattageCalculation).format('0.0000');
      }
      setWattageCalc(wattageCalculation);

      setRmsValues({ ...rmsValues, vrms: vrms, irms: irms });
    }
  }, [per]);

  return (
    <Fragment>
      <span className="wattage-calculation">
        {wattageCalc && (
          <>
            {wattageCalc} {config?.strings?.watts}
          </>
        )}
      </span>
      <span className="voltage--current">
        {rmsValues && rmsValues?.vrms && (
          <>
            {config?.strings?.voltage}
            {rmsValues?.vrms}
          </>
        )}
      </span>
      <span className="voltage--current">
        {rmsValues && rmsValues?.irms && (
          <>
            {config?.strings?.current}
            {rmsValues?.irms}
          </>
        )}
      </span>
    </Fragment>
  );
};

export default Wattage;
