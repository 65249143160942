import React, { useState, useEffect } from 'react';
import { EnterDataProps } from './interface';
import { Input } from 'business/modules/admin/common';
import { functions } from './helpers';
import './styles.scss';

const EnterDataCalculatedFlow: React.FC<EnterDataProps> = ({
  id,
  disabled,
  label,
  checkIfNumeric,
  onChangeValues,
  errors,
  userQuitSystemUpdate,
  value,
}) => {
  const [userSelected, setUserSelected] = useState<boolean>(false);

  useEffect(() => {
    if (userQuitSystemUpdate) {
      setUserSelected(false);
    }
  }, [userSelected, userQuitSystemUpdate]);

  return (
    <div className="calculated-flow-fields">
      <div className="container white-c fields-margin">
        <div className={`row set-spec-style1`}>
          <div className={`col set-spec-style1`}>
            <div className={`row styles-row1`}>
              <div className={`col-auto additional-label stronger-ft`}>
                <span className="lead smaller-font styles-lead-span-2- stronger-ft">
                  {label} {functions.renderLabel(id)}
                </span>
              </div>
            </div>

            <Input
              id=""
              disabled={disabled}
              type={checkIfNumeric}
              className={`form-control ${errors && errors[id] ? `error` : ``}`}
              ariaLabel="input-data"
              ariaDescribedBy="basic-addon1"
              onChange={onChangeValues}
              name={id}
              value={value}
            />

            <div className="render-error">{errors[id]} </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EnterDataCalculatedFlow;
