export default {
  btnLabels: {
    'companies-list': 'ADD COMPANY',
    'device-commands': 'RELOAD LIST',
    'setup-logs-list': 'RELOAD LIST',
    'dashboard-admin': 'CREATE ADMIN USER',
    'development-list': 'CREATE CLIENT',
  },
  pageLabels: {
    'device-commands': 'Device commands',
    'setup-logs-list': 'Setup logs',
    'companies-list': 'Companies List',
    'development-list': 'Users List',
  },
};
