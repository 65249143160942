import React, { Fragment, useState, useEffect } from 'react';
import config from './config';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { CreateAdminProps } from './interface';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import {
  Template,
  Input,
  Button,
  Modal,
  Notification,
} from 'business/modules/admin/common';
import { MdDelete } from 'react-icons/md';
import { HiOutlineXCircle } from 'react-icons/hi';
import { RiSave3Fill } from 'react-icons/ri';
import './styles.scss';

const CreateAdmin: React.FC<CreateAdminProps> = ({
  newUser,
  clearNewUser,
  saveNewUser,
  userCleared,
  userStartedToType,
  setUserIsTyping,
  createNewUser,
  modalMustClose,
  handleModalState,
}) => {
  const history = useHistory();
  const [userData, setUserData] = useState<any>({
    email: '',
    password: '',
    typeOfUser: { ...config.roles[1] },
  });

  const [errors, setErrors] = useState<any>({
    email: '',
    password: '', //for now, it only signals error if field is empty, not other requisites (password length, complexity, etc)
  });

  const validateEmail = (address: string) => {
    if (address.match(config.regex)) {
      setErrors({ ...errors, email: '' });
      return true;
    } else {
      setErrors({ ...errors, email: 'Invalid email address.' });
      return false;
    }
  };

  const createUser = async () => {
    if (newUser.email.trim().length > 0 && newUser.password.trim().length > 0) {
      let response: any = await createNewUser(newUser);

      if (response?.statusCode === 200) {
        Notification({
          title: 'Success!',
          message: config.notifications.create.success,
          type: 'success',
        });
      }
      clearNewUser();
    } else {
    }
  };

  const handleCancel = () => {
    clearNewUser();
    setErrors({ email: '', password: '' });
  };

  const getNewUserInput = (event: any) => {
    setUserIsTyping(true);

    let name: any = event.target.name;
    let value = event.target.value;
    let id: any = event.target.id;

    if (id === 'select--role') {
      setUserData({ ...userData, typeOfUser: value });
    } else {
      setUserData({ ...userData, [name]: value });
    }

    if (name === 'email') {
      validateEmail(value);
    }

    if (name === 'password') {
      if (value.trim().length === 0) {
        setErrors({ ...errors, password: 'Field is empty.' });
      } else {
        setErrors({ ...errors, password: '' });
      }
    }
  };

  useEffect(() => {
    saveNewUser(userData);
  }, [userData]);

  useEffect(() => {
    if (userCleared) {
      setUserData({
        email: '',
        password: '',
        typeOfUser: { ...config.roles[1] },
      });
    }
  }, [userCleared]);

  useEffect(() => {
    return () => {
      setUserIsTyping(false);
    };
  }, []);

  return (
    <Template
      id="dashboard-create"
      title={config.strings.title}
      goBack={() => {
        history.push(`/admin/dashboard/admin-list`);
      }}
    >
      <BreadcrumbsItem to={config?.strings?.breadcrumbItem}>
        {config.strings.breadcrumbStr}
      </BreadcrumbsItem>

      <div className="jumbotron container-jumbo">
        <h4 className="centered">{config.strings.mainTitle} </h4>
        <p className="lead centered card-top-ft">{config.strings.pText}</p>
        <hr className="my-4" />

        <div className="container">
          <div className="row">
            <div className="col-md-6 offset-md-3">
              <form>
                {config.fields.map((elem: any, index: number) => {
                  return (
                    <Fragment key={index}>
                      <label className={elem.labelClass} htmlFor={elem.htmlFor}>
                        {elem.label}
                      </label>
                      <Input
                        type={elem.type}
                        className={elem.className}
                        id={elem.id}
                        name={elem.name}
                        aria-describedby={elem.ariaDescribedBy}
                        placeholder={elem.placeholder}
                        children={elem.label}
                        value={
                          elem.name === 'email'
                            ? userData.email
                            : userData.password
                        }
                        onChange={getNewUserInput}
                      />

                      <div className="render-error">
                        {errors[elem.name as keyof typeof errors]}
                      </div>
                    </Fragment>
                  );
                })}

                <label className={`lead label-create-user`}>{`Role`}</label>
                <div className="input-group mt-1">
                  <select
                    className="custom-select"
                    id="select--role"
                    onChange={getNewUserInput}
                    value={userData?.typeOfUser?.type}
                  >
                    <option> {config.strings.role}</option>
                    {config.roles.map((role: any, index: number) => (
                      <option value={role.type} key={index}>
                        {role.option}
                      </option>
                    ))}
                  </select>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="container btns--cnt">
          <div className="row r-btns-create-adm mt-1">
            <div className="col-md-6 offset-md-3">
              <Button
                onClick={handleCancel}
                className={`btn small-button dismiss-btn float-right-btn mr--2`}
                dataDismiss={`modal`}
                spanClass={`btn--span`}
              >
                <span className="btn-child-ft">
                  {' '}
                  <HiOutlineXCircle className="cancel" /> {`CANCEL`}
                </span>
              </Button>

              <Button
                disabled={
                  userStartedToType &&
                  errors.email.length === 0 &&
                  errors.password.length === 0 &&
                  newUser.email.length > 0 &&
                  newUser.password.length > 0
                    ? false
                    : true
                }
                spanClass={`btn--span`}
                className={`btn small-button ok float-right-btn mr--01`}
                onClick={() => {}}
                dataToggle="modal"
                dataTarget="#save-new-user"
                type="button"
              >
                <span className="btn-child-ft">
                  {' '}
                  <RiSave3Fill className="edit" /> {`SAVE`}
                </span>
              </Button>
            </div>
          </div>
        </div>
      </div>

      <Modal
        config={config}
        id={`save-new-user`}
        title={config.modal.create.title}
        icon={<MdDelete className="edit" />}
        body={config?.modalStrings?.aboutToCreate}
        modalMustClose={modalMustClose}
        handleModalState={handleModalState}
        onClick={createUser}
        label={config?.modalStrings?.labelOK}
        className={`small-button ok`}
        bodyClass={`alert alert-info`}
        hasConfirmBtn={true}
        sureToProceed={config?.modalStrings?.sureToProceed}
      />
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminUsersState } = state;
  return {
    newUser: adminUsersState.newUser,
    userCleared: adminUsersState.userCleared,
    userStartedToType: adminUsersState.userStartedToType,
    modalMustClose: adminUsersState.admModalMustClose,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminUsersState } = dispatch;
  return {
    clearNewUser: adminUsersState.clearNewUser,
    saveNewUser: adminUsersState.saveNewUser,
    setUserIsTyping: adminUsersState.setUserIsTyping,
    createNewUser: adminUsersState.createNewUser,
    handleModalState: adminUsersState.handleAdmModalState,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CreateAdmin);
