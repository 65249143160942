export default {
  strings: {
    title: '',
    labels: {},
    buttons: {},
    localSensors: {
      temp: ' F',
      humd: ' RH ',
      tempSymbol: 'º',
      humdSymbol: '%',
    },
  },
  charts: {
    orderedTags: [
      'timeEllapsed', 'timeRemain', 'aircyclerEllapsed', 'aircyclerRemain',
      'thermostatEllapsed', 'bathroomExhaust', 'mixTimeEllapsed', 'mixTimeRemain',
    ],
    colors: {
        default: ['#2e3191', '#FF6800', '#10a9a0', '#00a3de', '#fedc1d', '#e77d33', '#a52a2a', '#6495ed'],
        timeEllaped: '#ffc900',
        timeRemain: '#fff4cd',
        freeTime: '#9adbd7',
        aircycler: '#9adbd7',
        airCyclerVentTime: '#0da89e',
        damper: '#9adbd7',
        ventilator: '#cf7c17',
        exhautor: '#10a9a0',
        thermostat: '#00a3de',
        bathroom: '#2e3191',
        empty: '#eeeeee',
        termination: '#9adbd7'
    },
  },
  // update chart colors, according sent document
  chartsColors: {
      time: {
        total: '#d7d8d6',
        ellapsed: '#797d82'
        // total: '#fff4cd',
        // ellapsed: '#ffc900'
      },
      aircycler: {
        // total: '#9adbd7',
        // ellapsed: '#0da89e',
        total: '#20aa9770',
        ellapsed: '#20aa97',
      },
      ventilation: {
        total: '#cf7c1747',
        ellapsed: '#cf7c17'
      },
      thermostat: {
        total: '#fff572',
        ellapsed: '#fff572'
      },
      sensor: {
        total: '#e77d3345',
        ellapsed: '#e77d33'
      },
      ventilationAqi: {
        total: '#fdb71470',
        ellapsed: '#fdb714',
      },
      ventilator1: {
        total: '#002f8770',
        ellapsed: '#002f87',
      },
      ventilator2: {
        total: '#adc8e970',
        ellapsed: '#adc8e9',
      },
      ventilator3: {
        total: '#006ca370',
        ellapsed: '#006ca3',
      },
      ventilator4: {
        total: '#1ba3dd70',
        ellapsed: '#1ba3dd',
      },
      TBD: {
        total: '#9dd8d7',
        ellapsed: '#00757a',
      },
      bathroomExhaust: {
        // total: '#1ba3dd70',
        // ellapsed: '#1ba3dd',
        total: '#20aa9770',
        ellapsed: '#20aa97',
      },
      mixTime: {
        total: '#6155aa96',
        // total: '#6155aa',
        ellapsed: '#6155aa',
        totalB: '#d7d8d6',
        ellapsedB: '#797d82',
      },
  },
  alerts: {},
  notifications: {},
  fieldsState: {},
  fieldsFocus: {},
  fields: {},
  constraints: {},
};
