export default {
  strings: {
    title1: 'Client Users List',
    noResults: 'No results found...',
    timeSlLabel: '',
    title: 'Order list',
    txt: `Return to default order by clicking here`,
    noData: `No data`,
    noReqStr: `No clients to display.`,
    main: `User overview`,
    lead: `Check some information about `,
    cardTitle: `Main data`,
    cardSubtitle: `If you wish to update the current data, please select the Edit option (Clients List menu).`,
  },
  keys: {
    createdAt: `Created at`,
    updatedAt: `Updated at`,
  },
  modalStrings: {
    confirm: ``,
    warning: `Information`,
    alertStr: ``,
    alertStr2: ``,
    confirmation: ``,
    sureToProceed: `Are you sure you wish to proceed?`,
    noUndo: `This action is irreversible.`,
    aboutToDel: `You are about to delete this client.`,
    labelOK: ` OK`,
  },
  menuLabels: {
    firstOption: `View`,
    secondOption: `Edit`,
    thirdOption: `Delete`,
  },
  resettedCounter: {
    date: 0,
    company: 0,
    email: 0,
    client: 0,
  },
  modal: {
    view: {
      title: `View client`,
      action: ``,
      bodyText: ``,
      btns: {
        labelOk: ``,
        labelCancel: ``,
      },
      modalHeaderId: `view-user1`,
      sectionTitle: `User details`,
      sectionSubtitle: `Some client data`,
    },
    delete: {
      title: `Delete client`,
      action: ``,
      bodyText: ``,
      btns: {
        labelOk: ``,
        labelCancel: ``,
      },
      modalHeaderId: `delete-user1`,
    },
  },
  notifications: {
    delete: {
      success: 'The client has been deleted!',
      error: 'Something went wrong!',
    },
  },
  tooltip: {
    title: `Grab the code`,
    txt: `Copy the code to clipboard`,
  },
  menu: [
    {
      id: `firstOpt`,
      label: `View`,
      dataTarget: '#view-devuser-modal-id',
      dataToggle: `modal`,
    },
    {
      id: `secondOpt`,
      label: `Edit`,
      dataTarget: ``,
      dataToggle: ``,
    },
    {
      id: `thirdOpt`,
      label: `Delete`,
      dataTarget: '#delete-dev-user-modal-id',
      dataToggle: `modal`,
    },
  ],
  rowClasses: {
    col1: `col-xs-12 col-sm-12 col-md-3 col-lg-3 client-date`,
    span1: `lead lead-adm bolder-ft the-name-with-the-circle date-span`,
    col2: `col-xs-12 col-sm-12 col-md-2 col-lg-2 client-company`,
    span2: `lead lead-adm`,
    col3: `col-xs-12 col-sm-12 col-md-2 col-lg-3 client-email`,
    span3: `lead lead-adm third--col--list`,
    col4: `col-xs-12 col-sm-12 col-md-3 col-lg-3 client--user`,
    span4: `lead lead-adm spec-lead-adm1`,
    colMenu: `col-xs-12 col-sm-12 col-md-2 col-lg-1 btn-vab3 cent-col-contents1`,
  },
  dataHeader: [
    {
      colClass: `col-xs-12 col-sm-12 col-md-2 col-lg-2 dev--data-header spec-style-date`,
      spanClass: `lead header-label span-styles`,
      spanClass2: `lead header-label name-span1`,
      headerDesignationCol: `Date`,
    },
    {
      colClass: `col-xs-12 col-sm-12 col-md-2 col-lg-3 dev--data-header spec-style-clt-comp`,
      spanClass: `lead header-label span-styles`,
      spanClass2: `lead header-label mb4-1`,
      headerDesignationCol: `Company`,
    },
    {
      colClass: `col-xs-12 col-sm-12 col-md-3 col-lg-3 dev--data-header spec-style-clt-email`,
      spanClass: `lead header-label span-styles`,
      spanClass2: `lead header-label mb4-1`,
      headerDesignationCol: `E-mail`,
    },
    {
      colClass: `col-xs-12 col-sm-12 col-md-3 col-lg-2 dev--data-header spec-style-clt-user`,
      spanClass: `lead header-label pl2 span-styles`,
      spanClass2: `lead header-label`,
      headerDesignationCol: `Client`,
    },
    {
      colClass: `col-xs-12 col-sm-12 col-md-2 col-lg-1 dev--data-header spec-style-clt-actions`,
      spanClass: `lead header-label pl2 span-styles span-styles-button`,
      spanClass2: `lead header-label`,
      headerDesignationCol: `Actions`,
    },
  ],
};
