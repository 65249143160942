//@ts-nocheck
import React, { useState, useEffect } from 'react';
import Autosuggest from 'react-autosuggest';
import { AutosuggestProps } from './interface';
import './styles.scss';

const AutoSuggest: React.FC<AutosuggestProps> = ({
  handleChange,
  value,
  data,
  type,
  placeholder,
  id,
  dataWasCleared,
  grabSuggestion,
}) => {
  const [suggestions, setSuggestions] = useState<any>([...data]);
  const [filteredSuggestions, setFilteredSuggestions]: any = useState([]);
  const [currentSuggestion, setCurrentSuggestion]: any = useState('');

  const renderSuggestion = (suggest: any) => {
    return <div className="suggest--element">{suggest?.label}</div>;
  };

  const onSuggestionsClearRequested = () => {
    setFilteredSuggestions([...suggestions]);
  };

  const getSuggestionValue = (suggest: any) => {
    return suggest?.label;
  };

  const onSuggestionSelected = (
    event: any,
    { suggestion, suggestionValue, suggestionIndex, sectionIndex, method }: any,
  ) => {
    handleChange({ ...suggestion });
  };

  const onChangeOption = (event: any, { newValue, method }) => {
    if (type === 'numeric') {
      const numericValue = newValue?.replace(/[^0-9]/g, '');
      setCurrentSuggestion(numericValue);

      grabSuggestion(id, numericValue);
    } else {
      setCurrentSuggestion(newValue);

      grabSuggestion(id, newValue);
    }
  };

  const onSuggestionsFetchRequested = ({ value, reason }) => {
    const inputValue = value.trim().toLowerCase();
    const inputLength = inputValue.length;

    const tempSuggestions: any = suggestions.filter((item: any) =>
      item.label.toLowerCase().includes(inputValue),
    );
    setFilteredSuggestions([...tempSuggestions]);
  };

  useEffect(() => {
    setFilteredSuggestions([...data]);
    return () => {};
  }, []);

  useEffect(() => {
    if (value) {
      setCurrentSuggestion(value);
    }
  }, [value]);

  useEffect(() => {
    setSuggestions([...data]);
  }, [data]);

  useEffect(() => {
    if (dataWasCleared) {
      setCurrentSuggestion('');
    }
  }, [dataWasCleared]);

  return (
    <div className={`col auto-sugg-adm`}>
      <div className="location-input">
        <Autosuggest
          id={id}
          suggestions={filteredSuggestions}
          onSuggestionsFetchRequested={onSuggestionsFetchRequested}
          onSuggestionSelected={onSuggestionSelected}
          onSuggestionsClearRequested={onSuggestionsClearRequested}
          getSuggestionValue={getSuggestionValue}
          renderSuggestion={renderSuggestion}
          inputProps={{
            placeholder,
            value: currentSuggestion || '',
            onChange: onChangeOption,
          }}
        />
      </div>
    </div>
  );
};

export default AutoSuggest;
