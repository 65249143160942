export default {
  strings: {
    title: 'System Settings',
    confirm: 'Save settings',
    warning: 'Values not saved in G3 Controller successfully, please save values again or contact support if the issue continues to occur.',
  },
  defaults: {
    g3CoreLabel: 'g3CoreSettings',
    ventConnectProps: ['vcfr', 'exct', 'vcdt', 'mvcr', 'vcwm',],
    ventConnectFlags: ['vcfr', 'exct', 'vcdt', 'mvcr', 'vcwm', 'cdst', 'frte', 'cail', 'slst', 'cdss'],
    dependentProps: {
      hrsv: {
        prop: 'hroa',
        value: 1
      },
      hstv: {
        prop: 'hroa',
        value: 1
      },
    },
    ventSetupTags: {
      calcFlow: ['fhfr', 'fcfr', 'fffr', 'carq', 'vcfr', 'effr', 'mixt', 'hroa', 'hrsv', 'hstv', 'tmzn', 'efdt'],
      calcTime: ['mnsf', 'mner', 'mixt', 'hroa', 'hrsv', 'hstv', 'tmzn', 'efdt'],
      balanceMode: ['mnsf', 'mner', 'efdt', 'mixt', 'hroa', 'hrsv', 'hstv', 'tmzn'],
    },
    menuOptions: [
      { id: 'save-config', text: 'Save As Configuration', _function: 'saveAsConfiguration' },
    ],
  },
  alerts: {
    update: {
      question: 'Are you sure you want to update System Settings?',
      btn: {
        confirm: 'Yes, update it!',
      },
    },
    notifications: {
      update: {
        success: 'Modification done successfully',
      },
    },
  },
  notifications: {
    update: {
      success: 'System updated successfully!',
      error: 'System can not be updated, Please try again!',
    },
    noConnDevs: {
      alert: 'Atention: Control Target is set to Exhaust but there are any "VentConnect" connected, please check connections.'
    },
  },
  fieldsState: {
    device_name: null,
    fhfr: 0,
    fcfr: 0,
    fffr: 0,
    hroa: 0,
    carq: 0,
    effr: 0,
    primary_control: '',
    add_sensor: '',
    add_sensor_id: '',
    ventilation_setup: 'Calculated Flow',
    mnsf: 0,
    mner: 0,
    mixt: 0,
    tmzn: 0,
    efdt: 0,
    exct: 0,
    hrsv: 0,
    hstv: 0,
    exwm: 0,
    vcfr: 0,
    vcdt: 0,
    mvcr: 0,
    vcwm: 0,
  },
  fieldsFocus: {},
  fields: {
    device_name: {
      label: 'System Name',
      type: 'text',
      placeholder: 'System Name',
    },
    fhfr: {
      label: 'Flow measured during heat call',
      type: 'number',
      placeholder: 'Flow measured during heat call',
      units: 'CFM',
      margings: {
        max: 250,
        min: 0,
      },
    },
    exct: {
      label: 'Balanced Mode',
      type: 'number',
      placeholder: 'Balanced Mode',
    },
    hours_operation: {
      label: 'Hours of Operation',
      type: 'number',
      placeholder: 'Hours of Operation',
    },
    fcfr: {
      label: 'Flow measured during cooling call',
      type: 'number',
      placeholder: 'Flow measured during cooling call',
      units: 'CFM',
      margings: {
        max: 250,
        min: 0,
      },
    },
    fffr: {
      label: 'Flow measured during fan-only call',
      type: 'number',
      placeholder: 'Flow measured during fan-only call',
      units: 'CFM',
      margings: {
        max: 250,
        min: 0,
      },
    },
    effr: {
      label: 'Exhaust Fan Flow Rate',
      type: 'number',
      placeholder: 'Exhaust Fan Flow Rate',
      units: 'CFM',
      margings: {
        max: 250,
        min: 0,
      },
    },
    carq: {
      label: 'Required continuous air flow',
      type: 'number',
      placeholder: 'Required continuous air flow',
      units: 'CFM',
      margings: {
        max: 250,
        min: 0,
      },
    },
    vcfr: {
      label: 'Exhaust Fan Flow Rate',
      type: 'number',
      placeholder: 'Exhaust Fan Flow Rate',
      units: 'CFM',
      margings: {
        max: 250,
        min: 0,
      },
    },
    vcdt: {
      label: 'Exhaust Fan Delay Time',
      type: 'number',
      placeholder: 'Exhaust Fan Delay Time',
      units: 'Min/Hr',
      margings: {
        max: 60,
        min: 0,
      },
    },
    mvcr: {
      label: 'Minutes per Hour Exhaust Run',
      type: 'number',
      placeholder: 'Minutes per Hour Exhaust Run',
      units: 'Min/Hr',
      margings: {
        max: 60,
        min: 0,
      },
    },
    vcwm: {
      label: 'Exhaust Worker Mode',
      type: 'number',
      placeholder: 'Exhaust Worker Mode',
    },
    exwm: {
      label: 'Exhaust Worker Mode',
      type: 'number',
      placeholder: 'Exhaust Worker Mode',
    },
    mnsf: {
      label: 'Central Fan Run Time',
      type: 'number',
      placeholder: 'Central Fan Run Time',
      units: 'Min/Hr',
      margings: {
        max: 60,
        min: 0,
      },
    },
    mner: {
      label: 'Minutes per Hour Exhaust Run',
      type: 'number',
      placeholder: 'Minutes per Hour Exhaust Run',
      units: 'Min/Hr',
      margings: {
        max: 60,
        min: 0,
      },
    },
    mixt: {
      label: 'Additional mix time',
      type: 'number',
      placeholder: 'Additional mix time',
      units: 'Min/Hr',
      margings: {
        max: 60,
        min: 0,
      },
    },
    tmzn: {
      label: 'Current time',
      type: 'text',
      placeholder: 'Current time',
    },
    efdt: {
      label: 'Exhaust Fan Delay Time',
      type: 'number',
      placeholder: 'Exhaust fan delay time',
      units: 'Min/Hr',
      margings: {
        max: 60,
        min: 0,
      },
    },
    hrsv: {
      label: 'Hour start',
      type: 'number',
      margings: {
        max: 23,
        min: 0,
      },
    },
    hstv: {
      label: 'Hour stop',
      type: 'number',
      margings: {
        max: 23,
        min: 0,
      },
    },
    ventilation_setup: {
      label: 'Ventilation Mode ',
      type: 'text',
      placeholder: 'Ventilation Mode',
    },
    primary_control: {
      label: 'Primary Exhaust Control ',
      type: 'text',
      placeholder: 'Primary Control',
    },
    add_sensor: {
      label: 'Add Sensor ',
      type: 'text',
      placeholder: 'Add Sensor',
    },
  },
  constraints: {
    device_name: {
      presence: true,
      length: {
        minimum: 6,
      },
    },
    fhfr: {
      numericality: {
        lessThanOrEqualTo: 250,
        message: "^'%{value}' is not allowed, the value cannot exceed 250 CFM",
      },
    },
    fcfr: {
      numericality: {
        lessThanOrEqualTo: 250,
        message: "^'%{value}' is not allowed, the value cannot exceed 250 CFM",
      },
    },
    fffr: {
      numericality: {
        lessThanOrEqualTo: 250,
        message: "^'%{value}' is not allowed, the value cannot exceed 250 CFM",
      },
    },
    effr: {
      numericality: {
        lessThanOrEqualTo: 250,
        message: "^'%{value}' is not allowed, the value cannot exceed 250 CFM",
      },
    },
    carq: {
      numericality: {
        lessThanOrEqualTo: 250,
        message: "^'%{value}' is not allowed, the value cannot exceed 250 CFM",
      },
    },
    mixt: {
      numericality: {
        lessThanOrEqualTo: 60,
        message:
          "^'%{value}' is not allowed, the value cannot exceed 60 minutes",
      },
    },
    efdt: {
      numericality: {
        lessThanOrEqualTo: 60,
        message:
          "^'%{value}' is not allowed, the value cannot exceed 60 minutes",
      },
    },
    mner: {
      numericality: {
        lessThanOrEqualTo: 60,
        message:
          "^'%{value}' is not allowed, the value cannot exceed 60 minutes",
      },
    },
    hrsv: {
      numericality: {
        lessThanOrEqualTo: 23,
        message: "^'%{value}' is not allowed, the value cannot exceed 23 hours",
      },
    },
    hstv: {
      numericality: {
        lessThanOrEqualTo: 23,
        message: "^'%{value}' is not allowed, the value cannot exceed 23 hours",
      },
    },
    vcfr: {
      numericality: {
        lessThanOrEqualTo: 250,
        message: "^'%{value}' is not allowed, the value cannot exceed 250 CFM",
      },
    },
    vcdt: {
      numericality: {
        lessThanOrEqualTo: 60,
        message: "^'%{value}' is not allowed, the value cannot exceed 60 Min",
      },
    },
    mvcr: {
      numericality: {
        lessThanOrEqualTo: 60,
        message: "^'%{value}' is not allowed, the value cannot exceed 60 Min",
      },
    },
    mnsf: {
      numericality: {
        lessThanOrEqualTo: 60,
        message: "^'%{value}' is not allowed, the value cannot exceed 60 Min",
      },
    },
  },
  timezone: [
    {
      label: 'Newfoundland Time w/ Daylight Savings Time',
      timezone: 'Canada/Newfoundland',
    },
    {
      label: 'Atlantic Time w/ Daylight Savings Time',
      timezone: 'Canada/Atlantic',
    },
    {
      label: 'Eastern Standard w/ Daylight Savings Time',
      timezone: 'Canada/Eastern',
    },
    {
      label: 'Central Standard w/ Daylight Savings Time',
      timezone: 'US/Central',
    },
    {
      label: 'Mountain Daylight Time',
      timezone: 'US/Mountain',
    },
    {
      label: 'Mountain Standard Time',
      timezone: 'MST',
    },
    {
      label: 'Pacific Standard w/ Daylight Savings Time',
      timezone: 'US/Pacific',
    },
    {
      label: 'Alaska Standard w/ Daylight Savings Time',
      timezone: 'US/Alaska',
    },
    {
      label: 'Hawaii Standard w/ Daylight Savings Time',
      timezone: 'US/Hawaii',
    },
  ],
  ventilationSelect: [
    { name: 'Calculated Flow' },
    { name: 'Calculated Time' },
    { name: 'Balanced Mode' },
  ],
};
