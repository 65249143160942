import React from 'react';
import { HiOutlineIdentification } from 'react-icons/hi';
import { MdOutlineGeneratingTokens } from 'react-icons/md';
import { IoCalendarNumber } from 'react-icons/io5';
import { IoCalendarNumberOutline } from 'react-icons/io5';
import { FaRegUserCircle } from 'react-icons/fa';
import { GrStatusGood } from 'react-icons/gr';
import { HiIdentification } from 'react-icons/hi';
import { MdAlternateEmail } from 'react-icons/md';
import { BsBriefcase } from 'react-icons/bs';
import { IoMdPerson } from 'react-icons/io';
import { AiOutlineNumber } from 'react-icons/ai';
import { FaSignature } from 'react-icons/fa';
import { TbSignature, TbNumbers } from 'react-icons/tb';
import { MdHome } from 'react-icons/md';
import { GiUsaFlag } from 'react-icons/gi';
import { MdLocationCity } from 'react-icons/md';
import { FaPhoneAlt } from 'react-icons/fa';

export const keys: string[] = [
  'status',
  'createdAt',
  'updatedAt',
  '_id',
  'client_id',
  'email',
  'company',
  'details',
  'client_user',
  'client_secret',
  '__v',
  'first_name',
  'last_name',
  'address',
  'phone',
  'state',
  'city',
  'zip_code',
];

export const icons: any = {
  client_id: <HiOutlineIdentification style={{ marginRight: '0.2rem' }} />,
  client_secret: (
    <MdOutlineGeneratingTokens style={{ marginRight: '0.2rem' }} />
  ),
  createdAt: <IoCalendarNumber style={{ marginRight: '0.2rem' }} />,
  updatedAt: <IoCalendarNumberOutline style={{ marginRight: '0.2rem' }} />,
  client_user: <FaRegUserCircle style={{ marginRight: '0.2rem' }} />,
  status: <GrStatusGood style={{ marginRight: '0.2rem' }} />,
  _id: <HiIdentification style={{ marginRight: '0.2rem' }} />,
  email: <MdAlternateEmail style={{ marginRight: '0.2rem' }} />,
  company: <BsBriefcase style={{ marginRight: '0.2rem' }} />,
  details: <IoMdPerson style={{ marginRight: '0.2rem' }} />,
  __v: <AiOutlineNumber style={{ marginRight: '0.2rem' }} />,
  first_name: <FaSignature style={{ marginRight: '0.2rem' }} />,
  last_name: <TbSignature style={{ marginRight: '0.2rem' }} />,
  address: <MdHome style={{ marginRight: '0.2rem' }} />,
  state: <GiUsaFlag style={{ marginRight: '0.2rem' }} />,
  city: <MdLocationCity style={{ marginRight: '0.2rem' }} />,
  zip_code: <TbNumbers style={{ marginRight: '0.2rem' }} />,
  phone: <FaPhoneAlt style={{ marginRight: '0.2rem' }} />,
};
