export default {
  strings: {
    add: {
      title: `Add installer`,
      breadcrumbTitle: `Add installer`,
      mainTitle: `Installer details`,
      pText: `Add the installer information.`,
      dataTarget: `#save-changes-to-new-installer`,
    },
    warning: `Please make sure all the required fields are filled to proceed!`,
  },
  emailRegex: /^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/,
  zipRegex: '[0-9][0-9\\- ]{0,10}[0-9]$',
  errorMsg: {
    name: 'Name should not be blank.',
    email: 'Invalid e-mail.',
    phone: 'Invalid phone number.',
    state: 'State is too short.',
    zip: 'Invalid zipcode.',
    city: 'City is too short',
  },
  fields: [
    {
      outerClass: 'form-group',
      label: 'Name',
      htmlFor: 'exampleInputEmail1',
      type: 'text',
      id: 'installer-name1',
      ariaDescribedby: 'inputHelp',
      placeholder: 'Enter name',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'name',
      disabled: '',
    },
    {
      outerClass: 'form-group',
      label: 'E-mail address',
      htmlFor: 'exampleInputEmail1',
      type: 'email',
      id: 'installer-email1',
      ariaDescribedby: 'emailHelp',
      placeholder: 'Enter e-mail address',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'email',
      disabled: '',
    },
    {
      outerClass: 'form-group',
      label: 'Phone number',
      htmlFor: 'exampleInputPassword1',
      type: 'text',
      id: 'telephone-input-spec1',
      ariaDescribedby: ' ',
      placeholder: 'Installer phone number',
      className: 'form-control',
      labelClass: 'lead label-create-user',
      name: 'phone',
      disabled: '',
    },
  ],
  formConfig: [
    {
      label: 'State',
      className: 'col-xs-12 col-sm-12 cont--1--p auto-sug-field1',
      placeholder: 'State',
      id: 'state',
      type: 'auto-suggest',
      field: 'installer_state',
      fields: {
        label: 'Installer Location - State',
        type: 'dropdown',
        title: 'Enter State',
        placeholder: 'Enter company state',
      },
    },
    {
      label: 'City',
      className: 'col-xs-12 col-sm-12 cont--1--p auto-sug-field1',
      placeholder: 'City',
      id: 'city',
      type: 'auto-suggest',
      field: 'installer_city',
      fields: {
        label: 'Installer Location - City',
        type: 'dropdown',
        title: 'Enter City',
        placeholder: 'Enter company city',
      },
    },
    {
      label: 'Zip Code',
      className: 'col-xs-12 col-sm-12 cont--1--p auto-sug-field1',
      placeholder: 'Zip Code',
      id: 'zip_code',
      type: 'auto-suggest',
      field: 'installer_zipcode',
      fields: {
        label: 'Installer Location - Zip Code',
        type: 'dropdown',
        title: 'Enter zip code',
        placeholder: 'Enter company zip code',
      },
    },
  ],
  states: {
    formValues: {
      installer_city: {},
      installer_state: {},
      installer_zipcode: {},
    },
  },
  defaults: {
    state: {
      name: 'Massachusetts',
      isoCode: 'MA',
      countryCode: 'US',
      latitude: '42.40721070',
      longitude: '-71.38243740',
      label: 'Massachusetts',
      value: 'MA',
    },
    city: {
      name: 'Boston',
      countryCode: 'US',
      stateCode: 'MA',
      latitude: '42.35843000',
      longitude: '-71.05977000',
      label: 'Boston',
      value: 'Boston',
    },
  },
  constraints: {
    name: {
      presence: true,
      length: {
        minimum: 1,
      },
    },
    email: {
      email: true,
      presence: true,
    },
    state: {
      presence: true,
      length: {
        minimum: 2,
      },
    },
    city: {
      presence: true,
      length: {
        minimum: 2,
      },
    },
    zip: {
      presence: true,
      length: {
        minimum: 5,
        maximum: 10,
      },
      format: {
        pattern: '[0-9][0-9\\- ]{0,10}[0-9]$',
        message: 'is invalid',
      },
    },
    phone: {
      presence: true,
      length: {
        minimum: 11,
        maximum: 11,
      },
    },
  },
  modalStrings: {
    edit: `Save installer`,
    confirm: ``,
    warning: `Information`,
    alertStr: ``,
    alertStr2: ``,
    confirmation: ``,
    sureToProceed: `Are you sure you wish to proceed?`,
    noUndo: ``,
    aboutToUpdate: `You are about to save this new installer.`,
    labelOK: ` OK`,
  },
};
