import config from './config';

const handleOpts = (action: string, section: string) => {
  if (section === `title`) {
    if (config.titles.hasOwnProperty(action)) {
      return config.titles[action as keyof typeof config.titles];
    }
  }
  if (section === `icon`) {
    if (config.icons.hasOwnProperty(action)) {
      return config.icons[action as keyof typeof config.icons];
    }
  }
  if (section === `bodies`) {
    if (config.bodies.hasOwnProperty(action)) {
      return config.bodies[action as keyof typeof config.bodies];
    }
  }
  if (section === `bodyClass`) {
    if (config.bodyClass.hasOwnProperty(action)) {
      return config.bodyClass[action as keyof typeof config.bodyClass];
    }
  }
};

const preventEmptyData = (cltData: any) => {
  let emptyFields: any = [];

  if (cltData) {
    Object.entries<any>(cltData).map(([key, val], i) => {
      if (
        (key === 'company' ||
          key === 'email' ||
          key === 'first_name' ||
          key === 'last_name' ||
          key === 'phone' ||
          key === 'address') &&
        typeof val === 'string' &&
        val.trim().length === 0
      ) {
        emptyFields.push(key);
      } else if (key !== 'code' && typeof val !== 'string') {
        //WIP
      }
    });

    if (emptyFields.length > 0) {
      return true;
    } else {
      return false;
    }
  }
};

const areFieldsEmpty = (formValues: any) => {
  if (
    Object.values(formValues?.client_state).length === 0 ||
    Object.values(formValues?.client_city).length === 0 ||
    Object.values(formValues?.client_zipcode).length === 0
  ) {
    return true;
  } else {
    return false;
  }
};

const formatZipCodes = (zipCodes: any) => {
  return zipCodes.map((zpCode: any) => {
    const { zip } = zpCode;
    return {
      ...zpCode,
      value: zip,
      label: zip,
    };
  });
};

const formatLocations = (data: any) => {
  return data.map((item: any) => {
    const { name, isoCode } = item;
    return {
      ...item,
      label: name,
      value: isoCode ? isoCode : name,
    };
  });
};

export const functions = {
  handleOpts: handleOpts,
  preventEmptyData: preventEmptyData,
  areFieldsEmpty: areFieldsEmpty,
  formatZipCodes: formatZipCodes,
  formatLocations: formatLocations,
};
