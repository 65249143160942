import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
  Row,
  Col,
  Modal,
  Form,
} from 'react-bootstrap';
import SweetAlert from 'react-bootstrap-sweetalert';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import {
  NativeSelect,
  Select,
  MenuItem,
  InputLabel,
} from '@material-ui/core';
import validate from 'validate.js';
import _ from 'lodash';
import { CiWarning } from 'react-icons/ci';
import { UserTemplate } from 'business/modules/common';
import { Button, Notification, Input } from 'react-components';
import { IconsHandler } from 'core/utils';
import {
  ActionsMenu,
  CalculatedFlow,
  CalculatedTime,
  BalancedMode,
  EditDevice,
} from './components';
import {
  DashboardProps,
  LoadingProps,
  Cdvs,
  DeviceData,
} from './helpers/interfaces';
import {
  filterSensorName,
  disableLocalSensorOption,
  disableLocalSensor,
  mapConnectedLabel,
  mapVentSetupMode,
  mapIconClassName,
  filterConnDeviceName,
  preparePayload,
} from './helpers';
import config from './config';
import './style.scss';



const VENTILATIONSELECT = [...config.ventilationSelect];
const VENT_CONNECT_PROPS: any = [...config.defaults.ventConnectProps];
const VENT_CONNECT_FLAGS: any = [...config.defaults.ventConnectFlags];
const MENU_DATA: any = [...config.defaults.menuOptions];
const DEP_PROPS: any = { ...config.defaults.dependentProps };
const DEFAULT_TIMEZONE: any = [...config.timezone];
const WIFI_CONN_DIFF: number = 30; // in seconds
const REQUEST_LOGS_INTERVAL: number = 60 * 1000 * 1;


const DeviceSettings: React.FC<DashboardProps> = ({
  getDevices,
  editSystemSettings,
  updateDevice,
  editDeviceAttributes,
  getAllSystemSettings,
  systemSettings,
  match,
  ...props
}) => {
  const history = useHistory();
  const [settingsDevice, setSettingsDevice]: any = useState<DeviceData>();
  // const [storedSettings, setStoredSettings]: any = useState(null);   // in standby
  const [settingsMatch, setSettingsMatch]: any = useState(true);
  const [allDevices, setAllDevices]: any = useState<DeviceData[]>();
  const [settings, setSettings]: any = useState<any>();
  const [sensorDevices, setSensorDevices]: any = useState<DeviceData[]>();
  const [canStartSettings, setCanStartSettings]: any = useState(false);
  const [cdvs, setCdvs]: any = useState<Cdvs[]>([]);
  const [option, setOption]: any = useState(0);
  const [fieldsState, setFieldsState]: any = useState({
    ...config?.fieldsState,
  });
  const [errors, setErrors]: any = useState({});
  const [showAlert, setShowAlert]: any = useState({
    alert: false,
    editDevice: {},
  });
  const [startUpdated, setStartUpdated]: any = useState(false);
  const [userNotified, setUserNotified]: any = useState(false);
  const [showSensorWarn, setShowSensorWarn]: any = useState(false);
  const [openMenu, setOpenMenu]: any = useState(false);
  const [showConfigModal, setShowConfigModal]: any = useState(false);
  const [configName, setConfigName]: any = useState('');
  let errorsLet: any = {};



  const handleEdit = () => {
    props.setCurrentDevice({ ...settingsDevice });
    history.push(`/dashboard/devices/edit?id=${settingsDevice?.device_id}`);
  };


  const onChangeState = (name: string, value: any) => {
    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        [name]: value,
      };
    });
    const constraints: any = config.constraints;
    validateInput({ [name]: value }, { [name]: constraints[name] });
  };

  function validateInput(fieldsStateValue: any, constraint = {}) {
    let vlResult: any = validate(fieldsStateValue, constraint);
    if (vlResult) {
      errorsLet = { ...(errorsLet || {}), ...vlResult };
      setErrors(errorsLet);
      return;
    } else {
      const [key] = Object.keys(fieldsStateValue);
      if (errorsLet[key]) {
        errorsLet[key] && delete errorsLet[key];
        setErrors(errorsLet);
      }
    }
  }


  const DeviceIcon = ({ type, ...other }: any) => {
    const Icon: any =
      type && type !== null && type !== undefined ? (
        IconsHandler.deviceIconByType(type)
      ) : (
        <></>
      );

    return <Icon className={mapIconClassName(type)} />;
  };


  const customHandleChange = (prop: string, event: any, newValue: any) => {
    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        [prop]: newValue,
      };
    });
  };

  const handleSelectVentilation = (event: any) => {
    let selectedVentilation = event?.target?.value;
    if (!selectedVentilation) return;
    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        ventilation_setup: selectedVentilation,
      };
    });
  };

  const handlePrimaryControl = (event: any) => {
    let selectedDevice = event?.target?.value;

    if (settings && settings[selectedDevice]) {
      setFieldsState((prevState: any) => {
        return {
          ...prevState,
          fhfr:
            fieldsState && fieldsState.fhfr
              ? fieldsState.fhfr
              : settings[selectedDevice]?.fhfr || 0,
          fcfr:
            fieldsState && fieldsState.fcfr
              ? fieldsState.fcfr
              : settings[selectedDevice]?.fcfr || 0,
          fffr:
            fieldsState && fieldsState.fffr
              ? fieldsState.fffr
              : settings[selectedDevice]?.fffr || 0,
          carq:
            fieldsState && fieldsState.carq
              ? fieldsState.carq
              : settings[selectedDevice]?.carq || 0,
          effr:
            fieldsState && fieldsState.effr
              ? fieldsState.effr
              : settings[selectedDevice]?.effr || 0,
          mner:
            fieldsState && fieldsState.mner
              ? fieldsState.mner
              : settings[selectedDevice]?.mner || 0,
          mixt:
            fieldsState && fieldsState.mixt
              ? fieldsState.mixt
              : settings[selectedDevice]?.mixt || 0,
          efdt:
            fieldsState && fieldsState.efdt
              ? fieldsState.efdt
              : settings[selectedDevice]?.efdt || 0,
          tmzn:
            fieldsState && fieldsState.tmzn
              ? fieldsState.tmzn
              : settings[selectedDevice]?.tmzn
              ? settings[selectedDevice]?.tmzn
              : 0,
          hrsv:
            fieldsState && fieldsState.hrsv
              ? fieldsState.hrsv
              : settings[selectedDevice]?.hrsv || 0,
          hstv:
            fieldsState && fieldsState.hstv
              ? fieldsState.hstv
              : settings[selectedDevice]?.hstv || 0,
          hroa:
            fieldsState && fieldsState.hroa
              ? fieldsState.hroa
              : settings[selectedDevice]?.hroa
              ? settings[selectedDevice].hroa
              : 0,
          exct:
            fieldsState && fieldsState.exct
              ? fieldsState.exct
              : settings[selectedDevice]?.exct || 0,
          vcfr:
            fieldsState && fieldsState.vcfr
              ? fieldsState.vcfr
              : settings[selectedDevice]?.vcfr || 0,
          vcdt:
            fieldsState && fieldsState.vcdt
              ? fieldsState.vcdt
              : settings[selectedDevice]?.vcdt || 0,
          mvcr:
            fieldsState && fieldsState.mvcr
              ? fieldsState.mvcr
              : settings[selectedDevice]?.mvcr || 0,
          vcwm:
            fieldsState && fieldsState.vcwm
              ? fieldsState.vcwm
              : settings[selectedDevice]?.vcwm || 0,
          exwm:
            fieldsState && fieldsState.exwm
              ? fieldsState.exwm
              : settings[selectedDevice]?.exwm || 0,
          primary_control:
            fieldsState && fieldsState.primary_control
              ? fieldsState.primary_control
              : settings[selectedDevice]?.primary_control,
          add_sensor:
            fieldsState && fieldsState.add_sensor
              ? fieldsState.add_sensor
              : settings[selectedDevice]?.add_sensor,
          add_sensor_id:
            fieldsState && fieldsState.add_sensor_id
              ? fieldsState.add_sensor_id
              : settings[selectedDevice]?.add_sensor_id,
        };
      });
    } else {
      setFieldsState((prevState: any) => {
        return {
          ...prevState,
          fhfr: 0,
          fcfr: 0,
          fffr: 0,
          carq: 0,
          effr: 0,
          mner: 0,
          mixt: 0,
          efdt: 0,
          tmzn: 0,
          hrsv: 0,
          hstv: 0,
          hroa: 0,
          exct: 0,
          vcfr: 0,
          vcdt: 0,
          mvcr: 0,
          vcwm: 0,
          exwm: 0,
          primary_control: '',
          add_sensor: '',
          add_sensor_id: '',
        };
      });
    }

    if (!selectedDevice) return;
    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        primary_control: selectedDevice,
      };
    });
  };

  const handleAddSensor = (event: any, child: any) => {
    let selectedSensor: any = event?.target?.value;
    let selectedId: any = child?.props?.id;
    let selectedName: any =
      child?.props?.children && child?.props?.children[1]
        ? child?.props?.children[1]
        : child?.key?.split('$').pop();

    if (!selectedSensor || !selectedId || !selectedName) return;

    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        add_sensor: selectedName,
        add_sensor_id: selectedId,
      };
    });
  };


  const handleSetTimezone = (event: any) => {
    let selectedTimezone = event?.target?.value;

    if (!selectedTimezone) return;

    const index: any = DEFAULT_TIMEZONE.findIndex(
      (_timezone: any) => _timezone.label === selectedTimezone,
    );

    setFieldsState((prevState: any) => {
      return {
        ...prevState,
        tmzn: index,
      };
    });
  };


  const handleSystemSettingsCompare = async (
    deviceId: string,
    currSettings: any,
    ventMode?: any,
    allSensors?: any,
  ) => {
    let result: any = await props.getAllSystemSettingsNoMerge();

    if (result && result.length > 0) {
      const devSettings: any = result.find(
        (dSet: any) => dSet.system_id === deviceId,
      );

      let currDevSettings: any =
        devSettings?.settings && Object.values(devSettings?.settings).length > 0
          ? Object.values(devSettings?.settings)[0]
          : null;

      if (currSettings && currDevSettings) {
        const currentMode: string = ventMode
          ? ventMode
          : currDevSettings?.vent_set
          ? currDevSettings.vent_set
          : 'Calculated Flow';
        const currentModeTags: any = mapVentSetupMode(currentMode);
        // setStoredSettings({...devSettings.settings});
        let hasDiffs: boolean = false;
        Object.keys(currDevSettings).forEach((key: string) => {
          if (currDevSettings.hasOwnProperty(key)) {
            if (!key.includes('_')) {
              if (DEP_PROPS[key]) {
                if (
                  DEP_PROPS[key].value == currSettings[key][DEP_PROPS[key].prop]
                ) {
                  if (currDevSettings[key] != currSettings[key]) {
                    hasDiffs = true;
                  }
                }
              } else {
                if (
                  currentModeTags.findIndex((prop: string) => prop === key) >
                    -1 &&
                  currDevSettings[key] != currSettings[key]
                ) {
                  hasDiffs = true;
                }
              }
            } else {
              if (
                key === 'add_sensor' &&
                currDevSettings[key] &&
                !currDevSettings.hasOwnProperty('add_sensor_id')
              ) {
                const matchSensor: any =
                  allSensors && allSensors.length > 0
                    ? allSensors.find(
                        (devSensor: any) =>
                          devSensor.device_name === currDevSettings[key],
                      )
                    : null;

                setFieldsState((prevState: any) => {
                  if (matchSensor) {
                    const { device_id } = matchSensor;
                    return {
                      ...prevState,
                      add_sensor: currDevSettings[key],
                      add_sensor_id: device_id,
                    };
                  }
                  return {
                    ...prevState,
                    add_sensor: currDevSettings[key],
                  };
                });
              }

              if (
                key === 'add_sensor_id' &&
                currDevSettings.hasOwnProperty(key)
              ) {
                const foundedSensor: any =
                  allSensors && allSensors.length > 0
                    ? allSensors.find(
                        (devSensor: any) =>
                          devSensor.device_id === currDevSettings[key],
                      )
                    : null;

                if (foundedSensor) {
                  setFieldsState((prevState: any) => {
                    return {
                      ...prevState,
                      add_sensor_id: currDevSettings[key],
                      add_sensor: currDevSettings['add_sensor'],
                    };
                  });
                }
              }
            }
          }
        });
        setSettingsMatch(!hasDiffs);
      }
    }
  };

  const pickControlTarget = (details: any, connDevs: any) => {
    const { conf }: any = details?.settings || {};
    let controlTarget: any;

    if (conf) {
      const { exct }: any = conf || {};

      switch (exct) {
        case 1:
          controlTarget =
            connDevs && connDevs.length > 0
              ? connDevs.find((dev: any) => dev.cdid === 'fanConnect')
              : null;
          break;
        case 2:
          const { cdvs }: any = conf || {};
          const tDev: any = cdvs.find((dev: any) => dev.vcct === 1);

          if (tDev) {
            const { cdid } = tDev;
            controlTarget =
              connDevs && connDevs.length > 0
                ? connDevs.find((dev: any) => dev.cdid === cdid)
                : null;
          } else {
            if (!userNotified) {
              Notification({
                title: 'Alert!',
                message: config?.notifications?.noConnDevs?.alert,
                type: 'warn',
              });
              setUserNotified(true);
            }
          }
          break;
        default:
          break;
      }
    }

    return controlTarget;
  };


  const filterCdvs = (details: any) => {
    const { fcsp }: any = details?.settings?.conf || {};

    let filteredDevicesTmp: any = details?.settings?.conf?.cdvs || [];

    if (filteredDevicesTmp.length > 0) {
      // filter cdvs from local aircycler sensors
      filteredDevicesTmp = filteredDevicesTmp.filter((item: any) => {
        const { cdid }: any = item || {};

        return (
          cdid !== '' &&
          !cdid.includes('AirCyclerAQ') &&
          !cdid.includes('AirCyclerFT') &&
          !cdid.includes('AirCyclerFH')
        );
      });

      filteredDevicesTmp.forEach((device: any, index: number) => {
        const { cdid } = device || {};
        device.vent = mapConnectedLabel(cdid);
      });
    }

    // checking for "FanConnect"
    if (fcsp) {
      let fanConnect: Cdvs = {
        cail: 'FanConnect',
        cdid: 'fanConnect',
        dmac: '',
        vent: 'FanConnect',
      };
      filteredDevicesTmp.push(fanConnect);
    }

    const controlTarget: any = pickControlTarget(details, filteredDevicesTmp);

    if (controlTarget) {
      setFieldsState((prevState: any) => {
        return {
          ...prevState,
          primary_control: controlTarget?.cdid,
        };
      });
    } else if (filteredDevicesTmp[0]?.cdid) {
      setFieldsState((prevState: any) => {
        return {
          ...prevState,
          primary_control: filteredDevicesTmp[0]?.cdid,
        };
      });
    }

    setCdvs(filteredDevicesTmp);
  };



  const handleAlertValidation = async (editDevice: any) => {
    let vlResult: any = validate(fieldsState, config.constraints);

    if (vlResult) {
      setErrors(vlResult);
      let msm: string = '';
      Object.keys(vlResult).forEach((key: string) => {
        msm += vlResult[key].map((err: any) => `${err}, `);
      });
      msm = msm.substring(0, msm.length - 2);
      Notification({
        title: 'Error',
        message: msm,
        type: 'error',
      });
    } else {
      setShowAlert({ alert: true, editDevice });
    }
  };

  const formatValues = () => {
    let fieldsStateTemp = {};
    if (cdvs.length > 0) {
      if (fieldsState.primary_control === 'fanConnect') {
        fieldsStateTemp = {
          effr: fieldsState.effr,
          efdt: fieldsState.efdt,
          mner: fieldsState.mner,
          exwm: fieldsState.exwm,
        };
      } else {
        fieldsStateTemp = {
          vcfr: fieldsState.vcfr,
          vcdt: fieldsState.vcdt,
          mvcr: fieldsState.mvcr,
          vcwm: fieldsState.vcwm,
        };
      }
    }
    return fieldsStateTemp;
  };


  const handleSaveSettings = async (editDevice: any) => {
    setShowAlert({ alert: false, editDevice: {} });
    props.loading.start('Loading...');

    const valuesFormated = formatValues();
    const systemSettings = {
      system_id: settingsDevice?.device_id,
      settings: {
        [filterConnDeviceName(fieldsState)]: {
          fhfr: fieldsState.fhfr,
          fcfr: fieldsState.fcfr,
          fffr: fieldsState.fffr,
          hroa: fieldsState.hroa,
          carq: fieldsState.carq,
          add_sensor: fieldsState.add_sensor,
          add_sensor_id: fieldsState?.add_sensor_id,
          mnsf: fieldsState.mnsf,
          vent_set: fieldsState.ventilation_setup,
          mixt: fieldsState.mixt,
          tmzn: fieldsState.tmzn,
          hrsv: fieldsState.hrsv,
          hstv: fieldsState.hstv,
          exct: fieldsState.exct,
          ...valuesFormated,
        },
      },
    };

    const respSystemSettings = await editSystemSettings(systemSettings);
    setSettings(respSystemSettings?.body?.settings);

    let respDevice;
    try {
      respDevice = await editDeviceAttributes(editDevice);
    } catch (error) {
      console.log(error);
    }
    props.loading.stop();

    if (
      respSystemSettings?.statusCode === 200 &&
      respDevice?.statusCode === 200
    ) {
      Notification({
        title: 'Successfully',
        message: config?.notifications?.update?.success,
        type: 'success',
      });
      setStartUpdated(false);
      setSettingsDevice((prevState: any) => {
        return {
          ...prevState,
          device_name: fieldsState?.device_name,
        };
      });
    } else {
      Notification({
        title: 'Alert!',
        message: config?.notifications?.update?.error,
        type: 'warn',
      });
    }
  };



  const saveAsConfiguration = (_event: any) => {
    setShowConfigModal(true);
  };

  const handleToSaveAsConfiguration = async () => {
    handleCloseModal();
    props.loading.start('Saving Configuration...');
    let fieldsCopy: any = _.cloneDeep(fieldsState);
    fieldsCopy.config_name = configName;

    const payload: any = preparePayload(
      fieldsCopy,
      {}, // outside data,
      {},  // indoor data,
    );
    let result: any = await props.createConfig(payload);

    setConfigName('');

    setTimeout(() => {
      props.loading.stop();
    }, 1000);
  };

  const handleCloseModal = () => {
    setShowConfigModal(false);
  };










  useEffect(() => {
    if (props?.location) {
      const { state } = props.location;
      if (state) {
        const { sensorAlert } = state;

        if (sensorAlert) {
          setShowSensorWarn(sensorAlert);
        }
      }
    }
  }, [props?.location]);


  useEffect(() => {
    if (showSensorWarn) {
      setTimeout(() => {
        setShowSensorWarn(false);
      }, 1000 * 15);
    }
  }, [showSensorWarn]);


  useEffect(() => {
    if (fieldsState?.add_sensor_id && fieldsState?.add_sensor) {
      setShowSensorWarn(false);
    }
  }, [fieldsState?.add_sensor_id]);


  useEffect(() => {
    async function loadData() {
      const allDevicesTemp: any = [];
      let allSensorTemp: any = [];

      props.loading.start('Loading...');
      const devicesData: any = await getDevices();
      await getAllSystemSettings();
      if (!devicesData?.result) return;

      if(devicesData?.body){
        devicesData.body.forEach((device: DeviceData) => {
          if (device.device_type === 'aircycler') {
            if (device.device_id === match.params.id) {
              setSettingsDevice(device);

              setFieldsState((prevState: any) => {
                return {
                  ...prevState,
                  device_name: device?.device_name,
                };
              });
              filterCdvs(device?.details);
            } else {
              allDevicesTemp.push(device);
            }
          } else {
            if (device.device_type === 'foobot') {
              allSensorTemp.push(device);
            } else if (device.device_type === 'airthings') {
              allSensorTemp.push(device);
            } else if (
              device.device_type === 'awair' ||
              device.device_type === 'awair-element' ||
              device.device_type === 'awair-omni'
            ) {
              allSensorTemp.push(device);
            } else if (
              device.device_type === 'aircycler-local'
            ) {
              allSensorTemp.push(device);
            }
          }
        });
      }

      setAllDevices(allDevicesTemp);
      setSensorDevices(allSensorTemp);
      setCanStartSettings(true);

      props.loading.stop();
    }

    loadData();
  }, []);


  useEffect(() => {
    function loadValueSettings() {
      if (systemSettings.length >= 0) {
        systemSettings.forEach((_settings: any) => {
          if (_settings.system_id === match.params.id) {
            const { device_name } = settingsDevice || {};
            let initialSetting: any;

            setSettings(_settings.settings);

            if (settingsDevice && settingsDevice?.details) {
              const _controlTarget: any = pickControlTarget(
                settingsDevice.details,
                cdvs,
              );

              if (_controlTarget) {
                const { cdid } = _controlTarget;

                if (
                  _settings?.settings &&
                  Object.values(_settings?.settings).length > 0 &&
                  _settings.settings[cdid]
                ) {
                  initialSetting = _settings.settings[cdid];
                } else {
                  initialSetting =
                    cdvs &&
                    cdvs.length > 0 &&
                    cdvs[0].cdid &&
                    cdvs[0].cdid !== ''
                      ? { ..._settings.settings[cdvs[0].cdid] }
                      : Object.values(_settings.settings)[0];
                }
              } else {
                initialSetting =
                  _settings?.settings &&
                  Object.values(_settings?.settings).length > 0
                    ? Object.values(_settings.settings)[0]
                    : cdvs &&
                      cdvs.length > 0 &&
                      cdvs[0].cdid &&
                      cdvs[0].cdid !== ''
                    ? { ..._settings.settings[cdvs[0].cdid] }
                    : Object.values(_settings.settings)[0];
              }
            } else {
              initialSetting =
                _settings?.settings &&
                Object.values(_settings?.settings).length > 0
                  ? Object.values(_settings.settings)[0]
                  : cdvs &&
                    cdvs.length > 0 &&
                    cdvs[0].cdid &&
                    cdvs[0].cdid !== ''
                  ? { ..._settings.settings[cdvs[0].cdid] }
                  : Object.values(_settings.settings)[0];
            }

            handleSystemSettingsCompare(
              match.params.id,
              initialSetting,
              initialSetting?.ventilation_setup,
              sensorDevices,
            );

            setFieldsState((prevState: any) => {
              return {
                ...prevState,
                device_name: device_name || '',
                fhfr: initialSetting?.fhfr || 0,
                fcfr: initialSetting?.fcfr || 0,
                fffr: initialSetting?.fffr || 0,
                carq: initialSetting?.carq || 0,
                effr: initialSetting?.effr || 0,
                mner: initialSetting?.mner || 0,
                mixt: initialSetting?.mixt || 0,
                efdt: initialSetting?.efdt || 0,
                exct: initialSetting?.exct || 0,
                tmzn: initialSetting?.tmzn ? initialSetting?.tmzn : 0,
                hrsv: initialSetting?.hrsv || 0,
                hstv: initialSetting?.hstv || 0,
                hroa: initialSetting?.hroa ? initialSetting.hroa : 0,
                vcfr: initialSetting?.vcfr || 0,
                vcdt: initialSetting?.vcdt || 0,
                mvcr: initialSetting?.mvcr || 0,
                vcwm: initialSetting?.vcwm || 0,
                exwm: initialSetting?.exwm || 0,
                mnsf: initialSetting?.mnsf || initialSetting?.fan_run_time || 0,
                add_sensor: initialSetting?.add_sensor || '',
                add_sensor_id: initialSetting?.add_sensor_id || '',
                ventilation_setup:
                  initialSetting?.ventilation_setup ||
                  prevState?.ventilation_setup,
              };
            });
          }
        });
      }
    }

    if (canStartSettings) loadValueSettings();
  }, [canStartSettings, cdvs, systemSettings]);


  useEffect(() => {
    if (settings) {
      const setSetting: any =
        Object.values(settings)?.length > 0
          ? Object.values(settings)[0]
          : cdvs && cdvs.length > 0 && cdvs[0].cdid && cdvs[0].cdid !== ''
          ? { ...settings.settings[cdvs[0].cdid] }
          : Object.values(settings)[0];

      handleSystemSettingsCompare(
        match.params.id,
        setSetting,
        fieldsState.ventilation_setup,
      );
    }
  }, [fieldsState?.ventilation_setup]);




  return (
    <>
      <UserTemplate id="device-settings" title={config?.strings?.title} titleBackButton>
        <BreadcrumbsItem to={`/dashboard/`}>
          {config?.strings?.title}
        </BreadcrumbsItem>
        <div className="header-session">
          <h2>{settingsDevice?.device_name || 'AirCycler g3'}</h2>
          <div className="header-session--menu-actions">
            <ActionsMenu
              data={MENU_DATA ? [...MENU_DATA] : []}
              functions={{
                saveAsConfiguration: saveAsConfiguration,
              }}
            />
          </div>
        </div>
        <div className="mt-5">
          <Row>
            <Col sm={12} md={12} lg={6} xl={6} className="session-select">
              <div className="container-input">
                <Input
                  handleChange={onChangeState.bind(null, 'device_name')}
                  handleChangeError={(err: any) => {
                    setErrors((prevState: any) => ({
                      ...prevState,
                      device_name: err,
                    }));
                  }}
                  type={config.fields.device_name.type}
                  label={config.fields.device_name.label}
                  labelStyles="system-name--label"
                  value={fieldsState?.device_name}
                  error={errors?.device_name}
                  name={'device_name'}
                />
              </div>

              <div className="container-select">
                <label className="inputLabel">
                  {config?.fields?.ventilation_setup?.label}
                </label>
                <div className="system--select">
                  <NativeSelect
                    className="select"
                    value={fieldsState.ventilation_setup}
                    onChange={handleSelectVentilation}
                  >
                    {VENTILATIONSELECT.map((ventilation: any) => (
                      <option key={ventilation.name} value={ventilation.name}>
                        {ventilation.name}
                      </option>
                    ))}
                  </NativeSelect>
                </div>
              </div>

              {cdvs?.length > 0 && (
                <div className="container-select">
                  <label className="inputLabel">
                    {config?.fields?.primary_control?.label}
                  </label>
                  <div className="system--select">
                    <Select
                      className="select"
                      value={fieldsState?.primary_control}
                      onChange={handlePrimaryControl}
                    >
                      {cdvs &&
                        cdvs.map((device: any, index: number) => (
                          <MenuItem
                            key={device.dmac + index}
                            value={device.cdid || device.vent}
                          >
                            {device.cail && device.cail !== ''
                              ? device.cail
                              : device.cdid}
                            {device.vent !== 'FanConnect' && (
                              <div
                                className="device--select-alias"
                                style={{
                                  marginLeft: 15,
                                  fontSize: '0.7rem',
                                  fontWeight: 500,
                                }}
                              >
                                ( {device.vent} )
                              </div>
                            )}
                          </MenuItem>
                        ))}
                    </Select>
                  </div>
                </div>
              )}

              <div className="container-select">
                <label
                  className={`inputLabel ${
                    showSensorWarn ? 'warn-alert--wrapper' : ''
                  }`}
                >
                  {config?.fields?.add_sensor?.label}
                  {showSensorWarn && (
                    <span className="warn-alert--icon">
                      <CiWarning className="w_a--icon" />
                    </span>
                  )}
                </label>
                <div
                  className={`system--select ${
                    showSensorWarn ? 'sensor--warn' : ''
                  }`}
                >
                  <Select
                    className="select"
                    id="add--sensor-dp"
                    value={fieldsState.add_sensor_id}
                    onChange={handleAddSensor}
                    placeholder="Select Sensor"
                    disabled={disableLocalSensor(
                      sensorDevices, settingsDevice
                    )}
                  >
                    <MenuItem key={Math.random()} value="Foobot">
                      Add Sensor
                    </MenuItem>
                    {sensorDevices &&
                      sensorDevices.map((device: any) => (
                        <MenuItem
                          key={device?.device_name}
                          value={device?.device_id}
                          id={device?.device_id}
                          className="add--sensor-dp-option"
                          disabled={disableLocalSensorOption(device)}
                        >
                          <DeviceIcon type={device?.device_type} />
                          {filterSensorName(device)}
                        </MenuItem>
                      ))}
                  </Select>
                </div>
              </div>

              <div className="edit-location-wrapper">
                <EditDevice
                  startUpdated={startUpdated}
                  updateDevice={handleAlertValidation}
                  device_name={fieldsState.device_name}
                  setStartUpdated={setStartUpdated}
                />
              </div>
            </Col>
            <Col sm={12} md={12} lg={6} xl={6} className="">
              {fieldsState.ventilation_setup === 'Calculated Flow' && (
                <CalculatedFlow
                  config={config}
                  customHandleChange={customHandleChange}
                  onChangeState={onChangeState}
                  errors={errors}
                  setErrors={setErrors}
                  handleSetTimezone={handleSetTimezone}
                  fieldsState={fieldsState}
                  isVentConnected={cdvs ? cdvs.length > 0 : false}
                  validateInput={validateInput}
                />
              )}
              {fieldsState.ventilation_setup === 'Calculated Time' && (
                <CalculatedTime
                  config={config}
                  onChangeState={onChangeState}
                  errors={errors}
                  setErrors={setErrors}
                  handleSetTimezone={handleSetTimezone}
                  fieldsState={fieldsState}
                  isVentConnected={cdvs ? cdvs.length > 0 : false}
                />
              )}
              {fieldsState.ventilation_setup === 'Balanced Mode' && (
                <BalancedMode
                  config={config}
                  onChangeState={onChangeState}
                  errors={errors}
                  setErrors={setErrors}
                  handleSetTimezone={handleSetTimezone}
                  fieldsState={fieldsState}
                  isVentConnected={cdvs ? cdvs.length > 0 : false}
                />
              )}

              {!settingsMatch && (
                <div className="warn-section">
                  <div className="ss-warn-msm--wrapper">
                    <span className="ss-warn-msm--title">WARNING!</span>
                    {config?.strings?.warning || ''}
                  </div>
                </div>
              )}

              <div className="container-button">
                <Button
                  variant="primary"
                  className="btn btn-primary"
                  type="button"
                  onClick={() => setStartUpdated(true)}
                  disabled={false}
                >
                  {config?.strings?.confirm}
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        <SweetAlert
          info
          show={showAlert.alert}
          showCancel
          confirmBtnText={config?.alerts?.update?.btn?.confirm}
          confirmBtnBsStyle="info"
          title={`${config?.alerts?.update?.question}`}
          onConfirm={() => handleSaveSettings(showAlert.editDevice)}
          onCancel={() => setShowAlert({ alert: false, editDevice: {} })}
          focusCancelBtn
        />

        <Modal
          id="sysSaveConfigModal"
          show={showConfigModal}
          onHide={handleCloseModal}
          centered
        >
          <Modal.Header closeButton>
            <Modal.Title>Save As Configuration</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Form>
              <Form.Group className="mb-3" controlId="saveAsConfigCtrl">
                <Form.Label>Configuraion Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Enter Configuration Name"
                  autoFocus
                  value={configName}
                  onChange={(event: any) => {
                    setConfigName(event.target.value);
                  }}
                />
              </Form.Group>
            </Form>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="default"
              className="btn btn-default"
              type="button"
              onClick={() => {
                handleCloseModal();
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              className="btn btn-primary"
              type="button"
              onClick={handleToSaveAsConfiguration}
              disabled={
                configName === null ||
                configName === undefined ||
                configName === ''
              }
            >
              Continue
            </Button>
          </Modal.Footer>
        </Modal>
      </UserTemplate>
    </>
  );
};


function mapStateToProps(state: any) {
  const {
    devices,
    foobot,
    env,
    systemSettings,
  } = state;

  return {
    devices: devices.devices,
    systemSettings: systemSettings.systemSettings,
    thirdParty: {
      foobot: {
        connected: foobot.isConnected,
        devices: foobot.fooDevices,
      },
    },
  };
}

function mapDispatchToProps(dispatch: any) {
  const {
    systemSettings,
    userConfigs,
    devices,
    loader,
  } = dispatch;

  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    getDevices: devices.getAllDevices,
    setCurrentDevice: dispatch.devices.setCurrentDevice,
    getAllSystemSettings: systemSettings.getAllSystemSettings,
    getAllSystemSettingsNoMerge: systemSettings.getAllSystemSettingsNoMerge,
    editSystemSettings: systemSettings.editSystemSettings,
    updateDevice: devices.editDevice,
    editDeviceAttributes: devices.editDeviceAttributes,
    createConfig: userConfigs.createConfig,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DeviceSettings);
