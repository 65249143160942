import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import CodeSnippet from '../Highlighter';
import './styles.scss';

interface CodeBlockProps {
  id: string;
  className: string;
  req: any;
  res: any;
  currentLanguage: string;
}

const CodeBlock: React.FC<CodeBlockProps> = ({
  id,
  className,
  req,
  res,
  currentLanguage,
}) => {
  const [buttonsState, setButtonsState] = useState<any>({
    [id]: {
      headers: false,
      body: true,
    },
  });

  const changeResponseScreen = (clickedBtn: string) => {
    switch (clickedBtn) {
      case 'body':
        setButtonsState({
          ...buttonsState,
          [id]: {
            headers: false,
            body: true,
          },
        });

        break;

      case 'headers':
        setButtonsState({
          ...buttonsState,
          [id]: {
            headers: true,
            body: false,
          },
        });
        break;

      default:
        return {
          ...buttonsState,
        };
    }
  };

  return (
    <div className={`col-12 ${className ? className : ''}`} id={id}>
      <div className="container code-blocks-sections">
        <div className="row row-m-side-0">
          <div className="col-12 code-blocks-sections">
            <div className="the-card">
              <div className="the-card-header">
                <h6 id={`${id}--header-req`}>Example Request</h6>
              </div>
              <div className="the-card-body-req">
                <CodeSnippet code={req?.code} />

                {/* <div className="overlay-div">
                  <button
                    className="btn expand-code-snippet"
                    // data-toggle="modal"
                    // data-target="#expand-req"
                    onClick={() => {}}
                  >
                    <span className="expand-span-label lead">View more</span>
                  </button>
                </div> */}
              </div>
            </div>
          </div>
          <div className="col-12 code-blocks-sections">
            <div className="the-card">
              <div className="the-card-header">
                <h6 id={`${id}--header-res`}>Example Response</h6>
              </div>
              <div className="the-card-body-res">
                <div className="container pdg">
                  <div className="row mlmr0">
                    <div className="col-12 pdg">
                      <button
                        className={`btn btn-sm btn-info body-headers ${
                          buttonsState[id].body
                            ? `border-shows`
                            : `border-does-not-show`
                        }`}
                        onClick={() => changeResponseScreen('body')}
                      >
                        <span className="section-btn-span">Body</span>
                      </button>
                      <button
                        className={`btn btn-sm btn-info body-headers ${
                          buttonsState[id].headers
                            ? `border-shows`
                            : `border-does-not-show`
                        }`}
                        onClick={() => changeResponseScreen('headers')}
                      >
                        <span className="section-btn-span">Headers</span>
                      </button>
                    </div>
                  </div>
                  <div className="row mlmr0">
                    <div className="col-12 pdg">
                      {buttonsState[id].body ? (
                        // <CodeSnippet code={res?.body} />
                        //here must be the RESPONSE body
                        <></>
                      ) : (
                        <>{res?.headers}</>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { apiState }: any = state || {};
  return {
    currentLanguage: apiState.currentLanguage,
  };
}
function mapDispatchToProps(dispatch: any) {
  const { apiState }: any = dispatch || {};
  return {
    updateLanguageChoice: apiState.updateLanguageChoice,
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(CodeBlock);
