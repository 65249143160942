//@ts-nocheck
import React from 'react';
import PropTypes from 'prop-types';
import { IoIosArrowDown } from 'react-icons/io';
import { IoIosArrowForward } from 'react-icons/io';
import { FaRegFolderOpen } from 'react-icons/fa';
import { alpha, makeStyles, withStyles } from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Collapse from '@material-ui/core/Collapse';
import { useSpring, animated } from 'react-spring/web.cjs'; // web.cjs is required for IE 11 support
import './styles.scss';

const ArrowDown = (props: any) => {
  return <IoIosArrowDown style={{ width: '0.8em', height: '0.8em' }} />;
};

const ArrowForward = (props: any) => {
  return <IoIosArrowForward style={{ width: '0.8em', height: '0.8em' }} />;
};

const CloseSquare = (props: any) => {
  return <IoIosArrowDown style={{ width: '0.8em', height: '0.8em' }} />;
};

const TransitionComponent = (props: any) => {
  const style = useSpring({
    from: { opacity: 0, transform: 'translate3d(20px,0,0)' },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
};

TransitionComponent.propTypes = {
  in: PropTypes.bool,
};

const StyledTreeItem = withStyles(theme => ({
  iconContainer: {
    '& .close': {
      opacity: 0.3,
    },
  },
  group: {
    marginLeft: 7,
    paddingLeft: 18,
    borderLeft: `1px dashed ${alpha(theme.palette.text.primary, 0.4)}`,
  },
}))(props => <TreeItem {...props} TransitionComponent={TransitionComponent} />);

const useStyles = makeStyles({
  root: {},
});

export default function CustomizedTreeView(props: any) {
  const classes = useStyles();

  const methodClasses = {
    GET: `get-method`,
    POST: `post-method`,
    PUT: `put-method`,
    DELETE: `delete-method`,
  };

  return (
    <TreeView
      className={classes.root}
      defaultExpanded={['1']}
      defaultCollapseIcon={<ArrowDown />}
      defaultExpandIcon={<ArrowForward />}
      defaultEndIcon={<CloseSquare />}
    >
      <StyledTreeItem
        nodeId="1"
        label={
          <>
            <FaRegFolderOpen className="folder-style" />
            <span className="folder-label lead">{props.mainFolder}</span>
          </>
        }
      >
        <div className="container tree-items pdg">
          {props.requests.map((req: any, index: number) => (
            <a className="request-link-spec-styles" href={req.href} key={index}>
              <div className="row mrg">
                <div
                  className={`col-auto pdg req-method request-side ${
                    methodClasses[req.method]
                  }`}
                >
                  {req.method}
                </div>
                <div className="col pdg  folder-label lead">{req.endpoint}</div>
              </div>{' '}
            </a>
          ))}
        </div>
      </StyledTreeItem>
    </TreeView>
  );
}
