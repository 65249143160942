import React from 'react';

interface ListItemProps {
  ulKey: number;
  href: string;
  liValue: string;
  liClassName: string;
}

const ListItem: React.FC<ListItemProps> = ({
  ulKey,
  href,
  liValue,
  liClassName,
}) => {
  return (
    <ul key={ulKey}>
      <a href={href}>
        <li className={liClassName}>{liValue}</li>
      </a>
    </ul>
  );
};

export default ListItem;
