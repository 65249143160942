import React, { useEffect, useState } from 'react';
import config from './config';
import { connect } from 'react-redux';
import validate from 'validate.js';
import { helpers } from './helpers';
import { useHistory } from 'react-router-dom';
import { AddDevLocationProps } from './interface';
import { State, City } from 'country-state-city';
import ZipCodes from 'zipcodes';
import {
  Button,
  Input,
  SwitchBtn,
  Modal,
  Spinner,
  Template,
  AutoSuggest,
} from 'business/modules/admin/common';
import { HiOutlineXCircle } from 'react-icons/hi';
import { RiSave3Fill } from 'react-icons/ri';
import './styles.scss';

const COUNTRY_CODE = 'US';
const DEFAULT_STATE = { ...config.defaults.state };
const DEFAULT_CITY = { ...config.defaults.city };

const AddDevLocation: React.FC<AddDevLocationProps> = ({
  getDevicesPerUser,
  dataWasCleared,
  eraseClearData,
  loading,
  eraseLocationDataOutAir,
}) => {
  const history = useHistory();
  const [loaded, setLoaded] = useState(false);
  const [currentField, setCurrentField] = useState('');
  const [devDetails, setDevDetails]: any = useState();
  const [switchValue, setSwitchValue] = useState(false);
  const [formValues, setFormValues] = useState<any>(config.states.formValues);
  const [allStates, setAllStates]: any = useState([]);
  const [allCities, setAllCities]: any = useState([]);
  const [allCityZipCodes, setAllCityZipCodes]: any = useState([]);
  const [disabled, setDisabled] = useState<boolean>(true);
  const [errors, setErrors]: any = useState(null);
  const [dataArrs, setDataArrs] = useState<any>({
    states: [],
    cities: [],
    zips: [],
  });
  const [suggs, setSuggests] = useState<any>({
    device_name: '',
    device_city: '',
    device_zipcode: '',
  });

  const saveChanges = async () => {
    //Updated data that will be sent to the server:
  };

  const updateDevStatus = () => {
    eraseClearData();
    setSwitchValue(!switchValue);
  };

  const onChangeValues = async (event: any) => {
    eraseClearData();

    let name: any = event.target.name;
    let value: any = event.target.value;

    setFormValues({ ...formValues, [helpers.returnStateProp(name)]: value });

    let currentFields: any = { [name]: event.target.value };

    let currentConstraints: any = {
      [name]: {
        ...config.constraints[name as keyof typeof config.constraints],
      },
    };

    await validate
      .async(currentFields, currentConstraints)
      .then((res: any) => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, [name]: '' };
        });
      })
      .catch((err: any) => {
        setErrors((prevErrors: any) => {
          return { ...prevErrors, [name]: err[name] };
        });
      });
  };

  const returnValue = (formField: any) => {
    return formField.id === `state`
      ? formValues.device_state?.name
      : formField.id === `city`
      ? formValues.device_city?.name
      : formField.id === `zip_code`
      ? formValues.device_zipcode?.value
      : ``;
  };

  const formatZipCodes = (zipCodes: any) => {
    return zipCodes.map((zpCode: any) => {
      const { zip } = zpCode;
      return {
        ...zpCode,
        value: zip,
        label: zip,
      };
    });
  };

  const initZipCodes = (cityData: any) => {
    const { stateCode, name } = cityData;
    let zipCodeData: any =
      stateCode && name ? ZipCodes.lookupByName(name, stateCode) : [];
    return zipCodeData.length > 0 ? formatZipCodes(zipCodeData) : zipCodeData;
  };

  const setLocationCities = (statesData: any) => {
    const { isoCode, value } = statesData;
    let tempCities: any = City.getCitiesOfState(COUNTRY_CODE, value);
    tempCities = formatLocations(tempCities);
    setAllCities([...tempCities]);
    return DEFAULT_STATE.isoCode === isoCode ? { ...DEFAULT_CITY } : {};
  };

  const formatLocations = (data: any) => {
    return data.map((item: any) => {
      const { name, isoCode } = item;
      return {
        ...item,
        label: name,
        value: isoCode ? isoCode : name,
      };
    });
  };

  const initAllStates = () => {
    let allStates: any = State.getStatesOfCountry(COUNTRY_CODE);
    allStates = formatLocations(allStates);
    setAllStates(allStates);

    const stateNames = allStates.map((elem: any) => elem.name);
    setDataArrs({ ...dataArrs, states: stateNames });

    return allStates;
  };

  const prepareFields = () => {
    const _allStates: any = initAllStates();

    let tempCity: any = [];
    let tempZipCodes: any = [];

    let deviceClone = { ...formValues };

    if (devDetails) {
      deviceClone.device_name = devDetails.device_name;

      setSwitchValue(devDetails.status);

      deviceClone.device_state = devDetails?.device_state
        ? { ...devDetails.device_state }
        : {};
      if (deviceClone?.device_state) {
        tempCity = setLocationCities(deviceClone.device_state);
      }

      deviceClone.device_city = devDetails?.device_city
        ? { ...devDetails.device_city }
        : {};
      deviceClone.device_zipcode = devDetails?.device_zipcode
        ? { ...devDetails.device_zipcode }
        : {};
      if (deviceClone.device_city)
        tempZipCodes = initZipCodes(deviceClone.device_city);
      setAllCityZipCodes([...tempZipCodes]);

      setFormValues(deviceClone);
    }
  };

  const retrieveList = (field: string) => {
    switch (field) {
      case 'device_state':
        return allStates;
      case 'device_city':
        return allCities;
      case 'device_zipcode':
        return allCityZipCodes;
      default:
        return [];
    }
  };

  const grabSuggestion = (id: string, currentSuggestion: string) => {
    //mark which input is being selected, in order to handle the error display
    setCurrentField(id);
    setSuggests({ ...suggs, [id]: currentSuggestion });
  };

  const validateInputtedVals = (suggs: any) => {
    switch (currentField) {
      case 'device_state':
        if (dataArrs?.states?.includes(suggs?.device_state)) {
          setErrors({
            ...errors,
            device_state: '',
          });
        } else {
          setErrors({
            ...errors,
            device_state: 'Please select a state from the list',
          });
        }
        break;

      case 'device_city':
        if (dataArrs?.cities?.includes(suggs?.device_city)) {
          setErrors({
            ...errors,
            device_city: '',
          });
        } else {
          setErrors({
            ...errors,
            device_city: 'Please select a city from the list',
          });
        }
        break;
      case 'device_zipcode':
        if (dataArrs?.zips?.includes(suggs?.device_zipcode)) {
          setErrors({
            ...errors,
            device_zipcode: '',
          });
        } else {
          setErrors({
            ...errors,
            device_zipcode: 'Please select a zipcode from the list',
          });
        }
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    const currentDeviceOAAdmin: any = localStorage.getItem(
      'selectedDevIdOutsideAirAdmin',
    );

    const fetchDevs = async () => {
      let currentUser: any = localStorage.getItem('currentAccount');
      if (currentUser) {
        const devs = await getDevicesPerUser(currentUser);

        if (devs && devs?.statusCode === 200) {
          let filterData: any = devs?.body?.data?.filter((elem: any) => {
            return elem.device_id == currentDeviceOAAdmin;
          });
          filterData?.length > 0
            ? setDevDetails(filterData[0])
            : setDevDetails({});
        }

        setLoaded(true);
      }
    };
    fetchDevs();
    return () => {};
  }, []);

  useEffect(() => {
    if (devDetails && Object.keys(devDetails).length > 0) {
      setFormValues({ ...formValues, device_name: devDetails?.device_name });
      setSwitchValue(devDetails?.status === `active` ? true : false);
    }
  }, [devDetails]);

  useEffect(() => {
    if (dataWasCleared) {
      setFormValues({
        device_name: devDetails?.device_name,
        device_city: devDetails?.device_city
          ? { ...devDetails.device_city }
          : {},
        device_state: devDetails?.device_state
          ? { ...devDetails.device_state }
          : {},
        device_zipcode: devDetails?.device_zipcode
          ? { ...devDetails.device_zipcode }
          : {},
      });
      setSwitchValue(devDetails?.status === `active` ? true : false);

      setErrors();
      // revert the value of dataWasCleared
      eraseClearData();
    }
  }, [dataWasCleared]);

  useEffect(() => {
    if (loaded) {
      prepareFields();
    }
  }, [loaded]);

  useEffect(() => {
    if (formValues?.device_state?.label) {
      const { name, value } = formValues.device_state;
      let tempCities: any = City.getCitiesOfState(COUNTRY_CODE, value);
      tempCities = formatLocations(tempCities);
      setAllCities([...tempCities]);

      //to posterior comparison - handle suggestion errs:
      const cityNames = tempCities.map((elem: any) => elem.name);

      setDataArrs({ ...dataArrs, cities: cityNames });
    }
  }, [formValues?.device_state?.label]);

  useEffect(() => {
    if (formValues?.device_city?.label) {
      const { name, stateCode, latitude, longitude } = formValues.device_city;
      let tempZipCodesByCoords: any = ZipCodes.lookupByCoords(
        latitude,
        longitude,
      );
      let tempZipCodes: any = ZipCodes.lookupByName(name, stateCode);
      tempZipCodes = formatZipCodes(
        tempZipCodes && tempZipCodes.length > 0
          ? tempZipCodes
          : [tempZipCodesByCoords],
      );

      setAllCityZipCodes([...tempZipCodes]);

      //to posterior comparison:
      const zipNums = tempZipCodes.map((elem: any) => elem.zip);

      setDataArrs({ ...dataArrs, zips: zipNums });
    }
  }, [formValues?.device_city?.label]);

  useEffect(() => {
    //TODO: or errors :

    if (
      helpers.areFieldsEmpty(formValues) ||
      (errors && errors?.device_name?.trim().length > 0) ||
      (errors && errors?.device_state?.trim().length > 0) ||
      (errors && errors?.device_zipcode?.trim().length > 0) ||
      (errors && errors?.device_state?.trim().length > 0)
    ) {
      setDisabled(true);
    } else {
      setDisabled(false);
    }
  }, [formValues, errors]);

  useEffect(() => {
    //if the suggestion is empty , empty also the formvalue to display the error

    validateInputtedVals(suggs);
  }, [suggs]);

  useEffect(() => {
    if (loaded) {
      loading.stop();
    } else {
      loading.start('Loading device details...');
    }
  }, [loaded]);

  return (
    <div className="out-air-add-location">
      <Template
        id="update-location"
        title={`Update device information`}
        goBack={() => {
          history.push(`/admin/dashboard/user-details`);
        }}
      >
        <div className="container" id="add-local">
          <div className="row">
            <div className="col">
              <>
                {!loaded ? (
                  <Spinner />
                ) : loaded && !devDetails ? (
                  <div className="jumbotron" id="out-dev-list-no-results">
                    <h3 className="display-6">{`No data`}</h3>

                    <p></p>
                    <hr className="my-4" />

                    <div className="alert alert-light message" role="alert">
                      <p className="lead">{`Something went wrong while loading the device data. Please, try again later!`}</p>
                    </div>
                  </div>
                ) : (
                  <>
                    <h4>{devDetails?.device_name}</h4>
                    <p className="lead sm-lead">
                      {config.strings.updateLocation}
                    </p>

                    <hr className="my-4 hr--location" />

                    <div className="container system-details">
                      <div className="row d-flex input-space">
                        <div className="col-xs-12 col-sm-12 col-md-12 cont--1--p name-input-location">
                          <div className="input-group">
                            <label className="lead label-location">
                              {config.strings.devName}
                              <span className="required">(required)</span>
                            </label>
                            <Input
                              type="text"
                              id=""
                              value={formValues?.device_name || ''}
                              onChange={onChangeValues}
                              placeholder={devDetails?.device_name}
                              name={`device_name`}
                              className="regular-w"
                            />
                          </div>

                          <span className="render-error">
                            {errors && errors['device_name']}
                          </span>
                        </div>
                      </div>

                      <div className="row d-flex">
                        {config.formConfig.map(
                          (formField: any, index: number) => {
                            return (
                              <div className={formField.className} key={index}>
                                <div className="input-group">
                                  <label className="lead label-location">
                                    {formField.label}
                                    <span className="required">(required)</span>
                                  </label>
                                </div>

                                {allStates.length > 0 && (
                                  <AutoSuggest
                                    grabSuggestion={grabSuggestion}
                                    dataWasCleared={dataWasCleared}
                                    id={formField.field}
                                    handleChange={(newState: any) => {
                                      setFormValues((prevState: any) => {
                                        return {
                                          ...prevState,
                                          [formField.field]: { ...newState },
                                        };
                                      });
                                    }}
                                    data={retrieveList(formField.field)}
                                    value={returnValue(formField.field)}
                                    {...formField.fields}
                                  />
                                )}

                                <span className="render-error">
                                  {errors && errors[formField.field]}
                                </span>
                              </div>
                            );
                          },
                        )}
                      </div>

                      <div className="container pdg">
                        <div className="row d-flex pdg">
                          <div className="col-xs-12 col-sm-12 col-md-9 pdg">
                            <div className={`container cont--1--p`}>
                              <div className="row mb-3">
                                <div className="col-auto adl-switch-styles-col centered--2">
                                  <div className="lead label--switch">
                                    {config.strings.devStatus}
                                  </div>
                                </div>
                                <div className="col-auto switch-btn-section">
                                  <SwitchBtn
                                    className=""
                                    id={``}
                                    label={``}
                                    value={switchValue}
                                    onChange={updateDevStatus}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row d-flex justify-content-center pdg">
                          <div
                            className="col-12"
                            id="section--confirmation--id"
                          >
                            <Button
                              className="btn btn-sm small-button dismiss-btn btn-position-right"
                              onClick={eraseLocationDataOutAir}
                            >
                              <span className="label-i btn-child-ft">
                                <HiOutlineXCircle className="cancel-i" />{' '}
                                {config.strings.cancel}
                              </span>
                            </Button>
                            <Button
                              className="btn btn-sm small-button ok btn-position-right"
                              dataToggle={`modal`}
                              dataTarget={`#confirm-settings-update`}
                              disabled={disabled}
                            >
                              <span className="label-i btn-child-ft">
                                {' '}
                                <RiSave3Fill className="edit-save" />
                                {config.strings.save}
                              </span>
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
            </div>
          </div>
        </div>
      </Template>
      <Modal
        config={config}
        id={`confirm-settings-update`}
        title={config.titles.loc__update}
        icon={<RiSave3Fill className="edit" />}
        body={config.strings.confirmation}
        onClick={saveChanges}
        onClickCancel={eraseLocationDataOutAir}
        /*
        handle these when there is an endpoint
        and a function call to change those values 
        */
        // modalMustClose={modalMustClose}
        // handleModalState={handleModalState}
        modalMustClose={false}
        handleModalState={() => {}}
        label={config.labels.loc__update}
        className={`small-button ok`}
        bodyClass={`alert alert-info`}
        sureToProceed={config.strings.sureToProceed}
        hasConfirmBtn={true}
      />
    </div>
  );
};

function mapStateToProps(state: any) {
  const { adminSectionsState } = state;
  return {
    selectedDevice: adminSectionsState.selectedDevice,
    dataWasCleared: adminSectionsState.dataWasCleared,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { adminUsersState, adminSectionsState, loader } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    getDevicesPerUser: adminUsersState.getDevicesPerUser,
    eraseClearData: adminSectionsState.eraseClearData,
    eraseLocationDataOutAir: adminSectionsState.eraseLocationDataOutAir,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddDevLocation);
