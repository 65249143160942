import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { useHistory } from 'react-router-dom';
import validate from 'validate.js';
import _ from 'lodash';
import {
  Input,
  InputAvatar,
  Button,
  InputPassword,
  InputPhone,
  InputCheck,
  DropdownFilter,
  RadioGroup,
  Notification,
} from '../../../../../react-components';
import { ConnectionBroker } from '../../../../../core';
import Form from '../../components/FormSession';
import genConfig from '../../../../config/config';
import config from './config';
import './styles.scss';


const AIRCYCLER_CONFIGS: any = { ...genConfig.strings };
const DEFAULT_FIELDS: any = { ...config.fields };
const DEFAULT_CONSTRAINTS: any = { ...config.constraints };


const SignIn: React.FC = () => {
  const history = useHistory();
  const mounted: any = useRef(false);
  const [fieldsState, setFieldsState]: any = useState({...config.fieldsState});
  const [errors, setErrors]: any = useState({});
  const [loading, setLoading]: any = useState(false);
  const [terms, setTerms]: any = useState(false);
  const [isConfirm, setIsConfirm]: any = useState(false);


  const handleSubmit = useCallback(
    async () => {
      let constraintsCopy: any = _.cloneDeep(DEFAULT_CONSTRAINTS);
      setIsConfirm(true);
      setErrors({});
      setLoading(true);
      let isValid = true;

      if(fieldsState?.account_type === 'home'){
        delete fieldsState.company;
        delete constraintsCopy.company;
      }

      await validate.async(fieldsState, constraintsCopy).then(null, err => {
        setErrors(err);
        setIsConfirm(false);
        setLoading(false);
        isValid = false;
      });

      if (!isValid) {
        setLoading(false);
        setIsConfirm(false);
        return;
      }

      let confirm_password: string = fieldsState.confirm_password;
      delete fieldsState.confirm_password;


      let response = await ConnectionBroker.fetchAPI(
        'jitBE',
        'session.registerWeb',
        fieldsState,
      );

      if (response.result) {
        history.push('/signup/confirm', { user: response.body.user });
      }

      fieldsState.confirm_password = confirm_password;
      setLoading(false);
      setIsConfirm(false);
    },
    // eslint-disable-next-line
    [fieldsState, config, isConfirm],
  );




  useEffect(() => {
    mounted.current = true;
    setIsConfirm(false);

    return () => {
      setErrors({});
      mounted.current = false;
    };
  }, []);


  return (
    <Form>
      <Helmet>
        <title>{config.strings.title} - AirCycler</title>
      </Helmet>
      <div className="text-center mb-4">
        <h4>{config.strings.title}</h4>
      </div>
      <div id="form--sign-up" className="row">
        {Object.keys(DEFAULT_FIELDS).map((name, index) => {
          switch (DEFAULT_FIELDS[name].type) {
            case 'input-avatar':
              return (
                <InputAvatar
                  handleChange={(value: any) =>
                    setFieldsState((prevState: any) => ({
                      ...prevState,
                      [name]: value,
                    }))
                  }
                  cols={DEFAULT_FIELDS[name].cols}
                  name={name}
                  key={'avatar--' + index}
                />
              );
            case 'dropdown':
              return (
                <DropdownFilter
                  key={'drop--' + index}
                  handleChange={(value: any) => {
                    setFieldsState((prevState: any) => ({
                      ...prevState,
                      [name]: value,
                    }))
                  }}
                  error={errors[name]}
                  value={fieldsState[name]}
                  disabled={!false}
                  {...DEFAULT_FIELDS[name]}
                />
              );
            case 'phone':
              return (
                <InputPhone
                  handleChange={(value: any) => {
                    setFieldsState((prevState: any) => ({
                      ...prevState,
                      [name]: value,
                    }))
                  }}
                  handleChangeError={(err: any) =>
                    setErrors((prevState: any) => ({
                      ...prevState,
                      [name]: err,
                    }))
                  }
                  name={name}
                  value={fieldsState[name]}
                  error={errors[name]}
                  constraint={DEFAULT_CONSTRAINTS[name]}
                  fieldsState={fieldsState}
                  key={'phone--' + index}
                  disabled={!mounted?.current}
                  {...DEFAULT_FIELDS[name]}
                />
              );
            case 'password':
              return (
                <InputPassword
                  handleChange={(value: any) =>
                    setFieldsState((prevState: any) => ({
                      ...prevState,
                      [name]: value,
                    }))
                  }
                  handleChangeError={(err: any) =>
                    setErrors((prevState: any) => ({
                      ...prevState,
                      [name]: err,
                    }))
                  }
                  name={name}
                  value={fieldsState[name]}
                  error={errors[name]}
                  constraint={{
                    password: config.constraints['password'],
                    confirm_password: config.constraints['confirm_password'],
                  }}
                  fieldsState={fieldsState}
                  key={'passwd--' + index}
                  {...DEFAULT_FIELDS[name]}
                />
              );
            case 'text':
            case 'email':
              return (
                <Input
                  handleChange={(value: any) =>
                    setFieldsState((prevState: any) => ({
                      ...prevState,
                      [name]: value,
                    }))
                  }
                  handleChangeError={(err: any) =>
                    setErrors((prevState: any) => ({
                      ...prevState,
                      [name]: err,
                    }))
                  }
                  type={DEFAULT_FIELDS[name]?.type}
                  cols={DEFAULT_FIELDS[name]?.cols}
                  label={DEFAULT_FIELDS[name]?.label}
                  name={name}
                  value={fieldsState[name]}
                  error={errors[name]}
                  constraint={DEFAULT_CONSTRAINTS[name]}
                  key={'input--' + index}
                />
              );
            case 'radio-option':
              return (
                <div
                  key={'radio-group--' + index}
                  className="account-type--wrapper"
                >
                  <RadioGroup
                    id={`radio-group-${index}`}
                    label={DEFAULT_FIELDS[name]?.label}
                    value={fieldsState[name]}
                    options={DEFAULT_FIELDS[name]?.options}
                    onChange={(event: any) => {
                      if(event?.target?.value){
                        setFieldsState((prevValues: any) => {
                          return {
                            ...prevValues,
                            account_type: event.target.value
                          };
                        });
                      }
                    }}
                  />
                </div>
              );
            case 'text-option':
              if(fieldsState?.account_type === 'installer'){
                return (
                  <Input
                    handleChange={(value: any) =>
                      setFieldsState((prevState: any) => ({
                        ...prevState,
                        [name]: value,
                      }))
                    }
                    handleChangeError={(err: any) =>
                      setErrors((prevState: any) => ({
                        ...prevState,
                        [name]: err,
                      }))
                    }
                    type={DEFAULT_FIELDS[name]?.type}
                    cols={DEFAULT_FIELDS[name]?.cols}
                    label={DEFAULT_FIELDS[name]?.label}
                    name={name}
                    value={fieldsState[name]}
                    error={errors[name]}
                    constraint={DEFAULT_CONSTRAINTS[name]}
                    key={'input-option--' + index}
                  />
                );
              }
              return null;
            default:
              return null;
          }
        })}
      </div>
      <div className="mt-0 d-flex terms-wrapper">
        <div className="terms-wrapper--container">
          <InputCheck
            handleChange={(value: any) => setTerms(!terms)}
            name="terms"
            value={terms}
          ></InputCheck>
          <p style={{ fontSize: '.8rem', marginBottom: 0, textAlign: 'justify' }}>
            To sign up, I accept the{' '}
            <a href={AIRCYCLER_CONFIGS.cloudTerms} target="_blank">AirCycler Cloud Terms</a> of Service
            and agree to the <a href={AIRCYCLER_CONFIGS.privacyPolicy} target="_blank">Privacy Policy</a>.
          </p>
        </div>
      </div>
      <div className="row d-flex pt-4">
        <div className="form-group">
          <Button
            variant="primary"
            className="btn  btn-primary btn-block"
            type="button"
            disabled={loading || !terms}
            onClick={() => handleSubmit()}
          >
            {config.strings.confirm}
          </Button>
        </div>
      </div>
      <div className="d-flex justify-content-center align-items-center pt-4">
        <Button
          variant="link"
          className="btn btn-link"
          type="button"
          onClick={() => history.push('/login')}
        >
          {config.strings.cancel}
        </Button>
      </div>
    </Form>
  );
};

export default SignIn;
