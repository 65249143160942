import React, { useEffect, useState, useRef } from 'react';
import { config } from './config';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { helpers } from './helpers';
import { DashboardAdminProps } from './interface';
import { Details } from './components';
import {
  List,
  Modal,
  Notification,
  Template,
} from 'business/modules/admin/common';
import { MdDelete } from 'react-icons/md';
import { MdErrorOutline } from 'react-icons/md';
import './styles.scss';

const DashboardAdmin: React.FC<DashboardAdminProps> = ({
  setCurrentUser,
  usersList,
  usersListIsLoaded,
  getDevicesPerUser,
  fillListWithDevQtyAndUserId,
  userAndDevsQuantityList,
  setIDAndDevListLoaded,
  loadingErrors,
  getUsersList,
  successfullyDeleted,
  orders,
  intendedCounter,
  counter,
  updateOrderState,
  orderedList,
  loading,
  currentUser,
  handleCounterAndListOrder,
  getManualCtrlDetailsAdmin,
  modalMustClose,
  handleModalState,
  deleteAnUser,
  handleErr,
  defUser,
  setEnteredInUserPage,
  defDevsByUser2,
  eachUserDevs2,
  markDevsLoaded2,
  eachUserDevsLoaded2,
}) => {
  const history = useHistory();
  const dashboardRef = useRef<any>(false);
  const [unorderedDefault, setUnorderedDefault] = useState([]);
  const [users, setUsers] = useState([]);
  const [loadedUsers, setLoadedUsers] = useState(false);
  const [current, setCurrent] = useState('');
  const [manualControlData, setManualControlData] = useState();

  const incremented = {
    name: intendedCounter.name + 1,
    location: intendedCounter.location + 1,
    count: intendedCounter.count + 1,
  };

  const handleView = async (user: any) => {
    defUser(user);

    history.push(`/admin/dashboard/user-details`, { user });
  };

  const fetchUsersData = async () => {
    let savedDefaultArr: any = [];
    loading.start('Loading Users...');

    const response: any = await getUsersList();

    if (response && response?.statusCode === 200) {
      const { body }: any = response || {};

      if (
        body &&
        body?.data &&
        Array.isArray(body?.data) &&
        body?.data?.length > 0
      ) {
        //save init data (unordered):
        let defaultData = body?.data?.forEach((item: any) => {
          savedDefaultArr.push(item);
        });

        setUnorderedDefault(savedDefaultArr);
      }
    } else {
      handleErr('usersListErr');
      loading.stop();
      return;
    }

    loading.stop();
  };

  const handleDevsCount = (item: any) => {
    let userDevs2: any = eachUserDevs2.hasOwnProperty(item?.account_id)
      ? eachUserDevs2[item.account_id].length
      : 0;

    return userDevs2;
  };

  const retrieveColValue = (item: any, position: string) => {
    switch (position) {
      case '1':
        return `${item?.first_name} ${item?.last_name}`;
      case '2':
        return `${
          item?.addresses.length > 0 && item?.addresses[0]?.zip_code
        } , ${item?.addresses?.length > 0 && item?.addresses[0]?.state}`;
      case '3':
        return `${handleDevsCount(item)}  ${
          handleDevsCount(item) === 1 ? `device` : `devices`
        }`;
      case '4':
        return '';
      default:
        return ``;
    }
  };

  const returnAccordionDetails = (item: any) => {
    return (
      <Details
        item={item}
        config={config}
        eachUserDevsLoaded2={eachUserDevsLoaded2}
        eachUserDevs2={eachUserDevsLoaded2 && eachUserDevs2}
        manualControlData={manualControlData}
      />
    );
  };

  const deleteThisUser = async () => {
    let response: any = await deleteAnUser({
      id: currentUser?.account_id,
    });

    if (response?.statusCode !== 200) {
      Notification({
        title: 'Warning!',
        message: config?.notifications?.delete?.error,
        type: 'warning',
      });
    } else {
      Notification({
        title: 'Success!',
        message: config?.notifications?.delete?.success,
        type: 'success',
      });
    }

    await fetchUsersData();
  };

  const performAction = (id: string) => {
    switch (id) {
      case `delete-reg-user-confirmation`:
        return deleteThisUser();
      default:
        break;
    }
  };

  useEffect(() => {
    if (usersList && usersList.length > 0 && usersListIsLoaded) {
      setUsers(usersList);
      setLoadedUsers(true);
    }
  }, [usersList, usersListIsLoaded]);

  useEffect(() => {
    if (loadedUsers) {
      let ids: any = users.map((item: any) => item.account_id);

      //handle devs data
      const callDevs = async () => {
        await Promise.all(
          ids.map(async (id: any) => {
            const response = await getDevicesPerUser(id);

            if (response && response?.statusCode === 200) {
              fillListWithDevQtyAndUserId({
                id: id,
                list: response?.body?.data,
              });

              defDevsByUser2({ id: id, array: response?.body?.data });
            }
          }),
        );
      };

      callDevs();
    }
  }, [loadedUsers]);

  useEffect(() => {
    if (
      helpers.dataIsLoaded(
        usersList,
        usersListIsLoaded,
        userAndDevsQuantityList,
      )
    ) {
      setIDAndDevListLoaded(true);
    }
  }, [usersList, userAndDevsQuantityList, usersListIsLoaded]);

  useEffect(() => {
    if (successfullyDeleted) {
      fetchUsersData();
    }
  }, [successfullyDeleted]);

  useEffect(() => {
    fetchUsersData();
  }, []);

  useEffect(() => {
    if (
      usersList &&
      usersListIsLoaded &&
      eachUserDevs2 &&
      usersList.length === Object.keys(eachUserDevs2).length
    ) {
      markDevsLoaded2(true);
    }
  }, [usersList, eachUserDevs2, usersListIsLoaded]);

  useEffect(() => {
    if (orders.orderByLocation) {
      setCurrent(`location`);
    } else if (orders.orderByName) {
      setCurrent(`name`);
    } else if (orders.orderByCount) {
      setCurrent(`count`);
    } else if (
      orderedList &&
      usersListIsLoaded &&
      unorderedDefault.length > 0
    ) {
      setCurrent(``);
    }
  }, [unorderedDefault, orders, intendedCounter, counter]);

  useEffect(() => {
    if (eachUserDevsLoaded2) {
      let mcInformation: any = {};
      let response: any = {};

      Object.entries(eachUserDevs2).forEach(async (elem: any) => {
        //it only asks mc data if the user has devs:
        if (elem[1].length > 0) {
          mcInformation[elem[0]] = { devs: [] };

          elem[1].forEach(async (el: any) => {
            response = await getManualCtrlDetailsAdmin({
              device_id: el.device_id,
              id: elem[0],
            });

            if (response?.statusCode !== 200) {
              return;
            } else {
              if (
                response &&
                response?.body &&
                response?.body?.data &&
                response?.body?.data?.message
              ) {
                mcInformation[elem[0]].devs.push({
                  system_id: el.device_id,
                  system_name: el?.device_name,
                  config: response?.body?.data,
                });
              } else {
                mcInformation[elem[0]].devs.push({
                  system_id: response?.body?.data?.system_id,
                  system_name: el?.device_name,
                  config: response?.body?.data,
                });
              }
            }
          });
        }
      });

      if (mcInformation) {
        setManualControlData(mcInformation);
      }
    }
  }, [eachUserDevsLoaded2]);

  useEffect(() => {
    setEnteredInUserPage(false);
    return () => {};
  }, []);

  return (
    <Template id="dashboard" title={config.strings.pageTitle}>
      <div ref={dashboardRef}>
        {loadingErrors &&
        loadingErrors?.usersListErr &&
        loadingErrors?.usersListErr?.length > 0 ? (
          <div id="errors-display--2">
            <div className="alert alert-info" role="alert">
              <MdErrorOutline className="alert-icon" />
              <span className="lead err-string">
                {loadingErrors?.usersListErr}
              </span>
            </div>
          </div>
        ) : (
          <List
            rowClasses={config.rowClasses}
            id="users-list-data--01"
            list={usersList}
            loaded={usersListIsLoaded}
            itemsPerPage={10}
            manageListOrder={handleCounterAndListOrder}
            orderedList={orderedList}
            orders={orders}
            sectionCounter={intendedCounter}
            sectionCount={counter}
            updateOrderState={updateOrderState}
            incremented={incremented}
            current={current}
            unorderedDefault={unorderedDefault}
            handles={{
              handleView,
            }}
            config={config}
            firstFun={(item: any) => {
              handleView(currentUser);
            }}
            setItem={(item: any) => {
              setCurrentUser(item);
            }}
            retrieveColValue={retrieveColValue}
            returnAccordionDetails={returnAccordionDetails}
            helperOrderData={userAndDevsQuantityList}
          />
        )}
      </div>
      <Modal
        config={config}
        id={`delete-reg-user-confirmation`}
        title={config.modal.delete.title}
        icon={<MdDelete className="edit" />}
        body={config?.modalStrings?.aboutToDel}
        onClick={() => performAction(`delete-reg-user-confirmation`)}
        modalMustClose={modalMustClose}
        handleModalState={handleModalState}
        label={config?.modalStrings?.labelOK}
        className={`small-button nok`}
        bodyClass={`alert alert-danger`}
        sureToProceed={config?.modalStrings?.sureToProceed}
        hasConfirmBtn={true}
        warning={config?.modalStrings?.noUndo}
      />
    </Template>
  );
};

function mapStateToProps(state: any) {
  const { adminUsersState } = state;
  return {
    currentUser: adminUsersState.currentUser,
    usersList: adminUsersState.usersList,
    usersListIsLoaded: adminUsersState.usersListIsLoaded,
    userAndDevsQuantityList: adminUsersState.userAndDevsQuantityList,
    loadingErrors: adminUsersState.loadingErrors,
    successfullyDeleted: adminUsersState.successfullyDeleted,
    orders: adminUsersState.orders,
    intendedCounter: adminUsersState.intendedCounter,
    counter: adminUsersState.counter,
    orderedList: adminUsersState.orderedList,
    modalMustClose: adminUsersState.modalMustClose,
    eachUserDevs2: adminUsersState.eachUserDevs2,
    eachUserDevsLoaded2: adminUsersState.eachUserDevsLoaded2,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { loader, adminUsersState, adminSectionsState } = dispatch;
  return {
    loading: {
      start: loader.startLoader,
      stop: loader.stopLoader,
    },
    getUsersList: adminUsersState.getUsersList,
    setCurrentUser: adminUsersState.setCurrentUser,
    getDevicesPerUser: adminUsersState.getDevicesPerUser,
    fillListWithDevQtyAndUserId: adminUsersState.fillListWithDevQtyAndUserId,
    setIDAndDevListLoaded: adminUsersState.setIDAndDevListLoaded,
    updateOrderState: adminUsersState.updateOrderState,
    handleCounterAndListOrder: adminUsersState.handleCounterAndListOrder,
    getManualCtrlDetailsAdmin: adminSectionsState.getManualCtrlDetailsAdmin,
    handleModalState: adminUsersState.handleModalState,
    deleteAnUser: adminUsersState.deleteAnUser,
    handleErr: adminUsersState.handleErr,
    defUser: adminUsersState.defUser,
    setEnteredInUserPage: adminUsersState.setEnteredInUserPage,
    defDevsByUser2: adminUsersState.defDevsByUser2,
    markDevsLoaded2: adminUsersState.markDevsLoaded2,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardAdmin);
