import React from 'react';
import config from './config';
import moment from 'moment-timezone';
import { Notification } from 'business/modules/admin/common';

const formatDate = (date: any, key: string) => {
  if (key === `createdAt` || key === `updatedAt`) {
    return date ? moment(new Date(date)).format('MM-DD-YYYY, hh:mm A') : '';
  }
};

const returnLabel = (key: string) => {
  return (
    <span className="badge badge-info spec-info-bdg">
      {transformKeyString(key)}
    </span>
  );
};

const transformKeyString = (key: string) => {
  if (config.keys && config.keys.hasOwnProperty(key)) {
    return config.keys[key as keyof typeof config.keys];
  }
};

const filterRelevantProps = (compItem: any) => {
  let filteredObject: any = {};
  if (Object.keys(compItem).length > 0) {
    let filteredPropsArray = Object.entries(compItem).filter(
      (item: any, val: any) => {
        return (
          item[0] !== 'company_id' && item[0] !== '_id' && item[0] !== '__v'
        );
      },
    );
    filteredPropsArray.forEach((item: any) => {
      filteredObject[item[0]] = item[1];
    });
  }
  return filteredObject;
};

const keyIsDate = (key: string) => {
  return key === `createdAt` || key === `updatedAt` ? true : false;
};

const copyContent = async (copyTxtToClip: any) => {
  try {
    await navigator.clipboard.writeText(copyTxtToClip);
  } catch (err) {
    //TODO: handle error copying to clipboard
  }
};

const copyToClipboard = (item: any, id: string) => {
  copyContent(item);

  Notification({
    title: 'Success',
    message: 'Code copied to clipboard',
    type: 'success',
  });
};

export const functions = {
  formatDate: formatDate,
  returnLabel: returnLabel,
  filterRelevantProps: filterRelevantProps,
  keyIsDate: keyIsDate,
  copyToClipboard: copyToClipboard,
};
