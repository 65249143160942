import React from 'react';
import { Route, Switch } from 'react-router-dom';
import ClientHomeRoutes from '../modules/clientAPI';



const ClientRoutes: React.FC = ({ ...props }) => {
  return (
    <Switch>
      <Route
        path="/client"
        component={ClientHomeRoutes}
      />
    </Switch>
  );
};

export default ClientRoutes;
