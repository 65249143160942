export default {
  strings: {
    title: 'Indoor Air Quality',
    stLabel: '@indoor-air::',
    noFoobots:
      'Connect an indoor air quality sensor and manage the air quality in your home.',
  },
  alerts: {
    save: {
      question:
        'Are you sure you want to save the new Indoor Air configuration?',
      btn: {
        confirm: 'Yes, save it!',
      },
    },
    warning: {
      noSensor: 'Please configure the sensor in "System Settings". You can click on the "Setup" button and you will go to the configuration page.',
      btn: {
        setup: 'Setup Sensor...'
      }
    }
  },
  notifications: {
    update: {
      success: 'Indoor Air setup saved successfully!',
    },
  },
  formats: {
    ppm: '0,0',
    ugm3: '0,0',
    ppb: '0,0',
  },
  model: {
    max: -101,
    min: -101,
    current: 0,
    init: false,
  },
  ckModel: {
    exct: false,
    dmst: false,
    frcn: false,
    ahuf: false,
  },
  defaults: {
    maxs: {
      pm25: 140,
      co2: 1500,
      tVoc: 1500,
    },
  },
  sliders_defaults: {
    pm25: 150,
    co2: 750,
    tVoc: 500,
  },
  sliderValues: {
    pm25: {
      max: 500,
      min: 0,
    },
    co2: {
      max: 4000,
      min: 0,
    },
    tVoc: {
      max: 2000,
      min: 0,
    },
  },
};
