export default {
  strings: {
    title: 'SIMPLE VENTILATION SOLUTIONS',
    subtitle: 'Get to know our services',
    btnTitle: 'KNOW MORE',
    ventTitle: 'smart ventilation',
    mobTitle: 'Mobile APP',
    supportTitle: 'Customer Support',
  },
  stores: {
    google: 'https://play.google.com/store/apps/details?id=com.aircycler',
    apple: 'https://apps.apple.com/us/app/aircycler/id1564316038',
  },
}
