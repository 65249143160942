import React from 'react';
import { HoursOfOperationProps } from './interface';
import { functions } from './helpers';
import './styles.scss';

const HoursOfOperation: React.FC<HoursOfOperationProps> = ({
  hoursOfOp,
  hours,
  disabled,
  onChangeValues,
  errors,
  systemDetails,
  timeToStartStop,
  loadedSysDetails,
}) => {
  const returnVal = (elem: any) => {
    return elem[0] === 'hourStart'
      ? timeToStartStop.hourStart
      : elem[0] === 'hourStop'
      ? timeToStartStop.hourStop
      : '';
  };

  const returnPlaceholderValue = (
    elem: string,
    hasVentConnect?: boolean,
    primaryCont?: string,
  ) => {
    if (loadedSysDetails && Object.keys(systemDetails).length > 0) {
      functions.handleElemPlaceholder(
        elem,
        hasVentConnect,
        primaryCont,
        systemDetails,
      );
    } else return '';
  };

  return (
    <div className="row hours-of-operation-fields">
      {hoursOfOp &&
        Object.entries(hours).map((elem: any, index: number) => {
          return (
            <div className="col-4 system-details--col" key={index}>
              <div className={`row styles-row1 mrg`}>
                <div className={`col-auto additional-label stronger-ft`}>
                  <span className="lead smaller-font styles-lead-span-2- stronger-ft">
                    {functions.returnPlaceHolder(elem[0])}
                  </span>
                </div>
              </div>

              <input
                disabled={disabled}
                type={functions.checkIfNum(elem[0])}
                className={`form-control ${
                  errors && errors[elem[0]] ? `error` : ``
                }`}
                placeholder={returnPlaceholderValue(elem[0])}
                onChange={onChangeValues}
                value={returnVal(elem)}
                name={elem[0]}
              />
              <div className="row handle-err-row">
                <span className="handle-err">
                  {(errors && errors[elem[0]]) || ''}
                </span>
              </div>
            </div>
          );
        })}
    </div>
  );
};

export default HoursOfOperation;
