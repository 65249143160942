import React, { Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import config from './config';
import { requestSections, treeview, snippets } from './config';
import { functions } from './helpers';
import {
  PageSection,
  ListItem,
  Navbar,
  CodeBlock,
  TooltipView,
  Modal,
  CodeSnippet,
} from './components';
import CustomizedTreeView from './components/Treeview';
import { FaRegCopy } from 'react-icons/fa';
import './styles.scss';

interface ApiDocsPageProps {
  currentLanguage: string;
}

const ApiDocsPage: React.FC<ApiDocsPageProps> = ({ currentLanguage }) => {
  const getMethod = (method: string) => {
    switch (method) {
      case 'DELETE':
        return `req-delete-method`;
      case 'POST':
        return `req-post-method`;
      case 'PUT':
        return `req-put-method`;
      case 'GET':
        return `req-get-method`;
      default:
        return '';
    }
  };

  const copyContent = async (copyTxtToClip: any) => {
    try {
      await navigator.clipboard.writeText(copyTxtToClip);
    } catch (err) {
      //handle error copying to clipboard
    }
  };

  const copyToClipboard = (item: any, id: string) => {
    copyContent(item);
  };

  const accessValues = (id: string) => {
    switch (id) {
      case 'client-session-auth':
        return 'auth';

      case 'register-user':
      case 'list-user':
      case 'show-users':
      case 'update-users':
      case 'delete-users':
      case 'generate-one-time-code':
      case 'login-doc':
      case 'refresh-token-doc':
        return 'users';

      case 'create-system':
      case 'list-systems':
      case 'show-system-details':
      case 'delete-system-doc':
      case 'update-system-doc':
        return 'systems';

      case 'show-sys-settings-doc':
      case 'create-sys-settings-doc':
      case 'update-sys-settings-doc':
      case 'show-sys-settings-doc':
        return 'system-settings';
    }
  };

  return (
    <div id="documentation-page-screen">
      <Navbar />
      {/* <div className="container-fluid pdg mh-70vh"> */}
      <div className="container-fluid  pdg mh-70vh">
        <div className="row flex-xl-nowrap mlmr0">
          <div className="col-xs-12 col-sm-12 col-md-3 col-lg-2 left--section">
            <div className="row page-row pdg">
              <div className="col-12   dev-label">
                <span className="developer-label">AirCycler API</span>
              </div>
            </div>
            <div className="row page-row pdg">
              {Object.entries(config?.sidebarOpts).map(
                ([key, value], index) => {
                  return (
                    <div
                      className="col-12 a-option navbar-a-option"
                      key={index}
                    >
                      <li className="side-list" key={index}>
                        <a
                          href={value.href}
                          className="lead a--link left-links"
                        >
                          <span> {value.str}</span>
                        </a>
                      </li>
                    </div>
                  );
                },
              )}

              <div id="documentation-treeview">
                {treeview.map((treeItem: any, index: number) => (
                  <CustomizedTreeView
                    key={index}
                    requests={treeItem?.requests}
                    mainFolder={
                      <a
                        href={treeItem?.mainFolder?.href}
                        className="request-link-spec-styles"
                      >
                        {treeItem?.mainFolder?.str}
                      </a>
                    }
                  />
                ))}
              </div>
            </div>
          </div>

          <div className="col-xs-12 col-sm-12 col-md-9 col-lg-10 middle-and-right">
            <div className="container-fluid mid--right-cont">
              <div className="row">
                <div className="col-sm-6">
                  <div className="card card-middle-section">
                    <div
                      className="card-body card-body-middle-section1"
                      id="back-to-the-top"
                    >
                      <h3 className="header-middlesection lead text-uppercase">
                        AirCycler Developer API
                      </h3>
                      <h6 className="card-title below-title">
                        Table of Contents
                      </h6>

                      {Object.entries(config?.sidebarOpts).map(
                        ([key, value], index) => {
                          return (
                            <div className="col-12 a-option" key={index}>
                              <ListItem
                                ulKey={index}
                                href={value.href}
                                liValue={value.str}
                                liClassName={`lead a--link`}
                              />

                              {Object.keys(value.internalData).length > 0 &&
                                value.internalData.map(
                                  (elem: any, index: number) => (
                                    <ListItem
                                      ulKey={index}
                                      href={elem.href}
                                      liValue={elem.str}
                                      liClassName={`lead a--link internal-li`}
                                    />
                                  ),
                                )}

                              {Object.keys(value.additionalData).length > 0 &&
                                value.additionalData.map(
                                  (elem: any, index: number) => (
                                    <ListItem
                                      ulKey={index}
                                      href={elem.href}
                                      liValue={elem.str}
                                      liClassName={`lead a--link internal-li-2`}
                                    />
                                  ),
                                )}
                            </div>
                          );
                        },
                      )}
                    </div>
                    {Object.entries(config?.sidebarOpts).map(
                      ([key, value], index) => {
                        return (
                          <div className="col-12 a-option" key={index}>
                            <PageSection
                              className={`card sections-card1`}
                              id={value.href.slice(1)}
                              cardTitle={value.str}
                              href={`#back-to-the-top`}
                              aTitle={`Back to the top`}
                              p={
                                config?.textsStrings[
                                  value.href.slice(
                                    1,
                                  ) as keyof typeof config.textsStrings
                                ]
                              }
                            />

                            {Object.keys(value.internalData).length > 0 &&
                              value.internalData.map(
                                (elem: any, index: number) => (
                                  <PageSection
                                    className={`card sections-card1`}
                                    id={elem.href.slice(1)}
                                    cardTitle={elem.str}
                                    href={`#back-to-the-top`}
                                    aTitle={`Back to the top`}
                                    p={
                                      config.textsStrings[
                                        value.href.slice(
                                          1,
                                        ) as keyof typeof config.textsStrings
                                      ]
                                    }
                                  />
                                ),
                              )}

                            {Object.keys(value.additionalData).length > 0 &&
                              value.additionalData.map(
                                (elem: any, index: number) => (
                                  <PageSection
                                    className={`card sections-card1`}
                                    id={elem.href.slice(1)}
                                    cardTitle={elem.str}
                                    href={`#back-to-the-top`}
                                    aTitle={`Back to the top`}
                                    p={
                                      config.textsStrings[
                                        value.href.slice(
                                          1,
                                        ) as keyof typeof config.textsStrings
                                      ]
                                    }
                                  />
                                ),
                              )}
                          </div>
                        );
                      },
                    )}
                  </div>
                </div>
                <div className="col-sm-6 dark-column">
                  <h1></h1>
                </div>
              </div>

              {requestSections.map((element: any, idx: number) => {
                return (
                  <>
                    <div className="row">
                      <div className="col-6 pdg">
                        <PageSection
                          className={`card sections-card1`}
                          id={element.href.slice(1)}
                          cardTitle={element.str}
                          href={`#back-to-the-top`}
                          aTitle={`Back to the top`}
                          p={
                            config?.textsStrings[
                              element.href.slice(
                                1,
                              ) as keyof typeof config.textsStrings
                            ]
                          }
                        />
                      </div>
                    </div>

                    {element &&
                      element.hasOwnProperty('internalData') &&
                      element?.internalData?.length > 0 &&
                      Object.entries(element?.internalData).map<any>(
                        ([key, value]: [any, any], index) => {
                          return (
                            <div className="container-fluid pdg">
                              <div
                                className="row mlmr0"
                                key={index}
                                id={value.href.slice(1)}
                              >
                                <div
                                  className={`col pdg ${value.href.slice(
                                    1,
                                  )}--height`}
                                >
                                  <div className="row   title--height">
                                    <div className="col-auto pdg method-designation">
                                      <span
                                        className={getMethod(value?.reqMethod)}
                                      >
                                        {value?.reqMethod}
                                      </span>
                                    </div>
                                    <div className="col-auto pl-8">
                                      <span className="req-description">
                                        {value?.reqDescription}
                                      </span>
                                    </div>
                                  </div>

                                  <div className="row mrtop05">
                                    <div className="col-11 pdg endpoint-information">
                                      <span className="endpoint-span">
                                        {value?.endpoint}
                                      </span>
                                    </div>
                                    <div className="col-1 endpoint-copy">
                                      <TooltipView
                                        title="Copy to clipboard"
                                        id="api-client-tooltip"
                                      >
                                        <span>
                                          <button
                                            data-backdrop="false"
                                            type="button"
                                            className="btn btn-fire-api-modal"
                                            data-toggle="modal"
                                            data-target="#copy-to-clip-alert"
                                          >
                                            <FaRegCopy
                                              className="copy-to-clip-icon"
                                              onClick={() =>
                                                copyToClipboard(
                                                  value?.endpoint,
                                                  'close--alert--clipboard',
                                                )
                                              }
                                            />
                                          </button>
                                        </span>
                                      </TooltipView>
                                    </div>
                                  </div>

                                  <div className="row mlmr0">{value?.text}</div>
                                </div>
                                <div className="row small-screens-code">
                                  <CodeBlock
                                    id={`${value?.href.slice(1)}-small`}
                                    className=""
                                    req={<></>}
                                    res={<></>}
                                  />
                                </div>
                                <div
                                  className="col pdg"
                                  style={{
                                    backgroundColor: 'rgb(40, 44, 52)',
                                    paddingBottom: '1rem',
                                  }}
                                >
                                  <div className="request-detailed-info black-backgrd">
                                    <Fragment key={index}>
                                      <CodeBlock
                                        id={snippets[value.href.slice(1)].id}
                                        className={
                                          snippets[value.href.slice(1)]
                                            .className
                                        }
                                        req={
                                          snippets[value.href.slice(1)].req[
                                            functions.handleLangProp(
                                              currentLanguage,
                                            )
                                          ]
                                        }
                                        res={snippets[value.href.slice(1)].res}
                                      />
                                    </Fragment>
                                  </div>
                                </div>
                              </div>
                            </div>
                          );
                        },
                      )}
                  </>
                );
              })}
            </div>
          </div>

          <Modal
            id={`copy-to-clip-alert`}
            mdDialogClass={`modal-dialog modal-dialog-centered`}
            modalTitle={`Copied to clipboard`}
            externalClass={`copy-to-clip-class-mdl`}
            content={
              <span className="lead ctc-small-lead">
                Endpoint successfully copied to clipboard.
              </span>
            }
          />

          <Modal
            id={`expand-req`}
            mdDialogClass={`modal-dialog modal-dialog-centered`}
            modalTitle={`Request`}
            externalClass={``}
            content={<></>}
          />
        </div>
      </div>
    </div>
  );
};

function mapStateToProps(state: any) {
  const { apiState }: any = state || {};
  return {
    currentLanguage: apiState.currentLanguage,
  };
}

function mapDispatchToProps(dispatch: any) {
  const { apiState }: any = dispatch || {};

  return {
    updateLanguageChoice: apiState.updateLanguageChoice,
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ApiDocsPage);
