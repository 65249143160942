import React from 'react';
import { BsArrowUp, BsArrowDown } from 'react-icons/bs';

const renderIcon = (col: string) => {
  switch (col) {
    case 'Requester':
    case 'Request date':
    case 'Status':
    case 'Name':
    case 'Location':
    case 'Code':
    case 'Installers':
    case 'Devices count':
    case 'Date':
    case 'Command Date':
    case 'Log Date':
    case 'Log Time':
    case 'Log Events':
    case 'Users':
    case 'Devices':
    case 'Client ID':
    case 'Creation date':
    case 'Company':
    case 'E-mail':
    case 'Client User':
    case 'Client':
      return (
        <>
          <BsArrowUp className="order-icon-header" />
          <BsArrowDown className="order-icon-header" />
        </>
      );
    default:
      return ``;
  }
};

const returnProp = (col: string) => {
  switch (col) {
    case 'Requester':
    case 'Name':
      return `name`;
    case 'Request date':
    case 'Command Date':
    case 'Date':
    case 'Log Date':
      return `date`;
    case 'Log Time':
      return `time`;
    case 'Log Events':
      return `eventCount`;
    case 'Status':
      return `status`;
    case 'Location':
      return `location`;
    case 'Code':
      return `code`;
    case 'Installers':
      return `instCount`;
    case 'Devices count':
      return 'count';
    case 'Devices':
      return 'devs';
    case 'Events':
      return 'events';
    case 'Users':
      return 'email';
    case 'Company':
      return 'company';
    case 'E-mail':
      return 'email';
    case 'Client':
      return 'client';
    default:
      return '';
  }
};

export const helpers = {
  renderIcon: renderIcon,
  returnProp: returnProp,
};
